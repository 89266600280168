import Title from 'antd/es/typography/Title';
//const dotenv = require('dotenv');
import {useEffect, useState} from 'react';
import {fetchUserAttributes, FetchUserAttributesOutput} from 'aws-amplify/auth';

export const Dashboard = () => {
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    if (!currentUser) {
      fetchUserAttributes().then((currUser: FetchUserAttributesOutput) => {
        setCurrentUser(currUser);
        //console.log('Auth.currentUserInfo()', currUser.email);
      });
    }
  }, []);

  return (
    <Title>
      Dashboard in progress : {`\n`} {currentUser?.email}
    </Title>
  );
};
