// TODO: add user to get student's parent
export const updateProgram = `
mutation updateProgram ($id: ID!, $updatedAt: String!, $input: ProgramUpdate!) {
    updateProgram (id: $id, updatedAt: $updatedAt, input: $input) {
        id
        createdAt
        updatedAt
    }
}
`;

export const createProgram = `
mutation createProgram ($input: ProgramInsert!) {
    createProgram (input: $input) {
        id
        createdAt
        updatedAt
    }
}
`;
