import {useEffect, useState, useRef} from 'react';
import {
  Button,
  Col,
  Image,
  Input,
  Menu,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
} from 'antd';
import {get, isEqual} from 'lodash';

import Title from 'antd/es/typography/Title';

import {
  Conversation,
  Group,
  GroupsConnection,
  Membership,
  Session,
  SessionsConnection,
  Slot,
  Status,
  Student,
  Studentconversation,
} from '../../graphql/API';

import {GroupService} from './GroupService';
import {Link, useHistory} from 'react-router-dom';
import {SearchOutlined} from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import Highlighter from 'react-highlight-words';
import {daysOfTheWeek} from '../Instructor';
import moment from 'moment-timezone';
import ChatIcon from '../../assets/icons/chatt-icon.png';
import {
  fetchMessagesBygroupConversationId,
  setGroupConversations,
  setLastSeenOnline,
  subscribeToNewGroupMessages,
} from '../../redux/actions/user';
import {useDispatch, useSelector} from 'react-redux';

const groupService = new GroupService();

export const GroupTable = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [groups, setGroups] = useState<GroupsConnection>();
  const [todaySessions, setTodaySessions] = useState<SessionsConnection>();
  const [current, setCurrent] = useState(1);
  const [maxNumReachedPages, setMaxNumReachedPages] = useState(2);

  const [isError, setIsError] = useState<boolean>(false);
  const [searchedColumn, setSearchedColumn] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  const [filterIndex, setFilterIndex] = useState<number>(0);
  const statusColors = {
    TODO: '#f1f1f1f1',
    DOING: 'yellow',
    DONE: 'green',
    SUSPENDED: 'black',
  };

  const statusTxtColors = {
    TODO: 'black',
    DOING: 'black',
    DONE: 'white',
    SUSPENDED: 'white',
  };

  let searchInput = useRef();
  const dispatch = useDispatch();

  const groupConversations: Array<Conversation> = useSelector(
    (state: any) => state.user.groupConversations,
  );

  useEffect(() => {
    if (current && filterIndex === 3 && groups?.nextToken) {
      setIsError(false);
      setTodaySessions(null);
      // setIsLoading(true);
      groupService
        .getGroups(groups?.nextToken)
        .then((grops: GroupsConnection) => {
          const currentItems = groups?.items ?? [];
          setGroups({
            ...groups,
            ...grops,
            items: [...currentItems, ...grops.items],
          });
          //console.log('getGroups', grops);
        })
        .catch((error: any) => {
          setIsError(true);
          //console.log('Error ->', error);
        })
        .finally(() => {
          // setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const onChangeLogic = ({current: e}, size) => {
    //console.log('CURRRRR_sizeChangeLogic', e);
    //console.log('CURRRRR_sizeChangeLogic', maxNumReachedPages);

    if (e >= maxNumReachedPages) {
      setCurrent(e);
      setMaxNumReachedPages(e + 1);
    }
  };

  useEffect(() => {
    dispatch(setLastSeenOnline());

    groupService
      .getTodaySessions(null)
      .then((sess: SessionsConnection) => {
        // setGroups(sess);
        setTodaySessions(sess);
        //console.log('getSessionToday', sess);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsError(true);
        //console.log('Error ->', error);
      });
  }, []);

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{padding: 8}}>
        <Input
          ref={(node) => {
            // @ts-ignore
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchedColumn(dataIndex);
              setSearchText(selectedKeys[0]);
            }}>
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value: any, record: any) =>
      get(record, dataIndex)
        ? get(record, dataIndex)
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        // @ts-ignore
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text: any) =>
      isEqual(searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };

  const columns: any = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      render: (name: string) => (
        <Text style={{fontWeight: 'bold'}}>{name} </Text>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: 'Group Session Time',
      // dataIndex: 'slot',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (group: Group) => (
        <Text style={{fontWeight: 'bold'}}>
          {daysOfTheWeek[
            moment
              .tz(group?.slot?.startTime, 'HH:mm:ss', 'UTC')
              .day(group?.slot?.day)
              .clone()
              .tz('Africa/Cairo')
              .day()
          ]?.slice(0, 3)}
          {moment
            .tz(group?.slot?.startTime, 'HH', 'UTC')
            .clone()
            .tz('Africa/Cairo')
            .format('hh:mm A')}
          {' EGY'}{' '}
        </Text>
      ),
    },

    {
      title: 'Language',
      dataIndex: ['program', 'name_i18n'],
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name_i18n: string) => (
        <Text style={{fontWeight: 'bold'}}>{JSON.parse(name_i18n)?.en} </Text>
      ),
    },
    {
      title: 'Level',
      dataIndex: ['level', 'number'],
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (number: string) => (
        <Text style={{fontWeight: 'bold'}}>{number} </Text>
      ),
      sorter: (a: any, b: any) => {
        return a.level.number === b.level.number;
      },
    },
    {
      title: 'Instructor',
      key: 'instructor',
      ...getColumnSearchProps(['instructor', 'name']),
      dataIndex: ['instructor', 'name'],
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.instructor.name.localeCompare(b.instructor.name);
      },
    },
    {
      title: 'Next Session Date',
      key: 'startdate',
      dataIndex: 'sessions',

      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'ascend',
      render: (sessions: Session[], _, index) => {
        const nextTodoSession = sessions?.find(
          (sess) => sess?.status === Status.TODO,
        );
        // //console.log('nextTodoSession', nextTodoSession);
        return (
          <Text
            style={{
              fontWeight: 'bold',
              // width: '100px',
              textAlign: 'center',
              display: 'table-cell',
            }}>
            {nextTodoSession?.startDateTime
              ? moment(
                  nextTodoSession.startDateTime,
                  'yyyy-MM-DD HH:mm:ss',
                ).format('YYYY-MM-DD')
              : 'Not started yet'}{' '}
          </Text>
        );
      },
      sorter: (a: Group, b: Group) => {
        const aNextTodoSession = a.sessions?.find(
          (sess) => sess?.status === Status.TODO,
        );

        const bNextTodoSession = b.sessions?.find(
          (sess) => sess?.status === Status.TODO,
        );

        return aNextTodoSession?.startDateTime?.localeCompare(
          bNextTodoSession?.startDateTime,
        );
      },
    },
    {
      title: 'Sessions',
      key: 'session',
      dataIndex: 'sessions',
      render: (sessions: Session[], _, index) => {
        const sessionsDone = sessions?.filter(
          (sess) => sess?.status !== Status.TODO,
        );
        return (
          <Text style={{fontWeight: 'bold'}}>
            {sessionsDone?.length + '/' + sessions?.length}
          </Text>
        );
      },
    },
    {
      title: 'Students',
      key: 'student',
      dataIndex: 'memberships',
      render: (memberships: Membership[], _, index) => (
        <Text style={{fontWeight: 'bold'}}>{memberships.length}</Text>
      ),
    },
    {
      title: 'Chat',
      dataIndex: 'conversation',
      sorter: (a: any, b: any) => {
        return a.language.country.localeCompare(b.language.country);
      },
      render: (conversation: Conversation) => (
        <Popconfirm
          title="Do you want to observe a group chat?"
          onConfirm={() => {
            //console.log('conversation', conversation);
            const nextToken = null;
            dispatch(setGroupConversations([conversation]));
            dispatch(subscribeToNewGroupMessages());
            dispatch(
              fetchMessagesBygroupConversationId(conversation.id, nextToken),
            );
          }}
          style={{
            border: 'none',
            width: 40,
            height: 40,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            padding: 8,
            backgroundColor: 'transparent',
          }}>
          <Image
            preview={false}
            src={ChatIcon}
            alt=""
            onClick={() => {}}
            style={{
              width: 40,
              height: 40,
              padding: 2,
            }}
          />
        </Popconfirm>
      ),
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      render: (groupId: string) => (
        <Button
          style={{
            width: '100%',
            height: 37,
            borderRadius: 5,
            backgroundColor: '#F2406A',
          }}>
          <Link
            style={{
              fontSize: 14,
              color: 'white',
              fontWeight: 'bold',
            }}
            to={{
              pathname: '/group/' + groupId + '/edit',
              state: {groupId},
            }}>
            Edit
          </Link>
        </Button>
      ),
    },
  ];

  const columnsTodaySessions: any = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      render: (name: string) => (
        <Text style={{fontWeight: 'bold'}}>{name} </Text>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: 'Group Session Time',
      // dataIndex: 'slot',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (session: Session) => (
        <Text style={{fontWeight: 'bold'}}>
          {daysOfTheWeek[
            moment
              .tz(session.slot.startTime, 'HH:mm:ss', 'UTC')
              .day(session.slot.day)
              .clone()
              .tz('Africa/Cairo')
              .day()
          ]?.slice(0, 3)}
          {moment
            .tz(session?.slot?.startTime, 'HH', 'UTC')
            .clone()
            .tz('Africa/Cairo')
            .format('hh:mm A')}
          {' EGY'}{' '}
        </Text>
      ),
    },

    {
      title: 'Language',
      dataIndex: ['group', 'program', 'name_i18n'],
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name_i18n: string) => (
        <Text style={{fontWeight: 'bold'}}>{JSON.parse(name_i18n)?.en} </Text>
      ),
    },
    {
      title: 'Level',
      dataIndex: ['level', 'number'],
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (number: string) => (
        <Text style={{fontWeight: 'bold'}}>{number} </Text>
      ),
      sorter: (a: any, b: any) => {
        return a.level.number === b.level.number;
      },
    },
    {
      title: 'Instructor',
      key: 'instructor',
      ...getColumnSearchProps(['instructor', 'name']),
      dataIndex: ['instructor', 'name'],
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.instructor.name.localeCompare(b.instructor.name);
      },
    },
    {
      title: 'Today Session Time',
      // dataIndex: 'slot',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (session: Session) => (
        <Text style={{fontWeight: 'bold'}}>
          {daysOfTheWeek[
            moment
              .tz(session?.startDateTime, 'YYYY-MM-DD HH:mm:ss', 'UTC')
              .clone()
              .tz('Africa/Cairo')
              .day()
          ]?.slice(0, 3)}
          {moment
            .tz(session?.startTime, 'HH', 'UTC')
            .clone()
            .tz('Africa/Cairo')
            .format('hh:mm A')}
          {' EGY'}{' '}
        </Text>
      ),
    },

    {
      title: 'Next Session Date',
      key: 'startDateTime',
      dataIndex: 'startDateTime',

      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'ascend',
      render: (startDateTime: string) => {
        // //console.log('nextTodoSession', nextTodoSession);
        return (
          <Text
            style={{
              fontWeight: 'bold',
              // width: '100px',
              textAlign: 'center',
              display: 'table-cell',
            }}>
            {startDateTime
              ? moment
                  .tz(startDateTime, 'yyyy-MM-DD HH:mm:ss', 'UTC')
                  .clone()
                  .tz('Africa/Cairo')
                  .format('YYYY-MM-DD')
              : 'Not started yet'}{' '}
          </Text>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status: string) => (
        <Text
          style={{
            fontWeight: 'bold',
            backgroundColor: statusColors[status],
            padding: '8px',
            color: statusTxtColors[status],
          }}>
          {status}{' '}
        </Text>
      ),
    },

    {
      title: 'Students',
      key: 'student',
      dataIndex: ['memberships'],
      render: (memberships: Membership[], _, index) => (
        <Text style={{fontWeight: 'bold'}}>{memberships.length}</Text>
      ),
    },
    {
      title: 'Chat',
      dataIndex: ['group', 'conversation'],
      sorter: (a: any, b: any) => {
        return a.language.country.localeCompare(b.language.country);
      },
      render: (conversation: Conversation) => (
        <Popconfirm
          title="Do you want to observe a group chat?"
          onConfirm={() => {
            //console.log('conversation', conversation);
            const nextToken = null;
            dispatch(setGroupConversations([conversation]));
            dispatch(subscribeToNewGroupMessages());
            dispatch(
              fetchMessagesBygroupConversationId(conversation.id, nextToken),
            );
          }}
          style={{
            border: 'none',
            width: 40,
            height: 40,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            padding: 8,
            backgroundColor: 'transparent',
          }}>
          <Image
            preview={false}
            src={ChatIcon}
            alt=""
            onClick={() => {}}
            style={{
              width: 40,
              height: 40,
              padding: 2,
            }}
          />
        </Popconfirm>
      ),
    },
    {
      title: '',
      key: 'id',
      dataIndex: ['group', 'id'],
      render: (groupId: string) => (
        <Button
          style={{
            width: '100%',
            height: 37,
            borderRadius: 5,
            backgroundColor: '#F2406A',
          }}>
          <Link
            style={{
              fontSize: 14,
              color: 'white',
              fontWeight: 'bold',
            }}
            to={{
              pathname: '/group/' + groupId + '/edit',
              state: {groupId},
            }}>
            Edit
          </Link>
        </Button>
      ),
    },
  ];
  const showResultFilterByIndex = (index) => {
    switch (index) {
      case 0:
        setIsLoading(true);
        setIsError(false);
        setTodaySessions(null);
        groupService
          .getTodaySessions(null)
          .then((sess: SessionsConnection) => {
            // setGroups(sess);
            setTodaySessions(sess);
            //console.log('getSessionToday', sess);
            setIsLoading(false);
          })
          .catch((error: any) => {
            setIsError(true);
            //console.log('Error ->', error);
          });
        break;
      case 1:
        setIsError(false);
        setTodaySessions(null);
        setIsLoading(true);
        groupService
          .getFinishedGroups(null)
          .then((grops: GroupsConnection) => {
            setGroups(grops);
            //console.log('getGroups', grops);
            setIsLoading(false);
          })
          .catch((error: any) => {
            setIsError(true);
            //console.log('Error ->', error);
          });
        break;
      case 2:
        setIsError(false);
        setTodaySessions(null);
        setIsLoading(true);
        groupService
          .getActiveGroups(null)
          .then((grops: GroupsConnection) => {
            setGroups(grops);
            //console.log('getGroups', grops);
            setIsLoading(false);
          })
          .catch((error: any) => {
            setIsError(true);
            //console.log('Error ->', error);
          });
        break;

      case 3:
        setIsError(false);
        setTodaySessions(null);
        setIsLoading(true);
        groupService
          .getGroups(null)
          .then((grops: GroupsConnection) => {
            setGroups(grops);
            //console.log('getGroups', grops);
            setIsLoading(false);
          })
          .catch((error: any) => {
            setIsError(true);
            //console.log('Error ->', error);
          });
        break;
    }
  };

  return (
    <div style={{padding: 10, backgroundColor: '#fff', height: '100%'}}>
      <Row>
        <Button
          onClick={() => {
            setFilterIndex(0);
            showResultFilterByIndex(0);
          }}
          style={{
            width: '15%',
            height: 55,
            // borderRadius: 10,
            backgroundColor: filterIndex !== 0 ? '#00142A' : '#40B2F2',
            marginBottom: '2.5%',
            color: 'white',
          }}>
          Today's Sessions
        </Button>

        <Button
          onClick={() => {
            setFilterIndex(1);
            showResultFilterByIndex(1);
          }}
          style={{
            width: '15%',
            minHeight: 55,
            // borderRadius: 10,
            backgroundColor: filterIndex !== 1 ? '#00142A' : '#40B2F2',
            marginBottom: '2.5%',
            color: 'white',
          }}>
          Finished Group
        </Button>

        <Button
          onClick={() => {
            setFilterIndex(2);
            showResultFilterByIndex(2);
          }}
          style={{
            width: '15%',
            height: 55,
            // borderRadius: 10,
            backgroundColor: filterIndex !== 2 ? '#00142A' : '#40B2F2',
            marginBottom: '2.5%',
            color: 'white',
          }}>
          Active Groups
        </Button>

        <Button
          onClick={() => {
            setFilterIndex(3);
            showResultFilterByIndex(3);
          }}
          style={{
            width: '15%',
            height: 55,
            // borderRadius: 10,
            backgroundColor: filterIndex !== 3 ? '#00142A' : '#40B2F2',
            marginBottom: '2.5%',
            color: 'white',
          }}>
          All Groups
        </Button>
      </Row>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '5%',
          marginInlineEnd: 20,
        }}>
        <Title
          style={{
            textAlign: 'center',
            marginInlineStart: 20,
            fontSize: 25,
          }}>
          Groups:{' '}
          {filterIndex === 0
            ? "Today's Session"
            : filterIndex === 1
            ? 'Finished Groups'
            : filterIndex === 2
            ? 'Active Groups'
            : 'All Groups'}
        </Title>
        <Button
          style={{
            width: '15%',
            height: 60,
            borderRadius: 10,
            backgroundColor: '#40B2F2',
            marginBottom: '2.5%',
          }}>
          <Link
            style={{
              fontSize: 16,
              color: 'white',
            }}
            to={'/group/create'}>
            New Group
          </Link>
        </Button>
      </Row>

      {!isError && !isLoading && filterIndex === 0 && (
        <Table
          pagination={{defaultPageSize: 100}}
          columns={columnsTodaySessions}
          dataSource={todaySessions?.items}
        />
      )}

      {!isError && !isLoading && filterIndex !== 0 && (
        <Table
          pagination={{pageSizeOptions: ['100'], pageSize: 100}}
          current={current}
          defaultPageSize={100}
          columns={columns}
          dataSource={groups.items}
          onChange={onChangeLogic}
        />
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}

      {isError && <Title>Somthing went wrong ⚠️, refresh the page</Title>}
    </div>
  );
};
