import {IDiscountCodesService} from './IDiscountCodeService';
import {DiscountCodeRepository} from './DiscountCodeRepository';
import {
  DiscountcodeConnection,
  DiscountCodeInsert,
  SubscriptionsByDiscountCode,
} from '../../graphql/API';

export class DiscountCodeService implements IDiscountCodesService {
  discountCodeRepository: DiscountCodeRepository;
  constructor() {
    this.discountCodeRepository = new DiscountCodeRepository();
  }

  async createDiscountCodes(
    discountcodeInput: DiscountCodeInsert,
  ): Promise<any> {
    return await this.discountCodeRepository.createDiscountCodes(
      discountcodeInput,
    );
  }

  async getDiscountCodes(
    nextToken: string,
    searchValue?: string,
  ): Promise<DiscountcodeConnection> {
    return await this.discountCodeRepository.getDiscountCodes(
      nextToken,
      searchValue,
    );
  }

  async getDiscountCodesByPartner(
    nextToken: string,
    partnerName: string,
  ): Promise<DiscountcodeConnection> {
    return await this.discountCodeRepository.getDiscountCodesByPartner(
      nextToken,
      partnerName,
    );
  }

  async getSubscriptionsByDiscountCode(
    codeName: string,
  ): Promise<SubscriptionsByDiscountCode> {
    return await this.discountCodeRepository.getSubscriptionsByDiscountCode(
      codeName,
    );
  }
}
