import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import user from './user';

const userPersistConfig = {
  key: 'user',
  storage: storage,
  whitelist: ['user', 'isLoggedIn'],
};

export default combineReducers({
  user: persistReducer(userPersistConfig, user),
});
