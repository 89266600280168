export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan($id: ID!, $updatedAt: String!, $input: PlanUpdate!) {
    updatePlan(id: $id, updatedAt: $updatedAt, input: $input)
  }
`;

export const createPlan = /* GraphQL */ `
  mutation CreatePlan($input: PlanInsert!) {
    createPlan(input: $input)
  }
`;
