import {Button, Spin, Table} from 'antd';
import {useEffect, useState} from 'react';
import {Course, VideosessionsConnection} from '../../graphql/API';
import {Link, useHistory} from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import {CoursesService} from '../Courses';

const coursesService = new CoursesService();

export const VideoSessionTable = () => {
  const history = useHistory();
  const [videoSessions, setVideoSessions] = useState<VideosessionsConnection>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    coursesService
      .getVideoSessions()
      .then((resp: VideosessionsConnection) => {
        setIsLoading(false);
        console.log('videosessions: ', resp);
        setVideoSessions(resp);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);

  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    //console.log('params', pagination, filters, sorter, extra);
  };

  const columns = [
    {
      title: 'Video Session ID',
      dataIndex: 'id',
      sorter: (a: any, b: any) => {
        return a.id.localeCompare(b.id);
      },
      sortDirections: ['descend'],
      render: (videosessionId: string) => (
        <Link to={'/videosession/' + videosessionId + '/edit'}>
          {videosessionId}
        </Link>
      ),
    },
    {
      title: 'Name',
      defaultSortOrder: 'ascend',
      dataIndex: 'name',
      sorter: (a: any, b: any) => {
        return a.level?.name?.localeCompare(b?.level.name);
      },
    },
    {
      title: 'Duration minutes',
      defaultSortOrder: 'ascend',
      dataIndex: 'duration',
      sorter: (a: any, b: any) => {
        return a.level?.name?.localeCompare(b?.level.name);
      },
    },
    {
      title: 'Level',
      defaultSortOrder: 'ascend',
      dataIndex: ['level', 'name'],
      sorter: (a: any, b: any) => {
        return a.level?.name?.localeCompare(b?.level.name);
      },
    },

    {
      title: 'Course name',
      dataIndex: ['course', 'name'],
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: 'Course ID',
      dataIndex: ['course', 'id'],
      sorter: (a: any, b: any) => {
        return a.id.localeCompare(b.id);
      },
    },
  ];

  return (
    <>
      <Button
        size={'large'}
        style={{float: 'right', margin: 20}}
        onClick={() => {
          history.push('/videosession/create');
        }}>
        Create
      </Button>
      {!isError && !isLoading && (
        <Table
          pagination={{defaultPageSize: 100}}
          expandRowByClick={true}
          //@ts-ignore
          columns={columns}
          dataSource={videoSessions?.items}
          onChange={onChange}
        />
      )}

      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>video session fetching error ⚠️</Title>}
    </>
  );
};
