export const schools = /* GraphQL */ `
  query Schools(
    $filter: SchoolsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    schools(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        nameArabic
        name
      }
      nextToken
      scannedCount
    }
  }
`;
