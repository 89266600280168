export const instructors = /* GraphQL */ `
  query Instructors(
    $filter: InstructorsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    instructors(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        user {
          id
          createdAt
          updatedAt
          cognitoUserId
          country
          state
          whatsappNumber
          timeZone
        }
        name
        gender
        isNative
        isActive
        program {
          id
          createdAt
          updatedAt
          name
          name_i18n
          flag
          supportAi
          isActive
        }
        promo {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
          title_i18n
          subTitle_i18n
          position
        }
        rating
        sessionsCount
        speakingLanguages
      }
      nextToken
      scannedCount
    }
  }
`;

export const getInstructor = /* GraphQL */ `
  query Instructor($id: String!) {
    instructor(id: $id) {
      id
      createdAt
      updatedAt
      isNative
      isActive
      paymentMethod
      availabilities {
        id
        createdAt
        updatedAt
        startTime
        endTime
        day
        slots {
          id
          name

          startTime
          endTime
          status
          limit
          current
          day
        }
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
      }
      name
      gender
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      promo {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      rating
      sessionsCount
      speakingLanguages
    }
  }
`;

export const sessions = /* GraphQL */ `
  query Sessions(
    $filter: SessionsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    sessions(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        rtcUserToken
        rtcInstructorToken
        rtcShareScreenToken
        zoomUrl
        instructor {
          id
        }
      }
      nextToken
      scannedCount
    }
  }
`;
