import {useEffect, useState} from 'react';

import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import {useHistory, useLocation} from 'react-router-dom';
import {ChannelService} from './ChannelService';
import {SegmentInsert} from '../../graphql/API';
import moment from 'moment-timezone';

const segementService = new ChannelService();
const {RangePicker} = DatePicker;

export const SegmentCreate = () => {
  const history = useHistory();

  const {Title, Text} = Typography;

  const [isError, setIsError] = useState<boolean>(false);
  const [isloading, setIsLoading] = useState<boolean>(false);

  const location: {
    state: any;
    hash: any;
    search: any;
    pathname: any;
  } = useLocation();

  const [segmentInput, setSegmentInput] = useState<SegmentInsert>({
    startDateTime: null,
    endDateTime: null,
    typeLogic:
      'GET_ACTIVE_MEMBERSHIPS_SUBS_BET_RANGE_PERIOD_AND_NOT_SUBS_AGAIN',
    segmentName: '',
  });

  console.log('segment', segmentInput);

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Create Segment</Title>

      <Text style={{display: 'block'}} strong={true}>
        Currenty default segment working on last active memberships and doesn't
        renew their subscriptions
      </Text>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Segment Name
              </Text>
              <Input
                placeholder={'Enter Segment Name'}
                onChange={(e) => {
                  setSegmentInput({
                    ...segmentInput,
                    segmentName: e.target.value,
                  });
                }}
                value={segmentInput?.segmentName}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Last activated memberships Range Segment
              </Text>

              <RangePicker
                style={{marginInlineEnd: 30}}
                showToday
                // disabledDate={disabledDate}
                // disabledTime={disabledRangeTime}
                // separator={'TO'}
                onChange={(e) => {
                  console.log('PICKER', e);
                  if (e?.length === 2) {
                    setSegmentInput({
                      ...segmentInput,
                      startDateTime: e[0].toISOString(),
                      endDateTime: e[1].toISOString(),
                    });
                  }
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [moment(), moment().add(1, 'day')],
                }}
                format="YYYY-MM-DD HH:mm:ss"
              />
            </div>
          </Col>
        </Row>
      </>

      <Button
        onClick={async () => {
          console.log('INCREATEEET', segmentInput);
          if (
            segmentInput.segmentName &&
            segmentInput?.endDateTime &&
            segmentInput?.startDateTime &&
            segmentInput.typeLogic
          ) {
            console.log('INCREATEEET_ID', segmentInput);
            setIsLoading(true);

            await segementService
              .createUserSegment(segmentInput)
              .then((e: any) => {
                setSegmentInput({
                  ...segmentInput,
                  segmentName: '',
                });
                history.go(0);
              })
              .catch((err) => {
                alert('error segment' + JSON.stringify(err));

                console.log('upadate error segment->', err);
              })
              .finally(() => {
                setIsLoading(false);
              });
          } else {
            alert('Missing data');
          }
        }}>
        Create
      </Button>

      {isloading && (
        <Spin
          size="large"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
    </div>
  );
};
