import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Input, InputNumber, Row, Select} from 'antd';
import CountriesWithCurrency from 'iso-country-currency';

import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

import {Package} from '../../graphql/API';
import {PackagesService} from '../Package';
import {PricesService} from './PricesService';
import {useHistory} from 'react-router-dom';

const pricesService = new PricesService();

export const PriceCreate = () => {
  const history = useHistory();

  const [countries, setCountries] = useState<Array<any>>([]);
  const [isAny, setIsAny] = useState<boolean>();
  const [packages, setPackages] = useState<Array<Package> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);

  const [price, setPrice] = useState({
    package: undefined,
    name: '',
    price: 2,
    currency: '',
  });
  const [selectedCounty, setSelectedCounty] = useState<string>('');

  useEffect(() => {
    let packagesService = new PackagesService();
    packagesService
      .getPackages()
      .then((pkgs: any) => {
        setPackages(pkgs);
      })
      .catch((error: any) => {
        setIsError(true);
        //console.log('Error ->', error);
      });
    setCountries(CountriesWithCurrency.getAllISOCodes());
  }, []);

  const onChange = (prop: any, value: any) => {
    if (prop === 'package') {
      //@ts-ignore
      let selectedPackage = packages.find((p) => p.id === value);
      setPrice({...price, [prop]: selectedPackage});
    } else {
      setPrice({...price, [prop]: value});
    }
  };

  const selectAnyCountryPrice = () => {
    setSelectedCounty('any');
    onChange('currency', 'USD');
  };

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Create Price </Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Name
              </Text>
              <Input
                onChange={(event) => onChange('name', event.target.value)}
                value={price.name}
              />
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Package
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                //@ts-ignore
                value={price.package?.id}
                showSearch
                style={{width: '100%'}}
                placeholder="Select a package"
                optionFilterProp="children"
                onChange={(pkgId) => onChange('package', pkgId)}>
                {packages?.map((pkg) => (
                  <Select.Option value={pkg.id!}>
                    {JSON.parse(pkg.name_i18n)['en']}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Country
              </Text>
              {isAny ? (
                <Text>any country</Text>
              ) : (
                <Select
                  disabled={isAny}
                  showSearch
                  style={{width: "100%"}}
                  placeholder="Select a country"
                  optionFilterProp="children"
                  onChange={(c) => {
                    if (isAny) {
                      selectAnyCountryPrice();
                    } else {
                      // @ts-ignore
                      setSelectedCounty(c);
                      onChange(
                        'currency',
                        // @ts-ignore
                        CountriesWithCurrency.getAllInfoByISO(c).currency,
                      );
                    }
                  }}>
                  {countries?.map((country) => {
                    return (
                      <Select.Option value={country.iso}>
                        {country.countryName}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
              <Checkbox
                style={{marginLeft: '50px'}}
                checked={isAny}
                onChange={(event) => {
                  if (event.target.checked) {
                    selectAnyCountryPrice();
                  }
                  setIsAny(event.target.checked);
                }}>
                Any / else county
              </Checkbox>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Currency
              </Text>
              <Input disabled={true} value={price.currency} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Price
              </Text>
              <InputNumber
                value={price.price}
                onChange={(pr) => onChange('price', pr)}
              />
            </div>
          </Col>
        </Row>
        {/************************************************************************************************************************/}
      </>
      <Button
        onClick={async () => {
          let priceObj = {
            //@ts-ignore
            packageId: price.package?.id,
            name: price.name,
            price: price.price,
            currency: price.currency,
            country: selectedCounty,
          };
          await pricesService
            .createPrice(priceObj)
            .then((resp: any) => {
              alert('Price Created');
              history.push('/prices/' + resp.id);
            })
            .catch((err) => {
              //console.log('create price error: ', err);
            });
        }}>
        Create
      </Button>
    </div>
  );
};
