export const curriculums = /* GraphQL */ `
  query Curriculums(
    $filter: CurriculumsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    curriculums(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
      }
      nextToken
      scannedCount
    }
  }
`;
