import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Image, Input, Row, Space, Spin, Table} from 'antd';
import moment from 'moment';
import {get, isEqual} from 'lodash';

import {Instructor, InstructorsConnection} from '../../graphql/API';
import {InstructorService} from './InstructorService';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import ProfilePictureCard from '../PreInstructor/ProfilePictureCard';

const instructorService = new InstructorService();

export const InstructorsTable = () => {
  const [instructors, setInstructors] = useState<InstructorsConnection>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchedColumn, setSearchedColumn] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  let searchInput = useRef();
  const [filterIndex, setFilterIndex] = useState<number>(0);

  useEffect(() => {
    instructorService
      .getInstructors(true, null)
      .then((resp: InstructorsConnection) => {
        setFilterIndex(0);
        setIsLoading(false);
        setInstructors(resp);
      })
      .catch((error) => {
        setIsError(true);
        //console.log('Error ->', error);
      });
  }, []);

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{padding: 8}}>
        <Input
          ref={(node) => {
            // @ts-ignore
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchedColumn(dataIndex);
              setSearchText(selectedKeys[0]);
            }}>
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value: any, record: any) =>
      get(record, dataIndex)
        ? get(record, dataIndex)
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        // @ts-ignore
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text: any) =>
      isEqual(searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };
  const columns: any = [
    {
      title: 'Instructor Name',
      dataIndex: ['instructor', 'name'],
      key: 'name',
      ...getColumnSearchProps('name'),
      render: (name: string, instructor: Instructor) => (
        <Text>{instructor.name} </Text>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.student.name.localeCompare(b.student.name);
      },
    },
    {
      title: 'Program',
      dataIndex: ['program', 'flag'],
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (flag: string, instructor: Instructor) => (
        <img src={flag} alt="" style={{width: 100, height: 70}} />
      ),
    },

    {
      title: 'Profile Picture',
      dataIndex: ['promo', 'thumbnailUri'],
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (thumbnailUri: string) => {
        return <ProfilePictureCard thumbnailUri={thumbnailUri} />;
      },
    },
    {
      title: 'Speaking Languages',
      dataIndex: 'speakingLanguages',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (speakingLanguages: string[], instructor: Instructor) =>
        speakingLanguages?.map((lang) => <Text>{lang} </Text>),
      sorter: (a: any, b: any) => {
        return a.speakingLanguages.localeCompare(b.speakingLanguages);
      },
    },
    {
      title: 'WhatsApp',
      key: 'whatsappNumber',
      dataIndex: ['user', 'whatsappNumber'],
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.user.whatsappNumber.localeCompare(b.user.whatsappNumber);
      },
    },
    // {
    //   title: 'Status',
    //   key: 'status',
    //   dataIndex: ['status'],
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   render: (status: string) => (
    //     <Text
    //       style={{
    //         fontWeight: 'bold',
    //         padding: 10,
    //         borderRadius: 5,
    //       }}>
    //       {status}
    //     </Text>
    //   ),
    // },
    {
      title: 'Active',
      key: 'isActive',
      dataIndex: ['instructor', 'isActive'],
      render: (name: string, instructor: Instructor) => (
        <Text
          style={{
            backgroundColor: instructor.isActive ? 'greenyellow' : 'brown',
            color: instructor.isActive ? 'black' : 'white',
            fontWeight: 'bold',
            padding: 1,
            textAlign: 'left',
          }}>
          {instructor.isActive ? 'YES' : 'NO'}
        </Text>
      ),
    },
    {
      title: 'is Native',
      key: 'isNative',
      dataIndex: ['instructor', 'isNative'],
      render: (name: string, instructor: Instructor) => (
        <Text
          style={{
            backgroundColor: instructor.isNative ? 'purple' : 'blueviolet',
            color: instructor.isNative ? 'white' : 'white',
            fontWeight: 'bold',
            padding: 5,
            borderRadius: 5,
            fontSize: '13px',
          }}>
          {instructor.isNative ? 'NATIVE_SPEAKER' : 'OTHER'}
        </Text>
      ),
    },
    {
      title: 'TimeZone',
      key: 'timezone',
      dataIndex: ['user', 'timeZone'],
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'id',
      dataIndex: 'id',
      ...getColumnSearchProps('id'),
      render: (id: string) => (
        <Link
          to={{
            pathname: '/instructor/' + id + '/edit',
            state: {instructorId: id},
          }}>
          {id}
        </Link>
      ),
      sorter: (a: any, b: any) => a.id.length - b.id.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const showResultFilterByIndex = (index) => {
    switch (index) {
      case 0: {
        setIsLoading(true);
        setIsError(false);
        setInstructors(null);
        const isActiveInstructors = true;

        instructorService
          .getInstructors(isActiveInstructors, null)
          .then((resp: InstructorsConnection) => {
            setIsLoading(false);
            setInstructors(resp);
          })
          .catch((error) => {
            setIsError(true);
            //console.log('Error ->', error);
          });
        break;
      }
      case 1: {
        setIsError(false);
        setIsLoading(true);
        setInstructors(null);
        const isActiveInstructors = false;

        instructorService
          .getInstructors(isActiveInstructors, null)
          .then((resp: InstructorsConnection) => {
            setIsLoading(false);
            setInstructors(resp);
          })
          .catch((error) => {
            setIsError(true);
            //console.log('Error ->', error);
          });
        break;
      }
      case 2: {
        setIsError(false);
        setIsLoading(true);
        setInstructors(null);
        instructorService
          .getInstructors(null, null)
          .then((resp: InstructorsConnection) => {
            setIsLoading(false);
            setInstructors(resp);
          })
          .catch((error) => {
            setIsError(true);
            //console.log('Error ->', error);
          });
        break;
      }
    }
  };

  return (
    <>
      <Button size={'large'} style={{float: 'right', margin: 20}}>
        <Link to={'/instructor/create'}>Create</Link>
      </Button>

      <Row>
        <Button
          onClick={() => {
            setFilterIndex(0);
            showResultFilterByIndex(0);
          }}
          style={{
            width: '15%',
            minHeight: 55,
            // borderRadius: 10,
            backgroundColor: filterIndex !== 0 ? '#00142A' : '#40B2F2',
            marginBottom: '2.5%',
            color: 'white',
          }}>
          Active Instructors
        </Button>

        <Button
          onClick={() => {
            setFilterIndex(1);
            showResultFilterByIndex(1);
          }}
          style={{
            width: '15%',
            height: 55,
            // borderRadius: 10,
            backgroundColor: filterIndex !== 1 ? '#00142A' : '#40B2F2',
            marginBottom: '2.5%',
            color: 'white',
            textAlign: 'center',
            flexWrap: 'wrap',
          }}>
          Suspended Instru...
        </Button>

        <Button
          onClick={() => {
            setFilterIndex(2);
            showResultFilterByIndex(2);
          }}
          style={{
            width: '15%',
            height: 55,
            // borderRadius: 10,
            backgroundColor: filterIndex !== 2 ? '#00142A' : '#40B2F2',
            marginBottom: '2.5%',
            color: 'white',
          }}>
          All Instructors
        </Button>
      </Row>
      {!isError && !isLoading && (
        <Table
          pagination={{defaultPageSize: 100}}
          columns={columns}
          dataSource={instructors?.items ?? []}
        />
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Instructors fetching error ⚠️</Title>}
    </>
  );
};
