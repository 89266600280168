export const createPromoCodes = /* GraphQL */ `
  mutation CreatePromoCodes(
    $numOfCodes: Int!
    $packageId: String!
    $sessionPerWeek: Int!
    $schoolId: String
    $classschoolId: String
    $levelId: String
    $curriculumId: String
  ) {
    createPromoCodes(
      numOfCodes: $numOfCodes
      packageId: $packageId
      sessionPerWeek: $sessionPerWeek
      schoolId: $schoolId
      classschoolId: $classschoolId
      levelId: $levelId
      curriculumId: $curriculumId
    )
  }
`;
