import {Spin} from 'antd';

export const Loading = () => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: 'red',
        zIndex: 999,
      }}>
      <Spin size="large" style={{margin: 'auto', width: '100%'}} />
    </div>
  );
};
