import {IIGroupsRepository} from './IIGroupsRepository';
import {
  getGroups,
  filteredMemberships,
  getGroup,
  sessions,
} from './GroupQueries';
import {
  Group,
  GroupInsert,
  GroupUpdate,
  DivisionType,
  Status,
  Session,
  GroupsConnection,
  SessionsConnection,
} from '../../graphql/API';
import {
  createGroup,
  startGroup,
  updateGroup,
  deleteGroup,
  endSession,
  levelUpGroup,
  startSession,
} from './GroupMutations';
import {resetSessionStatus} from '../../graphql/mutations';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();

export class GroupsRepository implements IIGroupsRepository {
  async getTodaySessions(
    nextToken: string,
    startDateTime: string,
    endDateTime: string,
  ): Promise<SessionsConnection> {
    const filter = {
      startDateTime: {eq: startDateTime},
      endDateTime: {eq: endDateTime},
    };

    try {
      const groups: GraphQLResult<any> = await client.graphql({
        query: sessions,
        variables: {
          filter,
          // scannedCount: 101,
          nextToken,
        },
      });
      //console.log('getTodaySessions->', groups);

      return groups.data?.sessions;
    } catch (error) {
      //console.log('Fetch getTodaySessions Error: ', error);
      throw new Error(error);
    }
  }

  async startSession(sessionId: string): Promise<any> {
    try {
      const res: GraphQLResult<any> = await client.graphql({
        query: startSession,
        variables: {
          id: sessionId,
        },
      });
      //console.log('startSession->', res);

      return res;
    } catch (error) {
      //console.log('Fetch startSession Error: ', error);
      throw new Error(error);
    }
  }
  async endSession(
    sessionId: string,
    updatedAt: string,
    status: Status,
  ): Promise<any> {
    try {
      const res: GraphQLResult<any> = await client.graphql({
        query: endSession,
        variables: {
          id: sessionId,
          status,
        },
      });
      //console.log('startSession->', res);

      return res;
    } catch (error) {
      //console.log('Fetch startSession Error: ', error);
      throw new Error(error);
    }
  }

  async updateGroup(
    groupId: string,
    updatedAt: string,
    groupUpdate: GroupUpdate,
  ): Promise<any> {
    try {
      const res: GraphQLResult<any> = await client.graphql({
        query: updateGroup,
        variables: {
          id: groupId,
          updatedAt,
          input: {
            ...groupUpdate,
          },
        },
      });
      //console.log('updateGroup->', res);

      return res;
    } catch (error) {
      //console.log('Fetch updateGroup Error: ', error);
      throw new Error(error);
    }
  }
  async deleteGroup(groupId: string): Promise<any> {
    try {
      const res: GraphQLResult<any> = await client.graphql({
        query: deleteGroup,
        variables: {
          id: groupId,
        },
      });
      //console.log('deleteGroup->', res);

      return res;
    } catch (error) {
      //console.log('Fetch deleteGroup Error: ', error);
      throw new Error(error);
    }
  }
  async startGroup(groupId: string): Promise<any> {
    try {
      const res: GraphQLResult<any> = await client.graphql({
        query: startGroup,
        variables: {
          groupId,
        },
      });
      //console.log('deleteGroup->', res);

      return res;
    } catch (error) {
      //console.log('Fetch deleteGroup Error: ', error);
      throw new Error(error);
    }
  }

  async changeSessionStatus(
    sessionId: string,
    updatedAt: string,
    status: Status,
  ): Promise<any> {
    //console.log('UDAS', sessionId, updatedAt);

    try {
      const res: GraphQLResult<any> = await client.graphql({
        query: resetSessionStatus,
        variables: {
          id: sessionId,
          updatedAt,
          status,
        },
      });
      //console.log('deleteGroup->', res);

      return res;
    } catch (error) {
      //console.log('Fetch deleteGroup Error: ', error);
      throw new Error(error);
    }
  }
  async getGroupById(id: string): Promise<Group> {
    try {
      const res: GraphQLResult<any> = await client.graphql({
        query: getGroup,
        variables: {
          id,
        },
      });
      //console.log('getGroupById->', res);

      return res.data?.group;
    } catch (error) {
      //console.log('Fetch getGroupById Error: ', error);
      throw new Error(error);
    }
  }

  async getActiveGroup(
    nextToken: string,
    isActive: boolean,
  ): Promise<GroupsConnection> {
    const filter = {
      isActive: {eq: isActive},
    };

    try {
      const res: GraphQLResult<any> = await client.graphql({
        query: getGroups,
        variables: {
          filter,
          nextToken,
          scannedCount: 101,
        },
      });
      //console.log('getActiveGroup->', res);

      return res.data?.groups;
    } catch (error) {
      //console.log('Fetch getActiveGroup Error: ', error);
      throw new Error(error);
    }
  }

  async getFinishedGroup(
    nextToken: string,
    fromRangeDate: string,
    ToRangeDate: string,
    isActive: boolean,
  ): Promise<GroupsConnection> {
    const filter = {
      fromRangeDate: {eq: fromRangeDate},
      ToRangeDate: {eq: ToRangeDate},
      isActive: {eq: isActive},
    };

    try {
      const res: GraphQLResult<any> = await client.graphql({
        query: getGroups,
        variables: {
          filter,
          nextToken,
          scannedCount: 101,
        },
      });
      //console.log('getFinishedGroup->', res);

      return res.data?.groups;
    } catch (error) {
      //console.log('Fetch getFinishedGroup Error: ', error);
      throw new Error(error);
    }
  }
  async getGroups(nextToken: string): Promise<GroupsConnection> {
    try {
      const res: GraphQLResult<any> = await client.graphql({
        query: getGroups,
        variables: {
          nextToken,
          scannedCount: 101,
        },
      });
      //console.log('getFinishedGroup->', res);

      return res.data?.groups;
    } catch (error) {
      //console.log('Fetch getFinishedGroup Error: ', error);
      throw new Error(error);
    }
  }

  async createGroup(group: GroupInsert): Promise<any> {
    try {
      const createdGroup: GraphQLResult<any> = await client.graphql({
        query: createGroup,
        variables: {
          input: {
            ...group,
          },
        },
      });
      //console.log('createdGroup->', createdGroup);

      return createdGroup;
    } catch (error) {
      //console.log('Fetch createdGroup Error: ', error);
      throw new Error(error);
    }
  }

  async filteredMemberships(
    programId: string,
    levelId: string,
    typeGroup: DivisionType,
  ): Promise<any> {
    //console.log('typeGroup', typeGroup);

    try {
      const filteredMembership: GraphQLResult<any> = await client.graphql({
        query: filteredMemberships,
        variables: {
          programId,
          levelId,
          typeGroup,
        },
      });
      //console.log('creatfilteredMembershipedGroup->', filteredMembership);

      return filteredMembership.data?.filteredMemberships;
    } catch (error) {
      //console.log('Fetch filteredMembership Error: ', error);
      throw new Error(error);
    }
  }

  async levelUp(groupId: string, levelId: string): Promise<any> {
    try {
      const levelUp: GraphQLResult<any> = await client.graphql({
        query: levelUpGroup,
        variables: {
          groupId,
          levelId,
        },
      });
      //console.log('levelUp->', levelUp);

      return levelUp;
    } catch (error) {
      //console.log('Fetch levelUp Error: ', error);
      throw new Error(error);
    }
  }
}
