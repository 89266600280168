import {IPricesService} from './IPricesService';
import {PricesRepository} from './PricesRepository';
import {Price} from '../../graphql/API';

export class PricesService implements IPricesService {
  pricesRepository: PricesRepository;
  constructor() {
    this.pricesRepository = new PricesRepository();
  }

  async prices(): Promise<any> {
    return await this.pricesRepository.prices();
  }

  async createPrice(price: any): Promise<any> {
    return await this.pricesRepository.createPrice(price);
  }

  async updatePrice(price: any): Promise<any> {
    const priceId = price.id;
    let priceUpdatedAt = price.updatedAt;
    let priceObj = price;
    delete priceObj.id;
    delete priceObj.updatedAt;
    delete priceObj.createdAt;
    delete priceObj.package;

    return await this.pricesRepository.updatePrice(
      priceId,
      priceUpdatedAt,
      priceObj,
    );
  }

  async getPriceById(priceId: string): Promise<any> {
    return await this.pricesRepository.price(priceId);
  }
}
