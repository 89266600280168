import {IProgramsService} from './IProgramsService';
import {ProgramsRepository} from './ProgramsRepository';
import {Program} from '../../graphql/API';
import {AssetsService} from '../Asset';
import {PricesService} from '../Price';
import {cloneDeep} from 'lodash';

export class ProgramsService implements IProgramsService {
  programsRepository: ProgramsRepository;
  assetsService: AssetsService;
  pricesService: PricesService;
  constructor() {
    this.programsRepository = new ProgramsRepository();
    this.assetsService = new AssetsService();
    this.pricesService = new PricesService();
  }

  async getPrograms(): Promise<Array<Program> | any> {
    let programs = await this.programsRepository.getPrograms();
    return programs;
  }

  async getProgramById(programId: string): Promise<Program> {
    let program = await this.programsRepository.getProgramById(programId);
    return program;
  }

  async updateProgram(program: Program | any): Promise<any> {
    let programId = program.id;
    let programUpdatedAt = program.updatedAt;

    let recordIds = [];
    if (program.records) {
      await Promise.all(
        program.records?.map(async (record) => {
          if (record.id) {
            await this.assetsService.updateAsset(record);
            recordIds.push(record.id);
          } else {
            let createRecordAssetRes = await this.assetsService.createAsset(
              record,
            );
            recordIds.push(createRecordAssetRes);
          }
        }),
      );
    }
    let activityIds = [];

    if (program.activities) {
      await Promise.all(
        program.activities?.map(async (activity) => {
          //console.log('activity->', activity);
          if (activity.id) {
            await this.assetsService.updateAsset(activity);
            activityIds.push(activity.id);
          } else {
            let createAssetRes = await this.assetsService.createAsset(activity);
            activityIds.push(createAssetRes);
          }
        }),
      );
    }

    //console.log('1--program->', program);
    let prg = cloneDeep(program);

    prg.activityIds = activityIds;
    prg.recordIds = recordIds;
    prg.testId = prg?.test?.id;
    delete prg.id;
    delete prg.updatedAt;
    delete prg.test;
    delete prg.createdAt;
    delete prg.courses;
    delete prg.packages;
    delete prg.program;
    delete prg.activities;
    delete prg.records;
    delete prg.instructors;

    //console.log('2--programPackage->', program);
    //console.log('final prg->', prg);
    return await this.programsRepository.updateProgram(
      programId,
      programUpdatedAt,
      {
        ...prg,
      },
    );
    //return null;
  }

  async createProgram(program: any): Promise<any> {
    try {
      let programObj: any = {
        programId: program.programId,
        name: program.name,
        sessionDuration: program.sessionDuration,
        description: program.description,
        onlyAI: program.onlyAI,
        benefits: program.benefits,
        number: program.number,
      };
      //console.log('inside create program');
      programObj.coverAssetId = await this.assetsService.createAsset(
        program.coverAsset,
      );
      programObj.promoAssetId = await this.assetsService.createAsset(
        program.promoAsset,
      );
      //console.log('programObj to create->', programObj);
      //TODO: Add validators
      let createProgramResponse = await this.programsRepository.createProgram(
        programObj,
      );

      //console.log('createProgramResponse->', createProgramResponse);
      let initalPrice = {
        programId: createProgramResponse.id,
        name: `${program.name} any`,
        price: 0,
        currency: 'USD',
        country: 'any',
      };
      let createPriceResp = await this.pricesService.createPrice(initalPrice);
      //console.log('createPriceResp->', createPriceResp);
      return createProgramResponse;
      /*

    "programId": "598a8593-89f3-4a82-8a39-4b14d565471f",
    "name": "AI USD",
    "price": 2,
    "currency": "KWD",
    "country": "KW"
       */
    } catch (e) {
      return new Promise((resolve, reject) =>
        reject(`Creating program error: ${e}`),
      );
    }
  }
}
