import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Input, Space, Spin, Table} from 'antd';
import moment from 'moment';
import {get, isEqual} from 'lodash';

import {Enrollment, EnrollmentsConnection} from '../../graphql/API';
import {EnrollmentsService} from './EnrollmentsService';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const enrollmentsService = new EnrollmentsService();

export const EnrollmentsTable = () => {
  const [enrollments, setEnrollments] = useState<EnrollmentsConnection>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchedColumn, setSearchedColumn] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  const [current, setCurrent] = useState(1);

  let searchInput = useRef();

  useEffect(() => {
    enrollmentsService
      .getEnrollments(enrollments?.nextToken)
      .then((resp) => {
        //console.log('enrollments', resp);
        setIsLoading(false);
        const currentItems = enrollments?.items ?? [];
        setEnrollments({
          ...enrollments,
          ...resp,
          items: [...currentItems, ...resp.items],
        });
      })
      .catch((error) => {
        setIsError(true);
        //console.log('Error ->', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{padding: 8}}>
        <Input
          ref={(node) => {
            // @ts-ignore
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchedColumn(dataIndex);
              setSearchText(selectedKeys[0]);
            }}>
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value: any, record: any) =>
      get(record, dataIndex)
        ? get(record, dataIndex)
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        // @ts-ignore
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text: any) =>
      isEqual(searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };
  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
      sorter: (a: any, b: any) => a.id.length - b.id.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (id: string) => <Link to={'/enrollments/' + id}>{id}</Link>,
    },
    {
      title: 'Started at',
      dataIndex: ['startDate'],
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) =>
        moment(a.startDate).unix() - moment(b.startDate).unix(),
    },

    {
      title: 'Created at',
      dataIndex: ['createdAt'],
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: 'PlacementStatus',
      dataIndex: 'placementStatus',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.placementStatus.localeCompare(b.placementStatus);
      },
      render: (placementStatus: string) => (
        <p
          style={{
            color:
              placementStatus === 'DONE'
                ? 'green'
                : placementStatus === 'DOING'
                ? '#E2D410'
                : 'red',
          }}>
          {placementStatus}
        </p>
      ),
    },
    {
      title: 'PaymentStatus',
      key: 'paymentStatus',
      dataIndex: 'paymentStatus',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.paymentStatus.localeCompare(b.paymentStatus);
      },
      render: (paymentStatus: string) => (
        <p
          style={{
            color:
              paymentStatus === 'DONE'
                ? 'green'
                : paymentStatus === 'DOING'
                ? '#E2D410'
                : 'red',
          }}>
          {paymentStatus}
        </p>
      ),
    },
    {
      title: 'Student',
      dataIndex: ['student', 'name'],
      ...getColumnSearchProps(['student', 'id']),
      render: (studentName: string, record: Enrollment) => (
        <Text>
          {`${record.student?.name} / ${record.student?.id?.split('-')[0]}`}
        </Text>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        return a.student.name.localeCompare(b.student.name);
      },
    },
  ];

  // const sizeChangeLogic = (current, size) => {
  //   setSize = true;
  //   //console.log('CURRRRR_sizeChangeLogic', current);
  //   //console.log('sizesize_sizeChangeLogic', size);

  //   setCurrent(1);
  // };

  const onChangeLogic = ({current}, size) => {
    //console.log('CURRRRR_sizeChangeLogic', current);

    if (current % 4 === 0) {
      setCurrent(current);
    }
  };

  return (
    <>
      <Button size={'large'} style={{float: 'right', margin: 20}}>
        <Link to={'/enrollments/create'}>Create</Link>
      </Button>
      {!isError && !isLoading && (
        <Table
          pagination={{pageSizeOptions: ['10']}}
          current={current}
          defaultPageSize={10}
          // onShowSizeChange={sizeChangeLogic}
          onChange={onChangeLogic}
          // showSizeChanger={true}
          columns={columns}
          dataSource={enrollments?.items}
        />
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Enrollments fetching error ⚠️</Title>}
    </>
  );
};
