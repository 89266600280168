/** User */
export const GET_USER = 'GET_USER';
export const NO_USER_FOUND = 'NO_USER_FOUND';
export const RESET_USER = 'RESET_USER';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const IS_LOADING_MESSAGE = 'IS_LOADING_MESSAGE';
export const SET_SUBSCRIBED_ON_CONVERSATION = 'SET_SUBSCRIBED_ON_CONVERSATION';
export const SET_GROUP_CONVERSATIONS = 'SET_GROUP_CONVERSATIONS';

export const PROGRESS_UPLOADING_PERCENTAGE = 'PROGRESS_UPLOADING_PERCENTAGE';
