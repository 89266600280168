// TODO: add user to get student's parent
export const createMembership = `
mutation createMembership ($input: MembershipInsert!) {
    createMembership (input: $input) {
        id
        createdAt
        updatedAt   
    }
}
`;

export const updateMembership = `
mutation UpdateMembership(
    $id: ID!
    $updatedAt: String!
    $input: MembershipUpdate!
  ) {
    updateMembership(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
    }
  }`;
