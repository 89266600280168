import {useEffect, useState} from 'react';
import {Link, Switch, useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {Alert, Button, Layout, Space, Spin} from 'antd';
import {Route} from 'react-router-dom';
import {getCurrentUserPermission} from '../utils';

import {Userconversation} from '../graphql/API';

import {PromoCodeTable} from '../modules/PromoCodes';
import {Chats} from './Chats';
import {SchoolEdit} from '../modules/Schools/SchoolEdit';

import {
  SyllableCreate,
  SyllableEdit,
  SyllablesTable,
} from '../modules/Syllable';
import {ChannelsNotifications} from '../modules/Channels';
import {SegmentCreate} from '../modules/Channels/SegementCreate';
import {CourseTable} from '../modules/Courses/CourseTable';
import {CourseEdit} from '../modules/Courses/CourseEdit';
import {VideoSessionEdit, VideoSessionTable} from '../modules/VideoSession';
import {VideoSessionCreate} from '../modules/VideoSession/VideoSessionCreate';

import {SiderComponent, SiderChat} from './';
import {
  EnrollmentsTable,
  EnrollmentDetails,
  EnrollmentCreate,
  EnrollmentEdit,
  PlanTable,
  StudentDetails,
  StudentsTable,
  TestDetails,
  TestsTable,
  Dashboard,
  PlanDetails,
  PlanEdit,
  TestCreate,
  PackagesTable,
  PackageDetails,
  PackageEdit,
  PackageCreate,
  PriceCreate,
  PriceEdit,
  PricesTable,
  StudentEdit,
  ProgramsTable,
  ProgramEdit,
  MembershipsTable,
  MembershipEdit,
  MembershipCreate,
  LessonEdit,
  LessonsTable,
  LessonCreate,
  LevelsTable,
  InstructorsTable,
  InstructorCreate,
  GroupCreate,
  GroupTable,
  GroupEdit,
  TestEdit,
  AddingRole,
  StudentSessionDetails,
  UserDetails,
  DiscountCodeTable,
  SchoolsTable,
  PreInstructorsTable,
  PreInstructorEdit,
  CurriculumCreate,
  CurriculumEdit,
  CurriculumsTable,
  StudentReport,
} from '../modules';
import {InstructorEdit} from '../modules/Instructor/InstructorEdit';
import {Authenticator} from '@aws-amplify/ui-react';
import {signIn, SignInInput} from 'aws-amplify/auth';
import {doSignIn} from '../redux/actions/user';
import {DiscountCodeForPartnerTable} from '../modules/DiscountCodeForPartner';
import {DiscountCodeForPartnerDetails} from '../modules/DiscountCodeForPartner/DiscountCodeForPartnerDetails';
import {SchoolCreate} from '../modules/Schools/SchoolCreate';
import { PlanTableContainer } from '../modules/Plans/PlanTableContainer';

const {Content} = Layout;

export const Routers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);

  const isLoading = useSelector((state: any) => state.user?.isLoading);
  const [selectedConversations, setSelectedConversations] = useState<
    Array<Userconversation>
  >([]);

  const [currentUserPermission, setCurrentUserPermission] = useState<any>();

  console.log('isLoggedIn', isLoggedIn);
  console.log('isLoading', isLoading);

  const renderPartnersRoutes = () => {
    return (
      <>
        <Route exact path="/partners">
          <DiscountCodeForPartnerTable />
        </Route>
        <Route path="/discount-code-details">
          <DiscountCodeForPartnerDetails />
        </Route>
      </>
    );
  };

  const renderEditorsRoutes = () => {
    return (
      <>
        {/** Enrollments routes*/}
        <Route path="/syllables/create">
          <SyllableCreate />
        </Route>
        <Route exact path="/syllables">
          <SyllablesTable />
        </Route>
        <Route exact path="/syllables/:syllableId/edit">
          <SyllableEdit />
        </Route>
        {/** Enrollments routes*/}
        <Route exact path="/enrollments">
          <EnrollmentsTable />
        </Route>
        <Route path="/enrollments/create">
          <EnrollmentCreate />
        </Route>
        <Route exact path="/enrollments/:enrollmentId">
          <EnrollmentDetails />
        </Route>
        <Route exact path="/school/:schoolId/edit">
          <SchoolEdit />
        </Route>

        <Route exact path="/enrollments/:enrollmentId/edit">
          <EnrollmentEdit />
        </Route>
        {/** Memberships routes*/}
        <Route path="/memberships/create">
          <MembershipCreate />
        </Route>
        <Route exact path="/memberships/:membershipId/edit">
          <MembershipEdit />
        </Route>
        {/** Students routes*/}
        <Route exact path="/students">
          <StudentsTable />
        </Route>
        <Route exact path="/students/:studentId">
          <StudentDetails />
        </Route>
        <Route exact path="/students/:studentId/edit">
          <StudentEdit />
        </Route>
        <Route exact path="/student-sessions/:studentId">
          <StudentSessionDetails />
        </Route>
        <Route exact path="/students/:studentId/report/:statisticId">
          <StudentReport />
        </Route>
        {/** Groups routes*/}
        <Route exact path="/groups">
          <GroupTable />
        </Route>
        <Route path="/group/create">
          <GroupCreate />
        </Route>
        <Route path="/group/:groupId/edit">
          <GroupEdit />
        </Route>
        {/** Instructor routes*/}
        <Route exact path="/instructors">
          <InstructorsTable />
        </Route>
        <Route path="/instructor/create">
          <InstructorCreate />
        </Route>
        <Route path="/instructor/:instructorId/edit">
          <InstructorEdit />
        </Route>
        {/** PreInstructor routes*/}
        <Route exact path="/preInstructors">
          <PreInstructorsTable />
        </Route>
        <Route path="/preInstructor/:preInstructorId/edit">
          <PreInstructorEdit />
        </Route>
        {/** PromoCodes routes*/}
        <Route exact path="/promocodes">
          <PromoCodeTable />
        </Route>
        {/** DiscountCodes routes*/}
        <Route exact path="/discountcodes">
          <DiscountCodeTable />
        </Route>
        {/** user routes*/}
        <Route path="/users/:userId">
          <UserDetails />
        </Route>
      </>
    );
  };

  const renderContentCreatorRoutes = () => (
    <Switch>
      <Route exact path="/tests">
        <TestsTable />
      </Route>
      <Route exact path="/tests/create">
        <TestCreate />
      </Route>
      <Route path="/tests/:testId">
        <TestDetails />
      </Route>
      <Route path="/test/:testId/edit">
        <TestEdit />
      </Route>
    </Switch>
  );
  const renderAdminRoutes = () => (
    <>
      <Route exact path="/videosession/:videosessionId/edit">
        <VideoSessionEdit />
      </Route>
      <Route exact path="/videosession/create">
        <VideoSessionCreate />
      </Route>

      <Route exact path="/videosessions">
        <VideoSessionTable />
      </Route>

      <Route exact path="/courses">
        <CourseTable />
      </Route>

      <Route exact path="/course/:courseId">
        <CourseEdit />
      </Route>

      <Route exact path="/plans">
        <PlanTableContainer />
      </Route>
      <Route exact path="/plans/:planId">
        <PlanDetails />
      </Route>
      <Route exact path="/plans/:planId/edit">
        <PlanEdit />
      </Route>
      {/** Tests routes*/}
      <Switch>
        <Route exact path="/tests">
          <TestsTable />
        </Route>
        <Route exact path="/tests/create">
          <TestCreate />
        </Route>
        <Route path="/tests/:testId">
          <TestDetails />
        </Route>
        <Route path="/test/:testId/edit">
          <TestEdit />
        </Route>
      </Switch>
      {/** Packages routes*/}
      <Route exact path="/packages">
        <PackagesTable />
      </Route>
      <Route path="/package/create">
        <PackageCreate />
      </Route>
      <Route exact path="/packages/:packageId">
        <PackageDetails />
      </Route>
      <Route path="/packages/:packageId/edit">
        <PackageEdit />
      </Route>
      {/** Packages routes*/}
      {/** Prices routes*/}
      <Route exact path="/prices">
        <PricesTable />
      </Route>
      <Route path="/prices/create">
        <PriceCreate />
      </Route>
      <Route exact path="/prices/:priceId/edit">
        <PriceEdit />
      </Route>

      {/** Program routes*/}
      <Route exact path="/programs">
        <ProgramsTable />
      </Route>
      <Route exact path="/programs/:programId/edit">
        <ProgramEdit />
      </Route>
      {/** Lesson routes*/}
      <Route exact path="/lessons">
        <LessonsTable />
      </Route>
      <Route path="/lessons/create">
        <LessonCreate />
      </Route>
      <Route exact path="/lessons/:lessonId/edit">
        <LessonEdit />
      </Route>
      {/** Levels routes*/}
      <Route exact path="/levels">
        <LevelsTable />
      </Route>

      {/** memberships routes*/}
      <Route exact path="/memberships">
        <MembershipsTable />
      </Route>

      {/** schools routes*/}
      <Route exact path="/schools">
        <SchoolsTable />
      </Route>
      <Route path="/schools/create">
        <SchoolCreate />
      </Route>

      {/** accounts routes*/}
      <Route path="/accounts/role">
        <AddingRole />
      </Route>

      {/** segment routes*/}
      <Route path="/segments/create">
        <SegmentCreate />
      </Route>

      {/** channels routes*/}
      <Route path="/channels">
        <ChannelsNotifications />
      </Route>

      {/** Curriculums routes*/}
      {/** memberships routes*/}
      <Route exact path="/curriculums">
        <CurriculumsTable />
      </Route>

      <Route path="/curriculums/create">
        <CurriculumCreate />
      </Route>
      <Route exact path="/curriculums/:curriculumId/edit">
        <CurriculumEdit />
      </Route>
    </>
  );

  const services = {
    async handleSignIn(input: SignInInput): Promise<any> {
      // custom username and email
      const {username, password, options} = input;

      console.log('username', username);
      dispatch(doSignIn(username, password));

      return;
    },
  };

  useEffect(() => {
    if (isLoggedIn) {
      getCurrentUserPermission()
        .then((p: any) => {
          setCurrentUserPermission(() => p);
          console.log('isLoggedIn000', p);
        })
        .catch((er) => {
          console.log('err->', er);
        });
    }
  }, [isLoggedIn]);
  console.log('currentUserPermission', currentUserPermission);
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
      }}>
      {!isLoggedIn && !isLoading ? (
        <Authenticator
          services={services}
          hideSignUp
          socialProviders={[]}
          loginMechanisms={['email']}
        />
      ) : (
        currentUserPermission && (
          <>
            <Content>
              <SiderComponent />
              <Route exact path="/">
                <Dashboard />
              </Route>
              {(currentUserPermission === 'editor' ||
                currentUserPermission === 'admin') &&
                renderEditorsRoutes()}
              {currentUserPermission === 'admin' && renderAdminRoutes()}

              {currentUserPermission === 'partner' && renderPartnersRoutes()}
              {currentUserPermission === 'CONTENT_CREATOR' &&
                renderContentCreatorRoutes()}

              {(currentUserPermission === 'editor' ||
                currentUserPermission === 'admin') && (
                <Chats
                  selectedConversations={selectedConversations}
                  setSelectedConversations={setSelectedConversations}
                />
              )}
            </Content>

            {(currentUserPermission === 'editor' ||
              currentUserPermission === 'admin') && (
              <SiderChat
                onPressChat={(usrConversation: Userconversation) => {
                  const conversationExsit = selectedConversations.some(
                    (usrConv) => usrConv.id === usrConversation.id,
                  );
                  if (!conversationExsit) {
                    setSelectedConversations([
                      ...selectedConversations,
                      usrConversation,
                    ]);
                  }
                }}
              />
            )}
          </>
        )
      )}

      {isLoading && (
        <Space
          direction="vertical"
          style={{
            width: '100%',
            top: '50%',
            backgroundColor: 'red',
            position: 'absolute',
          }}>
          <Spin tip="Loading">
            <div className="content" />
          </Spin>
        </Space>
      )}
    </div>
  );
};
