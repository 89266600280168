import {
  GET_USER,
  RESET_USER,
  NO_USER_FOUND,
  USER_IS_LOADING,
  SET_CONVERSATIONS,
  SET_SUBSCRIBED_ON_CONVERSATION,
  SET_GROUP_CONVERSATIONS,
  PROGRESS_UPLOADING_PERCENTAGE,
} from '../actions/types';

import {Conversation, User, Userconversation} from '../../graphql/API';

export const initialState: UserState = {
  user: null,
  conversations: [],
  subscribedConversationIds: [],
  groupConversations: [],
  isLoading: false,
  error: null,
  uploadingProgress: 0,
  isLoggedIn: false,
};

export type UserState = {
  user: User;
  conversations: Array<Userconversation>;
  subscribedConversationIds: Array<string>;
  groupConversations: Array<Conversation>;
  isLoading: boolean;
  error: null | any;
  uploadingProgress: number;
  isLoggedIn: boolean;
};

const user = (state = initialState, action: any) => {
  switch (action.type) {
    // case SIGN_IN_SUCCESS:
    //   return {
    //     ...state,
    //     user: action.payload,
    //     isLoggedIn: true,
    //     isLoading: false,
    //     error: null,
    //   };

    case GET_USER:
      console.log('state', state);
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
        isLoading: false,
        error: null,
      };

    case NO_USER_FOUND:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        isLoading: false,
        error: null,
      };
    case RESET_USER:
      return {
        ...state,
        isLoading: false,
        user: null,
        isLoggedIn: false,
      };
    case USER_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case SET_CONVERSATIONS: {
      return {
        ...state,
        conversations: action.payload,
      };
    }

    case SET_GROUP_CONVERSATIONS: {
      return {
        ...state,
        groupConversations: action.payload,
      };
    }

    case SET_SUBSCRIBED_ON_CONVERSATION: {
      return {
        ...state,
        subscribedConversationIds: action.payload,
      };
    }

    case PROGRESS_UPLOADING_PERCENTAGE: {
      return {
        ...state,
        uploadingProgress: action.payload,
      };
    }

    default:
      return state;
  }
};

export default user;
