import React, {useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  Progress,
  Row,
  Select,
  Spin,
  Upload,
  message,
} from 'antd';

import CountriesWithCurrency from 'iso-country-currency';

import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

import {
  AdminGetUserResponse,
  Asset,
  AttributeType,
  Gender,
  InstructorInsert,
  InstructorStatus,
  PreInstructor,
  PreInstructorUpdate,
  Program,
  UserUpdate,
} from '../../graphql/API';
import {ProgramsService} from '../Program';
import {PreInstructorService} from './PreInstructorService';
import {useHistory, useParams} from 'react-router-dom';
import {AssetsService} from '../Asset';
import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import {UserService} from '../User';
import {StorageService, UploadFileType} from '../Lesson/StorageService';
import {UploadFile, UploadProps} from 'antd/lib/upload/interface';
import {useSelector} from 'react-redux';
import {AccountService} from '../Accounts/AccountService';
import ProfilePictureCard from './ProfilePictureCard';
import VideoIntroCard from './VideoIntroCard';

const preInstructorService = new PreInstructorService();
const userService = new UserService();
const accountService = new AccountService();

const {Option} = Select;

const daysOfTheWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const {RangePicker} = DatePicker;
const storageService = new StorageService();

export const PreInstructorEdit = () => {
  const history = useHistory();
  let params: {preInstructorId: string} = useParams();
  const [countries, setCountries] = useState<Array<any>>([]);
  const [programs, setPrograms] = useState<Array<Program> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [targetArn, setTargetArn] = useState<string>('');
  const [uploading, setUploading] = useState(false);

  const [fileInstructorVideo, setFileInstructorVideo] = useState<UploadFile>();
  const [fileInstructorVideoThumbnail, setFileInstructorVideoThumbnail] =
    useState<UploadFile>();

  const [preInstructor, setPreInstructor] = useState({
    id: '',
    updatedAt: '',
    userUpdatedAt: '',
    email: '',
    userId: '',
    promo: null,
    name: '',
    whatsapp: '',
    gender: Gender.MALE,
    speakingLanguages: [],
    spokenLang: '',
    paymentMethod: '',
    timezone: '',
    isNative: null,
    program: undefined,
    currency: '',
    isActive: null,
    status: null,
    birthDate: null,
  });

  const [selectedCounty, setSelectedCounty] = useState<string>('');
  const [avaliableTimeZones, setAvaliableTimeZones] = useState<
    Array<string> | []
  >([]);

  let progressPercentage = useSelector(
    (state: any) => state?.user?.uploadingProgress,
  );

  useEffect(() => {
    axios.get('http://worldtimeapi.org/api/timezone/').then((response: any) => {
      //console.log(response);
      setAvaliableTimeZones(response.data);
    });

    let programService = new ProgramsService();
    programService
      .getPrograms()
      .then((programs: any) => {
        setPrograms(programs);
      })
      .catch((error: any) => {
        setIsError(true);
        //console.log('Error ->', error);
      });
    setCountries(CountriesWithCurrency.getAllISOCodes());

    preInstructorService
      .getPreInstructorById(params.preInstructorId)
      .then(async (preInstr: PreInstructor) => {
        const user: AdminGetUserResponse = await accountService.checkEmail(
          preInstr.user.cognitoUserId,
        );
        const instructorEmail = user.UserAttributes.find(
          (value: AttributeType) => value.Name === 'email',
        );

        setPreInstructor({
          id: preInstr.id,
          updatedAt: preInstr.updatedAt,
          userUpdatedAt: preInstr.user.updatedAt,
          promo: preInstr.promo,
          email: instructorEmail.Value,
          userId: preInstr.user.id,
          name: preInstr.name,
          whatsapp: preInstr.user.whatsappNumber,
          gender: preInstr.gender,
          speakingLanguages: preInstr.speakingLanguages ?? [],
          spokenLang: '',
          paymentMethod: '',
          timezone: preInstr.user.timeZone,
          program: preInstr.program,
          currency: '',
          isNative: preInstr.isNative,
          isActive: preInstr.isActive,
          status: preInstr.status,
          birthDate: preInstr.birthDate,
        });
        setTargetArn(preInstr.user.endPointArn);
        setSelectedCounty(preInstr.user.country);
      })
      .catch((error) => {
        //console.log('ERROR', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (prop: any, value: any) => {
    //console.log('value', value);
    if (prop === 'program') {
      //@ts-ignore
      let selectedProgram = programs.find((p) => p.id === value);
      setPreInstructor({...preInstructor, [prop]: selectedProgram});
    } else if (prop === 'promo.fileUri') {
      setPreInstructor({
        ...preInstructor,
        promo: {
          ...preInstructor.promo,
          fileUri: value,
        },
      });
    } else if (prop === 'promo.thumbnailUri') {
      setPreInstructor({
        ...preInstructor,
        promo: {
          ...preInstructor.promo,
          thumbnailUri: value,
        },
      });
    } else {
      setPreInstructor({...preInstructor, [prop]: value});
    }
  };

  const propsInstructorVideoThumbnial: UploadProps = {
    type: 'select',
    accept: '.png,.jpg',
    listType: 'picture',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setFileInstructorVideoThumbnail(null);
    },
    beforeUpload: (file) => {
      setFileInstructorVideoThumbnail(file);

      return false;
    },
  };
  const propInstructorVideo: UploadProps = {
    type: 'select',
    accept: '.mp4',
    listType: 'text',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setFileInstructorVideo(null);
    },
    beforeUpload: (file) => {
      setFileInstructorVideo(file);

      return false;
    },
  };

  const handleUpload = async (
    fileUri: UploadFile,
    typeUpload: UploadFileType,
  ) => {
    setUploading(true);
    //console.log('fileUri.url', fileUri);
    try {
      let filePath: string;
      switch (typeUpload) {
        case UploadFileType.INSTRUCTOR_PROFILE_URL:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            preInstructor.userId,
          );
          //console.log('filePath11', filePath);
          onChange('promo.thumbnailUri', filePath);
          break;

        case UploadFileType.INSTRUCTOR_INTRO_VIDEO:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            preInstructor.userId,
          );
          //console.log('filePath', filePath);
          onChange('promo.fileUri', filePath);
          break;

        default:
          break;
      }

      //console.log('file_PATH_IUPLOADED', filePath);
      message.success('upload successfully.');

      setUploading(false);
    } catch (error) {
      setUploading(false);
      //console.log('adssdadasdassa', error);
      message.error('upload failed.');
    }
  };

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Edit Pre Instructor </Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Email
              </Text>
              <Input
                disabled
                onChange={(event) => onChange('email', event.target.value)}
                value={preInstructor.email}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Name
              </Text>
              <Input
                onChange={(event) => onChange('name', event.target.value)}
                value={preInstructor.name}
              />
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                WhatsApp
              </Text>
              <Input
                type={'number'}
                onChange={(event) => onChange('whatsapp', event.target.value)}
                value={preInstructor.whatsapp}
              />
            </div>
          </Col>
        </Row>

        <Row>
          {/* <div style={{margin: 20}}> */}
          <Progress
            percent={progressPercentage}
            strokeColor={{'0%': '#108ee9', '40%': '#87d068'}}
          />
          <Col span={12}>
            <Text style={{display: 'block'}}>Type</Text>
            <Text style={{display: 'block', fontWeight: 'bold'}} strong={true}>
              Video
            </Text>
            <Text style={{display: 'block'}} strong={true}>
              Introduction Video Url
            </Text>

            {preInstructor?.promo?.fileUri ? (
              <>
                <Input
                  style={{marginBottom: 20}}
                  onChange={(event) => {
                    if (!event.target.value) {
                      setFileInstructorVideo(null);
                    }
                    onChange('promo.fileUri', event.target.value);
                  }}
                  value={preInstructor?.promo?.fileUri}
                />
                <VideoIntroCard
                  thumbnailUri={preInstructor?.promo?.thumbnailUri}
                  fileUri={preInstructor?.promo?.fileUri}
                />
              </>
            ) : (
              <Row>
                <Upload {...propInstructorVideo}>
                  <Button
                    disabled={fileInstructorVideo ? true : false}
                    icon={<UploadOutlined />}>
                    Select File
                  </Button>
                </Upload>

                <Button
                  type="primary"
                  onClick={() =>
                    handleUpload(
                      fileInstructorVideo,
                      UploadFileType.INSTRUCTOR_INTRO_VIDEO,
                    )
                  }
                  disabled={!fileInstructorVideo}
                  loading={uploading}>
                  {uploading ? 'Uploading' : 'Start Upload'}
                </Button>
              </Row>
            )}
          </Col>
          <Col span={12}>
            <Text style={{display: 'block'}} strong={true}>
              Profile Url
            </Text>
            {preInstructor?.promo?.thumbnailUri ? (
              <>
                <Input
                  value={preInstructor?.promo?.thumbnailUri ?? ''}
                  onChange={(event) => {
                    if (!event.target.value) {
                      setFileInstructorVideoThumbnail(null);
                    }
                    onChange('promo.thumbnailUri', event.target.value);
                  }}
                />
                <ProfilePictureCard
                  thumbnailUri={preInstructor?.promo?.thumbnailUri}
                  style={{
                    marginLeft: 20,
                    width: 500,
                    height: 500,
                    borderRadius: 8,
                  }}
                />
              </>
            ) : (
              <Row>
                <Upload {...propsInstructorVideoThumbnial}>
                  <Button
                    disabled={fileInstructorVideoThumbnail ? true : false}
                    icon={<UploadOutlined />}>
                    Select File
                  </Button>
                </Upload>

                <Button
                  type="primary"
                  onClick={() =>
                    handleUpload(
                      fileInstructorVideoThumbnail,
                      UploadFileType.INSTRUCTOR_PROFILE_URL,
                    )
                  }
                  disabled={!fileInstructorVideoThumbnail}
                  loading={uploading}>
                  {uploading ? 'Uploading' : 'Start Upload'}
                </Button>
              </Row>
            )}
          </Col>
          {/* </div> */}
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Current Status:{' '}
              </Text>
              <Text style={{color: 'red', fontSize: 15, fontWeight: 'bold'}}>
                {preInstructor.status}
              </Text>
              <Text style={{display: 'block'}} strong={true}>
                Change status To:
              </Text>
              <div>
                <Button
                  onClick={async () => {
                    if (
                      window.confirm(
                        'Delete preInstructor and create active instructor?',
                      )
                    ) {
                      setIsLoading(true);
                      const instructorInsertObject: InstructorInsert = {
                        userId: preInstructor.userId,
                        name: preInstructor.name,
                        programId: preInstructor.program.id,
                        gender: preInstructor.gender,
                        promoId: preInstructor.promo?.id,
                        speakingLanguages: preInstructor.speakingLanguages,
                        isNative: preInstructor.isNative,
                        isActive: true,
                        status: InstructorStatus.NEW,
                        birthDate: preInstructor.birthDate,
                      };
                      let cognitoUserId = preInstructor.email;
                      let createdInstructorId =
                        await preInstructorService.approvePreInstructor(
                          preInstructor.id,
                          preInstructor.updatedAt,
                          instructorInsertObject,
                          cognitoUserId,
                          targetArn,
                        );
                      setIsLoading(false);
                      alert(
                        'The pre instructor is approved, you will be redirected to the new instructor page.',
                      );
                      history.push(`/instructor/${createdInstructorId}/edit`);
                    }
                  }}
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    fontWeight: 'bold',
                    marginRight: 20,
                  }}>
                  Approve
                </Button>

                {preInstructor.status !== InstructorStatus.DECLINED && (
                  <Button
                    onClick={async () => {
                      await preInstructorService
                        .updatePreInstructor(
                          preInstructor.id,
                          preInstructor.updatedAt,
                          {status: InstructorStatus.DECLINED},
                          targetArn,
                        )
                        .then(() => {
                          alert('Pre instructor status updated');
                          window.location.reload();
                        })
                        .catch((e) => {
                          alert(`Pre instructor status update error: ${e}`);
                        });
                    }}
                    style={{
                      backgroundColor: 'red',
                      color: 'white',
                      fontWeight: 'bold',
                    }}>
                    Decline
                  </Button>
                )}

                {preInstructor.status !== InstructorStatus.PENDING && (
                  <Button
                    onClick={async () => {
                      await preInstructorService
                        .updatePreInstructor(
                          preInstructor.id,
                          preInstructor.updatedAt,
                          {status: InstructorStatus.PENDING},
                        )
                        .then(() => {
                          alert('Pre instructor status updated');
                          window.location.reload();
                        })
                        .catch((e) => {
                          alert(`Pre instructor status update error: ${e}`);
                        });
                    }}
                    style={{
                      backgroundColor: 'red',
                      color: 'white',
                      fontWeight: 'bold',
                    }}>
                    Pending
                  </Button>
                )}
              </div>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Gender
              </Text>
              <Select
                //@ts-ignore
                value={preInstructor.gender}
                showSearch
                style={{width: '100%'}}
                placeholder="Select Gender"
                optionFilterProp="children"
                onChange={(gender) => onChange('gender', gender)}>
                {[Gender.MALE, Gender.FEMALE, Gender.NONE]?.map((gender) => (
                  <Select.Option value={gender}>{gender}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <Row>
          <div
            style={{
              margin: 20,
            }}>
            <Checkbox
              checked={preInstructor?.isNative}
              onChange={(event) => onChange('isNative', event.target.checked)}>
              Is native ?
            </Checkbox>
          </div>
        </Row>
        <Row>
          <div
            style={{
              margin: 20,
            }}>
            <Checkbox
              checked={preInstructor?.isActive}
              onChange={(event) => onChange('isActive', event.target.checked)}>
              Active ?
            </Checkbox>
          </div>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Spoken language
              </Text>

              <Select
                mode="multiple"
                style={{width: '100%'}}
                value={preInstructor.speakingLanguages}
                placeholder="Enter Speaking languages"
                onChange={(selectedItem) => {
                  onChange('speakingLanguages', selectedItem);
                }}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{margin: '4px 0'}} />
                    <div
                      style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                      <Input
                        style={{flex: 'auto'}}
                        value={preInstructor.spokenLang}
                        onChange={(event) =>
                          onChange('spokenLang', event.target.value)
                        }
                      />
                      <a
                        style={{
                          flex: 'none',
                          padding: '8px',
                          display: 'block',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          const tempSpeakingLanguages = [
                            ...preInstructor.speakingLanguages,
                            preInstructor.spokenLang,
                          ];
                          onChange('speakingLanguages', tempSpeakingLanguages);
                          // onChange('spokenLang', null);
                        }}>
                        <PlusOutlined /> Add New language
                      </a>
                    </div>
                  </div>
                )}>
                {preInstructor?.speakingLanguages?.map((item: string) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Payment Method
              </Text>
              <Input
                onChange={(event) =>
                  onChange('paymentMethod', event.target.value)
                }
                value={preInstructor.paymentMethod}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                TimeZone
              </Text>
              <Select
                //@ts-ignore
                value={preInstructor.timezone}
                showSearch
                style={{width: '100%'}}
                placeholder="Select TimeZone"
                optionFilterProp="children"
                onChange={(value) => {
                  onChange('timezone', value);
                }}>
                {avaliableTimeZones?.map((timeZone) => (
                  <Select.Option value={timeZone}>{timeZone}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Program
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                //@ts-ignore
                value={preInstructor.program?.id}
                showSearch
                style={{width: '100%'}}
                placeholder="Select a Program"
                optionFilterProp="children"
                onChange={(programId) => onChange('program', programId)}>
                {programs?.map((prgm) => (
                  <Select.Option value={prgm.id!}>
                    {JSON.parse(prgm.name_i18n)['en']}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Country
              </Text>

              <Select
                showSearch
                style={{width: '100%'}}
                placeholder="Select a country"
                optionFilterProp="children"
                value={selectedCounty}
                onChange={(c) => {
                  // @ts-ignore
                  setSelectedCounty(c);
                  onChange(
                    'currency',
                    // @ts-ignore
                    CountriesWithCurrency.getAllInfoByISO(c).currency,
                  );
                }}>
                {countries?.map((country) => {
                  return (
                    <Select.Option value={country.iso}>
                      {country.countryName}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </Col>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Birthdate
              </Text>
              <DatePicker
                value={
                  preInstructor.birthDate && moment(preInstructor.birthDate)
                }
                onChange={(selectedDate) => {
                  onChange('birthDate', selectedDate.format('YYYY-MM-DD'));
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Currency
              </Text>
              <Input disabled={true} value={preInstructor.currency} />
            </div>
          </Col>
        </Row>
      </>
      <Button
        onClick={async () => {
          try {
            //console.log('MOREE_DATQ', preInstructor.userUpdatedAt);
            const updateUser: UserUpdate = {
              country: selectedCounty,
              timeZone: preInstructor.timezone,
              whatsappNumber: preInstructor.whatsapp,
            };

            await userService.updateUser(
              preInstructor.userId,
              preInstructor.userUpdatedAt,
              updateUser,
            );

            const assetsService = new AssetsService();
            //@ts-ignore
            const instructorAssetData: Asset = {
              ...preInstructor.promo,
            };
            if (instructorAssetData) {
              await assetsService.updateAsset(instructorAssetData);
            }
            const preInstructorObj: PreInstructorUpdate = {
              name: preInstructor.name,
              programId: preInstructor.program.id,
              gender: preInstructor.gender,
              speakingLanguages: preInstructor.speakingLanguages,
              isNative: preInstructor.isNative,
              isActive: preInstructor.isActive,
              status: preInstructor.status,
              birthDate: preInstructor.birthDate,
            };
            const UpdatedInstructor =
              await preInstructorService.updatePreInstructor(
                preInstructor.id,
                preInstructor.updatedAt,
                preInstructorObj,
              );
            //console.log('UpdatedInstructor', UpdatedInstructor);
            alert('Pre instructor updated');
            window.location.reload();
          } catch (error) {
            //console.log('EORRRRR', error);
            alert('Intructor create Error');
          }
        }}>
        Edit
      </Button>
      {isLoading && (
        <Spin
          size="large"
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#00000050',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
          }}
        />
      )}
    </div>
  );
};
