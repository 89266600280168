import {ISchoolService} from './ISchoolService';
import {SchoolRepository} from './SchoolRepository';
import {
  ClassesConnection,
  SchoolInsert,
  SchoolsConnection,
} from '../../graphql/API';

export class SchoolService implements ISchoolService {
  schoolRepository: SchoolRepository;
  constructor() {
    this.schoolRepository = new SchoolRepository();
  }
  async signUpSchool(email: string, password: string): Promise<any> {
    return await this.schoolRepository.signUpSchool(email, password);
  }

  async getSchools(
    nextToken: string,
    searchValue?: string,
  ): Promise<SchoolsConnection> {
    return await this.schoolRepository.getSchools(nextToken, searchValue);
  }

  async getClassesBySchoolId(schoolId: string): Promise<ClassesConnection> {
    return await this.schoolRepository.getClassesBySchoolId(schoolId);
  }

  async activateSubsctiptionInClass(
    classschoolId: string,
    discountcodeId: string,
    packageId: string,
  ): Promise<string> {
    return await this.schoolRepository.activateSubsctiptionInClass(
      classschoolId,
      discountcodeId,
      packageId,
    );
  }

  async createSchool(schoolInput: SchoolInsert): Promise<any> {
    return await this.schoolRepository.createSchool(schoolInput);
  }

  async createUser(
    cognitoUserId: string,
    country: string,
    whatsappNumber: string,
    timestamp: string,
  ): Promise<any> {
    let user = await this.schoolRepository.createUser(
      cognitoUserId,
      country,
      whatsappNumber,
      timestamp,
    );
    return user;
  }
}
