import React, {useEffect, useState, useCallback} from 'react';
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
} from 'antd';

import axios from 'axios';
import CountriesWithCurrency from 'iso-country-currency';
import {Image} from 'antd';

import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

import {
  AdminGetUserResponse,
  Asset,
  AssetInsert,
  AssetType,
  AttributeType,
  AvailabilityInsert,
  Gender,
  Instructor,
  InstructorInsert,
  Program,
  User,
} from '../../graphql/API';

import {ProgramsService} from '../Program';
import {InstructorService} from './InstructorService';
import {useHistory} from 'react-router-dom';
import {AssetsService} from '../Asset';
import {PlusOutlined} from '@ant-design/icons';
import moment from 'moment';
import {AccountService} from '../Accounts/AccountService';
import {SignUpOutput} from 'aws-amplify/auth';

const instructorService = new InstructorService();
const accountService = new AccountService();

const {Option} = Select;
const {RangePicker} = DatePicker;

export const daysOfTheWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const InstructorCreate = () => {
  const history = useHistory();

  const [countries, setCountries] = useState<Array<any>>([]);
  const [programs, setPrograms] = useState<Array<Program> | []>([]);
  const [avaliableTimeZones, setAvaliableTimeZones] = useState<
    Array<string> | []
  >([]);

  const [isEmailExist, setIsEmailExist] = useState<boolean>(true);
  const [isLoadingEmailCheck, setIsLoadingEmailCheck] =
    useState<boolean>(false);

  const [userEmailInfo, setUserEmailInfo] =
    useState<AdminGetUserResponse>(null);

  const [isEmailChecked, setIsEmailChecked] = useState<boolean>(false);

  const [instructorAvaliablities, setInstructorAvaliablities] = useState<
    Array<any> | []
  >([]);

  const [isError, setIsError] = useState<boolean>(false);

  const [instructor, setInstructor] = useState({
    email: '',
    password: '',
    name: '',
    whatsapp: '',
    thumbnailUri: '',
    fileUri: '',
    gender: Gender.MALE,
    speakingLanguages: [],
    spokenLang: '',
    paymentMethod: '',
    timezone: '',
    birthDate: '',
    program: undefined,
    currency: '',
    isNative: false,
    isActive: true,
  });

  const [selectedCounty, setSelectedCounty] = useState<string>('');

  const getAvaiableTimezone = useCallback(async () => {
    // axios
    // .get('http://worldtimeapi.org/api/timezone/' + value)
    // .then((response) => {
    //   //console.log(response);
    // });

    axios
      .get('https://worldtimeapi.org/api/timezone/')
      .then((response: any) => {
        //console.log(response);
        setAvaliableTimeZones(response.data);
      });
  }, []);

  useEffect(() => {
    getAvaiableTimezone();
    let programService = new ProgramsService();
    programService
      .getPrograms()
      .then((programs: any) => {
        setPrograms(programs);
      })
      .catch((error: any) => {
        setIsError(true);
        //console.log('Error ->', error);
      });
    setCountries(CountriesWithCurrency.getAllISOCodes());
  }, []);

  const onChange = (prop: any, value: any) => {
    if (prop === 'program') {
      //@ts-ignore
      let selectedProgram = programs.find((p) => p.id === value);
      setInstructor({...instructor, [prop]: selectedProgram});
    } else {
      setInstructor({...instructor, [prop]: value});
    }
  };

  const selectAnyCountryPrice = () => {
    setSelectedCounty('any');
    onChange('currency', 'USD');
  };

  //console.log('DSfsd', instructorAvaliablities);

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Create Instructor </Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Email
              </Text>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <Input
                  onChange={(event) => {
                    if (isEmailChecked) {
                      setIsEmailChecked(false);
                    }
                    onChange('email', event.target.value);
                  }}
                  value={instructor.email}
                />

                {!isEmailChecked && (
                  <Button
                    onClick={async () => {
                      if (instructor.email) {
                        try {
                          setIsLoadingEmailCheck(true);
                          const user: AdminGetUserResponse =
                            await accountService.checkEmail(instructor.email);
                          setUserEmailInfo(user);
                          setIsEmailChecked(true);
                          setIsEmailExist(true);
                          setIsLoadingEmailCheck(false);
                          const instructorEmail = user.UserAttributes.find(
                            (value: AttributeType) => value.Name === 'email',
                          );
                          //console.log('instructorEmail', instructorEmail.Value);
                          onChange('email', instructorEmail.Value);
                          alert('User Exist ');
                        } catch (error: any) {
                          alert(error.errors[0].message + ', Create New User');
                          setIsEmailChecked(true);
                          setIsEmailExist(false);
                          setIsLoadingEmailCheck(false);
                          setUserEmailInfo(null);
                        }
                      }
                    }}>
                    Check Email
                  </Button>
                )}
              </div>
            </div>
          </Col>
          {!isEmailExist && isEmailChecked && (
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Password
                </Text>
                <Input
                  type="password"
                  onChange={(event) => onChange('password', event.target.value)}
                  value={instructor.password}
                />
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Name
              </Text>
              <Input
                onChange={(event) => onChange('name', event.target.value)}
                value={instructor.name}
              />
            </div>
          </Col>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                BirthDate
              </Text>
              <DatePicker style={{width: '100%'}} onChange={() => {}} />
            </div>
          </Col>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                WhatsApp
              </Text>
              <Input
                type={'number'}
                onChange={(event) => onChange('whatsapp', event.target.value)}
                value={instructor.whatsapp}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Profile Url
              </Text>
              <Input
                onChange={(event) =>
                  onChange('thumbnailUri', event.target.value)
                }
                value={instructor.thumbnailUri}
              />
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Introduction Video Url
              </Text>
              <Input
                onChange={(event) => onChange('fileUri', event.target.value)}
                value={instructor.fileUri}
              />
            </div>
          </Col>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Gender
              </Text>
              <Select
                //@ts-ignore
                value={instructor.gender}
                showSearch
                style={{width: '100%'}}
                placeholder="Select Gender"
                optionFilterProp="children"
                onChange={(gender) => onChange('gender', gender)}>
                {[Gender.MALE, Gender.FEMALE, Gender.NONE]?.map((gender) => (
                  <Select.Option value={gender}>{gender}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <Row>
          <div
            style={{
              margin: 20,
            }}>
            <Checkbox
              checked={instructor?.isNative}
              onChange={(event) => onChange('isNative', event.target.checked)}>
              Is native ?
            </Checkbox>
          </div>
        </Row>

        <Row>
          <div
            style={{
              margin: 20,
            }}>
            <Checkbox
              checked={instructor?.isActive}
              onChange={(event) => onChange('isActive', event.target.checked)}>
              Active ?
            </Checkbox>
          </div>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Spoken language
              </Text>

              <Select
                mode="multiple"
                style={{width: '100%'}}
                value={instructor.speakingLanguages}
                placeholder="Enter Speaking languages"
                onChange={(selectedItem) => {
                  onChange('speakingLanguages', selectedItem);
                }}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{margin: '4px 0'}} />
                    <div
                      style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                      <Input
                        style={{flex: 'auto'}}
                        value={instructor.spokenLang}
                        onChange={(event) =>
                          onChange('spokenLang', event.target.value)
                        }
                      />
                      <a
                        style={{
                          flex: 'none',
                          padding: '8px',
                          display: 'block',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          const tempSpeakingLanguages = [
                            ...instructor.speakingLanguages,
                            instructor.spokenLang,
                          ];
                          onChange('speakingLanguages', tempSpeakingLanguages);
                          // onChange('spokenLang', null);
                        }}>
                        <PlusOutlined /> Add New language
                      </a>
                    </div>
                  </div>
                )}>
                {instructor.speakingLanguages.map((item: string) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Payment Method
              </Text>
              <Input
                onChange={(event) =>
                  onChange('paymentMethod', event.target.value)
                }
                value={instructor.paymentMethod}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                TimeZone
              </Text>
              <Select
                //@ts-ignore
                value={instructor.timezone}
                showSearch
                style={{width: '100%'}}
                placeholder="Select TimeZone"
                optionFilterProp="children"
                onChange={(value) => {
                  onChange('timezone', value);
                }}>
                {avaliableTimeZones?.map((timeZone) => (
                  <Select.Option value={timeZone}>{timeZone}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Program
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                //@ts-ignore
                value={instructor.program?.id}
                showSearch
                style={{width: '100%'}}
                placeholder="Select a Program"
                optionFilterProp="children"
                onChange={(programId) => onChange('program', programId)}>
                {programs?.map((prgm) => (
                  <Select.Option value={prgm.id!}>
                    {JSON.parse(prgm.name_i18n)['en']}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Country
              </Text>

              <Select
                showSearch
                style={{width: '100%'}}
                placeholder="Select a country"
                optionFilterProp="children"
                value={selectedCounty}
                onChange={(c) => {
                  // @ts-ignore
                  setSelectedCounty(c);
                  onChange(
                    'currency',
                    // @ts-ignore
                    CountriesWithCurrency.getAllInfoByISO(c).currency,
                  );
                }}>
                {countries?.map((country) => {
                  return (
                    <Select.Option value={country.iso}>
                      {country.countryName}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Currency
              </Text>
              <Input disabled={true} value={instructor.currency} />
            </div>
          </Col>
        </Row>
      </>

      <Row>
        {daysOfTheWeek?.map((day, index) => (
          <Col span={12}>
            <div style={{margin: 20, flexDirection: 'row'}}>
              <Text style={{display: 'block'}} strong={true}>
                {day}
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  // justifyContent: 'space-between',
                }}>
                <RangePicker
                  style={{marginInlineEnd: 30}}
                  // mode="time"
                  showToday
                  showTime
                  format="HH"
                  onOk={() => {}}
                  disabledDate={(current) => {
                    var a = moment(); //now
                    var b = moment(current, 'YYYY-MM-DD HH:mm:ss');
                    return a.diff(b, 'days') !== 0;
                  }}
                  separator={'TO'}
                  // placeholder="Select Start Time"
                  onChange={(date) => {
                    //console.log(date);
                    let _tempAvaliablities: Array<any> = [
                      ...instructorAvaliablities,
                    ];
                    const findIndex = instructorAvaliablities?.findIndex(
                      (e: any) => e.dayIndex === index,
                    );

                    if (date) {
                      let startTime = moment(date[0]).format('HH:mm:ss');
                      let endTime = moment(date[1]).format('HH:mm:ss');

                      if (findIndex === -1) {
                        //console.log('NEWWW', findIndex);

                        setInstructorAvaliablities([
                          ...instructorAvaliablities,
                          {
                            dayIndex: index,
                            startTime,
                            endTime,
                          },
                        ]);
                      } else {
                        //console.log('EDITTTTTT', findIndex);
                        _tempAvaliablities[findIndex] = {
                          dayIndex: index,
                          startTime,
                          endTime,
                        };
                        setInstructorAvaliablities([..._tempAvaliablities]);
                      }
                    } else if (findIndex !== -1) {
                      _tempAvaliablities.splice(findIndex, 1);
                      setInstructorAvaliablities([..._tempAvaliablities]);
                    }
                  }}
                />
              </div>
            </div>
          </Col>
        ))}
      </Row>
      {isLoadingEmailCheck && (
        <Spin
          size="large"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
      <Button
        onClick={async () => {
          if (isEmailChecked) {
            try {
              //console.log('MOREE_DATQ', instructor);
              let instructorUser: User | null = null;
              if (!isEmailExist) {
                const conginitoUser: SignUpOutput =
                  await instructorService.signUpInstructor(
                    instructor.email,
                    instructor.password,
                  );
                const cognitoUserId: string = conginitoUser.userId;
                //console.log('cognitoUserId', cognitoUserId);

                instructorUser = await instructorService.createUser(
                  cognitoUserId,
                  selectedCounty,
                  instructor.whatsapp,
                  instructor.timezone,
                );
                //console.log('instructorUser', instructorUser);
              } else {
                instructorUser = await accountService.getUserByCongintoId(
                  userEmailInfo.Username,
                );
              }

              const assetsService = new AssetsService();

              //@ts-ignore
              const instructorAssetData: AssetInsert = {
                type: AssetType.VIDEO,
                thumbnailUri: instructor.thumbnailUri,
                fileUri: instructor.fileUri,
              };
              const promoId: string = await assetsService.createAsset(
                instructorAssetData,
              );

              //console.log('instructorAsset', promoId);

              const instructorObj: InstructorInsert = {
                userId: instructorUser.id,
                name: instructor.name,
                programId: instructor.program.id,
                promoId,
                gender: instructor.gender,
                speakingLanguages: instructor.speakingLanguages,
                isNative: instructor.isNative,
                isActive: instructor.isActive,
              };
              const createdInstructor =
                await instructorService.createInstructor(instructorObj);

              await Promise.all(
                instructorAvaliablities.map(async (element) => {
                  const instruAvalitilty: AvailabilityInsert = {
                    instructorId: createdInstructor.id,
                    startTime: element.startTime,
                    endTime: element.endTime,
                    day: element.dayIndex,
                  };
                  return instructorService.createAvailability(instruAvalitilty);
                }),
              );
              //console.log('createdInstructor', createdInstructor);
              alert('Intructor Created');
              setInstructor({
                email: '',
                password: '',
                name: '',
                whatsapp: '',
                thumbnailUri: '',
                fileUri: '',
                gender: Gender.MALE,
                speakingLanguages: [],
                spokenLang: '',
                paymentMethod: '',
                timezone: '',
                birthDate: '',
                program: undefined,
                currency: '',
                isNative: false,
                isActive: false,
              });
              setIsEmailChecked(false);
              setIsEmailExist(true);
              setUserEmailInfo(null);
              setSelectedCounty('');
            } catch (error) {
              //console.log('EORRRRR', error);
              alert('Intructor create Error');
            }
          } else {
            alert('Check Email Address');
          }
        }}>
        Create
      </Button>
    </div>
  );
};
