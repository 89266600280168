import {ISchoolsRepository} from './ISchoolRepository';

import {
  SchoolsConnection,
  SchoolInsert,
  ClassesConnection,
} from '../../graphql/API';
import {schools} from './SchoolQueries';
import {createSchool, subscribeClassWithDiscountId} from './SchoolMutation';
import {signUp, SignUpOutput} from 'aws-amplify/auth';
import {createUser} from '../../graphql/mutations';
import {classesschool} from '../../graphql/queries';
import {generateClient, GraphQLResult} from 'aws-amplify/api';

const client = generateClient();

export class SchoolRepository implements ISchoolsRepository {
  async activateSubsctiptionInClass(
    classschoolId: string,
    discountcodeId: string,
    packageId: string,
  ): Promise<string> {
    const response: GraphQLResult<any> = await client.graphql({
      query: subscribeClassWithDiscountId,
      variables: {
        classschoolId,
        discountcodeId,
        packageId,
      },
    });

    console.log(
      'response.data?.subscribeClassWithDiscountId',
      response.data?.subscribeClassWithDiscountId,
    );

    return response.data?.subscribeClassWithDiscountId;
  }
  async createSchool(schoolInput: SchoolInsert): Promise<any> {
    const response: GraphQLResult<any> = await client.graphql({
      query: createSchool,
      variables: {
        input: {...schoolInput},
      },
    });

    console.log('response.data?.createSchool', response.data?.createSchool);
    return response.data?.createSchool;
  }

  async getSchools(
    nextToken: string,
    searchValue?: string,
  ): Promise<SchoolsConnection> {
    try {
      const filter = {name: {contains: searchValue}};

      const response: GraphQLResult<any> = await client.graphql({
        query: schools,
        variables: {
          nextToken,
          filter,
          ...(searchValue?.length > 0 ? null : {scannedCount: 101}),
        },
      });

      console.log('response.data?.schools', response.data?.schools);
      return response.data?.schools;
    } catch (e) {
      console.log('response Error->', e);
    }
  }

  async getClassesBySchoolId(schoolId: string): Promise<ClassesConnection> {
    try {
      const filter = {schoolId: {eq: schoolId}};

      const response: GraphQLResult<any> = await client.graphql({
        query: classesschool,
        variables: {
          filter,
        },
      });

      console.log('groupsdata?.sessions', response.data?.classesschool);
      return response.data?.classesschool;
    } catch (e) {
      console.log('response Error->', e);
    }
  }

  async signUpSchool(
    email: string,
    password: string,
  ): Promise<SignUpOutput | Error> {
    return await signUp({
      username: email,
      password: password,
    });
  }

  async createUser(
    cognitoUserId: string,
    country: string,
    whatsappNumber: string,
    timeZone: string,
  ): Promise<any> {
    const response: GraphQLResult<any> = await client.graphql({
      query: createUser,
      variables: {
        input: {
          cognitoUserId: cognitoUserId,
          country: country,
          whatsappNumber: whatsappNumber,
          timeZone: timeZone,
        },
      },
    });

    console.log('response.data.createUser', response.data.createUser);
    return response.data.createUser;
  }
}
