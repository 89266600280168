import {
  PreInstructor,
  PreInstructorUpdate,
  InstructorsFilter,
  PreInstructorsConnection,
  InstructorStatus,
} from '../../graphql/API';

import {
  deletePreInstructor,
  updatePreInstructor,
} from './PreInstructorMutation';
import {preInstructor, preInstructors} from './PreInstructorQueries';
import {IPreInstructorsRepository} from './IPreInstructorsRepository';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class PreInstructorsRepository implements IPreInstructorsRepository {
  async deletePreInstructor(preInstructorId: string): Promise<any> {
    try {
      const deletePreInstructorResponse: GraphQLResult<any> =
        await client.graphql({
          query: deletePreInstructor,
          variables: {
            id: preInstructorId,
          },
        });
      //console.log('deletePreInstructorResponse->', deletePreInstructorResponse);

      return deletePreInstructorResponse;
    } catch (error) {
      //console.log('Fetch plan Error: ', error);
      throw new Error(error);
    }
  }

  async getPreInstructorById(preInstructorId: string): Promise<PreInstructor> {
    try {
      const preInstructorById: GraphQLResult<any> = await client.graphql({
        query: preInstructor,
        variables: {
          id: preInstructorId,
        },
      });
      //console.log('preInstructorById->', preInstructorById);

      return preInstructorById.data?.preInstructor;
    } catch (error) {
      //console.log('Fetch plan Error: ', error);
      throw new Error(error);
    }
  }

  async getPreInstructors(
    status: InstructorStatus | null,
    nextToken: string | undefined,
  ): Promise<PreInstructorsConnection> {
    let filter: InstructorsFilter = {};
    if (typeof status !== 'undefined' && status !== null) {
      filter = {
        status: {eq: status},
      };
    }

    try {
      const preInstructorsList: GraphQLResult<any> = await client.graphql({
        query: preInstructors,
        variables: {
          filter,
          nextToken,
        },
      });
      //console.log('preInstructorById->', preInstructorsList);

      return preInstructorsList.data?.preInstructors;
    } catch (error) {
      //console.log('Fetch preInstructorById Error: ', error);
      throw new Error(error);
    }
  }

  async updatePreInstructor(
    preInstructorId: string,
    updatedAt: string,
    preInstructor: PreInstructorUpdate,
  ): Promise<any> {
    try {
      const updatePreInstructorResponse: GraphQLResult<any> =
        await client.graphql({
          query: updatePreInstructor,
          variables: {
            id: preInstructorId,
            updatedAt: updatedAt,
            input: preInstructor,
          },
        });
      //console.log('updatePreInstructorResponse->', updatePreInstructorResponse);

      return updatePreInstructorResponse;
    } catch (error) {
      //console.log('Fetch updatePreInstructorResponse Error: ', error);
      throw new Error(error);
    }
  }
}
