import {IIChannelRepository} from './IIChannelRepository';
import {segments, topics} from '../../graphql/queries';
import {
  CampaignInsert,
  SegmentConnection,
  SegmentInsert,
  TopicConnection,
} from '../../graphql/API';
import {
  createCampaign,
  createSegment,
  publishSNSMessageInTopic,
} from '../../graphql/mutations';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class ChannelRepository implements IIChannelRepository {
  async createCampaign(campaignInput: CampaignInsert): Promise<void> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: createCampaign,
        variables: {input: campaignInput},
      });
      console.log('createCampaign from repository:', response);

      return response?.data?.createCampaign;
    } catch (error) {
      console.log('Fetch campagin Error: ', error);
      throw new Error(error);
    }
  }
  async createUserSegment(segmentInput: SegmentInsert): Promise<void> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: createSegment,
        variables: {input: segmentInput},
      });
      console.log('createUserSegment from repository:', response);

      return response?.data?.createSegment;
    } catch (error) {
      console.log('Fetch campagin Error: ', error);
      throw new Error(error);
    }
  }

  async getTopicsList(
    nextToken: string,
    scannedCount: number,
  ): Promise<TopicConnection> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: topics,
        variables: {nextToken, scannedCount},
      });
      console.log('getTopicsList from repository:', response);

      return response?.data.topics;
    } catch (error) {
      console.log('Fetch getTopicsList Error: ', error);
      throw new Error(error);
    }
  }

  async getAllSegments(
    nextToken: string,
    scannedCount: number,
  ): Promise<SegmentConnection> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: segments,
        variables: {nextToken, scannedCount},
      });
      console.log('getAllSegments from repository:', response);

      return response?.data?.segments;
    } catch (error) {
      console.log('Fetch getAllSegments Error: ', error);
      throw new Error(error);
    }
  }

  async publishMessageInChannel(
    title: string,
    body: string,
    topicArn: string,
  ): Promise<void> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: publishSNSMessageInTopic,
        variables: {title, body, topicArn},
      });
      console.log('publishMessageInChannel from repository:', response);

      return response?.data.publishSNSMessageInTopic;
    } catch (error) {
      console.log('Fetch publishMessageInChannel Error: ', error);
      throw new Error(error);
    }
  }
}
