import {IEnrollmentsRepository} from './IEnrollmentsRepository';
import * as enrollmentQueries from './EnrollmentsQueries';
import {createEnrollment, updateEnrollment} from './EnrollmentsMutations';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();

export class EnrollmentsRepository implements IEnrollmentsRepository {
  async getEnrollments(nextToken: string): Promise<any> {
    try {
      const enrollments: GraphQLResult<any> = await client.graphql({
        query: enrollmentQueries.enrollments,
        variables: {
          nextToken,
          scannedCount: 40,
        },
      });
      //console.log('Enrollments->', enrollments);

      return enrollments.data?.enrollments;
    } catch (error) {
      //console.log('getEnrollments repository Error:', error);
      throw new Error(error);
    }
  }

  async getEnrollmentById(enrollmentId: string): Promise<any> {
    try {
      const enrollment: GraphQLResult<any> = await client.graphql({
        query: enrollmentQueries.enrollment,
        variables: {
          id: enrollmentId,
        },
      });
      //console.log('Enrollments->', enrollment);

      return enrollment.data?.enrollment;
    } catch (error) {
      //console.log('Fetch enrollment Error: ', error);
      throw new Error(error);
    }
  }

  async createEnrollment(enrollment: any): Promise<any> {
    try {
      const createEnrollmentResponse: GraphQLResult<any> = await client.graphql(
        {
          query: createEnrollment,
          variables: {
            input: enrollment,
          },
        },
      );
      //console.log('Enrollments->', enrollment);

      return createEnrollmentResponse.data?.createEnrollment;
    } catch (error) {
      //console.log('Fetch enrollment Error: ', error);
      throw new Error(error);
    }
  }

  async updateEnrollment(
    enrollmentId: string,
    updatedAt: string,
    enrollment: any,
  ): Promise<any> {
    try {
      const updateEnrollmentResponse: GraphQLResult<any> = await client.graphql(
        {
          query: updateEnrollment,
          variables: {
            id: enrollmentId,
            updatedAt: updatedAt,
            input: enrollment,
          },
        },
      );
      //console.log('updateEnrollmentResponse->', updateEnrollmentResponse);

      return updateEnrollmentResponse;
    } catch (error) {
      //console.log('Fetch enrollment Error: ', error);
      throw new Error(error);
    }
  }
}
