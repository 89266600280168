import {ICoursesService} from './ICoursesService';
import {CoursesRepository} from './CoursesRepository';
import {
  Course,
  CourseInsert,
  Language,
  Videosession,
  VideosessionInsert,
  VideosessionUpdate,
  VideosessionsConnection,
} from '../../graphql/API';

export class CoursesService implements ICoursesService {
  coursesRepository: CoursesRepository;
  constructor() {
    this.coursesRepository = new CoursesRepository();
  }

  async updateVideosession(
    id: string,
    updatedAt: string,
    videosession: VideosessionUpdate,
  ): Promise<Videosession> {
    return await this.coursesRepository.updateVideosession(
      id,
      updatedAt,
      videosession,
    );
  }

  async getVideoSession(videosessionId: string): Promise<Videosession> {
    return await this.coursesRepository.getVideoSession(videosessionId);
  }

  async getVideoSessions(): Promise<VideosessionsConnection> {
    let videoSessions: any = await this.coursesRepository.getVideoSessions();

    return videoSessions;
  }

  async getCourses(): Promise<any> {
    let stds: any = await this.coursesRepository.getCourses();
    console.log('stds-->', stds);
    stds = stds.map((cours) => {
      return {
        ...cours,
        plan: JSON.parse(cours.plan),
      };
    });

    return stds;
  }

  async getCoursesWithoutPlan(
    nextToken?: string,
    searchValue?: string,
  ): Promise<any> {
    let stds: any = await this.coursesRepository.getCoursesWithoutPlan(
      nextToken,
      searchValue,
    );
    console.log('stds-->', stds);

    return stds;
  }

  async getCourse(courseId: string): Promise<any> {
    let crse: any = await this.coursesRepository.getCourseById(courseId);
    console.log('stds-->', crse);

    return crse;
  }

  async createVideoSession(videoSession: VideosessionInsert): Promise<any> {
    return await this.coursesRepository.createVideoSession(videoSession);
  }
  async createCourse(course: CourseInsert): Promise<any> {
    return await this.coursesRepository.createCourse(course);
  }

  async getLanguages(): Promise<Language[]> {
    let lngs = await this.coursesRepository.getLanguages();
    //console.log('lngs-->', lngs);
    return lngs;
  }

  /*async getCourseById(courseId: string): Promise<Course> {
    let course = await this.coursesRepository.getCourseById(courseId);
    console.log('course from service ->', course);
    return course;
  }*/
}
