import {useEffect, useState} from 'react';
import {useParams, Link, useHistory} from 'react-router-dom';
import {
  Button,
  Select,
  Spin,
  Typography,
  Collapse,
  Row,
  Col,
  Input,
  Checkbox,
} from 'antd';

import {
  Course,
  CoursesConnection,
  Test,
  TestTypes,
  Videosession,
  VideosessionInsert,
  VideosessionUpdate,
  VideosessionsConnection,
} from '../../graphql/API';
import {TestsService} from '../Test';
import {CoursesService} from '../Courses';

const testsService = new TestsService();
const coursesService = new CoursesService();

export const VideoSessionEdit = () => {
  const history = useHistory();

  const [tests, setTests] = useState<Array<Test>>();

  const [videosession, setVideosession] = useState<Videosession | undefined>(
    undefined,
  );

  const [updateVideosession, setUpdateVideosession] =
    useState<VideosessionUpdate>();
  const [courses, setCourses] = useState<Course[]>();

  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let params: {videosessionId: string} = useParams();

  const {Panel} = Collapse;
  const {Title, Text} = Typography;

  useEffect(() => {
    testsService
      .getTests()
      .then((testsResponse) => {
        setTests(testsResponse);
      })
      .catch((e) => {
        alert('Fetching tests error');
        setIsLoading(false);
        setIsError(true);
      });

    coursesService
      .getCoursesWithoutPlan()
      .then((resp) => {
        console.log('course', resp);
        setCourses(resp);
      })
      .catch((error) => {
        alert('Fetching course error');
        console.log(error);
        setIsLoading(false);
        setIsError(true);
      });

    coursesService
      .getVideoSession(params.videosessionId)
      .then((resp: Videosession) => {
        console.log('videoSession?.name', resp.name);
        setIsLoading(false);
        setVideosession(resp);
      })
      .catch((error) => {
        alert('Fetching course error');
        console.log(error);
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  console.log('videoSession?.name', videosession?.name);
  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Video Session Edit</Title>

      {videosession && (
        <div>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  video session: {videosession.id}
                </Text>
              </div>
            </Col>

            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  curriculum name: {videosession?.curriculum?.name}
                </Text>
              </div>
            </Col>
            {/* <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  test name: {videosession?.test?.name}
                </Text>
              </div>
            </Col> */}
          </Row>

          <Row>
            <Col span={10}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Course
                </Text>

                <Select
                  showSearch
                  style={{width: '100%'}}
                  placeholder="Select a course"
                  optionFilterProp="children"
                  defaultValue={videosession?.course?.id}
                  onChange={(courseId) => {
                    const findCourse: Course = courses.find(
                      (curse: Course) => curse.id === courseId,
                    );

                    console.log('LSSSS', findCourse);
                    setUpdateVideosession({
                      ...updateVideosession,
                      curriculumId: findCourse.curriculum.id,
                      levelId: findCourse.level.id,
                      courseId: findCourse.id,
                    });
                  }}>
                  {courses?.map((curse: Course) => (
                    <Select.Option value={curse?.id!}>
                      {curse.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>

            <Col span={10}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Test
                </Text>
                <Select
                  showSearch
                  style={{width: '100%'}}
                  placeholder="Select a test"
                  optionFilterProp="children"
                  defaultValue={videosession?.test?.id}
                  onChange={(testId: string) => {
                    console.log('testId', testId);
                    const findTest = tests.find(
                      (test: Test) => test.id === testId,
                    );
                    setUpdateVideosession({
                      ...updateVideosession,
                      testId,
                      name: findTest?.name,
                    });
                    setVideosession({
                      ...videosession,
                      name: findTest?.name,
                    });
                  }}>
                  {tests?.map((tst) => {
                    if (tst.type === TestTypes.STORY) {
                      return (
                        <Select.Option value={tst.id!}>
                          {tst.name}
                        </Select.Option>
                      );
                    }
                  })}
                </Select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Session Name
                </Text>
                <Input
                  placeholder="Enter Session name"
                  defaultValue={videosession?.name}
                  value={videosession.name}
                  maxLength={25}
                  onChange={(event) => {
                    setUpdateVideosession({
                      ...updateVideosession,
                      name: event?.target?.value,
                    });
                    setVideosession({
                      ...videosession,
                      name: event?.target?.value,
                    });
                  }}
                />
              </div>
            </Col>

            <Col span={10}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Duration in minutes
                </Text>
                <Input
                  placeholder="Enter duration"
                  value={videosession.duration}
                  maxLength={3}
                  onChange={(event) => {
                    setUpdateVideosession({
                      ...updateVideosession,
                      duration: parseFloat(
                        event?.target?.value ? event?.target?.value : '0',
                      ),
                    });
                    setVideosession({
                      ...videosession,
                      duration: parseFloat(
                        event?.target?.value ? event?.target?.value : '0',
                      ),
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
          <Col>
            <div style={{margin: 20}}>
              <Checkbox
                checked={videosession?.isActive}
                onChange={(event) => {
                  setUpdateVideosession({
                    ...updateVideosession,
                    isActive: event.target.checked,
                    name: videosession.name,
                  });

                  setVideosession({
                    ...videosession,
                    isActive: event.target.checked,
                  });
                }}>
                Active
              </Checkbox>
            </div>
          </Col>
        </div>
      )}
      {videosession && (
        <div style={{width: '100%', textAlign: 'center', marginTop: '20px'}}>
          <Button
            style={{}}
            size={'large'}
            onClick={async () => {
              console.log('videeee', updateVideosession);
              if (
                updateVideosession?.courseId ||
                updateVideosession?.levelId ||
                updateVideosession?.testId ||
                updateVideosession?.duration ||
                updateVideosession?.curriculumId ||
                updateVideosession?.name ||
                updateVideosession?.isActive
              ) {
                console.log('Video session MIII', updateVideosession);
                try {
                  setIsLoading(true);
                  await coursesService.updateVideosession(
                    videosession.id,
                    videosession.updatedAt,
                    updateVideosession,
                  );
                  setIsLoading(false);

                  alert('updated Successfuly');

                  history.goBack();
                } catch (error) {
                  setIsLoading(false);

                  alert(
                    'Failed to create video session' + JSON.stringify(error),
                  );
                }
              }
            }}>
            Save
          </Button>
        </div>
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Course fetching error ⚠️</Title>}
    </div>
  );
};
