import {Link, useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Button, Menu} from 'antd';
import {signOut} from 'aws-amplify/auth';

import {getCurrentUserPermission, Permissions} from '../utils';
import {useDispatch, useSelector} from 'react-redux';
export const SiderComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentUserPermission, setCurrentUserPermission] =
    useState<Permissions>();
  const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      getCurrentUserPermission()
        .then((p) => {
          setCurrentUserPermission(p);
        })
        .catch((er) => {
          console.log('err->', er);
        });
    }
  }, [isLoggedIn]);

  const renderEditorTabs = () => (
    <>
      <Menu.Item key="students">
        <Link to={'/students'}>Students</Link>
      </Menu.Item>
      <Menu.Item key="enrollments">
        <Link to={'/enrollments'}>Enrollments</Link>
      </Menu.Item>

      <Menu.Item key="groups/*">
        <Link to={'/groups'}>Groups</Link>
      </Menu.Item>
      <Menu.Item key="Instructors">
        <Link to={'/instructors'}>Instructors</Link>
      </Menu.Item>
      <Menu.Item key="preInstructors">
        <Link to={'/preInstructors'}>Pre instructors</Link>
      </Menu.Item>

      <Menu.Item key="PromoCodes">
        <Link to={'/promocodes'}>Subscription Codes</Link>
      </Menu.Item>
      <Menu.Item key="DiscountCodes">
        <Link to={'/discountcodes'}>Discount Codes</Link>
      </Menu.Item>
    </>
  );
  const renderAdminTabs = () => (
    <>
      <Menu.Item key="videosessions">
        <Link to={'/videosessions'}>Video Session</Link>
      </Menu.Item>

      <Menu.Item key="tests">
        <Link to={'/tests'}>Tests</Link>
      </Menu.Item>
      <Menu.Item key="plans">
        <Link to={'/plans'}>Plans</Link>
      </Menu.Item>

      <Menu.Item key="lessons">
        <Link to={'/lessons'}>Lessons</Link>
      </Menu.Item>

      <Menu.Item key="levels">
        <Link to={'/levels'}>Levels</Link>
      </Menu.Item>

      <Menu.Item key="courses">
        <Link to={'/courses'}>Courses</Link>
      </Menu.Item>

      <Menu.Item key="packages">
        <Link to={'/packages'}>Packages</Link>
      </Menu.Item>
      <Menu.Item key="prices">
        <Link to={'/prices'}>Prices</Link>
      </Menu.Item>
      <Menu.Item key="programs">
        <Link to={'/programs'}>Programs</Link>
      </Menu.Item>
      <Menu.Item key="memberships">
        <Link to={'/memberships'}>Memberships</Link>
      </Menu.Item>

      <Menu.Item key="channels">
        <Link to={'/channels'}>Communication Channels</Link>
      </Menu.Item>
      <Menu.Item key="segments">
        <Link to={'/segments/create'}>Segment</Link>
      </Menu.Item>
      <Menu.Item key="accounts">
        <Link to={'/accounts/role'}>Accounts</Link>
      </Menu.Item>
      <Menu.Item key="schools">
        <Link to={'/schools'}>Schools</Link>
      </Menu.Item>
      <Menu.Item key="curriculums">
        <Link to={'/curriculums'}>Curriculums</Link>
      </Menu.Item>

      <Menu.Item key="syllables">
        <Link to={'/syllables'}>Syllables</Link>
      </Menu.Item>
    </>
  );

  const renderPartnerTabs = () => (
    <>
      <Menu.Item key="partners">
        <Link to={'/partners'}>Our Partners</Link>
      </Menu.Item>
    </>
  );

  const renderContentCreatorTabs = () => (
    <>
      <Menu.Item key="tests">
        <Link to={'/tests'}>Tests</Link>
      </Menu.Item>
    </>
  );

  return (
    <Menu theme="dark" mode="horizontal">
      {(currentUserPermission === 'editor' ||
        currentUserPermission === 'admin') &&
        renderEditorTabs()}
      {currentUserPermission === 'admin' && renderAdminTabs()}
      {currentUserPermission === 'partner' && renderPartnerTabs()}
      {currentUserPermission === 'CONTENT_CREATOR' &&
        renderContentCreatorTabs()}

      <Menu.Item style={{}} key="signOut">
        <Button
          onClick={async () => {
            await signOut();
            history.go(0);
          }}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );
};
