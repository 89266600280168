export const PackagePrices = `
query prices ($filter: PricesFilter, $scannedCount: Int, $nextToken: String) {
    prices (filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
        items {
            id
            createdAt
            updatedAt
            name
            price
            currency
            country
            package {
                id 
                name_i18n
            }
        }
        nextToken
        scannedCount
    }
}
`;
export const price = `
query price ($id: String!) {
    price (id: $id) {
        id
        createdAt
        updatedAt
        name
        price
        currency
        country
        package {
            id 
            name_i18n
        }
    }
}`;
