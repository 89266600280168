import {ILessonsService} from './ILessonsService';
import {LessonsRepository} from './LessonsRepository';
import {AssetType, Lesson, LessonInsert, LessonUpdate} from '../../graphql/API';
import {AssetsService} from '../Asset';
import {PricesService} from '../Price';

export class LessonsService implements ILessonsService {
  lessonsRepository: LessonsRepository;
  assetsService: AssetsService;
  pricesService: PricesService;
  constructor() {
    this.lessonsRepository = new LessonsRepository();
    this.assetsService = new AssetsService();
    this.pricesService = new PricesService();
  }

  async getLessons(): Promise<Array<Lesson> | any> {
    let lessons = await this.lessonsRepository.getLessons();
    // //console.log('getLessons from service -->', lessons);
    return lessons;
  }

  async getLessonById(lessonId: string): Promise<Lesson> {
    let programLesson = await this.lessonsRepository.getLessonById(lessonId);
    return programLesson;
  }

  async updateLesson(lesson: Lesson): Promise<any> {
    if (lesson?.lessonPDF?.type) {
      lesson.lessonPDF.type = AssetType.PDF;
      await this.assetsService.updateAsset(lesson.lessonPDF);
    }

    if (lesson?.instructionsPDF?.type) {
      lesson.instructionsPDF.type = AssetType.PDF;
      await this.assetsService.updateAsset(lesson.summary);
    }
    if (lesson?.summary?.type && lesson.instructionsPDF) {
      lesson.summary.type = AssetType.VIDEO;
      await this.assetsService.updateAsset(lesson.instructionsPDF);
    }

    let lessonToUpdate: LessonUpdate = {
      name: lesson.name,
      courseId: lesson?.course?.id,
    };
    return await this.lessonsRepository.updateLesson(
      lesson.id,
      lesson.updatedAt,
      lessonToUpdate,
    );
  }

  async createLesson(lesson: Lesson): Promise<any> {
    lesson.lessonPDF.type = AssetType.PDF;
    lesson.instructionsPDF.type = AssetType.PDF;
    lesson.summary.type = AssetType.VIDEO;
    //console.log('recieveed lesson->', lesson);
    let lessonToCreate: LessonInsert = {
      name: lesson.name,
      courseId: lesson?.course?.id,
    };
    lessonToCreate.lessonPDFId = await this.assetsService.createAsset(
      lesson.lessonPDF,
    );
    lessonToCreate.summaryId = await this.assetsService.createAsset(
      lesson.summary,
    );
    lessonToCreate.instructionsPDFId = await this.assetsService.createAsset(
      lesson.instructionsPDF,
    );
    // lesson.level.id && (lessonToCreate.levelId = lesson.level.id);

    //console.log('final lessonToCreate->', lessonToCreate);
    //TODO: Add validators
    return await this.lessonsRepository.createLesson(lessonToCreate);
  }
}
