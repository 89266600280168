import {Asset, AssetType} from '../graphql/API';
import {Button, Input, message, Row, Select, Upload, UploadProps} from 'antd';
import {useEffect, useState} from 'react';
import Text from 'antd/es/typography/Text';
import {UploadFile} from 'antd/lib/upload/interface';
import {CloseCircleFilled, UploadOutlined} from '@ant-design/icons';
import {StorageService, UploadFileType} from '../modules/Lesson/StorageService';

type CreateAssetTypeProps = {
  onChange: (asset: Asset) => void;
  defaultAssetType: AssetType;
  asset?: Asset | undefined;
  uploadFileType?: UploadFileType;
  syllableName?: string;
};
const storageService = new StorageService();

export const CreateAssetType = ({
  onChange,
  defaultAssetType,
  asset,
  uploadFileType,
  syllableName,
}: CreateAssetTypeProps) => {
  const [type, setType] = useState<AssetType>(asset?.type ?? defaultAssetType);
  const [thumbnailUri, setThumbnailUri] = useState<string>(
    asset?.thumbnailUri ?? '',
  );
  const [fileUri, setFileUri] = useState<string>(asset?.fileUri ?? '');
  const [uploading, setUploading] = useState(false);
  const [fileSummaryVideo, setFileSummaryVideo] = useState<UploadFile>();
  const [fileThumbnial, setFileThumbnial] = useState<UploadFile>();

  useEffect(() => {
    if (asset?.type) {
      setType(asset?.type);
    }
  }, [asset?.type]);

  useEffect(() => {
    if (asset) {
      setFileUri(asset.fileUri);
      setThumbnailUri(asset.thumbnailUri);
      // //console.log('asset', asset);
    }
  }, [asset]);





 

  return (
    <div>
      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <Text style={{display: 'block'}}>Asset type </Text>
        <Select
          value={type}
          showSearch
          style={{width: 200}}
          placeholder="Select a asset type"
          optionFilterProp="children"
          onChange={(selectedAssetType: AssetType) => {
            let tempAsset = {
              type: selectedAssetType,
              thumbnailUri: thumbnailUri,
              fileUri: fileUri,
            };
            setType(selectedAssetType);
            //@ts-ignore
            onChange(tempAsset);
          }}>
          <Select.Option value={AssetType.VIDEO}>
            {AssetType.VIDEO}
          </Select.Option>
          <Select.Option value={AssetType.PHOTO}>
            {AssetType.PHOTO}
          </Select.Option>
          <Select.Option value={AssetType.AUDIO}>
            {AssetType.AUDIO}
          </Select.Option>
        </Select>
        <Select.Option value={AssetType.PDF}>{AssetType.PDF}</Select.Option>
      </div>
     
    </div>
  );
};
