// TODO: add user to get student's parent
export const updateLesson = /* GraphQL */ `
  mutation UpdateLesson($id: ID!, $updatedAt: String!, $input: LessonUpdate!) {
    updateLesson(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      name
    }
  }
`;

export const createLesson = `
mutation createLesson ($input: LessonInsert!) {
    createLesson (input: $input) {
        id
        createdAt
        updatedAt
    }
}
`;
