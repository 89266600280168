import {useEffect, useState} from 'react';
import {Modal, Button, Row, Col, Spin} from 'antd';
import Text from 'antd/lib/typography/Text';
import {
  Availability,
  Instructor,
  Membership,
  Program,
  Slot,
  Status,
} from '../../graphql/API';
import {GroupService} from './GroupService';
import {ProgramsService} from '../Program';
import moment from 'moment-timezone';
import {daysOfTheWeek} from '../Instructor';
type PopUpModalProps = {
  visibleModal: boolean;
  title: string;
  programId: string;
  setVisibleModal: (value: boolean) => void;
  onChange: (instructor: Instructor, slot: Slot) => void;
};
const groupService = new GroupService();
let programService = new ProgramsService();

export const InstructorPopUpModal = ({
  visibleModal,
  title,
  programId,
  setVisibleModal,
  onChange,
}: PopUpModalProps) => {
  const [instructors, setInstructors] = useState<Array<Instructor> | []>([]);
  const [instructorSlots, setInstructorSlot] = useState<Array<Slot> | []>([]);
  const [selectedInstructor, setSelectedInstructor] = useState<Instructor>();
  const [selectedSlot, setSelectedSlot] = useState<Slot>();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (visibleModal) {
      setIsLoading(true);
      programService
        .getProgramById(programId)
        .then((program: Program) => {
          setInstructors(program.instructors);
          setIsLoading(false);
        })
        .catch((error: any) => {
          console.log('Error ->', error);
        });
    }
  }, [visibleModal, programId]);

  return (
    <Modal
      title={title}
      centered
      visible={visibleModal}
      okText={'Save'}
      onOk={() => {
        onChange(selectedInstructor, selectedSlot);
        setVisibleModal(false);
      }}
      onCancel={() => setVisibleModal(false)}
      width={1000}
      maskClosable={false}
      height={'95%'}
      bodyStyle={{overflowX: 'scroll'}}>
      {instructors?.length > 0 && (
        <Row>
          <Col span={12}>
            <div
              style={{
                margin: 20,
              }}>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginBottom: 20,
                  display: 'flex',
                }}>
                Add Instructor
              </Text>

              {instructors.map((instrctor: Instructor) => (
                <Button
                  onClick={() => {
                    let days = [];
                    setSelectedInstructor(instrctor);
                    instrctor?.availabilities?.forEach(
                      (availability: Availability) => {
                        availability?.slots?.forEach((slot) => {
                          let iterationDate: Date = new Date();
                          let nextDay: string = '';

                          for (let i = 1; i < 8; ++i) {
                            let now = new Date();
                            let nowDate = now.setDate(now.getDate() + i);
                            iterationDate = new Date(nowDate);

                            console.log('iterationDate: ' + iterationDate);
                            if (iterationDate.getDay() === slot.day) {
                              let firstSessionNumber =
                                iterationDate.toDateString() +
                                ' ' +
                                slot.startTime;
                              let firstSessionDate = new Date(
                                firstSessionNumber,
                              );
                              nextDay = firstSessionDate.toISOString();
                              break;
                            }
                          }
                          console.log('nextDay', nextDay);
                          const Index = days.findIndex(
                            (dy) =>
                              dy?.dayIndex ===
                              moment(nextDay, 'YYYY-MM-DD HH:mm:ss')
                                .utc(true)
                                .day(),
                          );
                          console.log(
                            'moment(nextDay).utc(true).day()',
                            moment(nextDay).utc(true).day(),
                          );

                          console.log('SLOT_DAY', slot.day);
                          console.log('SLOOOOOTTT', slot.id);

                          if (Index !== -1) {
                            days[Index] = {
                              dayIndex: moment(nextDay, 'YYYY-MM-DD HH:mm:ss')
                                .utc(true)
                                .day(),
                              slots: [...days[Index].slots, slot],
                            };
                          } else {
                            days.push({
                              dayIndex: moment(nextDay, 'YYYY-MM-DD HH:mm:ss')
                                .utc(true)
                                .day(),
                              slots: [slot],
                            });
                          }
                        });
                      },
                    );
                    // onChange('instructorId', instrctor.id);
                    setInstructorSlot(days);
                  }}
                  style={{
                    width: '40%',
                    height: 50,
                    backgroundColor:
                      selectedInstructor?.id === instrctor.id
                        ? '#40B2F2'
                        : '#ECECEC',
                    marginBottom: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    display: 'flex',
                    borderRadius: 8,
                  }}>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color:
                        selectedInstructor?.id === instrctor.id
                          ? '#fff'
                          : '#000',
                    }}>
                    {instrctor.name}
                  </Text>
                </Button>
              ))}
            </div>
          </Col>

          <Col span={11}>
            <div
              style={{
                margin: 20,
              }}>
              {selectedInstructor && (
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginBottom: 20,
                    display: 'flex',
                  }}>
                  Avaliablity for {selectedInstructor?.name}
                </Text>
              )}
              {instructorSlots.map((day: any) => (
                <>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}>
                    {daysOfTheWeek[day?.dayIndex]}
                  </Text>

                  {day.slots.map((slot: Slot) => (
                    <Button
                      hover={() => {}}
                      disabled={slot.status === Status.DONE}
                      onClick={() => {
                        // onChange('slotId', slot.id);
                        setSelectedSlot(slot);
                      }}
                      style={{
                        width: '70%',
                        height: 50,
                        backgroundColor:
                          selectedSlot?.id === slot.id ? '#40B2F2' : '#ECECEC',
                        marginBottom: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        borderRadius: 9,
                      }}>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: selectedSlot?.id === slot.id ? '#fff' : '#000',
                        }}>
                        {moment
                          .tz(slot.startTime, 'HH', 'UTC')
                          .clone()
                          .tz('Africa/Cairo')
                          .format('hh:mm A')}{' '}
                        -{' '}
                      </Text>

                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: selectedSlot?.id === slot.id ? '#fff' : '#000',
                        }}>
                        {' '}
                        {moment
                          .tz(slot.endTime, 'HH', 'UTC')
                          .clone()
                          .tz('Africa/Cairo')
                          .format('hh:mm A')}
                      </Text>
                    </Button>
                  ))}
                </>
              ))}
            </div>
          </Col>
        </Row>
      )}
    </Modal>
  );
};
