import {useEffect, useState} from 'react';
import Title from 'antd/es/typography/Title';
import {Button, Col, Input, Row, Typography} from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import {CreateAssetBlock} from '../../components';

import {AssetType, Syllable} from '../../graphql/API';

import {SyllablesService} from './SyllablesService';
import {cloneDeep} from 'lodash';
import {UploadFileType} from '../Lesson/StorageService';

const syllableService = new SyllablesService();

export const SyllableEdit = () => {
  let params: {syllableId: string} = useParams();
  const history = useHistory();
  const {Title, Text} = Typography;
  const [syllable, setSyllable] = useState<any>();
  const [letters, setLetters] = useState<string>('');
  // @ts-ignore
  const [correctSyllableAsset, setCorrectSyllableAsset] = useState<any>({
    type: AssetType.VIDEO,
    thumbnailUri: '',
    fileUri: '',
  });

  useEffect(() => {
    syllableService.getSyllableById(params.syllableId).then((s: Syllable) => {
      setSyllable(s);
      setLetters(s.letters);
      setCorrectSyllableAsset(s.correctSyllable);
    });
  }, []);

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Edit syllable</Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{marginTop: '20px', marginBottom: '20px'}}>
              <Text>Test name </Text>
              <Input
                value={letters}
                onChange={(e) => {
                  setLetters(e.target.value);
                }}
                placeholder="Syllable letters"
              />
            </div>
          </Col>

          <Col span={12}>
            <CreateAssetBlock
              syllableName={letters}
              uploadFileType={UploadFileType.SYLLABLE_VIDEO}
              asset={correctSyllableAsset}
              defaultAssetType={correctSyllableAsset?.type ?? null}
              // @ts-ignore TODO be checked
              onChange={(selectedAsset) => {
                setCorrectSyllableAsset(selectedAsset);
              }}
            />
          </Col>
        </Row>
      </>
      <Button
        onClick={async () => {
          if (letters.length === 0) {
            alert('Please enter the letters');
          }
          if (correctSyllableAsset.fileUri.length === 0) {
            alert('Please enter the file url');
          }
          if (correctSyllableAsset.thumbnailUri.length === 0) {
            alert('Please enter the thumbnail url');
          }
          let newSyllable = cloneDeep(syllable);
          let updatedCorrectAsset = cloneDeep(correctSyllableAsset);
          updatedCorrectAsset.id = syllable.correctSyllable.id;
          updatedCorrectAsset.updatedAt = syllable.correctSyllable.updatedAt;

          newSyllable.letters = letters;

          newSyllable.correctSyllable = updatedCorrectAsset;

          await syllableService
            .updateSyllable(syllable.id, syllable.updatedAt, newSyllable)
            .catch((err: any) => {
              //console.log('syllable creation error', err);
              alert(`Syllable creation failed ${err}`);
            });
          alert('Syllable updated');
          history.push('/syllables');
        }}>
        Edit
      </Button>
      <Button
        style={{backgroundColor: 'red', marginLeft: 20}}
        onClick={async () => {
          // eslint-disable-next-line no-restricted-globals
          if (confirm('Are you sure?')) {

            await syllableService
              .deleteSyllable(syllable.id)
              .catch((err: any) => {
                //console.log('syllable creation error', err);
                alert(`Syllable creation failed ${err}`);
              });
            alert('Syllable is deleted.');
            history.push('/syllables');
          }
        }}>
        Delete
      </Button>
    </div>
  );
};
