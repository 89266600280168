import {
  AuthSession,
  fetchAuthSession,
  fetchUserAttributes,
  FetchUserAttributesOutput,
} from 'aws-amplify/auth';
import {rememberDevice} from 'aws-amplify/auth';

export type Permissions =
  | 'admin'
  | 'editor'
  | 'partner'
  | 'notAuth'
  | 'CONTENT_CREATOR';

export const getCurrentUserPermission = async (): Promise<Permissions> => {
  let currentUser: FetchUserAttributesOutput = await fetchUserAttributes();
  let authUser: AuthSession = await fetchAuthSession();

  console.log('currentUser', currentUser);
  console.log(
    'authUser',
    authUser.tokens.accessToken.payload['cognito:groups'],
  );
  const userGroups: any =
    authUser?.tokens?.accessToken.payload['cognito:groups'];
  if (userGroups.some((value) => value === 'Admins')) {
    return 'admin';
  } else if (userGroups.some((value) => value === 'Editors')) {
    return 'editor';
  } else if (userGroups.some((value) => value === 'Partners')) {
    return 'partner';
  } else if (userGroups.some((value) => value === 'CONTENT_CREATOR')) {
    return 'CONTENT_CREATOR';
  } else {
    return 'notAuth';
  }
};

export const handleRememberDevice = async () => {
  try {
    console.log('REMBERDEVICE');
    await rememberDevice();
  } catch (error) {
    console.log('REMBERDEVICE_error', error);
  }
};
