import {Button, Image, Input, Popconfirm, Spin} from 'antd';
import {createRef, memo, useCallback, useEffect, useRef, useState} from 'react';
import {
  Gender,
  Message,
  SenderType,
  Student,
  User,
  Userconversation,
} from '../graphql/API';
import {useDispatch, useSelector} from 'react-redux';
import {
  acceptChat,
  createUserMessage,
  endChat,
  fetchMessagesByConversationId,
} from '../redux/actions/user';
import {
  CloseOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import ProfileStudent from '../assets/icons/child.png';

import UserProfile from '../assets/icons/pikdoIcon.png';
import {useHistory} from 'react-router-dom';
import moment from 'moment';

type ConversationProps = {
  userConversation: Userconversation;

  index: number;
  onClose: (convIndex: number) => void;
};

export const Conversation = ({
  userConversation,
  index,
  onClose,
}: ConversationProps) => {
  const dispatch = useDispatch();
  // @ts-ignore
  const textInput = useRef<Input>();
  const conversations: Array<Userconversation> = useSelector(
    (state: any) => state.user.conversations,
  );
  const [inputComment, setInputComment] = useState<string>('');
  const [isInstructorConversation, setIsInstructorConversation] =
    useState<boolean>(false);
  const history = useHistory();
  const [isConversationHasAdmin, setIsConversationHasAdmin] =
    useState<boolean>(false);

  const [chatPartnerName, setChatPartnerName] = useState<string>('');

  const [conversationIndex, setConversationIndex] = useState<number>(
    conversations.findIndex(
      (usrConv: Userconversation) => usrConv.id === userConversation.id,
    ),
  );

  const [isAvaliable, setIsAvaliable] = useState<boolean>(
    conversations.find(
      (usrConv: Userconversation) => usrConv.id === userConversation.id,
    )
      ? true
      : false,
  );
  const isLoading: Array<Userconversation> = useSelector(
    (state: any) => state.user.isLoading,
  );
  const messagesEndRef = createRef<any>();

  useEffect(() => {
    if (userConversation?.conversation?.id) {
      const nextToken = null;
      dispatch(
        fetchMessagesByConversationId(
          userConversation?.conversation?.id,
          nextToken,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToBottom = useCallback(() => {
    // messagesEndRef.current.scrollIntoView({behavior: 'smooth'});
  }, [messagesEndRef]);

  useEffect(() => {
    scrollToBottom();
    if (conversations) {
      const newConversationIndex = conversations.findIndex(
        (usrConv: Userconversation) => usrConv.id === userConversation.id,
      );
      setConversationIndex(newConversationIndex);

      if (newConversationIndex === -1) {
        setIsAvaliable(false);
      } else {
        setIsAvaliable(true);
      }
      getAdminsInConversation();
    }
  }, [conversations, scrollToBottom, userConversation.id]);

  const getAdminsInConversation = () => {
    let admins = [];

    conversations[conversationIndex]?.conversation?.userconversations?.map(
      (usrConv: Userconversation) => {
        if (usrConv.user?.preInstructor?.name) {
          setChatPartnerName(usrConv.user?.preInstructor?.name);
        } else if (usrConv?.user?.instructor?.name) {
          setChatPartnerName(usrConv?.user?.instructor?.name);
        } else if (
          userConversation?.conversation?.studentconversations[0]?.student?.name
        ) {
          setChatPartnerName(
            userConversation?.conversation?.studentconversations[0]?.student
              ?.name,
          );
        }
        if (usrConv.user?.admin?.id) {
          admins.push(usrConv.user?.admin);
        }
      },
    );
    if (admins.length === 1) {
      setIsConversationHasAdmin(true);
    } else {
      setIsConversationHasAdmin(false);
    }
  };

  const handleSentMessage = async (content: string) => {
    if (content !== '') {
      setInputComment('');
      dispatch(
        createUserMessage(
          content,
          userConversation.conversation.id,
          userConversation?.conversation?.studentconversations[0]?.student?.user
            .endPointArn,
        ),
      );
    }
  };

  const onScroll = useCallback(
    (event) => {
      var target = event.target;

      if (
        Math.abs(target.scrollTop) + target.offsetHeight ===
        target.scrollHeight
      ) {
        //@ts-ignore
        const isNextTokenNull = conversations[conversationIndex]?.nextToken
          ? false
          : true;
        if (!isNextTokenNull) {
          dispatch(
            fetchMessagesByConversationId(userConversation?.conversation?.id),
          );
        }
      }
    },
    [
      conversationIndex,
      conversations,
      dispatch,
      userConversation?.conversation?.id,
    ],
  );

  const getStudentOrInstructorId = () => {
    if (userConversation?.conversation?.studentconversations[0]?.student?.id) {
      return (
        <a
          onClick={() => {
            history.replace(
              `/students/` +
                userConversation?.conversation?.studentconversations[0]?.student
                  ?.id +
                `/edit`,
            );
          }}>
          {userConversation?.conversation?.studentconversations[0]?.student?.id}
        </a>
      );
    } else {
      return (
        userConversation?.conversation?.messages?.map((m: Message) => {
          if (m?.user?.instructor?.id) {
            return `instructor's ID: ${m?.user?.instructor?.id}`;
          } else if (m?.user?.preInstructor?.id) {
            return `preInstructor's ID: ${m?.user?.preInstructor?.id}`;
          }
        })[0] ?? 'No instructor has found in messages!!!'
      );
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        width: '20.5rem',
        height: '25.5rem',
        marginInlineStart: 15,
        background: '#ffffff',

        boxShadow: '0px 0px 10px 0px #00000026',

        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        paddingInline: 10,
        paddingBottom: 8,
        paddingTop: 8,
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: '#fff',
        overflow: 'hidden',
      }}
      key={userConversation.id}>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          padding: 5,
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <span
          className="nav-text"
          style={{
            display: 'flex',
            textAlign: 'left',
            fontWeight: 'bold',
            fontSize: 11,
            marginInlineEnd: 3,
            flexDirection: 'column',
          }}>
          student's ID:
          {getStudentOrInstructorId()}
        </span>
        <CloseOutlined
          onClick={() => {
            onClose(index);
          }}
        />
      </div>
      {isConversationHasAdmin ? (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}>
              <span className="nav-text" style={{fontWeight: 'bold'}}>
                {chatPartnerName}
              </span>
              <Popconfirm
                title="Are you sure？"
                onConfirm={() => {
                  dispatch(
                    endChat(
                      userConversation.id,
                      userConversation.conversation.id,
                    ),
                  );
                  onClose(index);
                }}
                icon={<QuestionCircleOutlined style={{color: 'red'}} />}>
                <a
                  style={{
                    fontWeight: 'bold',
                    color: 'white',
                    backgroundColor: '#F2406A',
                    padding: 7,
                    paddingInline: 10,
                    borderRadius: 3,
                  }}>
                  End Chat
                </a>
              </Popconfirm>
            </div>
            <div
              style={{
                backgroundColor: '#E6E7E8',
                height: 1,
                width: '100%',
                marginTop: 6,
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
              flex: 1,
              paddingBottom: 15,
              width: '100%',
              alignItems: 'flex-start',
              overflowY: 'scroll',
              height: '100%',
            }}
            onScroll={onScroll}>
            {isLoading && (
              <Spin
                size="large"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            )}
            {conversations[conversationIndex].conversation?.messages?.map(
              (messg: Message) => (
                <MessageLabel
                  sender={messg.student ?? messg.user}
                  content={messg?.content ?? ''}
                  senderType={messg?.senderType ?? SenderType.STUDENT}
                  chatPartnerName={
                    messg?.senderType === SenderType.STUDENT ||
                    messg?.senderType === SenderType.USER_INSTRUCTOR
                      ? chatPartnerName
                      : messg?.user?.admin?.firstName
                  }
                  studentGender={messg.student?.gender ?? Gender.NONE}
                  createdAt={messg?.createdAt ?? ''}
                />
              ),
            )}
            <div ref={messagesEndRef} />
          </div>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <Input
              ref={textInput}
              placeholder={'Add a new Message'}
              onChange={(e) => setInputComment(e.target.value)}
              value={inputComment}
            />
            <Button onClick={() => handleSentMessage(inputComment)}>
              <Text>Send</Text>
            </Button>
          </div>
        </>
      ) : isAvaliable ? (
        <div
          style={{
            backgroundColor: 'red',
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            bottom: '50%',
          }}>
          <Popconfirm
            title="You will be responsible for replying until solve student issues, after issue resolved end chat"
            onConfirm={() => {
              if (isAvaliable) {
                dispatch(
                  acceptChat(
                    userConversation.conversation.userconversations,
                    userConversation.conversation.id,
                  ),
                );
              }
            }}
            icon={<QuestionCircleOutlined style={{color: 'red'}} />}>
            <a
              style={{
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: 'green',
                padding: 7,
                paddingInline: 10,
                borderRadius: 3,
              }}>
              Accept Chat
            </a>
          </Popconfirm>
        </div>
      ) : (
        <Text
          style={{
            top: '50%',
            bottom: '50%',
            position: 'absolute',
            fontWeight: 'bold',
            color: 'red',
          }}>
          Chat was handled by another admin
        </Text>
      )}
    </div>
  );
};

type MessageLabelProps = {
  content: string;
  senderType: SenderType;
  chatPartnerName: string | null;
  studentGender: Gender;
  sender: Student | User;
  createdAt: string;
};

const MessageLabel = memo(
  ({
    chatPartnerName,
    content,
    senderType,
    studentGender,
    sender,
    createdAt,
  }: MessageLabelProps) => {
    const isSenderAdmin: boolean = senderType === SenderType.USER;
    const history = useHistory();

    const showDetails = () => {
      if (senderType === SenderType.STUDENT) {
        history.replace(`/students/` + sender.id + `/edit`);
      } else if (senderType === SenderType.USER_INSTRUCTOR) {
        //@ts-ignore
        if (sender?.preInstructor?.id) {
          history.replace(
            //@ts-ignore
            `/preInstructor/` + sender?.preInstructor?.id + `/edit`,
          );
          //@ts-ignore
        } else if (sender?.instructor?.id) {
          history.replace(
            //@ts-ignore
            `/instructor/` + sender?.instructor?.id + `/edit`,
          );
        }
      }
    };

    return (
      <div style={styles.mainContainer}>
        <div
          style={{
            display: 'flex',
            justifyContent: isSenderAdmin ? 'flex-start' : 'flex-end',
            alignItems: 'center',
          }}>
          {isSenderAdmin ? (
            <Image
              preview={false}
              src={UserProfile}
              alt=""
              style={styles.profileImg}
            />
          ) : (
            <>
              {studentGender === Gender.MALE ? (
                <Image
                  src={ProfileStudent}
                  alt=""
                  preview={false}
                  style={styles.profileImg}
                  onClick={showDetails}
                />
              ) : (
                <a>
                  <img
                    src={ProfileStudent}
                    alt=""
                    preview={false}
                    style={styles.profileImg}
                    onClick={showDetails}
                  />
                </a>
              )}
            </>
          )}
          <Text style={styles.studentNameTxt}>{chatPartnerName}</Text>
        </div>
        <div
          style={
            (styles.containerContent,
            {
              display: 'flex',
              justifyContent: isSenderAdmin ? 'flex-start' : 'flex-end',
            })
          }>
          <Text style={styles.messageTxt}>{content}</Text>
        </div>
        <Text
          style={{
            display: 'flex',
            justifyContent: isSenderAdmin ? 'flex-start' : 'flex-end',
            fontSize: 12,
            fontFamily: 'monospace',
          }}>
          {moment
            .tz(createdAt, 'YYYY-MM-DD HH:mm:ss', 'UTC')
            .clone()
            .tz('Africa/Cairo')
            .format('YYYY-MM-DD hh:mm:ss A')}
        </Text>
      </div>
    );
  },
);

const styles = {
  mainContainer: {
    alignSelf: 'center',
    marginBottom: 28,
    width: '100%',
  },

  studentNameTxt: {
    marginStart: 10,
    marginInlineEnd: 10,
    color: '#40B2F2',
    fontFamily: 'bold',
    fontSize: 14,
  },
  messageTxt: {
    fontFamily: 'bold',
    marginBottom: 5,
  },
  containerContent: {
    marginTop: 10,
  },
  profileImg: {
    width: 19,
    height: 19,
    resizeMode: 'contain',
    marginInlineEnd: 10,
  },
};
