import {IIGroupService} from './IIGroupsService';
import {GroupsRepository} from './GroupsRepository';
import {
  Group,
  GroupInsert,
  GroupUpdate,
  Instructor,
  DivisionType,
  Status,
  Session,
  GroupsConnection,
  SessionsConnection,
} from '../../graphql/API';
import moment from 'moment-timezone';

export class GroupService implements IIGroupService {
  GroupRepository: GroupsRepository;
  constructor() {
    this.GroupRepository = new GroupsRepository();
  }
  async startSession(sessionId: string): Promise<any> {
    return await this.GroupRepository.startSession(sessionId);
  }
  async levelUp(groupId: string, levelId: string): Promise<Group> {
    return await this.GroupRepository.levelUp(groupId, levelId);
  }
  async endSession(
    sessionId: string,
    updatedAt: string,
    status: Status,
  ): Promise<any> {
    return await this.GroupRepository.endSession(sessionId, updatedAt, status);
  }
  async updateGroup(
    groupId: string,
    updatedAt: string,
    input: GroupUpdate,
  ): Promise<any> {
    return await this.GroupRepository.updateGroup(groupId, updatedAt, input);
  }
  async deleteGroup(groupId: string): Promise<any> {
    return await this.GroupRepository.deleteGroup(groupId);
  }
  async startGroup(groupId: string): Promise<any> {
    return await this.GroupRepository.startGroup(groupId);
  }

  async getGroupById(id: string): Promise<Group> {
    return await this.GroupRepository.getGroupById(id);
  }
  async createGroup(group: GroupInsert): Promise<any> {
    const createdGroup = await this.GroupRepository.createGroup(group);
    return createdGroup;
  }

  async changeSessionStatus(
    sessionId: string,
    updatedAt: string,
    status: Status,
  ): Promise<any> {
    return await this.GroupRepository.changeSessionStatus(
      sessionId,
      updatedAt,
      status,
    );
  }

  async getGroups(nextToken: string): Promise<GroupsConnection> {
    return await this.GroupRepository.getGroups(nextToken);
  }

  async getActiveGroups(nextToken: string): Promise<GroupsConnection> {
    const isActive = true;
    return await this.GroupRepository.getActiveGroup(nextToken, isActive);
  }

  async getFinishedGroups(nextToken: string | null): Promise<GroupsConnection> {
    const fromRangeDate = moment().subtract(14, 'day').toISOString();
    const ToRangeDate: string = moment().toISOString();
    const isActive = false;

    return await this.GroupRepository.getFinishedGroup(
      nextToken,
      fromRangeDate,
      ToRangeDate,
      isActive,
    );
  }

  async getTodaySessions(
    nextToken: string | null = null,
  ): Promise<SessionsConnection> {
    const startDateTime = moment().subtract(1, 'd').toISOString();
    const endDateTime = moment().add(1, 'd').toISOString();
    let sessionsConnection: SessionsConnection;
    // do {
    sessionsConnection = await this.GroupRepository.getTodaySessions(
      nextToken,
      startDateTime,
      endDateTime,
    );
    // } while (sessionsConnection?.nextToken);

    //console.log('sessionsConnection', sessionsConnection);
    let sessionsFilterd: Session[] = [];

    sessionsConnection?.items?.forEach((session: Session) => {
      const sessionDate = moment
        .tz(session?.startDateTime, 'yyyy-MM-DD HH:mm:ss', 'UTC')
        .clone()
        .tz('Africa/Cairo')
        .format('yyyy-MM-DD');
      const today = moment().format('yyyy-MM-DD');
      const isSameDate = moment(sessionDate).isSame(today);
      //console.log('isSameee:', isSameDate);
      if (isSameDate) {
        sessionsFilterd.push(session);
      }
    });

    //console.log('sessionsFilterd', sessionsFilterd);
    sessionsConnection = {
      ...sessionsConnection,
      items: sessionsFilterd,
    };

    return sessionsConnection;
  }

  async filteredMemberships(
    programId: string,
    levelId: string,
    typeGroup: DivisionType,
  ): Promise<Instructor> {
    let filteredMemberships = await this.GroupRepository.filteredMemberships(
      programId,
      levelId,
      typeGroup,
    );

    return filteredMemberships;
  }
}
