export const students = /* GraphQL */ `
  query Students(
    $filter: StudentsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    students(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        status
        assignedTo
        name
        statistic {
          id
        }
        birthDate
        language {
          id
          country
        }
        age
        user {
          id
          whatsappNumber
          parent {
            id
            name
          }
        }
        gender
        notes
        facebookProfileUrl
        memberships {
          id
        }
        studentconversations {
          id
          conversation {
            id
            name
            isActive
            userconversations {
              id
              user {
                id
              }
            }
          }
        }
      }
      nextToken
      scannedCount
    }
  }
`;

export const student = /* GraphQL */ `
  query student($id: String!) {
    student(id: $id) {
      id
      createdAt
      updatedAt
      status
      assignedTo
      name
      birthDate
      age
      level {
        id
        name
      }
      user {
        id
        whatsappNumber
        parent {
          id
          name
        }
      }
      gender
      enrollments {
        id
        updatedAt
        createdAt
        startDate
        paymentStatus
        package {
          packageDuration
        }
      }
      memberships {
        id
        createdAt
        updatedAt
        isActive
        paymentStatus
      }
      language {
        id
        createdAt
        updatedAt
        language
        country
      }
      notes
      facebookProfileUrl
    }
  }
`;

export const studentWithFullReport = /* GraphQL */ `
  query student($id: String!) {
    student(id: $id) {
      id
      createdAt
      updatedAt
      status
      assignedTo
      name
      birthDate
      age
      user {
        id
        whatsappNumber
        parent {
          id
          name
        }
      }
      gender
      statistic {
        id
        createdAt
        updatedAt
        scores
        averageScore
        fullReportLastGeneratedDate
        phoneticsAsString
        syllablesAsString
        monthlyReports {
          id
          createdAt
          updatedAt
          month
          average
          mistakes
          attempts
          d1
          d2
          d3
          d4
          d5
          d6
          d7
          d8
          d9
          d10
          d11
          d12
          d13
          d14
          d15
          d16
          d17
          d18
          d19
          d20
          d21
          d22
          d23
          d24
          d25
          d26
          d27
          d28
          d29
          d30
          d31
        }
      }
    }
  }
`;

export const studentSessions = /* GraphQL */ `
  query student($id: String!) {
    student(id: $id) {
      id
      createdAt
      updatedAt
      name
      # user {
      #   id
      #   whatsappNumber
      #   parent {
      #     id
      #     name
      #   }
      # }
      sessions {
        id
        createdAt
        name
        startDateTime
        status
        group {
          id
          name
        }
        level {
          id
          name
        }
        lesson {
          id
          name
        }
        slot {
          id
          name
        }
        instructor {
          id
          name
        }
      }
    }
  }
`;

export const customGetUserById = /* GraphQL */ `
  query User($id: String!) {
    user(id: $id) {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      parent {
        id
        createdAt
        updatedAt
        name
        gender
      }
      admin {
        id
        createdAt
        updatedAt
        firstName
        lastName
        address
        SSN
        gender
        isActive
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        isNative
        totalRating
        totalReviews
        isActive
      }

      students {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        placementStatus
        level {
          id
          name
        }
      }

      userconversations {
        createdAt
        updatedAt
        id
      }
      deviceToken
      devicePlatform
      endPointArn
    }
  }
`;

export const customGetSyllablesByLetters = /* GraphQL */ `
  mutation GetSyllablesByLetters($lettersList: [String]) {
    getSyllablesByLetters(lettersList: $lettersList) {
      items {
        id
        createdAt
        updatedAt
        letters
        correctSyllable {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
          title_i18n
          subTitle_i18n
          position
        }
      }
      nextToken
      scannedCount
    }
  }
`;
