import {IPlansRepository} from './IPlansRepository';

import {getPlan} from './PlansQueries';
import {createPlan, updatePlan} from './PlansMutation';
import {PlanInsert, Plan} from '../../graphql/API';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class PlansRepository implements IPlansRepository {
  async createPlan(plan: PlanInsert): Promise<Plan> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: createPlan,
        variables: {
          input: plan,
        },
      });
      //console.log('createPackageResponse->', response);

      return JSON.parse(response.data?.createPlan);
    } catch (error) {
      //console.log('create plan Error: ', error);
      throw new Error(error);
    }
  }

  async getPlanById(planId: string): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: getPlan,
        variables: {
          id: planId,
        },
      });
      //console.log('createPackageResponse->', response);

      return JSON.parse(response.data?.getPlan);
    } catch (error) {
      //console.log('Fetch plan Error: ', error);
      throw new Error(error);
    }
  }

  async updatePlan(
    planId: string,
    updatedAt: string,
    newPlan: string,
  ): Promise<any> {
    //console.log('newPlan', newPlan);
    try {
      const updatePlanResponse: GraphQLResult<any> = await client.graphql({
        query: updatePlan,
        variables: {
          id: planId,
          updatedAt: updatedAt,
          input: {newPlan},
        },
      });
      //console.log('updatePlanResponse->', updatePlanResponse);

      return null;
    } catch (error) {
      //console.log('Fetch plan Error: ', error);
      throw new Error(error);
    }
  }
}
