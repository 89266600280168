export const updateUser = /* GraphQL */ `
  mutation UpdateUser($id: ID!, $updatedAt: String!, $input: UserUpdate!) {
    updateUser(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      cognitoUserId
    }
  }
`;
