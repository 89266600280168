import {
  Button,
  Checkbox,
  Col,
  Image,
  Input,
  Row,
  Space,
  Spin,
  Table,
} from 'antd';
import {Link} from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import moment from 'moment';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {get, isEqual} from 'lodash';

import {StudentsService} from './StudentsService';
import {useEffect, useRef, useState} from 'react';
import {
  Gender,
  Student,
  Studentconversation,
  StudentsConnection,
  Student_Status,
  Userconversation,
} from '../../graphql/API';

import ChatIcon from '../../assets/icons/chatt-icon.png';
import StatisticIcon from '../../assets/icons/statisticIcon.svg';

import Popconfirm from 'antd/es/popconfirm';
import {useDispatch, useSelector} from 'react-redux';
import {
  createPikadoConversation,
  createUserOnConversation,
  setLastSeenOnline,
} from '../../redux/actions/user';

const studentsService = new StudentsService();
export const StudentsTable = () => {
  const dispatch = useDispatch();
  const userConversation = useSelector(
    (state: any) => state.user.conversations,
  );
  const [students, setStudents] = useState<StudentsConnection>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [current, setCurrent] = useState(1);
  const [maxNumReachedPages, setMaxNumReachedPages] = useState(1);

  const [searchBy, setSearchBy] = useState<number>(0);
  const Timeout = useRef<any>();
  const [emptySeachValue, setEmptySeachValue] = useState<boolean>(true);
  const [searchedColumn, setSearchedColumn] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  let searchInput = useRef();

  useEffect(() => {
    if (emptySeachValue && current) {
      dispatch(setLastSeenOnline());

      studentsService
        .getStudents(students?.nextToken)
        .then((resp) => {
          const currentItems = students?.items ?? [];
          //console.log('students', resp);

          setStudents({
            ...students,
            ...resp,
            items: [...currentItems, ...resp.items],
          });

          setIsLoading(false);
        })
        .catch((error) => {
          setIsError(true);
          //console.log('Error ->', error);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, emptySeachValue]);

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{padding: 8}}>
        <Input
          ref={(node) => {
            // @ts-ignore
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchedColumn(dataIndex);
              setSearchText(selectedKeys[0]);
            }}>
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value: any, record: any) =>
      get(record, dataIndex)
        ? get(record, dataIndex)
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        // @ts-ignore
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text: any) =>
      isEqual(searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };

  const getStatusColor = (status: Student_Status): string => {
    switch (status) {
      case Student_Status.NEW_CUSTOMER:
        return '#EAD1DC';

      case Student_Status.ADDED_TO_WHATSAPP:
        return '#D0E2F2';

      case Student_Status.CONTACTED_BY_WHATSAPP:
        return '#D8EAD3';

      case Student_Status.RECEIVED_CALL:
        return '#FFF1CF';

      case Student_Status.INTERESTED:
        return '#B4A7D5';

      case Student_Status.NOT_INTERESTED:
        return '#E89A9A';
      case Student_Status.FOLLOW_UP:
        return '#E5B8B0';
      case Student_Status.CALLED_WITHOUT_RESPONSE:
        return '#D9D9D9';
      case Student_Status.JOINED:
        return '#3AA857';
      default:
        return '#FFFFFF';
    }
  };
  const columns: any = [
    {
      title: 'Name',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name);
      },
      render: (id: string, record: Student) => (
        <Link
          to={{
            pathname: '/students/' + record.id + '/edit',
            state: {student: record},
          }}>{`${record.name}`}</Link>
      ),
    },

    {
      title: 'Parent name',
      ...getColumnSearchProps(['user', 'parent', 'name']),
      dataIndex: ['user', 'parent', 'name'],
    },
    {
      title: 'Age',
      dataIndex: 'age',
      sorter: (a: any, b: any) => a.age - b.age,
    },
    {
      title: 'Number',
      dataIndex: ['user', 'whatsappNumber'],
      ...getColumnSearchProps(['user', 'whatsappNumber']),
      sorter: (a: any, b: any) =>
        a.user?.whatsappNumber - b.user?.whatsappNumber,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      sorter: (a: any, b: any) => a.gender.localeCompare(b.gender),
    },
    {
      title: 'Chat',
      dataIndex: 'studentconversations',
      sorter: (a: any, b: any) => {
        return a.language.country.localeCompare(b.language.country);
      },
      render: (
        studentconversations: Array<Studentconversation>,
        student: Student,
      ) => (
        <Popconfirm
          title="Do you want to contact with this student"
          onConfirm={() => {
            const findedPikadoTeamConversation: Studentconversation =
              studentconversations.find(
                (studentconversation: Studentconversation) =>
                  studentconversation.conversation.name === 'PikaDo Team' &&
                  studentconversation.conversation.isActive,
              );

            const isConversationFoundedOnUserConv = userConversation?.find(
              (usrCnv: Userconversation) =>
                usrCnv?.conversation?.id ===
                findedPikadoTeamConversation?.conversation?.id,
            );
            if (!isConversationFoundedOnUserConv) {
              if (findedPikadoTeamConversation) {
                dispatch(
                  createUserOnConversation(
                    findedPikadoTeamConversation.conversation.id,
                  ),
                );
              } else {
                dispatch(createPikadoConversation(student.id));
              }
            }
          }}
          style={{
            border: 'none',
            width: 40,
            height: 40,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            padding: 8,
            backgroundColor: 'transparent',
          }}>
          <Image
            preview={false}
            src={ChatIcon}
            alt=""
            onClick={() => {}}
            style={{
              width: 40,
              height: 40,
              padding: 2,
            }}
          />
        </Popconfirm>
      ),
    },
    {
      title: 'Report',
      dataIndex: ['statistic', 'id'],
      render: (statisticId: string, student: Student) => (
        <Link
          to={{
            pathname: `/students/${student.id}/report/${statisticId}`,
          }}>
          <Image
            preview={false}
            src={StatisticIcon}
            alt=""
            onClick={() => {}}
            style={{
              width: 40,
              height: 40,
              padding: 2,
            }}
          />
        </Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a: any, b: any) => a.status?.localeCompare(b.status),
      render: (studentStatus: Student_Status) => (
        <p style={{backgroundColor: getStatusColor(studentStatus)}}>
          {studentStatus}
        </p>
      ),
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: 'Assigned to',
      dataIndex: 'assignedTo',
      sorter: (a: any, b: any) => a.assignedTo?.localeCompare(b.assignedTo),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
      sorter: (a: any, b: any) => {
        return a.id.localeCompare(b.id);
      },
      sortDirections: ['descend'],
      render: (id: string, record: Student) => (
        <Link
          to={{
            pathname: '/students/' + record.id + '/edit',
            state: {student: record},
          }}>
          {id}
        </Link>
      ),
      filterSearch: true,
    },
  ];

  const onChangeLogic = ({current}, size) => {
    //console.log('CURRRRR_sizeChangeLogic', current);

    if (current >= maxNumReachedPages) {
      setCurrent(current);
      setMaxNumReachedPages(current);
    }
  };

  function toggleCheck(numb: number) {
    //console.log(`checked = `);
    setSearchBy(numb);
    setEmptySeachValue(true);
  }

  const searchStudents = (nextToken: string, textSearch?: string) => {
    const searchType = searchBy === 1 ? 'ID' : 'NAME';

    studentsService
      .getStudents(nextToken, searchType, textSearch)
      .then((stds) => {
        setStudents({
          ...stds,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        //console.log('Error ->', error);
      });
  };

  return (
    <>
      <Row
        style={{
          margin: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Col>
          <Checkbox checked={searchBy === 0} onChange={() => toggleCheck(0)}>
            SearchBy Name
          </Checkbox>
          <Checkbox checked={searchBy === 1} onChange={() => toggleCheck(1)}>
            SearchBy ID
          </Checkbox>

          <Input
            style={{marginTop: 10}}
            placeholder="Search All"
            onChange={(event) => {
              setIsLoading(true);
              if (event.target.value.length === 0) {
                setEmptySeachValue(true);
              } else {
                setEmptySeachValue(false);
              }

              if (Timeout.current) {
                clearTimeout(Timeout.current);
              }
              Timeout.current = setTimeout(() => {
                searchStudents(null, event.target.value);
              }, 1100);
            }}
          />
        </Col>
        <Col>
          <Button size={'large'} onClick={() => alert('In progress')}>
            Create
          </Button>
        </Col>
      </Row>
      {!isError && !isLoading && (
        <Table
          pagination={{pageSizeOptions: ['100'], pageSize: 100}}
          current={current}
          defaultPageSize={100}
          // onShowSizeChange={sizeChangeLogic}
          onChange={onChangeLogic}
          // showSizeChanger={true}
          columns={columns}
          dataSource={students?.items}
        />
      )}

      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Enrollments fetching error ⚠️</Title>}
    </>
  );
};
