import {useDispatch, useSelector} from 'react-redux';
import {Conversation, Userconversation} from '../graphql/API';
import {setGroupConversations} from '../redux/actions/user';
import {Conversation as ConversationComponent} from './Conversation';
import {GroupConversation} from './GroupConversation';

export const Chats = ({selectedConversations, setSelectedConversations}) => {
  const groupConversations: Array<Conversation> = useSelector(
    (state: any) => state.user.groupConversations,
  );
  const dispatch = useDispatch();
  console.log('process.env.NODE_ENV', process.env.NODE_ENV);
  return (
    <div
      style={{
        backgroundColor: 'transparent',
        textAlign: 'right',
        bottom: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        alignSelf: 'flex-start',
        marginInlineEnd: 210,
        zIndex: 100,
        position: 'fixed',
        overflowX: 'auto',
      }}>
      {groupConversations?.map((conver, index: number) => (
        <GroupConversation
          index={index}
          onClose={(convIndex) => {
            const groupConverss = [...groupConversations];
            groupConverss.splice(index, 1);
            dispatch(setGroupConversations([...groupConverss]));
          }}
          conversation={conver}
        />
      ))}
      {selectedConversations.map(
        (usrConver: Userconversation, index: number) => (
          <ConversationComponent
            index={index}
            onClose={(convIndex) => {
              selectedConversations.splice(convIndex, 1);
              setSelectedConversations([...selectedConversations]);
            }}
            userConversation={usrConver}
          />
        ),
      )}
    </div>
  );
};
