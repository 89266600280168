// TODO: add user to get student's parent
export const createCurriculum = /* GraphQL */ `
  mutation CreateCurriculum($input: CurriculumInsert!) {
    createCurriculum(input: $input) {
      id
      createdAt
      updatedAt
      name
      courses {
        id
        createdAt
        updatedAt
        name
      }
    }
  }
`;
