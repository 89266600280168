import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Spin, Table} from 'antd';
import moment from 'moment';

import {Test} from '../../graphql/API';
import {TestsService} from './TestsService';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

const testsService = new TestsService();

export const TestsTable = () => {
  const [tests, setTests] = useState<Array<Test> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    testsService
      .getTests()
      .then((resp) => {
        setIsLoading(false);
        setTests(resp);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);
  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a: any, b: any) => a.id.length - b.id.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (id: string) => <Link to={'/tests/' + id}>{id}</Link>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: any, b: any) => {
        return a?.name.localeCompare(b?.name);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: (a: any, b: any) => {
        return a?.type.localeCompare(b?.type);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => {
        return a?.createdAt.localeCompare(b?.createdAt);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  return (
    <>
      <Button size={'large'} style={{float: 'right', margin: 20}}>
        <Link to={'/tests/create'}>Create</Link>
      </Button>
      {!isError && !isLoading && (
        <Table
          pagination={{defaultPageSize: 100}}
          columns={columns}
          dataSource={tests}
          onChange={onChange}
        />
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Tests fetching error ⚠️</Title>}
    </>
  );
};
