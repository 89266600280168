import {UploadFile} from 'antd/lib/upload/interface';
import {StorageRepository} from './StorageRepository';
import ConfigAWS from '../../amplifyconfiguration.json';
import {Amplify} from 'aws-amplify';

export interface IStorageService {
  uploadFile(fileUri: UploadFile, typeUpload: UploadFileType): Promise<any>;
}

export enum UploadFileType {
  LESSION_PDF = 'LESSION_PDF',
  LESSION_PDF_THUMBNIAL = 'LESSION_PDF_THUMBNIAL',
  LESSION_SUMMARY_VIDEO = 'LESSION_SUMMARY_VIDEO',
  LESSION_SUMMARY_VIDEO_THUMBNIAL = 'LESSION_SUMMARY_VIDEO_THUMBNIAL',
  INSTRUCTION_LESSON_PDF = 'INSTRUCTION_LESSON_PDF',
  INSTRUCTION_LESSON_PDF_THUMBNIAL = 'INSTRUCTION_LESSON_PDF_THUMBNIAL',
  TEST_MEDIA = 'TEST_MEDIA',
  TEST_THUMBNIAL = 'TEST_THUMBNIAL',
  SYLLABLE_THUMBNAIL = 'SYLLABLE_THUMBNAIL',
  SYLLABLE_VIDEO = 'SYLLABLE_VIDEO',
  INSTRUCTOR_INTRO_VIDEO = 'INSTRUCTOR_INTRO_VIDEO',
  INSTRUCTOR_PROFILE_URL = 'INSTRUCTOR_PROFILE_URL',
  TEST_MEDIA_VIDEO_SESSION = 'TEST_MEDIA_VIDEO_SESSION',
}

export class StorageService implements IStorageService {
  storageRepository: StorageRepository;
  constructor() {
    this.storageRepository = new StorageRepository();
  }

  async uploadFile(
    fileUri: UploadFile,
    typeUpload: UploadFileType,
    subFolder?: string,
  ): Promise<any> {
    //console.log('fileUri', fileUri);
    let fileName: string;
    let parentFolder: string;
    let bucketName: string = ConfigAWS.aws_user_files_s3_bucket;

    // To be checked: https://pikado.atlassian.net/browse/PIK-141
    bucketName = bucketName.replace('uploads', 'assets');
    let accessLevel = 'public';

    switch (typeUpload) {
      case UploadFileType.INSTRUCTION_LESSON_PDF:
        parentFolder = 'lessons/';
        fileName =
          (subFolder ? `${subFolder}/` : '') +
          new Date().toISOString() +
          '-' +
          fileUri.name;
        break;

      case UploadFileType.INSTRUCTION_LESSON_PDF_THUMBNIAL:
        parentFolder = 'lessons/';
        fileName =
          (subFolder ? `${subFolder}/` : '') +
          new Date().toISOString() +
          '-' +
          fileUri.name;
        break;

      case UploadFileType.LESSION_PDF:
        parentFolder = 'lessons/';
        fileName =
          (subFolder ? `${subFolder}/` : '') +
          new Date().toISOString() +
          '-' +
          fileUri.name;
        break;

      case UploadFileType.LESSION_PDF_THUMBNIAL:
        parentFolder = 'lessons/';
        fileName =
          (subFolder ? `${subFolder}/` : '') +
          new Date().toISOString() +
          '-' +
          fileUri.name;
        break;

      case UploadFileType.LESSION_SUMMARY_VIDEO:
        parentFolder = 'lessons/';
        fileName =
          (subFolder ? `${subFolder}/` : '') +
          new Date().toISOString() +
          '-' +
          fileUri.name;
        break;

      case UploadFileType.LESSION_SUMMARY_VIDEO_THUMBNIAL:
        parentFolder = 'lessons/';
        fileName =
          (subFolder ? `${subFolder}/` : '') +
          new Date().toISOString() +
          '-' +
          fileUri.name;
        break;

      case UploadFileType.TEST_MEDIA:
        parentFolder = 'exercises/';
        fileName =
          (subFolder ? `${subFolder}/` : '') +
          new Date().toISOString() +
          '-' +
          fileUri.name;
        break;
      case UploadFileType.SYLLABLE_THUMBNAIL:
        parentFolder = 'syllables/';
        fileName =
          (subFolder ? `${subFolder}/` : '') +
          new Date().toISOString() +
          '-' +
          fileUri.name;
        break;
      case UploadFileType.SYLLABLE_VIDEO:
        parentFolder = 'syllables/';
        fileName =
          (subFolder ? `${subFolder}/` : '') +
          new Date().toISOString() +
          '-' +
          fileUri.name;
        break;

      case UploadFileType.TEST_MEDIA_VIDEO_SESSION:
        parentFolder = 'videosession/';
        fileName =
          (subFolder ? `${subFolder}/` : '') +
          new Date().toISOString() +
          '-' +
          fileUri.name;
        break;

      case UploadFileType.INSTRUCTOR_INTRO_VIDEO:
        parentFolder = '';
        bucketName = bucketName.replace('assets', 'uploads');

        fileName =
          (subFolder ? `${subFolder}/` : '') +
          new Date().toISOString() +
          '-' +
          fileUri.name;
        break;

      case UploadFileType.INSTRUCTOR_PROFILE_URL:
        parentFolder = '';
        bucketName = bucketName.replace('assets', 'uploads');
        fileName =
          (subFolder ? `${subFolder}/` : '') +
          new Date().toISOString() +
          '-' +
          fileUri.name;
        break;
      default:
        return Promise.reject('Method not found');
    }

    Amplify.configure({
      ...ConfigAWS,
      //@ts-ignore
      aws_user_files_s3_bucket: bucketName,
      Storage: {
        S3: {
          region: ConfigAWS.aws_user_files_s3_bucket_region, // (required) - Amazon S3 bucket region
          bucket: bucketName, // (required) - Amazon S3 bucket URI
        },
      },
    });

    //console.log('fileName', fileName);
    let response: any;
    if (
      typeUpload === UploadFileType.INSTRUCTOR_PROFILE_URL ||
      typeUpload === UploadFileType.INSTRUCTOR_INTRO_VIDEO
    ) {
      accessLevel = 'public';
      response = await this.storageRepository.uploadAudioFileEndpoint(
        fileName,
        fileUri,
        parentFolder,
        undefined,
        accessLevel,
      );
    } else {
      response = await this.storageRepository.uploadFile(
        fileName,
        fileUri,
        parentFolder,
        accessLevel,
      );
    }
    //console.log('response', response);
    return (
      process.env.REACT_APP_BASE_ASSET_PIKADO_IO_URL +
      bucketName +
      '/' +
      response?.path
    );
  }
}
