// TODO: add user to get student's parent
export const updatePackage = `
mutation updatePackage ($id: ID!, $updatedAt: String!, $input: PackageUpdate!) {
    updatePackage (id: $id, updatedAt: $updatedAt, input: $input) {
        id
        createdAt
        updatedAt
    }
}
`;

export const createPackage = `
mutation createPackage ($input: PackageInsert!) {
    createPackage (input: $input) {
        id
        createdAt
        updatedAt
    }
}
`;
