import {useEffect, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import {Button, Col, Row, Spin, Typography} from 'antd';
import {Package} from '../../graphql/API';
import {PackagesService} from './PackagesService';
import {PricesService} from '../Price';

const packagesService = new PackagesService();
const pricesService = new PricesService();
export const PackageDetails = () => {
  const [programPackage, setProgramPackage] = useState<Package | undefined>(
    undefined,
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  let params: {packageId: string} = useParams();

  const {Title, Text} = Typography;

  useEffect(() => {
    pricesService.prices().then((e) => {
      ////console.log('e->', e);
    });
    packagesService
      .getPackageById(params.packageId)
      .then((resp) => {
        // //console.log('res->', resp);
        setIsLoading(false);
        setProgramPackage(resp);
      })
      .catch((error) => {
        //console.log('er->', error);
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Package </Title>
      <Button size={'large'} style={{float: 'right'}}>
        <Link
          // @ts-ignore
          to={{
            pathname: '/packages/' + programPackage?.id + '/edit',
            state: {package: programPackage},
          }}>
          Edit
        </Link>
      </Button>
      {!isError && !isLoading && (
        <>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Package id
                </Text>
                <Link to={'/package/' + programPackage?.id}>
                  {programPackage?.id}
                </Link>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Package name
                </Text>
                <Text>{programPackage?.name}</Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Package description
                </Text>
                <Text>{programPackage?.description}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Only AI
                </Text>
                <Text>{programPackage?.onlyAI ? 'Yes' : 'No'}</Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Promo asset id
                </Text>
                <Text>{programPackage?.promoAsset?.id}</Text>
                <Text style={{display: 'block'}} strong={true}>
                  Promo Type
                </Text>
                <Text>{programPackage?.promoAsset?.type}</Text>
                <Text style={{display: 'block'}} strong={true}>
                  Promo file
                </Text>
                <Link to={programPackage?.promoAsset?.fileUri ?? ''}>
                  {programPackage?.promoAsset?.fileUri}
                </Link>
                <Text style={{display: 'block'}} strong={true}>
                  Promo thumbnail
                </Text>
                <Link to={programPackage?.promoAsset?.thumbnailUri ?? ''}>
                  {programPackage?.promoAsset?.thumbnailUri}
                </Link>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Cover asset id
                </Text>
                <Text>{programPackage?.coverAsset?.id}</Text>
                <Text style={{display: 'block'}} strong={true}>
                  Cover Type
                </Text>
                <Text>{programPackage?.coverAsset?.type}</Text>
                <Text style={{display: 'block'}} strong={true}>
                  Cover file
                </Text>
                <Link to={programPackage?.coverAsset?.fileUri ?? ''}>
                  {programPackage?.coverAsset?.fileUri}
                </Link>
                <Text style={{display: 'block'}} strong={true}>
                  Promo thumbnail
                </Text>
                <Link to={programPackage?.coverAsset?.thumbnailUri ?? ''}>
                  {programPackage?.coverAsset?.thumbnailUri}
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Number
                </Text>
                <Text>{programPackage?.number}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Package duration
                </Text>
                <Text>{programPackage?.packageDuration}</Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Benefits
                </Text>
                {programPackage?.benefits &&
                  JSON.parse(programPackage.benefits)?.map((e: any) => {
                    return (
                      <Text>
                        {`- ${e}`}
                        <br />
                      </Text>
                    );
                  })}
              </div>
            </Col>

            <Col span={12}>
              <div style={{margin: 20}}>
                <Link
                  // @ts-ignore
                  to={{
                    pathname: '/prices',
                  }}>
                  Prices
                </Link>
              </div>
            </Col>
          </Row>
        </>
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Package fetching error ⚠️</Title>}
    </div>
  );
};
