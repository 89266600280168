import {createStore, applyMiddleware} from 'redux';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';

import rootReducer from './reducers'; // the value from combineReducers
import {persistReducer} from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware: any = [];

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware, thunk)),
);

export {store};
