import {useEffect, useRef, useState} from 'react';
import Title from 'antd/es/typography/Title';
import moment from 'moment';
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import {useHistory, useLocation, useParams, Link} from 'react-router-dom';
import {
  Membership,
  Level,
  Package,
  Status,
  Student,
  Test,
  Enrollment,
  StudentsConnection,
} from '../../graphql/API';
import {StudentsService} from '../Students';
import {PackagesService} from '../Package';
import {LevelsService} from '../Level';
import {TestsService} from '../Test';
import {MembershipsService} from './MembershipsService';
import {resolveI18nObject} from '../../utils';
import {cloneDeep} from 'lodash';
import {EnrollmentsService} from '../Enrollments/EnrollmentsService';
import {SmileOutlined} from '@ant-design/icons';

const studentsService = new StudentsService();
const packagesService = new PackagesService();
const levelService = new LevelsService();
const membershipsService = new MembershipsService();
const enrollmentsService = new EnrollmentsService();

export const MembershipCreate = () => {
  const history = useHistory();
  const Timeout = useRef<any>();

  const {Title, Text} = Typography;
  const [students, setStudents] = useState<StudentsConnection>();
  const [packages, setPackages] = useState<Array<Package> | []>([]);
  const [levels, setLevels] = useState<Array<Level> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchBy, setSearchBy] = useState<number>(0);

  const location: {
    state: any;
    hash: any;
    search: any;
    pathname: any;
  } = useLocation();

  //@ts-ignore
  const [membership, setMembership] = useState<Membership>({
    student: location?.state?.student ?? {},
  });

  useEffect(() => {
    levelService
      .getLevels()
      .then((lvls) => {
        setLevels(lvls);
      })
      .catch((error) => {
        setIsError(true);
        //console.log('Error ->', error);
      });

    packagesService
      .getPackages()
      .then((pkgs) => {
        const livePackages = pkgs.filter((pack: Package) => !pack.onlyAI);
        setPackages(livePackages);
      })
      .catch((error) => {
        setIsError(true);
        //console.log('Error ->', error);
      });
    studentsService
      .getStudents(null)
      .then((stds) => {
        const currentItems = students?.items ?? [];
        setStudents({
          ...students,
          ...stds,
          items: [...currentItems, ...stds.items],
        });
        setIsLoading(false);
        onChange('isActive', false);
      })
      .catch((error) => {
        setIsError(true);
        //console.log('Error ->', error);
      });
  }, []);

  const onChange = (prop: any, value: any) => {
    //console.log('CURRENT_MEMBER', membership);
    //@ts-ignore
    setMembership({...membership, [prop]: value});
  };
  //console.log('memerbshippasdsad', membership);
  const onScroll = (event) => {
    var target = event.target;
    if (
      !isLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      setIsLoading(true);
      studentsService
        .getStudents(students?.nextToken)
        .then((stds) => {
          const currentItems = students?.items ?? [];
          setStudents({
            ...students,
            ...stds,
            items: [...currentItems, ...stds.items],
          });
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          //console.log('Error ->', error);
        });
    }
  };

  const customizeRenderEmpty = () => (
    <div style={{textAlign: 'center'}}>
      {isLoading ? (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      ) : (
        <>
          <SmileOutlined style={{fontSize: 20}} />
          <p>No Data</p>
        </>
      )}
    </div>
  );

  const searchStudents = (nextToken: string, textSearch?: string) => {
    const searchType = searchBy === 1 ? 'ID' : 'NAME';

    studentsService
      .getStudents(nextToken, searchType, textSearch)
      .then((stds) => {
        setStudents({
          ...stds,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        //console.log('Error ->', error);
      });
  };
  function toggleCheck(numb: number) {
    //console.log(`checked = `);
    setSearchBy(numb);
  }

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Create membership </Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Student
              </Text>
              <Checkbox
                checked={searchBy === 0}
                onChange={() => toggleCheck(0)}>
                SearchBy Name
              </Checkbox>
              <Checkbox
                checked={searchBy === 1}
                onChange={() => toggleCheck(1)}>
                SearchBy ID
              </Checkbox>
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Select
                  filterOption={false}
                  notFoundContent={isLoading ? <Spin size="small" /> : null}
                  showSearch
                  onSearch={(e) => {
                    setIsLoading(true);
                    if (Timeout.current) {
                      clearTimeout(Timeout.current);
                    }
                    Timeout.current = setTimeout(() => {
                      searchStudents(null, e);
                    }, 1100);
                  }}
                  style={{width: '100%'}}
                  defaultValue={membership?.student?.id}
                  placeholder={
                    membership?.student?.id
                      ? membership?.student?.name
                      : 'Select a student'
                  }
                  optionFilterProp="children"
                  onPopupScroll={onScroll}
                  loading={isLoading}
                  onChange={(ID: any) => {
                    //console.log('ONCHANEE', ID);

                    const id = ID;
                    let selectedStudent = students?.items?.find(
                      (s: Student) => s.id === id,
                    );
                    //console.log('ONCHANEE', selectedStudent);
                    onChange('student', selectedStudent);
                    searchStudents(null);
                  }}>
                  {students?.items?.map((std) => (
                    <Select.Option value={std.id}>
                      {std.name}{' '}
                      {std?.user?.parent?.name
                        ? std?.user?.parent?.name
                        : std?.birthDate ?? ''}{' '}
                      / {std.age}
                    </Select.Option>
                  ))}
                </Select>
              </ConfigProvider>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Package
              </Text>
              <Select
                value={membership?.package?.id}
                showSearch
                style={{width: '100%'}}
                placeholder="Select a package"
                optionFilterProp="children"
                onChange={(pkgId) => {
                  let selectedPackage = packages.find(
                    // @ts-ignore
                    (pkg) => pkg.id === pkgId,
                  );
                  onChange('package', selectedPackage);
                }}>
                {packages?.map((pkg) => (
                  <Select.Option value={pkg.id!}>
                    {JSON.parse(pkg.name_i18n)['en']}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Payment status
              </Text>
              <Select
                value={membership?.paymentStatus}
                showSearch
                style={{width: '100%'}}
                placeholder="Select a status"
                optionFilterProp="children"
                onChange={(pymtStatus) =>
                  onChange('paymentStatus', pymtStatus)
                }>
                <Select.Option value={Status.TODO}>TODO</Select.Option>
                <Select.Option value={Status.DOING}>DOING</Select.Option>
                <Select.Option value={Status.DONE}>DONE</Select.Option>
              </Select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Checkbox
                checked={membership?.isActive}
                onChange={(event) =>
                  onChange('isActive', event.target.checked)
                }>
                active?
              </Checkbox>
            </div>
          </Col>
        </Row>
      </>
      <Button
        onClick={async () => {
          //console.log('INCREATEEET');
          if (membership && membership?.student?.id) {
            //console.log('INCREATEEET_ID');

            setIsLoading(true);
            if (membership?.package?.enrollmentPackage) {
              const selectedStudent = await studentsService.getStudentById(
                membership.student.id,
              );

              const selectedRecentlyEnrollment =
                selectedStudent?.enrollments?.reduce(function (a, b) {
                  return a.updatedAt > b.updatedAt ? a : b;
                });

              if (selectedRecentlyEnrollment?.package?.packageDuration >= 90) {
              } else {
                const updateEnrollment = {
                  packageId: membership?.package?.enrollmentPackage?.id,
                  startDate: new Date().toISOString().split('T')[0],
                  paymentStatus: membership.paymentStatus,
                };

                await enrollmentsService.updateEnrollment(
                  selectedRecentlyEnrollment?.id,
                  selectedRecentlyEnrollment?.updatedAt,
                  updateEnrollment,
                );
              }
            }

            await membershipsService
              .createMembership(membership)
              .then((e: any) => {
                alert('Membership Created');
                history.go(0);
              })
              .catch((err) => {
                //console.log('upadate errpr ->', err);
              });
            setIsLoading(false);
          }
        }}>
        Create
      </Button>
    </div>
  );
};
