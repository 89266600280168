import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Table,
} from 'antd';
import {Link, useHistory} from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import moment, {Moment} from 'moment';

import {SchoolService} from './SchoolService';
import {useEffect, useRef, useState} from 'react';

import {useDispatch} from 'react-redux';
import {getCurrentUserPermission, Permissions} from '../../utils';
import Text from 'antd/lib/typography/Text';
import {PackagesService} from '../Package';
import {School, SchoolsConnection} from '../../graphql/API';

const schoolService = new SchoolService();

export const SchoolsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [schools, setSchools] = useState<SchoolsConnection>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showCreationPopUp, setShowCreationPopUp] = useState<boolean>(false);

  const [current, setCurrent] = useState(1);
  const [maxNumReachedPages, setMaxNumReachedPages] = useState(1);

  const Timeout = useRef<any>();
  const [emptySeachValue, setEmptySeachValue] = useState<boolean>(true);

  const [currentUserPermission, setCurrentUserPermission] =
    useState<Permissions>();

  useEffect(() => {
    getCurrentUserPermission()
      .then((p: Permissions) => {
        //console.log('currentUserPermission', p);
        setCurrentUserPermission(p);
        if (p === 'admin') {
        }
      })
      .catch((er) => {
        //console.log('err->', er);
      });
  }, []);

  useEffect(() => {
    if (emptySeachValue && current && currentUserPermission) {
      if (currentUserPermission === 'admin') {
        schoolService
          .getSchools(schools?.nextToken)
          .then((resp: SchoolsConnection) => {
            const currentItems = schools?.items ?? [];
            //console.log('discountCodes', resp);

            setSchools({
              ...schools,
              ...resp,
              items: [...currentItems, ...resp.items],
            });

            setIsLoading(false);
          })
          .catch((error) => {
            setIsError(true);
            //console.log('Error ->', error);
          });
      } else {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, emptySeachValue, currentUserPermission]);

  const columns: any = [
    {
      title: 'Name English',
      dataIndex: 'name',
      render: (name: string, school: any) => {
        //console.log('school', school);
        return <Link to={'/school/' + school.id + '/edit'}> {name}</Link>;
      },
    },
    {
      title: 'Name Arabic',
      dataIndex: 'nameArabic',
      render: (name: string) => {
        return (
          <Text
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
            }}>
            {name}
          </Text>
        );
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      render: (createdAt: string) => {
        return (
          <p
            style={{
              textAlign: 'left',
              fontWeight: 'bold',
            }}>
            {moment(createdAt).format('YYYY-MM-DD')}
          </p>
        );
      },
    },
    {
      title: 'id',
      dataIndex: 'id',
      render: (id: string) => {
        return (
          <p
            style={{
              textAlign: 'left',
              fontWeight: 'bold',
            }}>
            {id}
          </p>
        );
      },
    },
  ];

  const onChangeLogic = ({current}, size) => {
    //console.log('CURRRRR_sizeChangeLogic', current);

    if (current >= maxNumReachedPages) {
      setCurrent(current);
      setMaxNumReachedPages(current);
    }
  };

  return (
    <>
      <Row
        style={{
          margin: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Col></Col>
        {currentUserPermission === 'admin' && !isLoading && (
          <Col>
            <Button
              size={'large'}
              onClick={() => history.push('/schools/create')}>
              Create
            </Button>
          </Col>
        )}
      </Row>
      {!isError && !isLoading && (
        <Table
          pagination={{pageSizeOptions: ['100'], pageSize: 100}}
          current={current}
          defaultPageSize={100}
          // onShowSizeChange={sizeChangeLogic}
          onChange={onChangeLogic}
          // showSizeChanger={true}
          columns={columns}
          dataSource={schools?.items}
        />
      )}

      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Enrollments fetching error ⚠️</Title>}
    </>
  );
};
