import {useEffect, useState} from 'react';
import {useParams, Link, useHistory} from 'react-router-dom';
import {Button, Select, Spin, Typography, Collapse} from 'antd';

import {Plan, Test} from '../../graphql/API';
import {PlansService} from './PlansService';
import {TestsService} from '../Test';
import Title from 'antd/es/typography/Title';

const plansService = new PlansService();
const testsService = new TestsService();
export const PlanEdit = () => {
  const history = useHistory();

  const [tests, setTests] = useState<Array<Test>>();
  const [plan, setPlan] = useState<Plan | undefined>(undefined);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let params: {planId: string} = useParams();

  const {Panel} = Collapse;
  const {Title, Text} = Typography;

  useEffect(() => {
    testsService
      .getTests()
      .then((testsResponse) => {
        setTests(testsResponse);
      })
      .catch((e) => {
        alert('Fetching tests error');
        setIsLoading(false);
        setIsError(true);
      });
    plansService
      .getPlanById(params.planId)
      .then((resp) => {
        setIsLoading(false);
        setPlan(resp);
      })
      .catch((error) => {
        alert('Fetching plans error');
        //console.log(error);
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  const renderDay = (plan: Plan) => {
    return Array.from({ length: 365 }, (_, dayObject) => dayObject + 1).map((dayObject) => {
          return (
            <Panel header={`d${dayObject}`} key={dayObject}>
            <Select
              autoClearSearchValue={false}
              mode="multiple"
              style={{width: '100%'}}
              placeholder="Please select"
              //@ts-ignore
              defaultValue={plan[`d${dayObject}`]?.map((m) => {
                return m?.name + '+' + m?.id!;
              })}
              onChange={(idWithNameList) => {
                let tempPlan = plan;
                let testIds = idWithNameList?.map((e: any) => e.split('+')[1]);
                let selectedTests = tests?.filter((t) =>
                  testIds.includes(t.id),
                );
             //@ts-ignore
                tempPlan[`d${dayObject}`] = selectedTests;
                //@ts-ignore
                setPlan(tempPlan);

              }}>
              {/* @ts-ignore */}
              {tests?.map((k,index) => (
                <Select.Option key={k.id ||index} value={k.name + '+' + k.id!}>
                  <p>{k.name}</p>
                </Select.Option>
              ))}
            </Select>
          </Panel>
          );
        
      
      })
};

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Plan Edit</Title>
      {!isError && !isLoading && (
        <>
          <Collapse defaultActiveKey={'d1'}>{plan && renderDay(plan)}</Collapse>
        </>
      )}
      <div style={{width: '100%', textAlign: 'center', marginTop: '20px'}}>
        <Button
          style={{}}
          size={'large'}
          onClick={async () => {
            let planObj = plan;
            let planId = plan?.id;
            let updatedAt = plan?.updatedAt;

            delete planObj?.id;
            delete planObj?.createdAt;
            delete planObj?.updatedAt;
            //@ts-ignore
            delete planObj?.entity;

            let newPlan = {};
            //console.log('plan', plan);
            // @ts-ignore
            Object.entries(plan)?.map((dayObject) => {
              let key = dayObject[0];
              let value = dayObject[1];
              if (typeof value === 'object') {
                //console.log('VVVVVV', typeof value);
                //@ts-ignore
                let ids = value?.map((v) => v.id);
                //@ts-ignore
                newPlan[key] = ids;
              } else if (key.startsWith('d')) {
                //@ts-ignore
                newPlan[key] = [];
              }
            });
            let extraPlan = {...newPlan};
            // //console.log('PLAMN', newPlan);
            // if (Object.keys(newPlan).length < 120) {
            //   for (
            //     let index = Object.keys(newPlan).length;
            //     index <= 120;
            //     index++
            //   ) {
            //     extraPlan['d' + index] = [];
            //   }
            // }
            // //console.log(JSON.stringify(extraPlan));
            await plansService
              //@ts-ignore
              .updatePlan(planId!, updatedAt!, JSON.stringify(extraPlan))
              .then((e) => {
                alert('Plan edited');
                history.push('/plans/' + planId);
              })
              .catch((er) => {
                console.log('updating plan error ->', er);
                alert('Plan edit failed');
              });
          }}>
          Save
        </Button>
      </div>
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Plan fetching error ⚠️</Title>}
    </div>
  );
};
