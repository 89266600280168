import {IIAccountsService} from './IIAccountsService';
import {AccountsRepository} from './AccountsRepository';
import {AdminListUser, AdminListUserGroups, User} from '../../graphql/API';

export class AccountService implements IIAccountsService {
  AccountsRepository: AccountsRepository;
  constructor() {
    this.AccountsRepository = new AccountsRepository();
  }
  async addUserToRole(userName: string, groupName: string): Promise<void> {
    return await this.AccountsRepository.addUserToRole(userName, groupName);
  }

  async removeUserFromGroup(
    userName: string,
    groupName: string,
  ): Promise<void> {
    return await this.AccountsRepository.removeUserFromGroup(
      userName,
      groupName,
    );
  }

  async listEmailGroupList(userName: string): Promise<AdminListUserGroups> {
    return await this.AccountsRepository.listEmailGroupList(userName);
  }

  async checkEmail(userName: string): Promise<any> {
    return await this.AccountsRepository.checkEmail(userName);
  }

  async getUserByCongintoId(cognitoUserId: string): Promise<User> {
    return await this.AccountsRepository.getUserByCongintoId(cognitoUserId);
  }

  async getListCognitoUsers(
    nextToken: string | undefined,
    scannedCount: number = 60,
  ): Promise<AdminListUser> {
    return await this.AccountsRepository.getListCognitoUsers(
      nextToken,
      scannedCount,
    );
  }

  async sendNotification(
    title: string,
    body: string,
    targetArn: string,
    conversationId: string,
  ): Promise<void> {
    return await this.AccountsRepository.sendNotification(
      title,
      body,
      targetArn,
      conversationId,
    );
  }
}
