// TODO: add user to get student's parent
export const createEnrollment = `
mutation createEnrollment ($input: EnrollmentInsert!) {
    createEnrollment (input: $input) {
        id
        createdAt
        updatedAt   
    }
}
`;

export const updateEnrollment = `
mutation UpdateEnrollment(
    $id: ID!
    $updatedAt: String!
    $input: EnrollmentUpdate!
  ) {
    updateEnrollment(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
    }
  }`;
