export const tests = `
query tests ($filter: TestsFilter, $scannedCount: Int, $nextToken: String) {
    tests (filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
        items {
            id
            createdAt
            updatedAt
            type
            name
            description
            summary
            asset {
                id
                createdAt
                updatedAt
                type
                thumbnailUri
                fileUri
            }
        }
        nextToken
        scannedCount
    }
}
`;

export const test = `query test ($id: String!) {
    test (id: $id) {
        id
        createdAt
        updatedAt
        questions {
            id
            createdAt
            updatedAt
            type
            name
            videoAISessionUri
            correctAsset {
                id
                createdAt
                updatedAt
                type
                thumbnailUri
                fileUri
            }
            asset {
                id
                createdAt
                updatedAt
                type
                thumbnailUri
                fileUri
            }
            choices
            customChoices
            programType
            keywords
            summary
            description
            text
            textAI
        }
        type
        name
        description
        summary
        asset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
        }
    }
}
`;
