import {useEffect, useState} from 'react';
import {Modal, Button, Row, Col, Spin} from 'antd';
import Text from 'antd/lib/typography/Text';
import {Membership} from '../../graphql/API';
import {GroupService} from './GroupService';
import {DeleteOutlined, PlusCircleOutlined} from '@ant-design/icons';

type PopUpModalProps = {
  typeGroup: any;
  visibleModal: boolean;
  title: string;
  programId: string;
  levelId: string;
  setVisibleModal: (value: boolean) => void;
  onChangeMemberships: (Memberships: Membership[]) => void;
};
const groupService = new GroupService();

export const AddStudentPopUpModal = ({
  visibleModal,
  title,
  programId,
  levelId,
  typeGroup,
  setVisibleModal,
  onChangeMemberships,
}: PopUpModalProps) => {
  const [filterMemberships, setFilterMemberships] = useState<Membership[]>();
  const [selectedMemberShips, setSelectedMemberShip] = useState<
    Array<Membership>
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (visibleModal) {
      setIsLoading(true);
      setSelectedMemberShip([]);

      groupService
        .filteredMemberships(programId, levelId, typeGroup)
        .then((payload: any) => {
          setFilterMemberships(payload);
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [visibleModal, programId, levelId]);

  return (
    <Modal
      title={title}
      centered
      visible={visibleModal}
      okText={'Save'}
      onOk={() => {
        onChangeMemberships([...selectedMemberShips]);
        setVisibleModal(false);
      }}
      onCancel={() => setVisibleModal(false)}
      width={1000}>
      {filterMemberships?.length > 0 &&
        (isLoading ? (
          <Spin
            size="large"
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
            }}
          />
        ) : (
          <Row>
            <Col span={12}>
              <div
                style={{
                  margin: 20,
                }}>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginBottom: 20,
                    display: 'flex',
                    color: '#999999',
                  }}>
                  Add Students
                </Text>

                {filterMemberships.map((membership: Membership) => (
                  <Button
                    onClick={() => {
                      if (!selectedMemberShips.includes(membership)) {
                        setSelectedMemberShip([
                          ...selectedMemberShips,
                          membership,
                        ]);
                      }
                    }}
                    style={{
                      width: '50%',
                      height: 50,
                      backgroundColor: '#ECECEC',
                      marginBottom: 10,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      alignContent: 'center',
                      display: 'flex',
                      borderRadius: 8,
                    }}>
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                      {membership.student.name}
                    </Text>
                    <PlusCircleOutlined style={{color: '#A6A6A6'}} />
                  </Button>
                ))}
              </div>
            </Col>

            {selectedMemberShips?.length > 0 && (
              <Col span={12}>
                <div
                  style={{
                    margin: 20,
                  }}>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginBottom: 20,
                      display: 'flex',
                      color: '#999999',
                    }}>
                    Group Students
                  </Text>

                  {selectedMemberShips.map((membership) => (
                    <div
                      style={{
                        width: '50%',
                        height: 50,
                        backgroundColor: '#40B2F2',
                        marginBottom: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        borderRadius: 8,
                        paddingInline: 15,
                      }}>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: '#fff',
                        }}>
                        {membership.student.name}
                      </Text>

                      <DeleteOutlined
                        onClick={() => {
                          const index = selectedMemberShips.findIndex(
                            (item) => item.id === membership.id,
                          );

                          let _tempSelected = [...selectedMemberShips];

                          _tempSelected.splice(index, 1);
                          setSelectedMemberShip([..._tempSelected]);
                        }}
                        style={{fontSize: '18px', color: '#fff'}}
                      />
                    </div>
                  ))}
                </div>
              </Col>
            )}
          </Row>
        ))}
    </Modal>
  );
};
