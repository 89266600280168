export const lessons = `
  query Lessons($filter: LessonsFilter, $scannedCount: Int, $nextToken: String) {
    lessons(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        course {
          id
          name
          level {
            id
            createdAt
            updatedAt
            name
            number
          }
          program {
            id
            createdAt
            updatedAt
            name
            name_i18n
            flag
            supportAi
          }
        }
      instructionsPDF {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      summary {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      lessonPDF {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      }
      nextToken
      scannedCount
    }
  }
`;

//@ts-ignore
export const getLesson = `
  query Lesson($id: String!) {
    lesson(id: $id) {
      id
      createdAt
      updatedAt
      name
      course{
        id
        name
        level {
          id
          createdAt
          updatedAt
          name
          number
        }
        program {
          id
          createdAt
          updatedAt
          name
          name_i18n
          flag
          supportAi
        }
      }
      instructionsPDF {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }

      summary {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      lessonPDF {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
    }
  }
`;
