export const createAsset = `
mutation createAsset ($input: AssetInsert!) {
    createAsset (input: $input) {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
    }
}
`;

export const updateAsset = `
mutation updateAsset ($id: ID!, $updatedAt: String!, $input: AssetUpdate!) {
    updateAsset (id: $id, updatedAt: $updatedAt, input: $input) {
        id
        createdAt
    }
}`;
