import {UserUpdate} from '../../graphql/API';
import {IUserService} from './IUserService';
import {UserRepository} from './UserRepository';

export class UserService implements IUserService {
  userRepository: UserRepository;
  constructor() {
    this.userRepository = new UserRepository();
  }

  async updateUser(
    userId: string,
    updatedAt: string,
    user: UserUpdate,
  ): Promise<any> {
    return this.userRepository.updateUser(userId, updatedAt, user);
  }
}
