import {ILessonsRepository} from './ILessonsRepository';
import {lessons, getLesson} from './LessonsQueries';
import {updateLesson, createLesson} from './LessonsMutations';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class LessonsRepository implements ILessonsRepository {
  async getLessons(): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: lessons,
        variables: {},
      });
      //console.log('getLessons->', response);

      return response.data?.lessons.items;
    } catch (error) {
      //console.log('getLessons repository Error:', error);
      throw new Error(error);
    }
  }

  async getLessonById(lessonId: string): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: getLesson,
        variables: {
          id: lessonId,
        },
      });
      //console.log('getLessons->', response);

      return response.data?.lesson;
    } catch (error) {
      //console.log('getLesson repository Error:', error);
      throw new Error(error);
    }
  }

  async updateLesson(
    lessonId: string,
    updatedAt: string,
    programLesson: any,
  ): Promise<any> {
    //console.log('repo lessonId->', lessonId);
    //console.log('repo programLesson->', programLesson);

    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: updateLesson,
        variables: {
          id: lessonId,
          updatedAt: updatedAt,
          input: programLesson,
        },
      });
      //console.log('getLessons->', response);

      return response?.data?.updateLesson;
    } catch (error) {
      //console.log('getLesson repository Error:', error);
      throw new Error(error);
    }
  }

  async createLesson(lesson: any): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: createLesson,
        variables: {
          input: lesson,
        },
      });
      //console.log('getLessons->', response);

      return response?.data?.createLesson;
    } catch (error) {
      //console.log('Fetch lesson Error: ', error);
      throw new Error(error);
    }
  }
}
