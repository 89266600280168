export const getGroup = /* GraphQL */ `
  query Group($id: String!) {
    group(id: $id) {
      id
      createdAt
      updatedAt
      startDate
      name
      division
      isActive
      # limit
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      memberships {
        id
        createdAt
        updatedAt
        paymentStatus
        placementStatus
        isActive

        student {
          id
          name
        }
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        zoomUrl
        jitsiUrl
        moderatorToken
        # rtcInstructorToken
        rtcAdminToken
        instructor {
          id
          name
          user {
            id
            timeZone
          }
        }
        lesson {
          id
          name
          course {
            id
            name
            level {
              id
              name
            }
          }
          summary {
            id
            type
            fileUri
            thumbnailUri
            title_i18n
            subTitle_i18n
          }
          lessonPDF {
            id
            type
            fileUri
            thumbnailUri
            title_i18n
            subTitle_i18n
          }
          instructionsPDF {
            id
            type
            fileUri
            thumbnailUri
            title_i18n
            subTitle_i18n
          }
        }
      }
      instructor {
        id
        createdAt
        updatedAt
        name
        gender
        rating
        sessionsCount
        speakingLanguages
        user {
          id
          timeZone
        }
      }
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      slot {
        id
        createdAt
        updatedAt
        name
        # sessionDuration
        startTime
        endTime
        status
        limit
        current
        day
      }
    }
  }
`;

export const getGroups = /* GraphQL */ `
  query Groups($filter: GroupsFilter, $scannedCount: Int, $nextToken: String) {
    groups(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        isActive
        conversation {
          id
          name
          isActive
          group {
            id
          }
          studentconversations {
            id
            student {
              id
              name
              user {
                id
                endPointArn
              }
            }
          }
          userconversations {
            id
            user {
              id
            }
          }
        }
        slot {
          id
          name
          startTime
          endTime
          day
        }
        program {
          id
          name_i18n
          flag
        }
        level {
          id
          name
          number
        }
        instructor {
          id
          name
          user {
            id
            timeZone
          }
        }
        sessions {
          id
          status
          startDateTime
        }
        memberships {
          id
        }
        # limit
      }
      nextToken
      scannedCount
    }
  }
`;

export const filteredMemberships = /* GraphQL */ `
  query FilteredMemberships(
    $programId: String
    $levelId: String
    $typeGroup: DivisionType
  ) {
    filteredMemberships(
      programId: $programId
      levelId: $levelId
      typeGroup: $typeGroup
    ) {
      id
      createdAt
      updatedAt
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
        score
      }
      paymentStatus
      placementStatus
      isActive
    }
  }
`;

export const sessions = /* GraphQL */ `
  query Sessions(
    $filter: SessionsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    sessions(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        moderatorToken
        jitsiUrl
        zoomUrl
        group {
          id
          program {
            id
            name_i18n
            flag
          }
          conversation {
            id
            name
            isActive
            group {
              id
            }
            studentconversations {
              id
              student {
                id
                name
                user {
                  id
                  endPointArn
                }
              }
            }
            userconversations {
              id
              user {
                id
              }
            }
          }
        }

        slot {
          id
          name
          startTime
          endTime
          day
        }

        level {
          id
          name
          number
        }
        instructor {
          id
          name
          user {
            id
            timeZone
          }
        }
        memberships {
          id
        }
      }
      nextToken
      scannedCount
    }
  }
`;
