export const me = /* GraphQL */ `
  query Me {
    me {
      id
      createdAt
      updatedAt
      cognitoUserId
      country
      state
      whatsappNumber
      timeZone
      admin {
        id
        createdAt
        updatedAt
        isActive
        lastSeenOnline
      }
      userconversations {
        createdAt
        updatedAt
        id
        conversation {
          id
          name
          userconversations {
            id
            conversation {
              id
            }
            user {
              id
              createdAt
              admin {
                id
              }
              instructor {
                id
                name
              }
              preInstructor {
                id
                name
              }
            }
          }
          studentconversations {
            id
            student {
              id
              name
              user {
                id
                endPointArn
              }
            }
          }
        }
      }
    }
  }
`;

export const messagesByConversationId = /* GraphQL */ `
  query MessagesByConversationId(
    $conversationId: ID!
    $scannedCount: Int
    $nextToken: String
  ) {
    messagesByConversationId(
      conversationId: $conversationId
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        content
        createdAt
        updatedAt
        id
        isSent
        senderType
        conversationId
        student {
          id
          name
        }
        user {
          id
          admin {
            id
            createdAt
            updatedAt
            firstName
            lastName
            address
            SSN
            gender
            isActive
          }
          instructor {
            id
          }
          preInstructor {
            id
          }
        }
      }
      nextToken
      scannedCount
    }
  }
`;
