import {IMembershipsRepository} from './IMembershipsRepository';
import * as membershipQueries from './MembershipsQueries';
import {createMembership, updateMembership} from './MembershipsMutations';
import {
  Membership,
  MembershipInsert,
  MembershipUpdate,
} from '../../graphql/API';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();

export class MembershipsRepository implements IMembershipsRepository {
  async getMemberships(): Promise<any> {
    try {
      const memberships: GraphQLResult<any> = await client.graphql({
        query: membershipQueries.memberships,
        variables: {},
      });
      //console.log('memberships->', memberships);

      return memberships.data?.memberships.items;
    } catch (error) {
      //console.log('getMemberships repository Error:', error);
      throw new Error(error);
    }
  }

  async getMembershipById(membershipId: string): Promise<Membership> {
    try {
      const membership: GraphQLResult<any> = await client.graphql({
        query: membershipQueries.membership,
        variables: {
          id: membershipId,
        },
      });
      //console.log('memberships->', membership);

      return membership.data?.membership;
    } catch (error) {
      //console.log('Fetch membership Error: ', error);
      throw new Error(error);
    }
  }

  async createMembership(membershipp: MembershipInsert): Promise<any> {
    try {
      const createMembershipResponse: GraphQLResult<any> = await client.graphql(
        {
          query: createMembership,
          variables: {
            input: membershipp,
          },
        },
      );
      //console.log('createMembershipResponse->', createMembershipResponse);

      return createMembershipResponse.data?.createMembership;
    } catch (error) {
      //console.log('Fetch membership Error: ', error);
      throw new Error(error);
    }
  }

  async updateMembership(
    membershipId: string,
    updatedAt: string,
    membership: MembershipUpdate,
  ): Promise<any> {
    try {
      const updateMembershipResponse: GraphQLResult<any> = await client.graphql(
        {
          query: updateMembership,
          variables: {
            id: membershipId,
            updatedAt: updatedAt,
            input: membership,
          },
        },
      );
      //console.log('updateMembershipResponse->', updateMembershipResponse);

      return updateMembershipResponse;
    } catch (error) {
      //console.log('Fetch membership Error: ', error);
      throw new Error(error);
    }
  }
}
