import {useEffect, useState, useRef, createRef} from 'react';
import {
  Button,
  Col,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from 'antd';
import {get, isEqual} from 'lodash';

import Title from 'antd/es/typography/Title';

import {
  DivisionType,
  Group,
  GroupUpdate,
  Instructor,
  Level,
  Membership,
  Session,
  Slot,
  Status,
  User,
} from '../../graphql/API';

import {GroupService} from './GroupService';
import {Link, useHistory, useParams} from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import {daysOfTheWeek} from '../Instructor';
import moment from 'moment-timezone';
import {
  CheckCircleFilled,
  CheckOutlined,
  CheckSquareFilled,
  CloseOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  RollbackOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {AddStudentPopUpModal} from './StudentPopUpModal';
import {InstructorPopUpModal} from './InstructorPopUpModal';
import {LevelsService} from '../Level';
import AgoraVideoCall from './AgoraVideoCall';
import {useSelector} from 'react-redux';
const groupService = new GroupService();
const levelService = new LevelsService();

const serverJistiUrl = 'https://live.pikado.io/';
export const GroupEdit = () => {
  const history = useHistory();
  let params: {groupId: string} = useParams();
  const userInfo: User = useSelector((state: any) => state.user.user);
  //console.log('userInfo', userInfo);
  const [visibleModalStudents, setVisibleModalStudents] = useState(false);
  const [visibleModalInstructor, setVisibleModalInstructor] = useState(false);
  const parentEndRef = createRef<any>();
  const [selectedSession, setSelectedSession] = useState<Session>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [group, setGroup] = useState<Group>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isModalLevelVisible, setIsModalLevelVisible] =
    useState<boolean>(false);
  const [newLevelId, setNewLevelId] = useState<string>();
  const [levelList, setLevelList] = useState<Level[]>([]);
  const [videoCall, setVideoCall] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    groupService
      .getGroupById(params.groupId)
      .then((grop: any) => {
        setGroup(grop);
        //console.log('setGroup', grop);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsError(true);
        //console.log('Error ->', error);
      });

    levelService
      .getLevels()
      .then((lvls: any) => {
        setLevelList(lvls);
        //console.log('setLevelList', lvls);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsError(true);
        //console.log('Error ->', error);
      });
  }, [params.groupId]);

  useEffect(() => {
    if (group?.sessions?.length > 0 && !isLoading) {
      const isDoingSession = group?.sessions.find(
        (sess: Session) => sess.status === Status.DOING,
      );

      let nowISO = new Date();

      if (isDoingSession) {
        const startedSession = isDoingSession;
        var now = moment
          .tz(nowISO.toISOString(), 'YYYY-MM-DD hh:mm:ss', 'UTC')
          .clone()
          .tz(userInfo?.timeZone ?? 'Africa/Cairo')
          .utc(true)
          .format('YYYY-MM-DD HH:mm:ss');

        const startDateTime = moment(startedSession?.startDateTime);

        const startTimeSession = moment
          .tz(
            startDateTime.format('YYYY-MM-DD') + startedSession?.startTime ??
              '',
            'YYYY-MM-DD HH:mm:ss',
            'UTC',
          )
          .clone()
          .tz(userInfo?.timeZone ?? 'Africa/Cairo')
          .subtract(5, 'm')
          .format('YYYY-MM-DD HH:mm:ss');

        const endTimeSession = moment
          .tz(
            startDateTime.format('YYYY-MM-DD') + startedSession?.endTime ?? '',
            'YYYY-MM-DD HH:mm:ss',
            'UTC',
          )
          .clone()
          .tz(userInfo?.timeZone ?? 'Africa/Cairo')
          .format('YYYY-MM-DD HH:mm:ss');

        //console.log('NOWWW', now);

        //console.log('startTimeSession', startTimeSession);

        //console.log('endTimeSession', endTimeSession);
        var isNowBetweenSessionTime = moment(now).isBetween(
          startTimeSession,
          endTimeSession,
        );
        //console.log('isNowBetweenSessionTime', isNowBetweenSessionTime);
        //console.log('isDoingSession', isDoingSession);
        //console.log('!isModalVisible', !isModalVisible);

        if (isNowBetweenSessionTime && !isModalVisible && isDoingSession) {
          //console.log('SUSCCCESS_TRUE');
          setVideoCall(true);

          setSelectedSession(isDoingSession);
          setTimeout(() => {
            //console.log('SCRRORLLLLLL');
            parentEndRef?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
          }, 1500);
        }
      }
    }
  }, [
    group?.sessions,
    parentEndRef,
    userInfo?.timeZone,
    isLoading,
    isModalVisible,
  ]);

  const handleJitsiLink = (jitsiUrl, moderatorToken?: any) => {
    //console.log('session?.jitsiUrl', moderatorToken);
    window.open(serverJistiUrl + jitsiUrl + '?jwt=' + moderatorToken);
  };

  const handleCancelLevel = () => {
    setIsModalLevelVisible(false);
  };

  const handleOkLevel = async () => {
    setIsModalLevelVisible(false);
    setIsLoading(true);
    try {
      await groupService.levelUp(group.id, newLevelId);
      history.go(0);

      // setIsLoading(false);
    } catch (err: any) {
      // dispatch(isLoadingRequest(false, err));
      setIsError(true);
      setIsLoading(false);
    }
  };

  return (
    <div
      ref={parentEndRef}
      style={{
        padding: 10,
        backgroundColor: '#fff',
        height: '100%',
        paddingInlineStart: 20,
      }}>
      {group && (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '5%',
            marginInlineEnd: 20,
          }}>
          <Title
            style={{
              textAlign: 'center',
              fontSize: 25,
              fontWeight: 'bold',
            }}>
            Group: {group?.name}
          </Title>

          {group.isActive && (
            <Button
              onClick={async () => {
                setIsLoading(true);

                const updateGroup: GroupUpdate = {
                  name: group.name,
                  instructorId: group?.instructor?.id,
                  slotId: group.slot.id,
                  membershipIds: group?.memberships?.map(
                    (membership: Membership) => membership.id,
                  ),
                };

                await groupService.updateGroup(
                  params.groupId,
                  group.updatedAt,
                  updateGroup,
                );
                setIsLoading(false);
                window.location.reload();
              }}
              style={{
                width: '17%',
                height: 52,
                borderRadius: 10,
                backgroundColor: '#40B2F2',
                marginBottom: '2.5%',
              }}>
              <Text
                style={{
                  fontSize: 16,
                  color: 'white',
                  fontWeight: 'bold',
                }}>
                Save
              </Text>
            </Button>
          )}
          {group?.sessions?.filter(
            (sessions) => sessions?.status === Status.DONE,
          ).length <= 1 && (
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={async () => {
                setIsLoading(true);
                await groupService.deleteGroup(params.groupId);
                setIsLoading(false);
                history.goBack();
              }}>
              <Button
                style={{
                  width: '17%',
                  height: 52,
                  borderRadius: 10,
                  backgroundColor: '#FF5C63',
                  marginBottom: '2.5%',
                }}>
                <Text
                  style={{
                    fontSize: 16,
                    color: 'white',
                    fontWeight: 'bold',
                  }}>
                  Delete Group
                </Text>
              </Button>
            </Popconfirm>
          )}
          {!group.isActive && (
            <Button
              disabled={
                group?.sessions?.filter(
                  (sessions) => sessions?.status === Status.DONE,
                ).length === 4
                  ? true
                  : false
              }
              onClick={async () => {
                setIsLoading(true);
                await groupService.startGroup(params.groupId);
                setIsLoading(false);
                window.location.reload();
              }}
              style={{
                width: '17%',
                height: 52,
                borderRadius: 10,
                backgroundColor:
                  group?.sessions?.filter(
                    (sessions) => sessions?.status === Status.DONE,
                  ).length === 4
                    ? '#C4C4C4'
                    : '#40B2F2',
                marginBottom: '2.5%',
              }}>
              <Text
                style={{
                  fontSize: 16,
                  color: 'white',
                  fontWeight: 'bold',
                }}>
                {group?.sessions?.filter(
                  (sessions) => sessions?.status === Status.DONE,
                ).length === 4
                  ? 'Group Ended'
                  : 'Start Group'}
              </Text>
            </Button>
          )}
        </Row>
      )}
      {group && (
        <div style={{display: 'flex', flexDirection: 'row', marginTop: '3%'}}>
          <div style={{display: 'flex', flex: 0.5, flexDirection: 'column'}}>
            <Row>
              <Text
                style={{
                  fontWeight: 'bold',
                  color: '#A6A6A6',
                  paddingBottom: 10,
                }}>
                Language:{' '}
              </Text>
              <Text
                style={{fontWeight: 'bold', color: '#000', paddingBottom: 10}}>
                {JSON.parse(group?.program.name_i18n)['en']}
              </Text>
            </Row>
            <Row>
              <Text
                style={{
                  fontWeight: 'bold',
                  color: '#A6A6A6',
                  paddingBottom: 10,
                }}>
                Level:{' '}
              </Text>
              <Text
                style={{fontWeight: 'bold', color: '#000', paddingBottom: 10}}>
                {group.level.name}
              </Text>

              {group?.division === DivisionType.ONE_TO_ONE && (
                <a>
                  <Text
                    onClick={() => {
                      setIsModalLevelVisible(true);
                    }}
                    style={{
                      fontWeight: 'bold',
                      color: '#FF5C63',
                      paddingBottom: 10,
                      marginInlineStart: 10,
                    }}>
                    Edit
                  </Text>
                </a>
              )}
            </Row>
            <Row>
              <Text style={{fontWeight: 'bold', color: '#A6A6A6'}}>
                Instructor:{' '}
              </Text>
              <Text
                style={{fontWeight: 'bold', color: '#000', paddingBottom: 10}}>
                {group?.instructor?.name}
              </Text>
            </Row>
            {group?.sessions?.some((ses) => ses?.status === 'TODO') && (
              <Row style={{alignItems: 'baseline'}}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    color: '#A6A6A6',
                    paddingBottom: 10,
                  }}>
                  Next Session UTC: &nbsp;
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    color: '#FF5C63',
                    paddingBottom: 10,
                  }}>
                  {
                    daysOfTheWeek[
                      moment(
                        group.sessions.find((ses) => ses?.status === 'TODO')
                          ?.startDateTime,
                        'YYYY-MM-DD HH:mm:ss',
                      ).day()
                    ]
                  }
                  {'  '} - {'  '}
                  {'  ' +
                    moment(
                      group.sessions.find((ses) => ses?.status === 'TODO')
                        ?.startDateTime ?? '',
                      'YYYY-MM-DD HH:mm:ss',
                    ).format('yyyy-MM-DD')}
                </Text>
              </Row>
            )}
            <Row style={{alignItems: 'baseline'}}>
              <Text
                style={{
                  fontWeight: 'bold',
                  color: '#A6A6A6',
                  paddingBottom: 10,
                }}>
                Session Instructor Day :&nbsp;
              </Text>
              <Text
                style={{fontWeight: 'bold', color: '#000', paddingBottom: 10}}>
                {'  ' + daysOfTheWeek[group?.slot?.day]}
              </Text>
            </Row>
            <Row style={{alignItems: 'baseline'}}>
              <Text
                style={{
                  fontWeight: 'bold',
                  color: '#A6A6A6',
                  paddingBottom: 10,
                }}>
                Session Time :&nbsp;
              </Text>
              <Text
                style={{fontWeight: 'bold', color: '#000', paddingBottom: 10}}>
                {'   '}
                {moment
                  .tz(group.slot.startTime, 'HH', 'UTC')
                  .clone()
                  .tz('Africa/Cairo')
                  .format('hh:mm A')}
                {' EGY'}
              </Text>
              {group?.sessions?.some((ses) => ses?.status === 'TODO') && (
                <Button
                  onClick={() => setVisibleModalInstructor(true)}
                  style={{
                    // alignSelf: 'baseline',
                    padding: 0,
                    outline: 'none',
                    marginInlineStart: 26,
                    borderRadius: 0,
                    border: 'none',
                  }}>
                  <Text
                    onClick={() => {}}
                    underline
                    style={{
                      color: '#F2406A',
                      fontWeight: 'bold',
                    }}>
                    Change
                  </Text>
                </Button>
              )}
            </Row>

            <div
              style={{
                width: '80%',
                height: 1,
                backgroundColor: '#ECECEC',
                marginBlock: 22,
              }}
            />
            <Text style={{color: '#999999', fontWeight: 'bold'}}>
              Group Students
            </Text>
            <Row>
              <Col span={12}>
                <div
                  style={{
                    marginTop: 20,
                  }}>
                  {group?.memberships?.map((membership: Membership) => (
                    <div
                      style={{
                        width: '100%',
                        height: 50,
                        backgroundColor: '#ECECEC',
                        marginBottom: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        borderRadius: 8,
                      }}>
                      <a
                        onClick={() => {
                          history.push(
                            `/students/` + membership.student.id + `/edit`,
                          );
                        }}
                        style={{
                          display: 'flex',
                          flex: 0.8,
                          height: '100%',
                          alignItems: 'center',
                        }}>
                        <Text
                          onClick={() => {
                            //console.log('SAD');
                          }}
                          style={{
                            paddingInline: 15,
                            fontSize: 14,
                            fontWeight: 'bold',
                            textAlign: 'center',
                          }}>
                          {membership.student.name}
                        </Text>
                      </a>
                      <Popconfirm
                        disabled={
                          group.sessions?.some((ses) => ses?.status === 'TODO')
                            ? false
                            : true
                        }
                        title="Are you sure？"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                          let _tempMemberships = [...group.memberships];
                          const findIndex = _tempMemberships.findIndex(
                            (member_ship: Membership) =>
                              member_ship.id === membership.id,
                          );
                          _tempMemberships.splice(findIndex, 1);
                          setGroup({
                            ...group,
                            memberships: [..._tempMemberships],
                          });
                        }}>
                        <DeleteOutlined
                          style={{
                            paddingInline: 15,
                            // display: 'flex',
                          }}
                        />
                      </Popconfirm>
                    </div>
                  ))}
                </div>
                {group.sessions?.some((ses) => ses?.status === 'TODO') && (
                  <Button
                    onClick={() => {
                      setVisibleModalStudents(true);
                    }}
                    style={{
                      // alignSelf: 'baseline',
                      padding: 0,
                      outline: 'none',
                      borderRadius: 0,
                      border: 'none',
                    }}>
                    <Text
                      onClick={() => {}}
                      underline
                      style={{
                        color: '#F2406A',
                        fontWeight: 'bold',
                      }}>
                      Add Students
                    </Text>
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <div style={{display: 'flex', flex: 0.5}}>
            <Row>
              <Col span={12}>
                <div
                  style={{
                    marginTop: 20,
                  }}>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: '#999999',
                      marginBottom: 10,
                    }}>
                    Group Sessions
                  </Text>
                  {group?.sessions?.map((session: Session) => (
                    <Row
                      onClick={() => {}}
                      style={{
                        minWidth: 500,
                        minHeight: 130,
                        // backgroundColor: 'red',
                      }}>
                      <Col
                        style={{
                          width: 360,
                          backgroundColor: '#ECECEC',
                          marginBottom: 10,
                          borderRadius: 8,
                          paddingBottom: 20,
                          marginInlineEnd: 5,
                        }}>
                        <div
                          style={{
                            width: '100%',
                            minHeight: 70,
                            alignSelf: 'baseline',
                            flexWrap: 'wrap',
                            display: 'flex',
                            backgroundColor:
                              session?.status === Status.DONE
                                ? '#C3E56E'
                                : session?.status === Status.SUSPENDED
                                ? '#FF5C63'
                                : session?.status === Status.DOING
                                ? '#FFD252'
                                : '#C4C4C4',
                          }}>
                          <div
                            style={{
                              alignContent: 'center',
                              alignItems: 'center',
                              display: 'flex',
                              paddingInline: 10,
                            }}>
                            <Text
                              style={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                textAlign: 'center',
                              }}>
                              {session?.name}
                            </Text>
                          </div>
                          <Row>
                            <div
                              style={{
                                alignContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingInline: 10,
                                marginTop: 10,
                                marginBottom: 10,
                              }}>
                              {session?.status === Status.DONE &&
                                group.isActive && (
                                  <a>
                                    <Popconfirm
                                      title="Are you sure？"
                                      okText="Yes"
                                      cancelText="No"
                                      onConfirm={async () => {
                                        setIsLoading(true);
                                        await groupService.changeSessionStatus(
                                          session.id,
                                          session.updatedAt,
                                          Status.TODO,
                                        );
                                        setIsLoading(false);
                                        alert('updated successfully');
                                        window.location.reload();
                                      }}>
                                      <Text
                                        style={{
                                          backgroundColor: '#6CBDF4',
                                          // height: '30px',
                                          padding: 7,
                                          marginInline: 10,
                                          color: '#fff',
                                          justifyContent: 'center',
                                          fontWeight: 'bold',
                                          // display: 'flex',
                                          borderRadius: 4,
                                          fontSize: '11px',
                                        }}>
                                        Reset Session
                                      </Text>
                                    </Popconfirm>
                                  </a>
                                )}
                              {group.sessions.find(
                                (ses) => ses?.status === 'TODO',
                              )?.startDateTime === session?.startDateTime &&
                                group.isActive && (
                                  <a>
                                    <Popconfirm
                                      title="Are you sure？"
                                      okText="Yes"
                                      cancelText="No"
                                      onConfirm={async () => {
                                        //console.log('SDASD');
                                        setIsLoading(true);
                                        await groupService.endSession(
                                          session.id,
                                          session.updatedAt,
                                          Status.SUSPENDED,
                                        );
                                        setIsLoading(false);

                                        alert('Session Failed');
                                        window.location.reload();
                                      }}>
                                      <Text
                                        style={{
                                          backgroundColor: '#FF5C63',
                                          // height: '30px',
                                          padding: 7,
                                          marginInline: 10,
                                          color: '#fff',
                                          justifyContent: 'center',
                                          fontWeight: 'bold',
                                          // display: 'flex',
                                          borderRadius: 4,
                                          fontSize: '11px',
                                        }}>
                                        End Session
                                      </Text>
                                    </Popconfirm>
                                  </a>
                                )}
                              {session?.status === Status.TODO &&
                              group.isActive ? (
                                <a>
                                  <Popconfirm
                                    title="Are you sure？"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={async () => {
                                      //console.log('SDASD');
                                      setIsLoading(true);
                                      await groupService.startSession(
                                        session.id,
                                      );
                                      setIsLoading(false);
                                      alert('Session Started');
                                      window.location.reload();
                                    }}>
                                    <Text
                                      style={{
                                        backgroundColor: '#C3E56E',
                                        // height: '30px',
                                        padding: 7,
                                        marginInline: 10,
                                        color: '#fff',
                                        justifyContent: 'center',
                                        fontWeight: 'bold',
                                        // display: 'flex',
                                        borderRadius: 4,
                                        fontSize: '11px',
                                      }}
                                      onClick={async () => {}}>
                                      Start Session
                                    </Text>
                                  </Popconfirm>
                                </a>
                              ) : (
                                session?.status === Status.DOING &&
                                group.isActive && (
                                  <CheckOutlined
                                    onClick={async () => {
                                      //console.log('SDASD');
                                      setIsLoading(true);
                                      await groupService.endSession(
                                        session.id,
                                        session.updatedAt,
                                        Status.DONE,
                                      );
                                      setIsLoading(false);

                                      alert('Session Updated');
                                      window.location.reload();
                                    }}
                                  />
                                )
                              )}
                            </div>
                          </Row>
                        </div>

                        <div style={{padding: 10}}>
                          <Text
                            onClick={() => {}}
                            style={{
                              color: '#A6A6A6',
                              fontWeight: 'bold',
                            }}>
                            Lesson:
                          </Text>

                          <Text
                            onClick={() => {}}
                            style={{
                              color: '#000',
                              fontWeight: 'bold',
                            }}>
                            {' '}
                            {session?.lesson?.name}
                          </Text>
                        </div>
                        <div style={{paddingInline: 10}}>
                          <Text
                            onClick={() => {}}
                            style={{
                              color: '#A6A6A6',
                              fontWeight: 'bold',
                            }}>
                            Instructor:
                          </Text>

                          <Text
                            onClick={() => {}}
                            style={{
                              color: '#000',
                              fontWeight: 'bold',
                            }}>
                            {' '}
                            {session?.instructor?.name}
                          </Text>
                        </div>
                        <div style={{padding: 10}}>
                          <Text
                            onClick={() => {}}
                            style={{
                              color: '#A6A6A6',
                              fontWeight: 'bold',
                            }}>
                            Lesson Level:
                          </Text>

                          <Text
                            onClick={() => {}}
                            style={{
                              color: '#000',
                              fontWeight: 'bold',
                            }}>
                            {' '}
                            {session?.lesson?.course?.level?.name}
                          </Text>
                        </div>
                        {session?.status === 'DOING' && group?.isActive ? (
                          <div
                            style={{
                              paddingInline: 10,
                              marginTop: 10,
                              textAlign: 'center',
                            }}>
                            {/* <Text
                                      style={{
                                        color: '#A6A6A6',
                                        fontWeight: 'bold',
                                      }}>
                                      :
                                    </Text> */}

                            <Button
                              onClick={() => {
                                parentEndRef?.current?.scrollIntoView({
                                  behavior: 'smooth',
                                  block: 'end',
                                  inline: 'nearest',
                                });
                              }}
                              style={{
                                backgroundColor: 'green',
                                color: 'white',
                                fontWeight: 'bold',
                                border: 'none',
                                // padding: 0,
                                margin: 'none',
                                display: 'flex',
                                height: '100%',
                                whiteSpace: 'normal',
                              }}>
                              Go To The Session
                            </Button>
                          </div>
                        ) : null}
                      </Col>
                      <div>
                        <Col>
                          <Text
                            style={{
                              fontWeight: 'bold',
                              color: '#999999',
                              fontSize: 14,
                            }}>
                            {moment(
                              session?.startDateTime,
                              'yyyy-MM-DD HH:mm:ss',
                            )
                              .utc(false)
                              .format('yyyy-MM-DD')}{' '}
                          </Text>
                        </Col>
                        <Col>
                          <Text
                            style={{
                              fontWeight: 'bold',
                              color: '#999999',
                              fontSize: 14,
                            }}>
                            {moment
                              .tz(
                                session?.startDateTime,
                                'yyyy-MM-DD HH:mm:ss',
                                'UTC',
                              )
                              .clone()
                              .tz('Africa/Cairo')
                              .format('hh:mm A')}
                          </Text>
                        </Col>
                      </div>
                    </Row>
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {isLoading && (
        <Spin
          size="large"
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
          }}
        />
      )}
      {group && (
        <AddStudentPopUpModal
          onChangeMemberships={(member_ships: Membership[]) => {
            let array = [...member_ships, ...group.memberships];

            setGroup({
              ...group,
              memberships: [
                ...array
                  .reduce(
                    (map: any, obj: any) => map?.set(obj.id, obj),
                    new Map(),
                  )
                  .values(),
              ],
            });
          }}
          typeGroup={group.division}
          title={'Add Students'}
          visibleModal={visibleModalStudents}
          setVisibleModal={(value: boolean): void => {
            setVisibleModalStudents(value);
          }}
          programId={group.program.id}
          levelId={group.level.id}
        />
      )}

      {group && (
        <InstructorPopUpModal
          onChange={(instructor: Instructor, slot: Slot) => {
            //console.log('NSSSSSSS', instructor);

            setGroup((prevState) => ({
              ...prevState,
              instructor: instructor,
              slot,
            }));
          }}
          title={'Change Instructor'}
          visibleModal={visibleModalInstructor}
          setVisibleModal={(value: boolean): void => {
            setVisibleModalInstructor(value);
          }}
          programId={group.program.id}
          levelId={group.level.id}
        />
      )}

      {isError && <Title>Somthing went wrong ⚠️, refresh the page</Title>}

      <Modal
        title="Change Group Level"
        visible={isModalLevelVisible}
        okText="Change"
        onOk={handleOkLevel}
        onCancel={handleCancelLevel}>
        <div style={{marginTop: '20px', marginBottom: '20px'}}>
          <Text style={{display: 'block'}}>Select New Level</Text>
          <Select
            value={newLevelId}
            showSearch
            style={{width: 200}}
            placeholder="select level"
            optionFilterProp="children"
            onChange={(levelId: string) => {
              setNewLevelId(levelId);
            }}>
            {levelList.map((lvl: Level) => (
              <Select.Option value={lvl.id}>{lvl.name}</Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
      {videoCall && (
        <div
          style={{
            width: '100%',
            // height: '100%',
            // display: 'flex',
            justifyContent: 'center',
            // backgroundColor: 'red',
          }}>
          {videoCall && selectedSession?.rtcAdminToken && (
            <AgoraVideoCall session={selectedSession} />
          )}
        </div>
      )}
    </div>
  );
};
