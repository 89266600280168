import React, {useEffect, useState, useCallback} from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  Select,
  Spin,
} from 'antd';

import axios from 'axios';
import CountriesWithCurrency from 'iso-country-currency';

import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

import {
  Availability,
  GroupInsert,
  Instructor,
  Level,
  Membership,
  DivisionType,
  Program,
  Slot,
  Status,
} from '../../graphql/API';

import {ProgramsService} from '../Program';
import {GroupService} from './GroupService';
import {useHistory} from 'react-router-dom';
import {InstructorService} from '../Instructor/InstructorService';
import {LevelsService} from '../Level/LevelsService';
import {
  DeleteOutlined,
  PlusCircleOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import moment from 'moment-timezone';

const groupService = new GroupService();
const instructorService = new InstructorService();
const levelsService = new LevelsService();
let programService = new ProgramsService();

const {Option} = Select;
const {RangePicker} = DatePicker;

var dayName = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const GroupCreate = () => {
  const history = useHistory();

  const [levels, setLevels] = useState<Array<Level> | []>([]);
  const [instructors, setInstructors] = useState<Array<Instructor> | []>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [programs, setPrograms] = useState<Array<Program> | []>([]);
  const [avaliableTimeZones, setAvaliableTimeZones] = useState<
    Array<string> | []
  >([]);
  const [instructorSlots, setInstructorSlot] = useState<Array<Slot> | []>([]);
  const [selectedInstructor, setSelectedInstructor] = useState<Instructor>();

  const [isError, setIsError] = useState<boolean>(false);
  const [filterMemberships, setFilteredMemberships] = useState<Array<any>>([]);
  const [selectedMemberShips, setSelectedMemberShip] = useState<Array<any>>([]);

  const [group, setGroup] = useState<GroupInsert>({
    name: '',
    // limit: null,
    levelId: '',
    division: DivisionType.GROUP,
    instructorId: '',
    membershipIds: [],
    programId: '',
    slotId: '',
  });

  useEffect(() => {
    levelsService
      .getLevels()
      .then((resp) => {
        setLevels(resp);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });

    programService
      .getPrograms()
      .then((programs: any) => {
        setPrograms(programs);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);

  const onChange = useCallback(
    (prop: any, value: any) => {
      setGroup({...group, [prop]: value});
    },
    [group],
  );

  useEffect(() => {
    if (group.programId && group.levelId) {
      setIsLoading(true);
      setSelectedMemberShip([]);
      onChange('membershipIds', []);
      const typeGroup: any = group.division;

      groupService
        .filteredMemberships(group.programId, group.levelId, typeGroup)
        .then((payload: any) => {
          setFilteredMemberships(payload);
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group.programId, group.levelId, group.division]);

  function toggleCheck(GroupDivision: DivisionType) {
    console.log(`checked = ${GroupDivision}`);
    onChange('division', GroupDivision);
  }

  return (
    <div style={{padding: 10}}>
      <Row>
        <Title
          style={{
            textAlign: 'center',
            marginInlineStart: 20,
            marginTop: 20,
            fontSize: 25,
          }}>
          Create New Group
        </Title>
      </Row>
      <Row>
        <Col span={8}>
          <div style={{margin: 20}}>
            <Text style={{display: 'block'}} strong={true}>
              Group Name
            </Text>
            <Input
              placeholder="Write group name"
              onChange={(event) => onChange('name', event.target.value)}
              value={group.name}
            />
          </div>
        </Col>
        <Col span={8}>
          <div style={{margin: 20}}>
            <Text style={{display: 'block'}} strong={true}>
              Group Level
            </Text>
            <Select
              //@ts-ignore
              value={group.levelId}
              showSearch
              style={{width: '100%'}}
              placeholder="Select Level"
              optionFilterProp="children"
              onChange={(lvlId) => onChange('levelId', lvlId)}>
              {levels?.map((lvl) => (
                <Select.Option value={lvl.id}>{lvl.name}</Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div style={{margin: 20}}>
            <Text style={{display: 'block'}} strong={true}>
              Group Program
            </Text>
            <Select
              //@ts-ignore
              value={group.programId}
              showSearch
              style={{width: '100%'}}
              placeholder="Select Program"
              optionFilterProp="children"
              onChange={(programId) => {
                const selectedProgram = programs.find(
                  (prgm: Program) => prgm.id === programId,
                );
                setInstructors(selectedProgram.instructors ?? []);
                onChange('programId', programId);
              }}>
              {programs?.map((prgm) => (
                <Select.Option value={prgm.id}>
                  {JSON.parse(prgm.name_i18n)['en']}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>

      <Row>
        <div
          style={{
            margin: 20,
          }}>
          <Checkbox
            checked={group.division === DivisionType.ONE_TO_ONE}
            onChange={() => toggleCheck(DivisionType.ONE_TO_ONE)}>
            1to1
          </Checkbox>
          <Checkbox
            checked={group.division === DivisionType.GROUP}
            onChange={() => toggleCheck(DivisionType.GROUP)}>
            Group
          </Checkbox>
        </div>
      </Row>

      {instructors?.length > 0 && (
        <Row>
          <Col span={12}>
            <div
              style={{
                margin: 20,
              }}>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginBottom: 20,
                  display: 'flex',
                }}>
                Add Instructor
              </Text>

              {instructors.map((instrctor: Instructor) => (
                <Button
                  onClick={() => {
                    let days = [];
                    setSelectedInstructor(instrctor);
                    instrctor?.availabilities?.forEach(
                      (availability: Availability) => {
                        availability?.slots?.forEach((slot) => {
                          let iterationDate: Date = new Date();
                          let nextDay: string = '';

                          for (let i = 1; i < 8; ++i) {
                            let now = new Date();
                            let nowDate = now.setDate(now.getDate() + i);
                            iterationDate = new Date(nowDate);

                            console.log('iterationDate: ' + iterationDate);
                            if (iterationDate.getDay() === slot.day) {
                              let firstSessionNumber =
                                iterationDate.toDateString() +
                                ' ' +
                                slot.startTime;
                              let firstSessionDate = new Date(
                                firstSessionNumber,
                              );
                              nextDay = firstSessionDate.toISOString();
                              break;
                            }
                          }

                          const Index = days.findIndex(
                            (dy) =>
                              dy?.dayIndex === moment(nextDay).utc(true).day(),
                          );
                          console.log(
                            'moment(nextDay).utc(true).day()',
                            moment(nextDay).utc(true).day(),
                          );

                          console.log('SLOT_DAY', slot.day);
                          console.log('SLOOOOOTTT', slot.id);

                          if (Index !== -1) {
                            days[Index] = {
                              dayIndex: moment(nextDay).utc(true).day(),
                              slots: [...days[Index].slots, slot],
                            };
                          } else {
                            days.push({
                              dayIndex: moment(nextDay).utc(true).day(),
                              slots: [slot],
                            });
                          }
                        });
                      },
                    );
                    onChange('instructorId', instrctor.id);
                    setInstructorSlot(days);
                  }}
                  style={{
                    width: '40%',
                    height: 50,
                    backgroundColor:
                      group.instructorId === instrctor.id
                        ? '#40B2F2'
                        : '#ECECEC',
                    marginBottom: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    display: 'flex',
                    borderRadius: 8,
                  }}>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color:
                        group.instructorId === instrctor.id ? '#fff' : '#000',
                    }}>
                    {instrctor.name}
                  </Text>
                </Button>
              ))}
            </div>
          </Col>

          <Col span={11}>
            <div
              style={{
                margin: 20,
              }}>
              {selectedInstructor && (
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginBottom: 20,
                    display: 'flex',
                  }}>
                  Avaliablity for {selectedInstructor?.name}
                </Text>
              )}
              {instructorSlots.map((day: any) => (
                <>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}>
                    {dayName[day.dayIndex]}
                  </Text>

                  {day.slots.map((slot: Slot) => (
                    <Button
                      disabled={slot.status === Status.DONE}
                      onClick={() => {
                        onChange('slotId', slot.id);
                      }}
                      style={{
                        width: '70%',
                        height: 50,
                        backgroundColor:
                          group.slotId === slot.id ? '#40B2F2' : '#ECECEC',
                        marginBottom: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        borderRadius: 9,
                      }}>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: group.slotId === slot.id ? '#fff' : '#000',
                        }}>
                        {moment
                          .tz(slot.startTime, 'HH', 'UTC')
                          .clone()
                          .tz('Africa/Cairo')
                          .format('hh:mm A')}{' '}
                        -{' '}
                      </Text>

                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: group.slotId === slot.id ? '#fff' : '#000',
                        }}>
                        {' '}
                        {moment
                          .tz(slot.endTime, 'HH', 'UTC')
                          .clone()
                          .tz('Africa/Cairo')
                          .format('hh:mm A')}
                      </Text>
                    </Button>
                  ))}
                </>
              ))}
            </div>
          </Col>
        </Row>
      )}

      {filterMemberships?.length > 0 &&
        (isLoading ? (
          <Spin size="large" style={{margin: 'auto', width: '100%'}} />
        ) : (
          <Row>
            <Col span={12}>
              <div
                style={{
                  margin: 20,
                }}>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginBottom: 20,
                    display: 'flex',
                    color: '#999999',
                  }}>
                  Add Students
                </Text>

                {filterMemberships.map((membership: Membership) => (
                  <Button
                    onClick={() => {
                      if (!selectedMemberShips.includes(membership)) {
                        setSelectedMemberShip([
                          ...selectedMemberShips,
                          membership,
                        ]);
                        onChange('membershipIds', [
                          ...group.membershipIds,
                          membership.id,
                        ]);
                      }
                    }}
                    style={{
                      width: '50%',
                      height: 50,
                      backgroundColor: '#ECECEC',
                      marginBottom: 10,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      alignContent: 'center',
                      display: 'flex',
                      borderRadius: 8,
                    }}>
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                      {membership.student.name}
                    </Text>
                    <PlusCircleOutlined style={{color: '#A6A6A6'}} />
                  </Button>
                ))}
              </div>
            </Col>

            {selectedMemberShips?.length > 0 && (
              <Col span={12}>
                <div
                  style={{
                    margin: 20,
                  }}>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginBottom: 20,
                      display: 'flex',
                      color: '#999999',
                    }}>
                    Group Students
                  </Text>

                  {selectedMemberShips.map((membership) => (
                    <div
                      style={{
                        width: '50%',
                        height: 50,
                        backgroundColor: '#40B2F2',
                        marginBottom: 10,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        borderRadius: 8,
                        paddingInline: 15,
                      }}>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: '#fff',
                        }}>
                        {membership.student.name}
                      </Text>

                      <DeleteOutlined
                        onClick={() => {
                          const index = selectedMemberShips.findIndex(
                            (item) => item.id === membership.id,
                          );

                          const groupMembershipsIndexIds =
                            group?.membershipIds?.findIndex(
                              (ID: string) => ID === membership.id,
                            );

                          let _tempSelected = [...selectedMemberShips];
                          let _tempGroupMembershipsId = [
                            ...group.membershipIds,
                          ];

                          _tempGroupMembershipsId.splice(
                            groupMembershipsIndexIds,
                            1,
                          );

                          _tempSelected.splice(index, 1);
                          setSelectedMemberShip([..._tempSelected]);
                          onChange('membershipIds', _tempGroupMembershipsId);
                        }}
                        style={{fontSize: '18px', color: '#fff'}}
                      />
                    </div>
                  ))}
                </div>
              </Col>
            )}
          </Row>
        ))}
      <Button
        style={{
          backgroundColor: '#40B2F2',
          width: '25%',
          height: 60,
          fontSize: 16,
          color: 'white',
          borderRadius: 10,
        }}
        onClick={async () => {
          if (
            group.programId &&
            group.slotId &&
            group.name &&
            group.levelId &&
            group.division &&
            group.instructorId &&
            group.membershipIds.length > 0
          ) {
            console.log('grou_create', group);
            try {
              await groupService.createGroup(group);
              alert('Group Created');
              window.location.reload();
            } catch (error) {}
          }
        }}>
        Create Group
      </Button>

      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Somthing went wrong ⚠️, refresh the page</Title>}
    </div>
  );
};
