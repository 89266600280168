export const deletePreInstructor = /* GraphQL */ `
  mutation DeletePreInstructor($id: ID!) {
    deletePreInstructor(id: $id) {
      id
    }
  }
`;
export const updatePreInstructor = /* GraphQL */ `
  mutation UpdatePreInstructor(
    $id: ID!
    $updatedAt: String!
    $input: PreInstructorUpdate!
  ) {
    updatePreInstructor(id: $id, updatedAt: $updatedAt, input: $input) {
      id
    }
  }
`;
