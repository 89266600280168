import {ICoursesRepository} from './ICoursesRepository';

import {
  Course,
  CourseInsert,
  Language,
  VideosessionInsert,
  VideosessionUpdate,
  VideosessionsConnection,
} from '../../graphql/API';
import {
  courses,
  coursesWithoutPlan,
  languages,
  videosessions,
  videosession,
} from './CoursesQueries';
import {createCourse, createVideosession} from '../../graphql/mutations';
import {course} from '../../graphql/queries';
import {updateVideosession} from './CoursesMutations';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class CoursesRepository implements ICoursesRepository {
  async getVideoSessions(): Promise<VideosessionsConnection> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: videosessions,
        variables: {},
      });
      console.log('video sessions from repository:', response);

      return response.data?.videosessions;
    } catch (error) {
      console.log('getVideoSession Error->', error);
      throw new Error(error);
    }
  }

  async getVideoSession(videosessionId: string): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: videosession,
        variables: {
          id: videosessionId,
        },
      });
      console.log('video sessions from repository:', response);

      return response.data?.videosession;
    } catch (error) {
      console.log('Fetch video session Error: ', error);
      throw new Error(error);
    }
  }

  async createCourse(course: CourseInsert): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: createCourse,
        variables: {
          input: course,
        },
      });
      console.log('courses from repository:', response);

      return response.data?.createCourse;
    } catch (error) {
      console.log('createCourse Error->', error);
      throw new Error(error);
    }
  }

  async updateVideosession(
    id: string,
    updatedAt: string,
    videoSession: VideosessionUpdate,
  ): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: updateVideosession,
        variables: {
          id,
          updatedAt,
          input: videoSession,
        },
      });
      console.log('courses from repository:', response);

      return response.data?.updateVideosession;
    } catch (error) {
      console.log('update video session Error->', error);
      throw new Error(error);
    }
  }

  async createVideoSession(videoSession: VideosessionInsert): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: createVideosession,
        variables: {
          input: videoSession,
        },
      });
      console.log('courses from repository:', response);

      return response.data?.createVideosession;
    } catch (error) {
      console.log('createVideoSession Error->', error);
      throw new Error(error);
    }
  }

  async getCourses(): Promise<Course | any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: courses,
        variables: {},
      });
      console.log('courses from repository:', response);

      return response.data?.courses?.items;
    } catch (error) {
      console.log('getCourses Error->', error);
      throw new Error(error);
    }
  }

  async getCoursesWithoutPlan(
    nextToken?: string,
    searchValue?: string,
  ): Promise<Course | any> {
    const filter = {name: {contains: searchValue}};

    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: coursesWithoutPlan,
        variables: {
          nextToken,
          filter,
        },
      });
      console.log('courses from repository:', response);

      return response.data?.courses?.items;
    } catch (error) {
      console.log('getCourses Without Plan Error->', error);
      throw new Error(error);
    }
  }

  async getLanguages(): Promise<Language[]> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: languages,
        variables: {},
      });
      console.log('courses from repository:', response);

      return response.data?.languages?.items;
    } catch (error) {
      console.log('getLanguages Error->', error);
      throw new Error(error);
    }
  }

  async getCourseById(courseId: string): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: course,
        variables: {id: courseId},
      });
      console.log('courses from repository:', response);

      return response.data?.course;
    } catch (error) {
      console.log('Fetch course Error: ', error);
      throw new Error(error);
    }
  }
}
