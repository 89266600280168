export const createSchool = /* GraphQL */ `
  mutation CreateSchool($input: SchoolInsert!) {
    createSchool(input: $input) {
      id
      createdAt
      updatedAt
      nameArabic
      name
    }
  }
`;

export const subscribeClassWithDiscountId = /* GraphQL */ `
  mutation SubscribeClassWithDiscountId(
    $classschoolId: String!
    $discountcodeId: String!
    $packageId: String!
  ) {
    subscribeClassWithDiscountId(
      classschoolId: $classschoolId
      discountcodeId: $discountcodeId
      packageId: $packageId
    )
  }
`;
