export const promocodes = /* GraphQL */ `
  query Promocodes(
    $filter: PromocodeFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    promocodes(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        codeName
        sessionPerWeek
        isApplied
        school {
          id
          name
        }
        level {
          id
          name
        }
        classschool {
          id
          name
        }
        package {
          id
          name_i18n
          program {
            name
            id
            flag
          }
        }
        student {
          id
        }
      }
      nextToken
      scannedCount
    }
  }
`;
