import {Button, Spin, Table} from 'antd';
import {useEffect, useState} from 'react';
import {Course} from '../../graphql/API';
import {Link} from 'react-router-dom';
import Title from 'antd/es/typography/Title';

interface PlanTableProps {
  data: Array<Course> | [];
  title: string;
  language:string;

}

export const PlanTable: React.FC<PlanTableProps>  = ({data,title,language}) => {

  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const columns = [
    {
      title: 'Course name',
      dataIndex: 'name',
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name);
      },
      width:"10%"
      
    },
    {
      title: 'Level',
      defaultSortOrder: 'ascend',
      dataIndex: ['level', 'name'],
      sorter: (a: any, b: any) => {
        return a.level?.name?.localeCompare(b?.level.name);
      },
      width:"5%",
      align:"center"
      
    },
    {
      title: 'Plan ID',
      dataIndex: ['plan', 'id'],
      sorter: (a: any, b: any) => {
        return a.language.country.localeCompare(b.language.country);
      },
      render: (planId: string) => <Link to={'/plans/' + planId}>{planId}</Link>,
      width:"25%"
    },
   
    {
      title: 'course ID',
      dataIndex: 'id',
      sorter: (a: any, b: any) => {
        return a.id.localeCompare(b.id);
      },
      sortDirections: ['descend'],
      width:"25%"
    },
  ];

  return (
    <>
      {!isError && !isLoading &&(
        <>
        <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>

       { title && <Title style={{fontSize:"1rem", color:"#6c757d",paddingLeft:"1rem"}}>Curriculum Name: <span style={{color:'black'}}>{title !== "undefined"?title:"None"} </span> </Title> }
       { language && <Title style={{fontSize:"1rem", color:"#6c757d",paddingRight:"1rem",marginTop:"0"}}>Language: <span style={{color:'black'}}>{language} </span> </Title> }
        </div>
      <Table
      pagination={{defaultPageSize: 10,hideOnSinglePage:true}}
      expandRowByClick={true}
      //@ts-ignore
      columns={columns}
      dataSource={data}
   
      />
      </>
      )      
      }
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Enrollments fetching error ⚠️</Title>}
    </>
  );
};
