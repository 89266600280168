import React, {memo, useEffect} from 'react';
import 'antd/dist/antd.css';
import {Layout, Menu, Avatar, Badge} from 'antd';
import {useState} from 'react';
import 'antd/dist/antd.css';
import {
  NotificationOutlined,
  PicLeftOutlined,
  SendOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {useSelector} from 'react-redux';
import {Gender, SenderType, Userconversation} from '../graphql/API';
import Dot from '../assets/icons/dot.png';
import Text from 'antd/lib/typography/Text';
const {Content, Footer, Sider} = Layout;

type SiderChatProps = {
  onPressChat: (usrConversation: Userconversation) => void;
};

const getPartnerName = (usrConv: Userconversation) => {
  if (usrConv?.conversation?.studentconversations[0]?.student?.name) {
    return usrConv?.conversation?.studentconversations[0]?.student?.name;
  }

  let instructor = usrConv?.conversation?.userconversations.find(
    (userConversation) => userConversation?.user?.instructor,
  );
  if (instructor) {
    return instructor?.user?.instructor?.name;
  }

  let preInstructor = usrConv?.conversation?.userconversations.find(
    (userConversation) => userConversation?.user?.preInstructor,
  );
  if (preInstructor) {
    return preInstructor?.user?.preInstructor?.name;
  }

  if (usrConv.user?.userconversations) {
    return usrConv.user?.preInstructor?.name;
  } else if (usrConv?.user?.instructor?.name) {
    return usrConv?.user?.instructor?.name;
  } else if (usrConv?.conversation?.studentconversations[0]?.student?.name) {
    return usrConv?.conversation?.studentconversations[0]?.student?.name;
  }
};
export const SiderChat = ({onPressChat}: SiderChatProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const [chatPartnerName, setChatPartnerName] = useState<string>('');
  const userConversations = useSelector(
    (state: any) => state?.user?.conversations,
  );

  return (
    <Sider
      theme={'dark'}
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'sticky',
        top: 0,
        left: 0,
      }}>
      <Menu>
        {userConversations?.map((usrConv: Userconversation, index) => (
          <MenuItemCustom
            key={index}
            collapsed={collapsed}
            usrConv={usrConv}
            index={index}
            onClick={onPressChat}
          />
        ))}
      </Menu>
    </Sider>
  );
};

type MenuItem = {
  onClick: (usrConv: Userconversation) => void;
  usrConv: Userconversation;
  index: number;
  collapsed: boolean;
};

const MenuItemCustom = memo(
  ({onClick, usrConv, index, collapsed}: MenuItem) => {
    const conversations: Array<Userconversation> = useSelector(
      (state: any) => state.user.conversations,
    );
    const [isMessageReplied, setIsMessageReplied] = useState<boolean>(true);
    useEffect(() => {
      try {
        console.log(
          'ENTERRRR_USEEFTTE',
          conversations[index]?.conversation?.messages[0],
        );
      } catch (e) {}
      if (conversations) {
        let isMessageRepl =
          conversations[index]?.conversation?.messages &&
          conversations[index]?.conversation?.messages[0]?.senderType ===
            SenderType.STUDENT
            ? false
            : !conversations[index]?.conversation?.messages
            ? false
            : true;

        setIsMessageReplied(isMessageRepl);
      }
    }, [conversations, index]);
    return (
      <Menu.Item key={usrConv.id} onClick={() => onClick(usrConv)}>
        {!isMessageReplied ? (
          <>
            <Badge size="small" dot style={{right: 5}} />
            <UserOutlined
              style={{
                fontSize: 15,
              }}
            />
          </>
        ) : (
          <UserOutlined
            style={{
              fontSize: 15,
            }}
          />
        )}
        <Text style={{fontWeight: 'bold', color: collapsed ? '#fff' : '#000'}}>
          {getPartnerName(usrConv)}
        </Text>
      </Menu.Item>
    );
  },
);
