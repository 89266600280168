import {IStudentsService} from './IStudentsService';
import {StudentsRepository} from './StudentsRepository';
import {Student} from '../../graphql/API';
import {stat} from 'fs';

export class StudentsService implements IStudentsService {
  studentsRepository: StudentsRepository;
  constructor() {
    this.studentsRepository = new StudentsRepository();
  }
  async getUserById(userId: string): Promise<any> {
    return await this.studentsRepository.getUserById(userId);
  }
  async getSessionsStudentById(studentId: string): Promise<any> {
    return await this.studentsRepository.getSessionsStudentById(studentId);
  }

  async getStudents(
    nextToken: string,
    searchKey?: string,
    searchValue?: string,
  ): Promise<any> {
    return await this.studentsRepository.getStudents(
      nextToken,
      searchKey,
      searchValue,
    );
  }

  async getStudentById(studentId: string): Promise<Student> {
    return await this.studentsRepository.getStudentById(studentId);
  }
  async getStudentWithFillReportByStudentId(
    studentId: string,
  ): Promise<Student> {
    return await this.studentsRepository.getStudentWithFillReportByStudentId(
      studentId,
    );
  }

  async updateStudent(student: Student): Promise<any> {
    let studentObj: any = {};
    studentObj.languageId = student.language?.id;
    studentObj.status = student.status;
    studentObj.assignedTo = student.assignedTo;
    studentObj.notes = student.notes;
    return await this.studentsRepository.updateStudent(
      student.id!,
      student.updatedAt!,
      studentObj,
    );
  }

  async getSyllablesByLetters(lettersList: Array<string>): Promise<any> {
    return await this.studentsRepository.getSyllablesByLetters(lettersList);
  }

  async generateStudentFullReport(
    studentId: string,
    statisticId: string,
  ): Promise<any> {
    return await this.studentsRepository.generateStudentFullReport(
      studentId,
      statisticId,
    );
  }
}
