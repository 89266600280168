import {useEffect, useState} from 'react';

import {Button, Col, Input, Row, Select, Typography} from 'antd';
import {useHistory, useLocation} from 'react-router-dom';
import {Level, Curriculum, Program, Course, Language} from '../../graphql/API';
import {LevelsService} from '../Level/LevelsService';
import {CurriculumsService} from './CurriculumsService';
import {ProgramsService} from '../Program';
import {CoursesService} from '../Courses';
import {isNaN} from 'lodash';

const levelService = new LevelsService();
const curriculumsService = new CurriculumsService();
const programsService = new ProgramsService();
const courseService = new CoursesService();

export const CurriculumCreate = () => {
  const history = useHistory();

  const {Title, Text} = Typography;

  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const location: {
    state: any;
    hash: any;
    search: any;
    pathname: any;
  } = useLocation();

  const [curriculum, setCurriculum] = useState<Curriculum>();
  const [programs, setPrograms] = useState<Array<Program> | []>([]);
  const [lanuages, setLanguages] = useState<Array<Language> | []>([]);

  const [selectedProgramId, setSelectedProgramId] = useState<string>();
  const [selectedLanguageId, setSelectedLanguageId] = useState<string>();

  const [levels, setLevels] = useState<Array<Level> | []>([]);

  useEffect(() => {
    levelService
      .getLevels()
      .then((lvls) => {
        setLevels(lvls);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });

    programsService
      .getPrograms()
      .then((resp) => {
        setIsLoading(false);
        // console.log('getProgramsr response ->->', resp);
        setPrograms(resp);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });

    courseService
      .getLanguages()
      .then((resp) => {
        setIsLoading(false);
        // console.log('setCourses response ->->', resp);
        setLanguages(resp);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);

  const onChange = (prop: any, value: any) => {
    console.log('curre_curriculum', curriculum);
    //@ts-ignore
    setCurriculum({...curriculum, [prop]: value});
  };

  console.log('curriculum', curriculum);

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Create Curriculum</Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Curriculum Name
              </Text>
              <Input
                placeholder={'Enter Curriculum Name'}
                onChange={(e) => onChange('name', e.target.value)}
                value={curriculum?.name}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Plan Period
              </Text>
              <Input
                placeholder={'Enter plan period in Days'}
                onChange={(e) => onChange('totalPlanPeriod', e.target.value)}
                value={curriculum?.totalPlanPeriod}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Select Curriculum Program *
              </Text>
              <Select
                value={selectedProgramId}
                showSearch
                style={{width: '100%'}}
                placeholder="Select a Program"
                optionFilterProp="children"
                onChange={(programId) => setSelectedProgramId(programId)}>
                {programs?.map((prgm) => (
                  <Select.Option value={prgm.id!}>
                    {JSON.parse(prgm.name_i18n)['en']}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Select Curriculum Language *
              </Text>
              <Select
                value={selectedLanguageId}
                showSearch
                style={{width: '100%'}}
                placeholder="Select a language"
                optionFilterProp="children"
                onChange={(lngId) => setSelectedLanguageId(lngId)}>
                {lanuages?.map((lng: Language) => (
                  <Select.Option value={lng.id!}>{lng.language}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
      </>

      <Button
        onClick={async () => {
          console.log('INCREATEEET');
          if (
            curriculum &&
            curriculum?.name &&
            curriculum?.totalPlanPeriod &&
            selectedProgramId &&
            selectedLanguageId
          ) {
            console.log('INCREATEEET_ID', curriculum);

            setIsLoading(true);

            await curriculumsService
              .createCurriculum(
                curriculum,
                selectedProgramId,
                selectedLanguageId,
              )
              .then((e: any) => {
                alert('curriculum Created');
                history.go(0);
              })
              .catch((err) => {
                alert('error curriculum' + JSON.stringify(err));

                console.log('upadate errpr curriculum->', err);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        }}>
        Create
      </Button>
    </div>
  );
};
