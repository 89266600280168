export const packages = `query packages ($filter: PackagesFilter, $scannedCount: Int, $nextToken: String) {
    packages (filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
        items {
            id
            description_i18n
            createdAt
            updatedAt
            number
            sessionPerWeek
            isHidden
            numberOfSessions
            isNative
            enrollmentPackage{
                id
                name_i18n
            }
            program{
            id
            name_i18n
            flag
            }
            onlyAI
            name
            isFree
            isActive
            benefits_i18n
            sessionDuration
            description
            benefits
            details_i18n
            name_i18n
            activities {
                id
                createdAt
                updatedAt
                type
                thumbnailUri
                fileUri
                title_i18n
                subTitle_i18n
                position
            }
             records {
                id
                createdAt
                updatedAt
                type
                thumbnailUri
                fileUri
                title_i18n
                subTitle_i18n
                position
            }
        }
        nextToken
        scannedCount
    }
}
`;

//@ts-ignore
export const getPackage = `
query package ($id: String!) {
    package (id: $id) {
        id
        createdAt
        isNative
        updatedAt
        numberOfSessions
        sessionPerWeek
        description_i18n
        number
        onlyAI
        isHidden
        typePackage
        name
        enrollmentPackage{
        id
        name_i18n
        }
        isFree
        isActive
         activities {
                id
                createdAt
                updatedAt
                type
                thumbnailUri
                fileUri
                title_i18n
                subTitle_i18n
                position
            }
             records {
                id
                createdAt
                updatedAt
                type
                thumbnailUri
                fileUri
                title_i18n
                subTitle_i18n
                position
            }
        program{
            id
            name_i18n
            flag
            }
        sessionDuration
        description
        benefits
        details_i18n
        name_i18n
        benefits_i18n
        price {
            id
            createdAt
            updatedAt
            name
            price
            currency
            country
        }
        description
        benefits
        discountDescription
        isRecommended
        sessionPerWeek
        packageDuration
        promoAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
        }
        coverAsset {
            id
            createdAt
            updatedAt
            type
            thumbnailUri
            fileUri
        }
    }
}
`;
