import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Image, Input, Space, Spin, Table} from 'antd';

import {get, isEqual} from 'lodash';

import {Syllable} from '../../graphql/API';

import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {SyllablesService} from './SyllablesService';
import ProfilePictureCard from '../PreInstructor/ProfilePictureCard';

const syllablesService = new SyllablesService();

export const SyllablesTable = () => {
  const [syllables, setSyllables] = useState<Array<Syllable> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchedColumn, setSearchedColumn] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  let searchInput = useRef();

  useEffect(() => {
    syllablesService
      .getSyllables()
      .then((resp) => {
        setIsLoading(false);
        setSyllables(resp);
        console.log('resp->', resp);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{padding: 8}}>
        <Input
          ref={(node) => {
            // @ts-ignore
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchedColumn(dataIndex);
              setSearchText(selectedKeys[0]);
            }}>
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value: any, record: any) =>
      get(record, dataIndex)
        ? get(record, dataIndex)
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        // @ts-ignore
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text: any) =>
      isEqual(searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };
  const columns: any = [
    {
      title: 'Syllable letters',
      dataIndex: ['syllable', 'letters'],
      key: 'letters',
      ...getColumnSearchProps('letters'),
      render: (name: string, syllable: Syllable) => (
        <Text>{syllable.letters} </Text>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: Syllable, b: Syllable) => {
        return a.letters.localeCompare(b.letters);
      },
    },
    {
      title: 'Syllable thumbnail',
      dataIndex: ['correctSyllable', 'thumbnailUri'],
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (thumbnailUri: string) => {
        console.log('thumbnailUri', thumbnailUri);
        return <ProfilePictureCard thumbnailUri={thumbnailUri} />;
      },
    },
    {
      title: 'id',
      dataIndex: 'id',
      ...getColumnSearchProps('id'),
      render: (id: string) => (
        <Link
          to={{
            pathname: '/syllables/' + id + '/edit',
            state: {syllableId: id},
          }}>
          {id}
        </Link>
      ),
      sorter: (a: any, b: any) => a.id.length - b.id.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  return (
    <>
      <Button size={'large'} style={{float: 'right', margin: 20}}>
        <Link to={'/syllables/create'}>Create</Link>
      </Button>
      {!isError && !isLoading && (
        <Table
          pagination={{defaultPageSize: 100}}
          columns={columns}
          dataSource={syllables}
        />
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Syllables fetching error ⚠️</Title>}
    </>
  );
};
