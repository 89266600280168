import {IAssetsService} from './IAssetsService';
import {AssetsRepository} from './AssetsRepository';
import {Asset, AssetInsert} from '../../graphql/API';
import {cloneDeep} from 'lodash';

export class AssetsService implements IAssetsService {
  assetsRepository: AssetsRepository;
  constructor() {
    this.assetsRepository = new AssetsRepository();
  }

  async createAsset(asset: AssetInsert): Promise<any> {
    return await this.assetsRepository.createAsset(asset);
  }

  async updateAsset(asset: Asset): Promise<any> {
    let assetId = asset.id;
    let assetUpdatedAt = asset.updatedAt;
    let ast = cloneDeep(asset);
    delete ast.id;
    delete ast.updatedAt;
    delete ast.createdAt;
    if (assetId && assetUpdatedAt) {
      return await this.assetsRepository.updateAsset(
        assetId!,
        assetUpdatedAt!,
        ast,
      );
    }
  }
}
