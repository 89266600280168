import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';

//@ts-ignore
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  BrowserRouter,
} from 'react-router-dom';
import {withAuthenticator} from '@aws-amplify/ui-react';

import './App.css';

import {getCurrentUserPermission, Permissions} from './utils';
import {InstructorEdit} from './modules/Instructor/InstructorEdit';
import {Col, Layout, Menu} from 'antd';
import {store} from './redux/store';

import {
  getMe,
  setLastSeenOnline,
  subscribeToNewConversation,
} from './redux/actions/user';
import {Provider} from 'react-redux';
import {Routers} from './components/Routers';

function App() {
  // const groupConversations: Array<any> = useSelector(
  //   (state: any) => state.user.groupConversations,
  // );

  useEffect(() => {
    //@ts-ignore
    store.dispatch(getMe());
    //@ts-ignore
    store.dispatch(subscribeToNewConversation());

    //@ts-ignore
    store.dispatch(setLastSeenOnline());
  }, []);

  return (
    <>
      <Router>
        <Provider store={store}>
          <Layout>
            <BrowserRouter>
              <Switch>
                <Route
                  render={() => {
                    return <Routers />;
                  }}
                />
              </Switch>
            </BrowserRouter>
          </Layout>
        </Provider>
      </Router>
    </>
  );
}

export default App;
//export default App;
