import {ICurriculumsRepository} from './ICurriculumsRepository';
import * as curriculumsQueries from './CurriculumsQueries';
import {createCurriculum} from './CurriculumsMutations';
import {CurriculumInsert} from '../../graphql/API';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class CurriculumsRepository implements ICurriculumsRepository {
  async getCurriculums(): Promise<any> {
    try {
      const curriculums: GraphQLResult<any> = await client.graphql({
        query: curriculumsQueries.curriculums,
        variables: {},
      });
      console.log('checkEmail from repository:', curriculums);

      return curriculums.data?.curriculums.items;
    } catch (error) {
      console.log('getCurriculums repository Error:', error);
      throw new Error(error);
    }
  }

  async createCurriculum(curriculum: CurriculumInsert): Promise<any> {
    try {
      const createCurriculumResponse: GraphQLResult<any> = await client.graphql(
        {
          query: createCurriculum,
          variables: {
            input: curriculum,
          },
        },
      );
      console.log(
        'createCurriculumResponse from repository:',
        createCurriculumResponse,
      );

      return createCurriculumResponse.data?.createCurriculum;
    } catch (error) {
      console.log('createCurriculumResponse repository Error:', error);
      throw new Error(error);
    }
  }
}
