export const createPrice = `mutation createPrice ($input: PriceInsert!) {
    createPrice (input: $input) {
        id
        createdAt
        updatedAt
    }
}
`;
export const updatePrice = `
mutation updatePrice ($id: ID!, $updatedAt: String!, $input: PriceUpdate!) {
    updatePrice (id: $id, updatedAt: $updatedAt, input: $input) {
        id
        createdAt
        updatedAt
    }
}
`;
