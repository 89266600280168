import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Image, Typography, Progress, Divider, List, Modal} from 'antd';
import {ScrollMenu, VisibilityContext} from 'react-horizontal-scrolling-menu';
//@ts-ignore
import {countries} from 'country-data';

import {detect} from 'detect-browser';
import moment from 'moment';

import './reportPage.css';
import './reportPage.css';
import PikadoSmallLogo from './assets/PikadoSmallLogo.svg';
import AttendanceCircle from './assets/AttendanceCircle.svg';
import Boy from './assets/Boy02.svg';

import {DemoLine} from './Graph';

import {
  MonthlyReport,
  Statistic,
  Student,
  Syllable,
  User,
} from '../../../graphql/API';

import {Loading} from '../../../components';
import useDrag from './useDrag';
import {LeftArrow, RightArrow} from './Arrows';
import {StudentsService} from '../StudentsService';
import {useParams} from 'react-router-dom';
import BasicImageDisplayAuth from '../../../components/BasicImageDisplayAuthS3';
import VideoModal from './VideoModal';

const studentService = new StudentsService();
type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

export const StudentReport = () => {
  const browser = detect();
  const {dragStart, dragStop, dragMove, dragging} = useDrag();
  const videoPlayerRef: any = useRef();
  const handleDrag =
    ({scrollContainer}: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });
  const {Text} = Typography;
  const [selectedStudent, setSelectedStudent]: any = useState();
  //@ts-ignore
  const user: User = useSelector((state: any) => state.user.user);
  const params: {studentId: string; statisticId: string} = useParams();
  const [isVideoModalVisible, setIsVideoModalVisible] =
    useState<boolean>(false);

  const [selectedSyllable, setSelectedSyllable] = useState<any>();

  const [report, setReport] = useState<Statistic>();
  const [currentMonthlyReport, setCurrentMonthlyReport] =
    useState<MonthlyReport>();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [performanceData, setPerformanceData] = useState<any>([]);

  const [sortedDaysList, setSortedDaysList] = useState<any>();

  const [syllableVideos, setSyllableVideos] = useState<Array<Syllable>>([]);
  useEffect(() => {
    browser?.name === 'safari' &&
      alert("The website doesn't support safari, Please use chrome or firefox");
  }, []);

  useEffect(() => {
    if (params?.statisticId !== 'null') {
      studentService
        .generateStudentFullReport(params.studentId, params.statisticId)
        .then((e) => {
          studentService
            .getStudentWithFillReportByStudentId(params?.studentId)
            .then((student) => {
              setSelectedStudent(student);
              let fullReport = student.statistic;
              let sortedMonthlyReports = fullReport?.monthlyReports.sort(
                (a: any, b: any) => {
                  //@ts-ignore
                  return new Date(a.month) - new Date(b.month);
                },
              );

              let currentReport = fullReport.monthlyReports.find(
                (mr: MonthlyReport) =>
                  moment(mr.month).format('YYYY-MM') ===
                  moment().format('YYYY-MM'),
              );
              sortedMonthlyReports.map((mr: MonthlyReport) => {
                let temp = performanceData;
                if (performanceData.length <= 5) {
                  temp?.push({
                    Score: mr?.average ? mr.average : 0,
                    Month: moment(mr.month).format('MMMM YYYY'),
                  });
                }
                setPerformanceData(temp);
              });
              setCurrentMonthlyReport(currentReport);

              try {
                fullReport.phoneticsAsString = JSON.parse(
                  fullReport.phoneticsAsString,
                );
              } catch (e) {
                //console.log('Inside parsing catch->', e);
              }
              try {
                fullReport.syllablesAsString = JSON.parse(
                  fullReport.syllablesAsString,
                );
              } catch (e) {
                //console.log('Inside parsing catch->', e);
              }
              setReport(fullReport);
              getSortedDaysList();
              setIsLoading(false);
              let first20Syllable = fullReport.syllablesAsString
                //@ts-ignore
                ?.sort(
                  //@ts-ignore
                  (a: any, b: any) => b.mistakes - a.mistakes,
                )
                ?.slice(0, 20);

              let first20Letters = first20Syllable.map((s: any) => s.letters);

              studentService
                .getSyllablesByLetters(first20Letters)
                .then((first20SyllableVideo: any) => {
                  setSyllableVideos(first20SyllableVideo);
                });
            })
            .catch((error) => {
              //console.log('error 1->', error);
              alert(`Error in getting your report. Please contact us:${error}`);
            });
        })
        .catch((error) => {
          //console.log('error 2->', error);
          alert(`Error in generating your report. Please contact us:${error}`);
        });
    } else {
      alert("This student didn't record any answers yet.");
    }
  }, []);

  useEffect(() => {
    getSortedDaysList();
  }, [currentMonthlyReport]);
  const getSortedDaysList = () => {
    let list = {};

    for (let i = 1; i <= 31; i++) {
      if (currentMonthlyReport) {
        //@ts-ignore
        list['d' + i] = currentMonthlyReport['d' + i];
      }
    }

    setSortedDaysList(list);
  };

  const getAbsenceDays = () => {
    let absenceDays = 0;
    for (let i = 1; i <= 31; i++) {
      //@ts-ignore
      if (currentMonthlyReport && currentMonthlyReport[`d${i}`] === null) {
        absenceDays = absenceDays + 1;
      }
    }
    return absenceDays;
  };
  const getGradeByScore = (score: number) => {
    if (score >= 90 && score <= 100) {
      return 'A+';
    } else if (score >= 85 && score <= 89) {
      return 'A';
    } else if (score >= 80 && score <= 84) {
      return 'A-';
    } else if (score >= 75 && score <= 79) {
      return 'B+';
    } else if (score >= 70 && score <= 74) {
      return 'B';
    } else if (score >= 65 && score <= 69) {
      return 'C+';
    } else if (score >= 60 && score <= 64) {
      return 'C';
    } else if (score >= 55 && score <= 59) {
      return 'D+';
    } else if (score >= 50 && score <= 54) {
      return 'D';
    } else if (score >= 40 && score <= 49) {
      return 'E';
    } else {
      return 'F';
    }
  };

  const getGradeColor = (score: number) => {
    if (score >= 90 && score <= 100) {
      return '#C3E56E';
    } else if (score >= 85 && score <= 89) {
      return '#FFD252';
    } else if (score >= 80 && score <= 84) {
      return '#40B2F2';
    } else if (score >= 75 && score <= 79) {
      return '#EF426B';
    } else if (score >= 70 && score <= 74) {
      return '#F772FA';
    } else if (score >= 65 && score <= 69) {
      return '#D7D7D7';
    } else if (score >= 60 && score <= 64) {
      return '#B4B4B4';
    } else if (score >= 55 && score <= 59) {
      return '#808080';
    } else if (score >= 50 && score <= 54) {
      return '#2F2F2F';
    } else if (score >= 40 && score <= 49) {
      return '#D9D9D9';
    } else {
      return '#D9D9D9';
    }
  };

  const renderReportHeader = () => {
    return (
      <div
        style={{
          border: '2px solid #ECECEC',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
        }}>
        <div style={{display: 'grid', gridTemplateColumns: '0.1fr 1fr'}}>
          <Image
            width={100}
            preview={false}
            src={Boy}
            style={{display: 'inline-block'}}
            wrapperStyle={{verticalAlign: 'top', padding: 10}}
          />
          <div
            style={{
              marginLeft: 20,
              alignSelf: 'center',
            }}>
            <Text
              style={{
                display: 'block',
                fontFamily: 'Arial Rounded MT Bold',
                fontSize: 21,
              }}>
              {selectedStudent?.name}
            </Text>
            <Text
              style={{display: 'block', fontFamily: 'Arial Rounded MT Bold'}}>
              {selectedStudent?.age}, {countries[user?.country!].name}
            </Text>
            <Text
              style={{
                display: 'block',
                fontFamily: 'Arial Rounded MT Bold',
                color: '#999999',
              }}>
              Since {moment(selectedStudent.createdAt).format('MMM. YYYY')}
            </Text>
          </div>
        </div>
        <div
          style={{
            borderRight: '2px solid #ECECEC',
            borderLeft: '2px solid #ECECEC',
            display: 'grid',
            gridTemplateColumns: '0.5fr 1fr',
            position: 'relative',
          }}>
          <div
            style={{
              backgroundColor: getGradeColor(
                selectedStudent.statistic?.averageScore
                  ? selectedStudent.statistic?.averageScore
                  : 0,
              ),
              position: 'relative',
            }}>
            <Text
              style={{
                color: '#FFFFFF',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 25,
                fontFamily: 'Arial Rounded MT Bold',
              }}>
              {getGradeByScore(
                selectedStudent.statistic?.averageScore
                  ? selectedStudent.statistic?.averageScore
                  : 0,
              )}
            </Text>
          </div>
          <div style={{alignSelf: 'center', marginLeft: 20}}>
            <Text
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                display: 'block',
                fontSize: 21,
                color: '#999999',
              }}>
              Total performance
            </Text>
            <Text
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                fontSize: 27,
                color: '#40B2F2',
              }}>
              {report?.averageScore}%
            </Text>
          </div>
        </div>

        <div style={{display: 'grid', gridTemplateColumns: '0.1fr 1fr'}}>
          <Image width={100} preview={false} src={PikadoSmallLogo} />
          <div style={{alignSelf: 'center'}}>
            <Text
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                color: '#999999',
                marginLeft: 20,
                alignSelf: 'center',
                display: 'block',
              }}>
              PikaDo Analysis Using Artificial Intelligence (AI) Student
              Dashboard For Listening & Speaking Version 1.0 Generated on{' '}
            </Text>
            <Text
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                color: '#EF426B',
                marginLeft: 20,
              }}>
              {moment(report?.fullReportLastGeneratedDate).format(
                'ddd. DD.MM.YYYY',
              ) === 'Invalid date'
                ? 'No reports generated yet.'
                : moment(report?.fullReportLastGeneratedDate).format(
                    'ddd. DD.MM.YYYY',
                  )}
            </Text>
          </div>
        </div>
      </div>
    );
  };

  const attendanceGraph = () => {
    return Object.entries(sortedDaysList).map(function ([key, value], index) {
      return (
        <div
          style={{
            marginBottom: 10,
            alignSelf: 'self-end',
          }}>
          <div
            style={{
              borderBottom: '1px solid #999999',
              paddingBottom: 10,
              display: 'flex',
              flexDirection: 'column-reverse',
              alignItems: 'center',
            }}>
            {[...new Array(sortedDaysList[key])].map((_, index) => {
              return index < 5 ? (
                <Image
                  wrapperStyle={{display: 'block'}}
                  width={8}
                  preview={false}
                  src={AttendanceCircle}
                />
              ) : (
                index === 5 && (
                  <Text
                    style={{
                      fontFamily: 'Arial Rounded MT Bold',
                      color: '#999999',
                      fontSize: 13,
                    }}>
                    +{sortedDaysList[key] - 5}
                  </Text>
                )
              );
            })}
          </div>
          <Text
            style={{
              marginTop: 10,
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 13,
            }}>
            {index + 1}
          </Text>
        </div>
      );
    });
  };
  const renderMonthlyAttendance = () => {
    return (
      <div
        style={{
          marginTop: 30,
        }}>
        <div style={{marginBottom: 30}}>
          <Text
            style={{
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              display: 'inline-block',
              fontSize: 20,
            }}>
            Attendance This Month {moment().format('MMMM')}
          </Text>
          <div style={{display: 'inline-block', float: 'right'}}>
            <Text
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                color: '#C3E56E',
                marginRight: 20,
                fontSize: 20,
              }}>
              {currentMonthlyReport?.attempts !== 0
                ? `${currentMonthlyReport?.attempts} Recorded Exercises`
                : 'No Recorded Exercises for this month yet.'}
            </Text>
            <Text
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                color: '#F2406A',
                fontSize: 20,
              }}>
              {getAbsenceDays()} Absence Days
            </Text>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridAutoFlow: 'column',
            //@ts-ignore
            textAlign: '-webkit-center',
            minHeight: 200,
          }}>
          {attendanceGraph()}
        </div>
      </div>
    );
  };

  const renderPerformance = () => {
    return (
      <div
        style={{
          display: 'grid',
          gridAutoFlow: 'column',
          gridTemplateColumns: '1fr 0.5fr 3fr',
        }}>
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              fontWeight: 'bold',
            }}>
            Current Month Performance
          </Text>
          <Progress
            width={300}
            format={(percent) => (
              <div>
                <Text
                  style={{
                    display: 'block',
                    marginTop: 10,
                    fontFamily: 'Arial Rounded MT Bold',
                    color: '#40B2F2',
                    fontSize: 40,
                  }}>
                  {currentMonthlyReport?.average
                    ? currentMonthlyReport?.average
                    : 0}
                  %
                </Text>

                <Text
                  style={{
                    display: 'block',
                    fontFamily: 'Arial Rounded MT Bold',
                    color: '#000000',
                    fontSize: 13,
                    fontWeight: 'bold',
                  }}>
                  Native American Accent
                </Text>
                <Text
                  style={{
                    display: 'block',
                    marginTop: 10,
                    fontFamily: 'Arial Rounded MT Bold',
                    color: '#999999',
                    fontSize: 13,
                  }}>
                  {moment().format('MMMM')}
                </Text>
              </div>
            )}
            type="circle"
            percent={
              currentMonthlyReport?.average ? currentMonthlyReport?.average : 0
            }
            strokeWidth={3}
            strokeColor={{'0%': '#6EBEF2', '100%': '#C3E56F'}}
          />
        </div>
        <Divider
          orientation={'center'}
          style={{height: '100%', justifySelf: 'center'}}
          type={'vertical'}
        />
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              fontWeight: 'bold',
            }}>
            Last 6 Months Performance
          </Text>
          {/*@ts-ignore*/}
          <DemoLine data={performanceData} />
        </div>
      </div>
    );
  };

  const renderMistakes = () => {
    // @ts-ignore
    return (
      <div
        style={{
          display: 'grid',
          gridAutoFlow: 'column',
          paddingBottom: 30,
        }}>
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
            }}>
            Top 20 Syllables Mistakes
          </Text>

          <List
            className={'scrollable-element'}
            style={{overflow: 'auto', height: 500}}
            dataSource={
              //@ts-ignore
              report?.syllablesAsString
                ? report?.syllablesAsString
                    //@ts-ignore
                    ?.sort(
                      //@ts-ignore
                      (a: any, b: any) => b.mistakes - a.mistakes,
                    )
                    ?.slice(0, 20)
                : []
            }
            split={false}
            renderItem={(item, index) => (
              <List.Item
                //@ts-ignore
                key={`${item?.letters}+${index}`}
                style={{paddingLeft: 0}}>
                <div style={{display: 'grid', gridAutoFlow: 'column'}}>
                  <div style={{width: 100}}>
                    <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#40B2F2',
                        fontSize: 18,
                        marginRight: 50,
                      }}>
                      {/*//@ts-ignore*/}
                      {item?.letters}
                    </Text>
                  </div>
                  <div style={{}}>
                    <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#000000',
                        fontSize: 18,
                      }}>
                      {/*//@ts-ignore*/}
                      {item?.mistakes} Mistake
                    </Text>
                    <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#999999',
                        fontSize: 18,
                      }}>
                      {/*//@ts-ignore*/}
                      {item?.attempts} Trails
                    </Text>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
        <Divider
          orientation={'center'}
          style={{height: '50%', justifySelf: 'center', alignSelf: 'center'}}
          type={'vertical'}
        />
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
            }}>
            Top 20 Phonetics Mistakes
          </Text>
          <List
            className={'scrollable-element'}
            style={{overflow: 'auto', height: 500}}
            dataSource={
              report?.phoneticsAsString
                ? report?.phoneticsAsString
                    //@ts-ignore
                    ?.sort(
                      //@ts-ignore
                      (a: any, b: any) => b.mistakes - a.mistakes,
                    )
                    .slice(0, 20)
                : []
            }
            split={false}
            renderItem={(item, index) => (
              <List.Item
                //@ts-ignore
                key={`${item?.phone}+${index}`}
                style={{paddingLeft: 0}}>
                <div style={{display: 'grid', gridAutoFlow: 'column'}}>
                  <div style={{width: 100}}>
                    <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#F2406A',
                        fontSize: 18,
                        marginRight: 50,
                      }}>
                      {/*@ts-ignore*/}
                      {item?.phone}
                    </Text>
                  </div>
                  <div>
                    <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#000000',
                        fontSize: 18,
                      }}>
                      {/*@ts-ignore*/}
                      {item?.mistakes} Mistake
                    </Text>
                    <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#999999',
                        fontSize: 18,
                      }}>
                      {/*@ts-ignore*/}
                      {item?.attempts} Trails
                    </Text>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
        <Divider
          orientation={'center'}
          style={{height: '50%', justifySelf: 'center', alignSelf: 'center'}}
          type={'vertical'}
        />
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
            }}>
            Previous 20 trials
          </Text>
          <List
            className={'scrollable-element'}
            style={{overflow: 'auto', height: 500}}
            dataSource={report?.scores?.slice(0).slice(-20).reverse()}
            split={false}
            renderItem={(item, index) => (
              <List.Item key={`${item}+${index}`} style={{paddingLeft: 0}}>
                <div style={{display: 'grid', gridAutoFlow: 'column'}}>
                  <div style={{marginRight: 20}}>
                    <Progress
                      width={100}
                      type="circle"
                      percent={item ? item : 0}
                      strokeWidth={10}
                      strokeColor={{'0%': '#F2406A', '100%': '#FFD252'}}
                    />
                  </div>
                  <div>
                    {/* <Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#000000',
                        fontSize: 18,
                      }}>
                      {item}%
                    </Text>*/}
                    {/*<Text
                      style={{
                        display: 'block',
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#000000',
                        fontSize: 12,
                      }}>
                      {moment(item.date).format('lll')}
                    </Text>*/}
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div style={{textAlign: 'center'}}>
        <Text
          style={{
            display: 'block',
            fontFamily: 'Arial Rounded MT Bold',
            color: '#C4C4C4',
            fontSize: 13,
          }}>
          PikaDo LLC
        </Text>
        <Text
          style={{
            display: 'block',
            fontFamily: 'Arial Rounded MT Bold',
            color: '#C4C4C4',
            fontSize: 13,
          }}>
          Suite 302, 4001 Kennett Pike, County of New Castle, Wilmington,
          Delaware 19807, USA
        </Text>
        <div>
          <a
            style={{display: 'inline-block'}}
            target="_blank"
            href={'https://pikado.io/'}>
            <Text
              style={{
                display: 'inline-block',
                fontFamily: 'Arial Rounded MT Bold',
                color: '#C4C4C4',
                fontSize: 13,
                marginRight: 20,
              }}>
              www.PikaDo.io
            </Text>
          </a>
          <a
            style={{display: 'inline-block'}}
            target="_blank"
            href={'mailto:abc@example.com'}>
            <Text
              style={{
                display: 'inline-block',
                fontFamily: 'Arial Rounded MT Bold',
                color: '#C4C4C4',
                fontSize: 13,
              }}>
              info@PikaDo.io
            </Text>
          </a>
        </div>
      </div>
    );
  };

  const handleItemClick = (item: any) => () => {
    if (dragging) {
      return false;
    }
    setSelectedSyllable(item);
    setIsVideoModalVisible(true);
  };

  const renderMistakesVideos = () => {
    return (
      <div
        style={{
          marginTop: 20,
          marginBottom: 30,
          position: 'relative',
        }}>
        <ScrollMenu
          Header={
            <Text
              style={{
                display: 'inline-block',
                fontFamily: 'Arial Rounded MT Bold',
                color: '#999999',
                fontSize: 20,
                marginBottom: 20,
              }}>
              Personalised videos depends on your mistakes
            </Text>
          }
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onWheel={onWheel}
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}>
          {syllableVideos?.map((item, index) => (
            <div
              onClick={handleItemClick(item)}
              style={{textAlign: 'center', display: 'inline-table'}}>
              <BasicImageDisplayAuth
                thumbnailUri={item.correctSyllable?.thumbnailUri}
              />

              <Text
                style={{
                  fontFamily: 'Arial Rounded MT Bold',
                  color: '#000000',
                  fontSize: 20,
                  textAlign: 'center',
                }}>
                {item.letters}
              </Text>
            </div>
          ))}
        </ScrollMenu>
      </div>
    );
  };
  function onWheel(apiObj: any, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }

  return (
    <div
      style={{
        marginTop: 30,
        marginRight: 60,
        marginLeft: 60,
      }}>
      {report && (
        <>
          {renderReportHeader()}
          {syllableVideos?.length !== 0 && renderMistakesVideos()}
          {renderMonthlyAttendance()}
          <Divider />
          {renderPerformance()}
          <Divider />
          {renderMistakes()}
          {renderFooter()}
          {isVideoModalVisible && (
            <VideoModal
              thumbnailUri={selectedSyllable?.correctSyllable.thumbnail}
              fileUri={selectedSyllable?.correctSyllable.fileUri}
              letters={selectedSyllable?.letters}
              onCancelModal={function (): void {
                setIsVideoModalVisible(false);
              }}
            />
          )}
        </>
      )}
      {isLoading && <Loading />}
    </div>
  );
};
