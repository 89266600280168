import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserHistory} from 'history';
import {Amplify} from 'aws-amplify';
import awsconfig from './amplifyconfiguration.json';

import 'video-react/dist/video-react.css'; // import css
import '@aws-amplify/ui-react/styles.css';
import '@aws-amplify/ui-react/styles/reset.layer.css'; // global CSS reset
import '@aws-amplify/ui-react/styles/base.layer.css'; // base styling needed for Amplify UI
import '@aws-amplify/ui-react/styles/button.layer.css'; // component specific styles
export const browserHistory = createBrowserHistory();
Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
