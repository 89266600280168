import React, {useEffect, useState, useCallback} from 'react';
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
} from 'antd';

import axios from 'axios';
import CountriesWithCurrency from 'iso-country-currency';
import {Image} from 'antd';

import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

import {
  AdminGetUserResponse,
  AdminListUser,
  AdminListUserGroups,
  AdminListUsersResponse,
  AttributeType,
  GroupType,
} from '../../graphql/API';
import {ProgramsService} from '../Program';
import {useHistory} from 'react-router-dom';
import {AssetsService} from '../Asset';
import {PlusOutlined} from '@ant-design/icons';
import {cloneDeep, cloneWith} from 'lodash';
import moment from 'moment';
import {AccountService} from './AccountService';
import {CSVLink} from 'react-csv';

export const currentGroupsRoles = [
  'Admins',
  'Instructors',
  'Editors',
  'Schools',
];
const accountService = new AccountService();
export const AddingRole = () => {
  const history = useHistory();

  const [email, setEmail] = useState<string>('');
  const [isEmailFound, setIsEmailFound] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [counterPagesUsers, setCounterPagesUsers] = useState<number>(0);

  const [emailGroups, setEmailGroups] = useState<GroupType[]>([]);
  const [pendingGroups, setPendingGroups] = useState<typeof currentGroupsRoles>(
    [],
  );

  const headers = [{label: 'Email', key: 'email'}];

  const [data, setData] = useState<any>([]);

  const [cognitoUsers, setCongnitoUsers] = useState<AdminListUser>();
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    accountService
      .getListCognitoUsers(cognitoUsers?.PaginationToken)
      .then((usrs: AdminListUser) => {
        setCongnitoUsers(usrs);
        const data = usrs.Users.map((value: AdminListUsersResponse) => {
          return {
            [value.Attributes[0].Name]: value.Attributes[0].Value,
          };
        });

        setData(data);
        console.log('usrs', usrs);
        setIsLoading(false);
        setCounterPagesUsers(counterPagesUsers + 1);
      })
      .catch((error: any) => {
        setIsError(true);
        setIsLoading(false);

        console.log('Error ->', error);
      });
  }, []);

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Adding Role</Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Find user to assign roles
              </Text>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <Input
                  onChange={(event) => {
                    setEmail(event.target.value);
                    if (isEmailFound) {
                      setIsEmailFound(false);
                    }
                  }}
                  value={email}
                  placeholder="Email"
                />
                <Button
                  onClick={async () => {
                    if (email) {
                      try {
                        setIsLoading(true);
                        setIsEmailFound(false);
                        const userGroups: AdminListUserGroups =
                          await accountService.listEmailGroupList(email);

                        setEmailGroups(userGroups.Groups);
                        setPendingGroups(
                          userGroups.Groups.map(
                            (value: GroupType) => value.GroupName,
                          ),
                        );
                        setIsEmailFound(true);
                        setIsLoading(false);
                      } catch (error) {
                        alert('email not found / error occurs');
                        setIsLoading(false);
                      }
                    } else {
                      setIsEmailFound(false);
                    }
                  }}>
                  Find
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        {isEmailFound && (
          <Col
            span={12}
            style={{
              backgroundColor: '#F7F7F7',
              marginInline: 20,
            }}>
            <Row>
              <Text>Roles</Text>
            </Row>
            {currentGroupsRoles.map((value: string) => (
              <Checkbox
                checked={pendingGroups?.includes(value) ? true : false}
                onChange={(isChecked) => {
                  console.log('isChecked', isChecked.target.checked);
                  if (
                    !pendingGroups.includes(value) &&
                    isChecked.target.checked
                  ) {
                    setPendingGroups([...pendingGroups, value]);
                  } else if (
                    pendingGroups.includes(value) &&
                    !isChecked.target.checked
                  ) {
                    let tempPendingGroups = [...pendingGroups];
                    const index = tempPendingGroups.indexOf(value);
                    if (index > -1) {
                      tempPendingGroups.splice(index, 1);
                      setPendingGroups(tempPendingGroups);
                    }
                  }
                }}>
                {value}
              </Checkbox>
            ))}
            <Row>
              <Button
                style={{marginTop: 15}}
                onClick={async () => {
                  const currentGroupsName = emailGroups.map(
                    (group: GroupType) => group.GroupName,
                  );

                  if (
                    pendingGroups.length > 0 &&
                    JSON.stringify(currentGroupsName) !==
                      JSON.stringify(pendingGroups)
                  ) {
                    setIsLoading(true);
                    try {
                      await Promise.all(
                        pendingGroups.map((groupName: string) => {
                          return accountService.addUserToRole(email, groupName);
                        }),
                      )
                        .then(() => {
                          alert('Success');
                          setIsEmailFound(false);
                          setEmail('');
                        })
                        .catch((error) => {
                          console.error(error);
                          alert('error');
                          setIsLoading(false);
                        })
                        .finally(() => {
                          setIsLoading(false);
                        });
                    } catch (error) {
                      console.error(error);
                      alert('error occurs');
                      setIsLoading(false);
                    }
                  }
                }}>
                Save
              </Button>
            </Row>
          </Col>
        )}
      </>
      {counterPagesUsers > 0 && !isLoading && (
        <CSVLink
          data={data}
          headers={headers}
          filename={'pikado-emails-list.csv'}
          className="btn btn-primary"
          target="_blank"
          onClick={() => {
            if (cognitoUsers?.PaginationToken) {
              setIsLoading(true);
              accountService
                .getListCognitoUsers(cognitoUsers?.PaginationToken)
                .then((usrs: AdminListUser) => {
                  setCongnitoUsers(usrs);
                  const data = usrs.Users.map(
                    (value: AdminListUsersResponse) => {
                      return {
                        [value.Attributes[0].Name]: value.Attributes[0].Value,
                      };
                    },
                  );

                  setData(data);
                  console.log('usrs', usrs);
                  setIsLoading(false);
                  setCounterPagesUsers(counterPagesUsers + 1);
                })
                .catch((error: any) => {
                  setIsError(true);
                  console.log('Error ->', error);
                });
            }
          }}>
          Download Emails {counterPagesUsers}
        </CSVLink>
      )}
      {isLoading && (
        <Spin
          size="large"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
    </div>
  );
};
