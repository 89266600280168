import {useEffect, useState} from 'react';
import {Button, Input} from 'antd';
import Text from 'antd/es/typography/Text';

type InputI18nStringObjectBlockProps = {
  stringifiedJson: string;
  onChange: (newText: string) => void;
  fieldName: string;
};

export const InputI18nStringObjectBlock = ({
  stringifiedJson,
  onChange,
  fieldName,
}: InputI18nStringObjectBlockProps) => {
  let tempObject = {
    en: [{icon: '', text: ''}],
    ar: [{icon: '', text: ''}],
    tr: [{icon: '', text: ''}],
  };
  const [textObject, setTextObject] = useState<
    | undefined
    | {
        en: Array<{icon: string; text: string}>;
        ar: Array<{icon: string; text: string}>;
        tr: Array<{icon: string; text: string}>;
      }
  >(tempObject);
  useEffect(() => {
    try {
      setTextObject(JSON.parse(stringifiedJson));
    } catch (e) {
      console.log('inside catch ->', e);
    }
  }, [stringifiedJson]);
  return (
    <div style={{border: '1px solid black'}}>
      <Text style={{fontWeight: 'bold', fontSize: '20px'}}>{fieldName}</Text>
      <Button
        onClick={() => {
          setTextObject({
            ...textObject,
            en: [...textObject.en, {text: '', icon: ''}],
            ar: [...textObject.ar, {text: '', icon: ''}],
            tr: [...textObject.tr, {text: '', icon: ''}],
          });
        }}>
        Add more fields
      </Button>
      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <Text style={{fontWeight: 'bold', fontSize: '20px'}}>En</Text>
        {textObject?.en?.map((item, index) => {
          return (
            <div
              key={`${item.text}_${index}`}
              style={{border: '1px solid red', margin: 10}}>
              <div>
                <Text>Text</Text>
                <Input
                  value={item.text}
                  onChange={(e) => {
                    let newTextObj = textObject;
                    textObject['en'][index].text = e.target.value;
                    setTextObject(textObject);
                    onChange(JSON.stringify(newTextObj));
                  }}
                />
              </div>
              <div>
                <Text>Icon url</Text>
                <Input
                  value={item.icon}
                  onChange={(e) => {
                    let newTextObj = textObject;
                    textObject['en'][index].icon = e.target.value;
                    setTextObject(textObject);
                    onChange(JSON.stringify(newTextObj));
                  }}
                />
              </div>
            </div>
          );
        })}
        <Text style={{fontWeight: 'bold', fontSize: '20px'}}>Ar</Text>
        {textObject?.ar?.map((item, index) => {
          return (
            <div style={{border: '1px solid red', margin: 10}}>
              <div>
                <Text>Text</Text>
                <Input
                  value={item.text}
                  onChange={(e) => {
                    let newTextObj = textObject;
                    textObject['ar'][index].text = e.target.value;
                    setTextObject(textObject);
                    onChange(JSON.stringify(newTextObj));
                  }}
                />
              </div>
              <div>
                <Text>Icon url</Text>
                <Input
                  value={item.icon}
                  onChange={(e) => {
                    let newTextObj = textObject;
                    textObject['ar'][index].icon = e.target.value;
                    setTextObject(textObject);
                    onChange(JSON.stringify(newTextObj));
                  }}
                />
              </div>
            </div>
          );
        })}

        <Text style={{fontWeight: 'bold', fontSize: '20px'}}>Tr</Text>
        {textObject?.tr?.map((item, index) => {
          return (
            <div style={{border: '1px solid red', margin: 10}}>
              <div>
                <Text>Text</Text>
                <Input
                  value={item.text}
                  onChange={(e) => {
                    let newTextObj = textObject;
                    textObject['tr'][index].text = e.target.value;
                    setTextObject(textObject);
                    onChange(JSON.stringify(newTextObj));
                  }}
                />
              </div>
              <div>
                <Text>Icon url</Text>
                <Input
                  value={item.icon}
                  onChange={(e) => {
                    let newTextObj = textObject;
                    textObject['tr'][index].icon = e.target.value;
                    setTextObject(textObject);
                    onChange(JSON.stringify(newTextObj));
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
