export const customSyllables = /* GraphQL */ `
  query Syllables(
    $filter: SyllablesFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    syllables(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        letters
        correctSyllable {
          id
          createdAt
          updatedAt
          type
          thumbnailUri
          fileUri
        }
      }
      nextToken
      scannedCount
    }
  }
`;
