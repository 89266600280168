import {CSSProperties, useEffect, useLayoutEffect, useState} from 'react';
import {Input} from 'antd';
import Text from 'antd/es/typography/Text';

type InputI18nStringBlockProps = {
  stringifiedJson: string;
  onChange: (newText: string) => void;
  fieldName: string;
  containerStyle?: CSSProperties;
};

export const InputI18nStringBlock = ({
  stringifiedJson,
  onChange,
  fieldName,
  containerStyle = {border: '1px solid black'},
}: InputI18nStringBlockProps) => {
  const [textObject, setTextObject] = useState<any>(
    JSON.parse(stringifiedJson),
  );
  console.log('textObject', textObject);

  useEffect(() => {
    try {
      setTextObject(JSON.parse(stringifiedJson));
    } catch (e) {
      console.log('inside catch ->', e);
    }
  }, [stringifiedJson]);

  return (
    <div style={containerStyle}>
      <Text style={{fontWeight: 'bold', fontSize: '20px'}}>{fieldName}</Text>
      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <Text>{fieldName} en</Text>
        <Input
          value={textObject?.en ? textObject['en'] : ''}
          onChange={(e) => {
            let newTextObj = textObject;
            setTextObject({
              ...textObject,
              en: e.target.value,
            });
            newTextObj['en'] = e.target.value;
            onChange(JSON.stringify(newTextObj));
          }}
        />
      </div>
      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <Text>{fieldName} ar</Text>
        <Input
          value={textObject?.ar ? textObject['ar'] : ''}
          onChange={(e) => {
            let newTextObj = textObject;
            setTextObject({
              ...textObject,
              ar: e.target.value,
            });
            newTextObj['ar'] = e.target.value;
            onChange(JSON.stringify(newTextObj));
          }}
        />
      </div>
      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <Text>{fieldName} tr</Text>
        <Input
          value={textObject?.ar ? textObject['tr'] : ''}
          onChange={(e) => {
            let newTextObj = textObject;
            setTextObject({
              ...textObject,
              tr: e.target.value,
            });
            newTextObj['tr'] = e.target.value;
            onChange(JSON.stringify(newTextObj));
          }}
        />
      </div>
    </div>
  );
};
