import {useEffect, useState} from 'react';
import {useParams, Link, useHistory, useLocation} from 'react-router-dom';
import {Button, Col, Divider, Row, Spin, Typography} from 'antd';
import {Session, Status, Student, User} from '../../graphql/API';
import {StudentsService} from './StudentsService';
import moment from 'moment-timezone';

const studentsService = new StudentsService();

export const UserDetails = () => {
  // 4fe2f51f-302e-40d1-ad9a-5d28d8e3fd69
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let params: {userId: string} = useParams();
  const {Title, Text} = Typography;
  const location = useLocation();

  useEffect(() => {
    studentsService
      .getUserById(params.userId)
      .then((resp) => {
        setIsLoading(false);
        setUser(resp);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [location.pathname]);

  //console.log('params.studentId', location.pathname);
  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>User/Parent Data</Title>

      {!isError && !isLoading && (
        <>
          <Row>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                User id
              </Text>

              <Text style={{display: 'block'}} strong={true}>
                {user?.id}
              </Text>
            </div>
          </Row>
          <Row>
            <Col span={4}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  User TimeZone
                </Text>

                <Text style={{display: 'block'}} strong={true}>
                  {user?.timeZone}
                </Text>
              </div>
            </Col>
            <Col span={3}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Device
                </Text>

                <Text style={{display: 'block'}} strong={true}>
                  {user?.devicePlatform}
                </Text>
              </div>
            </Col>

            <Col span={4}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  whatsappNumber
                </Text>

                <Text style={{display: 'block'}} strong={true}>
                  {user?.whatsappNumber}
                </Text>
              </div>
            </Col>
            <Col span={3}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Country
                </Text>

                <Text style={{display: 'block'}} strong={true}>
                  {user?.country}
                </Text>
              </div>
            </Col>
            <Col span={7}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  cognito Id
                </Text>

                <Text style={{display: 'block'}} strong={true}>
                  {user?.cognitoUserId}
                </Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div style={{margin: 20}}>
                <Text
                  style={{display: 'block', marginBottom: 15, fontSize: 20}}
                  strong={true}>
                  User/Parent Students:
                </Text>
                {user?.students?.map((student: Student) => {
                  return (
                    <div>
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}>
                          <Text style={{fontSize: 15, fontWeight: 'bold'}}>
                            Student Name: {student.name}
                          </Text>

                          <Text style={{fontSize: 15}}>
                            Joined At:{' '}
                            {moment(student.createdAt).format('yyyy-MM-DD')}
                          </Text>

                          <Text style={{fontSize: 15}}>
                            Current Student Level:{' '}
                            <b>{student?.level?.name ?? 'unknown'}</b>
                          </Text>
                          <Link to={'/students/' + student?.id + '/edit'}>
                            {student?.id}
                          </Link>
                        </div>
                      </div>
                      <Divider />
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </>
      )}

      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Student fetching error ⚠️</Title>}
    </div>
  );
};
