import {IMembershipsService} from './IMembershipsService';
import {MembershipsRepository} from './MembershipsRepository';
import {
  Membership,
  MembershipInsert,
  MembershipUpdate,
} from '../../graphql/API';

export class MembershipsService implements IMembershipsService {
  membershipsRepository: MembershipsRepository;
  constructor() {
    this.membershipsRepository = new MembershipsRepository();
  }

  async getMemberships(): Promise<any> {
    let memberships = await this.membershipsRepository.getMemberships();
    ////console.log('enrollemnts from service -->', memberships);
    return memberships;
  }

  async getMembershipById(membershipId: string): Promise<Membership> {
    let membership = await this.membershipsRepository.getMembershipById(
      membershipId,
    );
    ////console.log('membership from service ->', membership);
    return membership;
  }

  async createMembership(membership: Membership): Promise<any> {
    //@ts-ignore
    let membershipToCreate: MembershipInsert = {
      studentId: membership.student.id,
      packageId: membership.package.id,
      paymentStatus: membership.paymentStatus,
      isActive: membership.isActive,
      programId: membership.package.program.id,
    };

    return await this.membershipsRepository.createMembership(
      membershipToCreate,
    );
  }
  async updateMembership(
    membershipId: string,
    updatedAt: string,
    membership: Membership,
  ): Promise<any> {
    let membershipToUpdate: MembershipUpdate = {
      packageId: membership.package?.id,
      programId: membership.package?.program.id,
      paymentStatus: membership.paymentStatus,
      isActive: membership.isActive,
      isFullFilled: membership.isFullFilled,
      // startDate: membership.startDate,
      // endDate: membership.endDate,
    };
    return await this.membershipsRepository.updateMembership(
      membershipId,
      updatedAt,
      membershipToUpdate,
    );
  }
}
