import {
  Col,
  Input,
  notification,
  Row,
  Select,
  Spin,
  Statistic,
  StatisticProps,
  Table,
  Typography,
} from 'antd';
import {Link, useLocation, useParams} from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import moment, {Moment} from 'moment';

import {DiscountCodeService} from '../DiscountCode/DiscountCodeService';
import {useEffect, useRef, useState} from 'react';
import {
  Discountcode,
  DiscountcodeConnection,
  DiscountUsage,
  Enrollment,
  Level,
  Membership,
  PikaDoPartners,
  SubscriptionsByDiscountCode,
} from '../../graphql/API';

import {useDispatch} from 'react-redux';
import {getCurrentUserPermission, Permissions} from '../../utils';
import {NotificationPlacement} from 'antd/lib/notification';

import CountUp from 'react-countup';

const discountCodeService = new DiscountCodeService();

export const DiscountCodeForPartnerDetails = () => {
  const {Text} = Typography;
  const dispatch = useDispatch();
  const location: {
    state: any;
    hash: any;
    search: any;
    pathname: any;
  } = useLocation();
  //console.log('params', location);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [discountCodeSubscripotions, setDiscountCodeSubscripotions] =
    useState<SubscriptionsByDiscountCode>();
  const [discountCodeEnrollments, setDiscountCodeEnrollments] =
    useState<Enrollment[]>();
  const [discountCodeMemberships, setDiscountCodeMemberships] =
    useState<Membership[]>();

  const [currentUserPermission, setCurrentUserPermission] =
    useState<Permissions>();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    getCurrentUserPermission().then((p: Permissions) => {
      //console.log('currentUserPermission', p);
      setCurrentUserPermission(p);
    });
  }, []);

  useEffect(() => {
    if (currentUserPermission) {
      if (currentUserPermission === 'partner') {
        discountCodeService
          .getSubscriptionsByDiscountCode(location?.state?.id)
          .then((resp: SubscriptionsByDiscountCode) => {
            setDiscountCodeSubscripotions(resp);
            setDiscountCodeEnrollments(resp?.discountcode?.enrollments);
            setDiscountCodeMemberships(resp?.discountcode?.memberships);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsError(true);
            console.log('getSubscriptionsByDiscountCode Error ->', error);
          });
      } else {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserPermission]);

  const openErrorNotification = (
    placement: NotificationPlacement,
    message: string,
  ) => {
    api.error({
      // key,
      message: 'Cannot generate codes',
      description: message,
      placement,
    });
  };

  const formatter: StatisticProps['formatter'] = (value) => (
    <CountUp end={value as number} separator="," style={{fontSize: 50}} />
  );

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a: any, b: any) => {
        return a.id.localeCompare(b.id);
      },
      sortDirections: ['descend'],
      width:"10%"
    },
    {
      title: 'Start date',
      dataIndex: 'startDate',
      // sorter: (a: any, b: any) => {
      //   return a.name.localeCompare(b.name);
      // },
      width:"5%"
      
    },
    {
      title: 'Student',
      defaultSortOrder: 'ascend',
      dataIndex: ['student', 'name'],
      sorter: (a: any, b: any) => {
        return a.level?.name?.localeCompare(b?.level.name);
      },
      width:"5%",
      align:"center"
      
    },
    {
      title: 'Class',
      dataIndex: ['student','classschool', 'name'],
      width:"5%"
    },
    {
      title: 'School',
      defaultSortOrder: 'ascend',
      dataIndex: ['student', 'schoolName'],
      width:"5%",
      align:"center"
      
    },

  ];

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          marginTop: 10,
        }}>
        <Text
          style={{
            fontSize: 40,
            marginBottom: 20,
            borderRadius: 20,
            borderWidth: 1,
            borderColor: '#000',
            borderStyle: 'solid',
            padding: 15,
          }}>
          Code Name: {location?.state?.codeName}
        </Text>
      </div>
      {!isError && !isLoading && (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Statistic
                style={{fontSize: 15, marginInlineStart: 15}}
                title="Discount Percentage %"
                value={discountCodeSubscripotions.discountcode.valuePercentage}
                formatter={formatter}
              />
            </Col>
            <Col span={12}>
              <Statistic
                style={{marginInlineStart: 10}}
                title="Number of subscriptions"
                value={discountCodeSubscripotions.discountcode.enrollments.length+discountCodeSubscripotions.discountcode.memberships.length}
                precision={2}
                formatter={formatter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                {location?.state.school && (
                  <>
                    <Text
                      style={{fontSize: 16, display: 'block'}}
                      strong={true}>
                      School Name
                    </Text>
                    <Text style={{fontSize: 16}}>
                      {location?.state.school.name}
                    </Text>
                  </>
                )}
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                {location?.state.level && (
                  <>
                    <Text style={{display: 'block'}} strong={true}>
                      Level
                    </Text>
                    <Text>{location?.state.level.name}</Text>
                  </>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                {location?.state?.validFrom && (
                  <>
                    <Text style={{display: 'block'}} strong={true}>
                      Valid From
                    </Text>
                    <Text>
                      {moment(location?.state.validFrom).format('LLLL')}
                    </Text>
                  </>
                )}
              </div>
            </Col>

            <Col span={12}>
              <div style={{margin: 20}}>
                {location?.state?.expirationDate && (
                  <>
                    <Text style={{display: 'block'}} strong={true}>
                      Expire In
                    </Text>
                    <Text>
                      {moment(location?.state?.expirationDate).format('LLLL')}
                    </Text>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                {location?.state?.curriculum && (
                  <>
                    <Text style={{display: 'block'}} strong={true}>
                      curriculum
                    </Text>
                    <Text>{location?.state?.curriculum.name}</Text>
                  </>
                )}
              </div>
            </Col>

            <Col span={12}>
              <div style={{margin: 20}}>
                {location?.state?.expirationDate && (
                  <>
                    <Text style={{display: 'block'}} strong={true}>
                      Package
                    </Text>
                    <Text>
                      {JSON.parse(location?.state.package.name_i18n)['en']}
                    </Text>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Title>Enrollments: {discountCodeEnrollments.length}</Title>
          {discountCodeEnrollments.length>0 && (
          <Table
      pagination={{defaultPageSize: 10,hideOnSinglePage:true}}
      expandRowByClick={true}
      //@ts-ignore
      columns={columns}
      dataSource={discountCodeEnrollments}
      />
          )}
            <Title>Memberships: {discountCodeMemberships.length}</Title>
          {discountCodeMemberships.length>0 && (
          <Table
      pagination={{defaultPageSize: 10,hideOnSinglePage:true}}
      expandRowByClick={true}
      //@ts-ignore
      columns={columns}
      dataSource={discountCodeMemberships}
      />
          )}
        </>
      )}

      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>discount codes fetching error ⚠️</Title>}
    </div>
  );
};
