import {
  InstructorInsert,
  InstructorStatus,
  PreInstructor,
  PreInstructorUpdate,
  PreInstructorsConnection,
} from '../../graphql/API';
import {IPreInstructorsService} from './IPreInstructorsService';
import {PreInstructorsRepository} from './PreInstructorsRepository';
import {InstructorService} from '../Instructor/InstructorService';
import {AccountService} from '../Accounts/AccountService';

export class PreInstructorService implements IPreInstructorsService {
  preInstructorRepository: PreInstructorsRepository;
  instructorService: InstructorService;
  accountService: AccountService;
  constructor() {
    this.preInstructorRepository = new PreInstructorsRepository();
    this.instructorService = new InstructorService();
    this.accountService = new AccountService();
  }

  async deletePreInstructor(preInstructorId: string): Promise<any> {
    return await this.preInstructorRepository.deletePreInstructor(
      preInstructorId,
    );
  }

  async getPreInstructorById(preInstructorId: string): Promise<PreInstructor> {
    return await this.preInstructorRepository.getPreInstructorById(
      preInstructorId,
    );
  }

  async getPreInstructors(
    status: InstructorStatus | null,
    nextToken: string | undefined,
  ): Promise<PreInstructorsConnection> {
    return await this.preInstructorRepository.getPreInstructors(
      status,
      nextToken,
    );
  }

  async updatePreInstructor(
    preInstructorId: string,
    updatedAt: string,
    preInstructor: PreInstructorUpdate,
    targetArn?: string,
  ): Promise<any> {
    if (preInstructor.status === InstructorStatus.DECLINED) {
      await this.accountService.sendNotification(
        'Sorry',
        'You have been declined.',
        targetArn,
        'undefiend',
      );
    }
    return await this.preInstructorRepository.updatePreInstructor(
      preInstructorId,
      updatedAt,
      preInstructor,
    );
  }

  /**
   * 1- Create Instructor using the PreInstructors data
   * 2- delete the preInstructor
   * 3- Add user to instructor group
   * 4- Remove user from PreInstructors group
   * */
  async approvePreInstructor(
    preInstructorId: string,
    updatedAt: string,
    instructorInsert: InstructorInsert,
    cognitoUserId: string,
    targetArn: string,
  ): Promise<any> {
    try {
      let createdInstructor = await this.instructorService.createInstructor(
        instructorInsert,
      );
      await this.preInstructorRepository.deletePreInstructor(preInstructorId);
      await this.accountService.addUserToRole(cognitoUserId, 'Instructors');
      await this.accountService.removeUserFromGroup(
        cognitoUserId,
        'PreInstructors',
      );
      await this.accountService.sendNotification(
        'Congratulation',
        'You have been accepted.',
        targetArn,
        'undefiend',
      );
      return createdInstructor.id;
    } catch (e) {
      //console.log('approvePreInstructor error: ', e);
      throw new Error(e);
    }
  }
}
