import {Asset, AssetType} from '../graphql/API';
import {Button, Input, message, Row, Select, Upload, UploadProps,Alert, Col} from 'antd';
import {useEffect, useState} from 'react';
import Text from 'antd/es/typography/Text';
import { RcFile,UploadFile} from 'antd/lib/upload/interface';
import {CloseCircleFilled, UploadOutlined} from '@ant-design/icons';
import {StorageService, UploadFileType} from '../modules/Lesson/StorageService';

type CreateAssetBlockProps = {
  onChange: (asset: Asset) => void;
  defaultAssetType: AssetType;
  asset?: Asset | undefined;
  uploadFileType?: UploadFileType;
  syllableName?: string;
};
const storageService = new StorageService();

export const CreateAssetBlock = ({
  onChange,
  defaultAssetType,
  asset,
  uploadFileType,
  syllableName,
}: CreateAssetBlockProps) => {
  const [type, setType] = useState<AssetType>(asset?.type ?? defaultAssetType);
  const [thumbnailUri, setThumbnailUri] = useState<string>(
    asset?.thumbnailUri ?? '',
  );
  const [fileUri, setFileUri] = useState<string>(asset?.fileUri ?? '');
  const [uploading, setUploading] = useState(false);
  const [fileSummaryVideo, setFileSummaryVideo] = useState<UploadFile>();
  const [fileQThumbnial, setFileQThumbnial] = useState<UploadFile>();
  const [thumbnailErrorQ,setThumbnailErrorQ]= useState<string>("")
  const [photoError,setPhotoError]= useState<string>("")
  useEffect(() => {
    if (asset?.type) {
      setType(asset?.type);
      // console.log('asset?.type',asset?.type)
      if(asset.type === 'AUDIO'){
        setThumbnailErrorQ('')
        setFileQThumbnial(null);
        setThumbnailUri('');
        }
        setFileSummaryVideo(null);
        setPhotoError('');
      }
      // console.log('fileQThumbnial',fileQThumbnial)
  }, [asset?.type]);

  useEffect(() => {
    if (asset) {
      setFileUri(asset.fileUri);
      setThumbnailUri(asset.thumbnailUri);
      // console.log('asset', asset);
    }
  }, [asset]);
  useEffect(() => {
    // if (asset) {
    //   setFileUri(asset.fileUri);
    //   setThumbnailUri(asset.thumbnailUri);
      // console.log('type', ' =>',type,'#');
      if(type === 'AUDIO'){
        setThumbnailErrorQ('')
        setFileQThumbnial(null);
        setThumbnailUri('');
        }
        setFileSummaryVideo(null);
        setPhotoError('');
    // }
  }, [type]);

  function validateAssetBeforeUpload(file: RcFile,setImage:React.Dispatch<React.SetStateAction<any>>,setError:React.Dispatch<React.SetStateAction<any>>,isThumbnail:boolean): boolean {
    
    // console.log("asset.type:", type);
      if (type === 'PHOTO' ||isThumbnail) {

        const isJpgOrJpeg = file.type === 'image/jpeg' || file.type === 'image/jpg';
        // console.log("isJpgOrJpeg:", isJpgOrJpeg);
        if (!isJpgOrJpeg) {
          setError('You can only upload JPG/JPEG file!')
          // setThumbnailErrorQ('You can only upload JPG/JPEG file!')
          return false;
        }
    
        const isLt25KB = file.size / 1024  <= 250;
        // console.log("isLt25KB:", isLt25KB);
        if (!isLt25KB) {
          setError('Image must be smaller than 250KB!');
          // setThumbnailErrorQ('Image must be smaller than 250KB!');
          return false; 
        }
    
        // Check image dimensions
        const img = new Image();
        img.src = URL.createObjectURL(file);
    
        img.onload = () => {
          const { width, height } = img;
          // console.log("Image dimensions:", width, height);
          if (width !== 1800 || height !== 1200) {
            setError('Image must be 1800x1200 pixels!');
            // setThumbnailErrorQ('Image must be 1800x1200 pixels!');
                    return false;
                  }
        };
        // console.log("after");
      }else if ( type === 'VIDEO' || !isThumbnail) {
        const isVideo = file.type.split('/')[0] === 'video';
        // console.log("isVideo:", isVideo, file.type.split('/')[0]);
        if (!isVideo) {
          setError('You should upload a video!')
          return false;
        }else{
          setImage(file)
                  return false;
        }
      }else{
        setImage(file)
                return false;
      }
      setError("")
                setImage(file)
                return false;
};

  const propsSummaryVideo: UploadProps = {
    type: 'select',
    accept:type === 'PHOTO'? '.jpg,.jpeg':'.mp4,.aac',
    listType: 'text',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setPhotoError("")
      setFileSummaryVideo(null);
    },
    beforeUpload:(file)=> validateAssetBeforeUpload(file,setFileSummaryVideo,setPhotoError,false)
  };

  const propsFileQThumbnial: UploadProps = {
    type: 'select',
    accept: '.jpg,.jpeg',
    listType: 'picture',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setFileQThumbnial(null);
      setThumbnailErrorQ("")
    },
    beforeUpload:(file: RcFile)=> validateAssetBeforeUpload(file,setFileQThumbnial,setThumbnailErrorQ,true)
    // beforeUpload:(file: RcFile)=>{ 
    //   console.log("beforeUpload")
    //   sayHi(file,setFileQThumbnial,setThumbnailErrorQ,true);
    //   // validateAssetBeforeUpload(file,setFileQThumbnial,setThumbnailErrorQ,true);
    //   console.log("thumbnailErrorQ",thumbnailErrorQ)
    //   console.log("fileQThumbnial",fileQThumbnial)
    // }
    // beforeUpload:(file)=> console.log("hi")
  };

  const handleUpload = async (
    fileUploadUri: UploadFile,
    typeUpload: UploadFileType,
  ) => {
    // console.log('typeUpload->', typeUpload);
    setUploading(true);
    // console.log('fileUri.url', fileUploadUri);
    try {
      let filePath: string;
      switch (typeUpload) {
        case UploadFileType.TEST_MEDIA:
          filePath = await storageService.uploadFile(
            fileUploadUri,
            typeUpload,
            'test/media',
          );
          let tempAsset = {
            type: type,
            thumbnailUri: thumbnailUri,
            fileUri: filePath,
          };
          setFileUri(filePath);
          //@ts-ignore
          onChange(tempAsset);
          break;

        case UploadFileType.TEST_THUMBNIAL:
          filePath = await storageService.uploadFile(
            fileUploadUri,
            UploadFileType.TEST_MEDIA,
            'test/photos',
          );

          let tempAsset2 = {
            type: type,
            thumbnailUri: filePath,
            fileUri: fileUri,
          };
          setThumbnailUri(filePath);
          //@ts-ignore
          onChange(tempAsset2);
          break;

        case UploadFileType.SYLLABLE_THUMBNAIL:
          filePath = await storageService.uploadFile(
            fileUploadUri,
            UploadFileType.SYLLABLE_THUMBNAIL,
            syllableName,
          );

          let syllableTempAsset = {
            type: type,
            thumbnailUri: filePath,
            fileUri: fileUri,
          };
          // console.log('filePathfilePath->', filePath);

          setThumbnailUri(filePath);
          //@ts-ignore
          onChange(syllableTempAsset);
          break;

        case UploadFileType.SYLLABLE_VIDEO:
          filePath = await storageService.uploadFile(
            fileUploadUri,
            UploadFileType.SYLLABLE_VIDEO,
            syllableName,
          );
          let syllableVideoTempAsset = {
            type: type,
            thumbnailUri: thumbnailUri,
            fileUri: filePath,
          };
          setFileUri(filePath);
          //@ts-ignore
          onChange(syllableVideoTempAsset);
          break;

        default:
          break;
      }

      // console.log('file_PATH_IUPLOADED', filePath);
      message.success('upload successfully.');

      setUploading(false);
    } catch (error) {
      setUploading(false);
      message.error('upload failed.');
    }
  };

  return (
    <div>
      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <Text style={{display: 'block'}}>Asset type </Text>
        <Select
          value={type}
          showSearch
          style={{width: 200}}
          placeholder="Select a asset type"
          optionFilterProp="children"
          onChange={(selectedAssetType: AssetType) => {
            let tempAsset = {
              type: selectedAssetType,
              thumbnailUri: thumbnailUri,
              fileUri: fileUri,
            };
            setType(selectedAssetType);
            //@ts-ignore
            onChange(tempAsset);
          }}>
          <Select.Option value={AssetType.VIDEO}>
            {AssetType.VIDEO}
          </Select.Option>
          <Select.Option value={AssetType.PHOTO}>
            {AssetType.PHOTO}
          </Select.Option>
          <Select.Option value={AssetType.AUDIO}>
            {AssetType.AUDIO}
          </Select.Option>
        </Select>
        <Select.Option value={AssetType.PDF}>{AssetType.PDF}</Select.Option>
      </div>
      {type !== AssetType.AUDIO && (
        <div style={{marginTop: '20px', marginBottom: '20px'}}>
          <Text>Thumbnail url </Text>
          <Text style={{display: 'block',color: '#6c757d', fontWeight:"semibold"  }}>hint ( extension: JPG/JPEG, max size: 250KB, dimensions: 1800x1200) </Text>
          {thumbnailUri ? (
            <Row
              style={{
                display: 'flex',
                // justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
              }}>
              <Input
                style={{width: '80%'}}
                value={thumbnailUri}
                onChange={(e) => {}}
                placeholder="Basic usage"
              />
              {thumbnailUri && (
                <CloseCircleFilled
                  style={{marginInline: 20}}
                  onClick={() => {
                    setThumbnailUri('');
                    setFileQThumbnial(null);

                    let tempAsset2 = {
                      type: type,
                      thumbnailUri: '',
                      fileUri: fileUri,
                    };

                    //@ts-ignore
                    onChange(tempAsset2);
                  }}
                />
              )}
            </Row>
          ) : (
            <Row>
              <Upload {...propsFileQThumbnial}>
              {/* <Upload beforeUpload={()=> console.log("hi")}> */}
                <Button
                  disabled={fileQThumbnial ? true : false}
                  icon={<UploadOutlined />}>
                  Select File
                </Button>
              </Upload>

              <Button
                type="primary"
                onClick={() => {
                  if (uploadFileType) {
                    syllableName?.length !== 0
                      ? handleUpload(
                          fileQThumbnial,
                          UploadFileType.SYLLABLE_THUMBNAIL,
                        )
                      : alert(
                          'Please enter the syllable name before uploading',
                        );
                  } else {
                    handleUpload(fileQThumbnial, UploadFileType.TEST_THUMBNIAL);
                  }
                }}
                disabled={!fileQThumbnial || thumbnailErrorQ !== ''}
                loading={uploading}>
                {uploading ? 'Uploading' : 'Start Upload'}
              </Button>
              {thumbnailErrorQ &&
              <Col span={3}><Alert style={{padding:'3px', textAlign:'center'}}  message={thumbnailErrorQ} type='error'/></Col> 
             }
            </Row>
          )}
        </div>
      )}
      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <Text>File url </Text>
     { type ===  'PHOTO' &&  <Text style={{display: 'block',color: '#6c757d', fontWeight:"semibold"  }}>hint ( extension: JPG/JPEG, max size: 250KB, dimensions: 1800x1200) </Text>}
        {fileUri ? (
          <Row
            style={{
              display: 'flex',
              // justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
            }}>
            <Input
              style={{
                width: '80%',
              }}
              value={fileUri}
              onChange={(e) => {}}
              placeholder="Basic usage"
            />
            {fileUri && (
              <CloseCircleFilled
                style={{marginInline: 20}}
                onClick={() => {
                  setFileUri('');
                  setFileSummaryVideo(null);

                  let tempAsset2 = {
                    type: type,
                    thumbnailUri: thumbnailUri,
                    fileUri: '',
                  };

                  //@ts-ignore
                  onChange(tempAsset2);
                }}
              />
            )}
          </Row>
        ) : (
          <Row>
            <Upload {...propsSummaryVideo}>
              <Button
                disabled={fileSummaryVideo ? true : false}
                icon={<UploadOutlined />}>
                Select File
              </Button>
            </Upload>

            <Button
              type="primary"
              onClick={() => {
                if (uploadFileType) {
                  syllableName?.length !== 0
                    ? handleUpload(
                        fileSummaryVideo,
                        UploadFileType.SYLLABLE_VIDEO,
                      )
                    : alert('Please enter the syllable name before uploading');
                } else {
                  handleUpload(fileSummaryVideo, UploadFileType.TEST_MEDIA);
                }
              }}
              disabled={!fileSummaryVideo || photoError !==''}
              loading={uploading}>
              {uploading ? 'Uploading' : 'Start Upload'}
            </Button>
            {photoError  && 
              <Col span={3}><Alert style={{padding:'3px', textAlign:'center'}}  message={photoError} type='error'/></Col> 
             }
          </Row>
        )}
      </div>
    </div>
  );
};
