export const memberships = `
  query Memberships(
    $filter: MembershipsFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    memberships(filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
      items{
      id
      createdAt
      updatedAt
      student {
        id
        name
        birthDate
        age
      }
      package {
        id
        name_i18n
        sessionDuration
        numberOfSessions
      }
      paymentStatus
      isActive
    }
      nextToken
      scannedCount
    }
  }
`;

export const membership = `
  query Membership($id: String!) {
    membership(id: $id) {
      id
      createdAt
      updatedAt
      discountcode {
        id 
        codeName
      }
      student {
        id
        name
        birthDate
        age
      }
      sessionPerWeek
      isFullFilled
      groups { 
        createdAt
        id
        name
      }
      curriculum { 
        id
        name
      }
      sessions {
        id
        createdAt
        updatedAt
        name
        duration
        startDateTime
        startTime
        endTime
        status
        isRescheduled
        moderatorToken
        jitsiUrl
        rtcUserToken
        rtcInstructorToken
        rtcShareScreenToken
        rtcAdminToken
        zoomUrl
        group {
          id
        }
      }
      package {
        id
        name_i18n
        sessionDuration
        numberOfSessions
        enrollmentPackage {
          id
          name_i18n
        }
        program {
          name
          id
          flag
        }
      }
      level {
        id
        createdAt
        updatedAt
        name
        number
      }
      paymentStatus
      isActive
    }
  }
`;
