export const listCognitoUsers = /* GraphQL */ `
  query ListCognitoUsers(
    $filter: AdminListFilter
    $scannedCount: Int
    $nextToken: String
  ) {
    listCognitoUsers(
      filter: $filter
      scannedCount: $scannedCount
      nextToken: $nextToken
    ) {
      Users {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        Attributes {
          Name
          Value
        }
      }
      PaginationToken
    }
  }
`;

export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin($id: ID!, $updatedAt: String!, $input: AdminUpdate!) {
    updateAdmin(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      isActive
      lastSeenOnline
    }
  }
`;
