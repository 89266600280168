import {useCallback, useEffect, useRef, useState} from 'react';
import {Input, List, Select, Spin, Typography} from 'antd';
import {useHistory, useParams} from 'react-router-dom';

import {SchoolService} from './SchoolService';
import {ClassesConnection, Classschool, Package} from '../../graphql/API';
import {PackagesService} from '../Package';

const schoolsService = new SchoolService();
const packagesService = new PackagesService();
interface DataType {
  id: string;
  name: string;
}

interface DataTypeApply {
  id: string;
  packageId: string;
  discountcodeId: string;
}

export const SchoolEdit = () => {
  const history = useHistory();
  const Timeout = useRef<any>();
  let params: {schoolId: string} = useParams();

  const {Title, Text} = Typography;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<DataType[]>([]);
  const [dataApply, setDataApply] = useState<DataTypeApply[]>([]);

  const [packages, setPackages] = useState<Array<Package> | []>([]);

  useEffect(() => {
    //console.log('rams.sch', params.schoolId);
    schoolsService
      .getClassesBySchoolId(params.schoolId)
      .then((classess: ClassesConnection) => {
        //console.log('classess', classess.items);
        const newData = classess?.items?.map((classS: Classschool) => {
          const tempDataApply = [...dataApply];
          tempDataApply.push({
            id: classS.id,
            packageId: '',
            discountcodeId: '',
          });

          setDataApply(tempDataApply);

          return {
            name: classS.name,
            id: classS.id,
          };
        });
        setData(newData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert("Error in fetching classesBySchoolId")
        console.log('getClassesBySchoolId Error:', error);
      });

    packagesService
      .getPackages()
      .then((pkgs) => {
        //console.log('pkgs', pkgs);
        const exercisesPackages = pkgs.filter((pack: Package) => pack.onlyAI);
        setPackages(exercisesPackages);
      })
      .catch((error) => {
        setIsLoading(true);
        alert("Error in getPackages")
        console.log('Error getPackages->', error);
      });
  }, []);

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Activate Classess</Title>
      <table style={{width: '100%', borderCollapse: 'collapse'}}>
        <thead>
          <tr>
            <th style={{border: '1px solid #ddd', padding: '10px'}}>
              Class Name
            </th>
            <th style={{border: '1px solid #ddd', padding: '8px'}}>
              Select Package
            </th>
            <th style={{border: '1px solid #ddd', padding: '8px'}}>
              Enter discount code (ID)
            </th>

            <th style={{border: '1px solid #ddd', padding: '8px'}}>Action</th>
          </tr>
        </thead>
      </table>

      <List
        className="demo-loadmore-list"
        loading={isLoading}
        itemLayout="horizontal"
        // loadMore={loadMore}
        dataSource={data}
        renderItem={(item, index: number) => (
          <List.Item
            actions={[
              <a
                key="list-loadmore-edit"
                onClickCapture={async () => {
                  if (
                    dataApply[index]?.id &&
                    dataApply[index]?.discountcodeId?.length !== 0 &&
                    dataApply[index]?.packageId
                  ) {
                    //console.log('in School', dataApply[index]);
                    try {
                      setIsLoading(true);
                      await schoolsService.activateSubsctiptionInClass(
                        dataApply[index].id,
                        dataApply[index].discountcodeId,
                        dataApply[index].packageId,
                      );
                      setIsLoading(false);
                    } catch (error) {
                      alert(JSON.stringify(error));
                      //console.log('ERRIR', error);
                      setIsLoading(false);
                    }
                  } else {
                      alert("Please check the entered data")
                  }
                }}>
                Create
              </a>,
            ]}>
            <div style={{fontWeight: 'bold', fontSize: 20}}>{item.name}</div>

            <Select
              value={dataApply[index]?.packageId}
              showSearch
              style={{width: '20%'}}
              placeholder="Select a package"
              optionFilterProp="children"
              onChange={(pkgId) => {
                if (
                  dataApply[index]?.packageId ||
                  dataApply[index]?.discountcodeId
                ) {
                  let tempDataApply = [...dataApply];

                  tempDataApply[index] = {
                    ...dataApply[index],
                    packageId: pkgId,
                  };

                  setDataApply(tempDataApply);
                } else {
                  const newDataApply: DataTypeApply = {
                    id: item.id,
                    discountcodeId: dataApply[index]?.discountcodeId,
                    packageId: pkgId,
                  };

                  let tempDataApply = [...dataApply];
                  tempDataApply[index] = newDataApply

                  setDataApply(tempDataApply);
                }
              }}>
              {packages?.map((pkg: Package) => (
                <Select.Option value={pkg.id}>
                  {JSON.parse(pkg.name_i18n)['en']}
                </Select.Option>
              ))}
            </Select>
            <Input
              style={{width: '20%'}}
              placeholder="Enter a valid (ID)"
              value={dataApply[index]?.discountcodeId}
              onChange={(event) => {
                if (
                  dataApply[index]?.discountcodeId ||
                  dataApply[index]?.packageId
                ) {
                  let tempDataApply = [...dataApply];

                  tempDataApply[index] = {
                    ...dataApply[index],
                    discountcodeId: event.target.value,
                  };
                  //console.log('in School33', tempDataApply);

                  setDataApply(tempDataApply);
                } else {
                  const newDataApply: DataTypeApply = {
                    id: item.id,
                    discountcodeId: event.target.value,
                    packageId: dataApply[index]?.packageId,
                  };
                  let tempDataApply = [...dataApply];
                  tempDataApply[index] = newDataApply
                  setDataApply(tempDataApply);
                }
              }}
            />
          </List.Item>
        )}
      />

      {/* {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )} */}
    </div>
  );
};
