import {UploadFile} from 'antd/lib/upload/interface';
import {store} from '../../redux/store';
import {setUploadingProgress} from '../../redux/actions/user';
import axios from 'axios';
import {generateSignedUrlUploadAudioFile} from '../../graphql/mutations';

import {generateClient, GraphQLResult} from 'aws-amplify/api';
import {uploadData} from 'aws-amplify/storage';

const client = generateClient();
export interface IStorageRepository {
  uploadFile(
    fileName: string,
    fileBlob: UploadFile,
    customPrefix?: string,
    accessLevel?: any,
  ): Promise<any>;

  uploadAudioFileEndpoint(
    fileName: string,
    fileBlob: UploadFile,
    customPrefix?: string,
    typeFile?: string,
    accessLevel?: any,
  ): Promise<any>;
}
export const ObjectCannedACL = {
  authenticated_read: 'authenticated-read',
  aws_exec_read: 'aws-exec-read',
  bucket_owner_full_control: 'bucket-owner-full-control',
  bucket_owner_read: 'bucket-owner-read',
  private: 'private',
  public_read: 'public-read',
  public_read_write: 'public-read-write',
};
export class StorageRepository implements IStorageRepository {
  constructor() {}
  async uploadFile(
    fileName: string,
    fileBlob: any,
    customPrefix?: string,
    accessLevel: any = 'guest',
  ): Promise<any> {
    console.log('customPrefix', customPrefix);
    console.log('fileName', fileName);
    // accessLevel = accessLevel === 'public' ? 'guest' : accessLevel;
    try {
      const uploadResult = await uploadData({
        path: accessLevel + '/' + customPrefix + fileName,
        data: fileBlob,

        options: {
          accessLevel: 'public',
          onProgress: ({transferredBytes, totalBytes}) => {
            if (totalBytes) {
              store.dispatch(
                setUploadingProgress(
                  Math.round((transferredBytes / totalBytes) * 100),
                ),
              );

              console.log(
                `Upload progress ${Math.round(
                  (transferredBytes / totalBytes) * 100,
                )} %`,
              );
            }
          },
        },
      }).result;

      console.log('Key from Response: ', uploadResult);

      return uploadResult;
    } catch (error) {
      console.log('Error : ', error);
    }
  }

  async uploadAudioFileEndpoint(
    fileName: string,
    fileBlob: UploadFile,
    customPrefix?: string,
    typeFile: string = 'binary/octet-stream',
    accessLevel: any = 'guest',
  ): Promise<any> {
    accessLevel = accessLevel === 'public' ? 'guest' : accessLevel;

    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: generateSignedUrlUploadAudioFile,
        variables: {
          input: {
            fileName: fileName,
            typeUpload: 'binary/octet-stream',
            ACL: ObjectCannedACL.bucket_owner_read,
          },
        },
      });
      console.log('uploadAudioFileEndpoint->', response);

      console.log(
        'data_->respGenratedUrlS3',
        response.data.generateSignedUrlUploadAudioFile,
      );

      const headers = {
        'Content-Type': typeFile,
      };

      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );

          store.dispatch(
            setUploadingProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100),
            ),
          );
          console.log(percentCompleted);
        },
      };

      await axios
        .put(response?.data?.generateSignedUrlUploadAudioFile, fileBlob, {
          headers: headers,
          ...config,
        })
        .catch((error) => {
          throw new Error(
            'Error in uploading audio record using generateSignedUrlUploadAudioFile: ' +
              error,
          );
        });
      console.log('Upload->fileName', fileName);
      return {
        path: fileName,
      };
    } catch (error) {
      console.log('Fetch lesson Error: ', error);
      throw new Error(error);
    }
  }
}
