import {Button, Spin, Table} from 'antd';
import {useEffect, useState} from 'react';
import {Course} from '../../graphql/API';
import {Link} from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import {CoursesService} from '../Courses';
import { PlanTable } from './PlanTable';

const coursesService = new CoursesService();

export const PlanTableContainer = () => {
  const [courses, setCourses] = useState<Array<any> | []>([]);
  const [curriculumNames, setCurriculumNames] = useState<Array<any>  | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    coursesService
      .getCourses()
      .then((resp) => {
        setIsLoading(false);
        const groupedByCourseName =  resp.reduce((acc, course,index) => {
          const courseName = course?.curriculum?.name;

  if (!acc[courseName]) {
    acc[courseName] = [];
  }

  acc[courseName].push(course);

  return acc;
        }, {});
        setCourses(resp);
        let temp =Object.entries(groupedByCourseName).map(([key, value]) => value)
        let temp2 =Object.entries(groupedByCourseName).map(([key, value]) => key)
        setCourses([...temp])
        setCurriculumNames([...temp2])
      })
      .catch((error) => {
        setIsError(true);
        console.log('PlanTableContainer groupedByCourseName Error ->', error);
      });
  }, []);


  return (
    <>
    <div style={{display: 'flex', justifyContent: 'end',minWidth:"100%"}}>

      <Button
        size={'large'}
        
        onClick={() => alert('In progress')}>
        Create
      </Button>
    </div>
  {courses?.map((course,index)=> <div style={{padding:"1rem",marginBottom:"2rem"}}><PlanTable key={index} data={course} title={curriculumNames[index]} language={course[0]?.language?.language}/> </div> )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Enrollments fetching error ⚠️</Title>}
    </>
  );
};
