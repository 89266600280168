export const levels = `
    query levels ($filter: LevelsFilter, $scannedCount: Int, $nextToken: String) {
    levels (filter: $filter, scannedCount: $scannedCount, nextToken: $nextToken) {
        items {
            id
            createdAt
            updatedAt
            name
            score
            number
        }
        nextToken
        scannedCount
    }
}
`;
