export const updateVideosession = /* GraphQL */ `
  mutation UpdateVideosession(
    $id: ID!
    $updatedAt: String!
    $input: VideosessionUpdate!
  ) {
    updateVideosession(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
    }
  }
`;
