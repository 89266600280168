import {IIChannelService} from './IIChannelService';
import {ChannelRepository} from './ChannelRepository';
import {
  CampaignInsert,
  SegmentConnection,
  SegmentInsert,
  TopicConnection,
} from '../../graphql/API';

export class ChannelService implements IIChannelService {
  ChannelRepository: ChannelRepository;
  constructor() {
    this.ChannelRepository = new ChannelRepository();
  }
  async getTopicsList(
    nextToken: string,
    scannedCount: number,
  ): Promise<TopicConnection> {
    return await this.ChannelRepository.getTopicsList(nextToken, scannedCount);
  }

  async getAllSegments(
    nextToken: string,
    scannedCount: number,
  ): Promise<SegmentConnection> {
    return await this.ChannelRepository.getAllSegments(nextToken, scannedCount);
  }

  async publishMessageInChannel(
    title: string,
    body: string,
    topicArn: string,
  ): Promise<void> {
    return await this.ChannelRepository.publishMessageInChannel(
      title,
      body,
      topicArn,
    );
  }

  async createUserSegment(segmentInput: SegmentInsert): Promise<void> {
    return await this.ChannelRepository.createUserSegment(segmentInput);
  }

  async createCampaign(campaignInput: CampaignInsert): Promise<void> {
    return await this.ChannelRepository.createCampaign(campaignInput);
  }
}
