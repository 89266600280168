import React, {useEffect, useRef, useState} from 'react';
import {getFileUrlByKey} from '../../../utils';
import {Image, Modal} from 'antd';

type VideoModalProps = {
  thumbnailUri: string | undefined;
  fileUri: string | undefined;
  style?: any;
  letters: any;
  onCancelModal: () => void;
};

export default function VideoModal({
  thumbnailUri,
  fileUri,
  style,
  onCancelModal,
  letters,
}: VideoModalProps) {
  const videoPlayerRef: any = useRef();
  //console.log('EHEEHHEHEEHEH');
  const [img, setImg] = useState<string | undefined>(thumbnailUri);
  const [fileVideoUrl, setFileVideoUrl] = useState<string | undefined>(fileUri);

  const fetchVideoUrl = async () => {
    //console.log('SDSDDS', thumbnailUri);
    if (thumbnailUri) {
      const pathContent = thumbnailUri.split('/');
      const bucketName = pathContent[3];
      let uri = thumbnailUri;
      //console.log('bucketName', bucketName);
      //console.log('proPicPath', pathContent);

      try {
        const pathVideo = thumbnailUri?.split('.pikado.io/');
        //console.log('thumbnailUri00', pathVideo);

        const videoProfileUrl = await getFileUrlByKey(pathVideo[1], bucketName);
        uri = videoProfileUrl.url.toJSON();
        // //console.log('uri33', uri);
        setImg(uri);
      } catch (error) {
        console.error('ERRRR:->', error);
      }
    }

    if (fileUri) {
      const pathVideo = fileUri?.split('.pikado.io/');
      const pathContent = fileUri.split('/');
      const bucketName = pathContent[3];

      let uri = fileUri;
      const videoProfileUrl = await getFileUrlByKey(pathVideo[1], bucketName);

      uri = videoProfileUrl.url.toJSON();

      setFileVideoUrl(uri);
    }
  };

  useEffect(() => {
    fetchVideoUrl();
  }, []);

  return (
    <Modal
      className={'exercise-modal'}
      style={{maxHeight: '70%'}}
      width={'auto'}
      centered={true}
      footer={null}
      title={letters}
      open={true}
      onOk={() => {}}
      onCancel={() => {
        onCancelModal();
      }}>
      <video
        controls={true}
        onLoadedData={() => {
          videoPlayerRef?.current?.play();
        }}
        onClick={() => {
          videoPlayerRef?.current?.paused
            ? videoPlayerRef?.current?.pause()
            : videoPlayerRef?.current?.play();
        }}
        ref={videoPlayerRef}
        src={fileVideoUrl}
        poster={img}
        width={700}
      />
    </Modal>
  );
}
