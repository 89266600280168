import {Asset, AssetType} from '../graphql/API';
import {Alert, Button, Input, Select} from 'antd';
import {useEffect, useState} from 'react';
import Text from 'antd/es/typography/Text';
import {InputI18nStringBlock} from './InputI18nStringBlock';
import {cloneDeep} from 'lodash';

type EditAssetsBlockProps = {
  onChange: (assets: Array<Asset>) => void;
  defaultAssetType: AssetType;
  fieldName: string;
  fieldNameSingular: string;
  assets?: Array<Asset> | [];
};
export const EditAssetsBlock = ({
  assets,
  onChange,
  fieldName,
  fieldNameSingular,
  defaultAssetType = AssetType.VIDEO,
}: EditAssetsBlockProps) => {
  const [assetsList, setAssetsList] = useState<Array<any> | []>(
    assets
      ? assets
      : [
          {
            type: defaultAssetType,
            thumbnailUri: '',
            fileUri: '',
            title_i18n: '{"en":"","ar":"","tr":""}',
            subTitle_i18n: '{"en":" ","ar":"","tr":""}',
            position: 0,
          },
        ],
  );
  useEffect(() => {
    setAssetsList(
      assets
        ? assets
        : [
            {
              type: defaultAssetType,
              thumbnailUri: '',
              fileUri: '',
              title_i18n: '{"en":"","ar":"","tr":""}',
              subTitle_i18n: '{"en":" ","ar":"","tr":""}',
              position: 0,
            },
          ],
    );
  }, [assets]);

  const onAssetListChange = (index, field, newValue) => {
    let tempAssetsList = assetsList;
    tempAssetsList[index][field] = newValue;
    setAssetsList(tempAssetsList);
    //@ts-ignore
    onChange(tempAssetsList);
  };

  const onDelete = (index) => {
    let tempAssetsList = cloneDeep(assetsList);
    let assetId = assetsList[index].id;
    console.log('tempAssetsList before 1->', tempAssetsList);
    delete tempAssetsList[index];
    console.log('tempAssetsList after 1->', tempAssetsList);
    tempAssetsList = tempAssetsList.filter((tempAsset) => tempAsset);
    console.log('tempAssetsList after 2->', tempAssetsList);
    setAssetsList(tempAssetsList);
    //@ts-ignore
    onChange(tempAssetsList);

    // To be continued to delete the asset from the backend too, risky!!!
    //addDeletedAsset(assetId);
  };
  return (
    <div style={{margin: 20}}>
      <Text style={{fontWeight: 'bold'}}>{`${fieldName}`}</Text>
      <Button
        onClick={() => {
          setAssetsList([
            ...assetsList,
            {
              type: defaultAssetType,
              thumbnailUri: '',
              fileUri: '',
              title_i18n: '{"en":" ","ar":"","tr":""}',
              subTitle_i18n: '{"en":" ","ar":"","tr":""}',
              position: 0,
            },
          ]);
        }}>
        Add more {fieldName}
      </Button>
      {assetsList?.map((asset, index) => (
        <div
          key={`${asset.fileUri}_${index}`}
          style={{border: '1px solid black', marginTop: 20}}>
          <div>
            <Text>{`${fieldNameSingular} ${index}`}</Text>
            <Button
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals
                if (confirm('Are you sure?')) {
                  onDelete(index);
                }
              }}>
              Delete
            </Button>
          </div>
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text style={{display: 'block'}}>Asset type </Text>
            <Select
              value={asset.type}
              showSearch
              style={{width: 200}}
              placeholder="Select a asset type"
              optionFilterProp="children"
              onChange={(selectedAssetType: AssetType) => {
                onAssetListChange(index, 'type', selectedAssetType);
              }}>
              <Select.Option value={AssetType.VIDEO}>
                {AssetType.VIDEO}
              </Select.Option>
              <Select.Option value={AssetType.PHOTO}>
                {AssetType.PHOTO}
              </Select.Option>
              <Select.Option value={AssetType.AUDIO}>
                {AssetType.AUDIO}
              </Select.Option>
            </Select>
          </div>
          {asset.type !== AssetType.AUDIO && (
            <div style={{marginTop: '20px', marginBottom: '20px'}}>
              <Text>Thumbnail url </Text>
              <Input
                value={asset.thumbnailUri}
                onChange={(e) => {
                  onAssetListChange(index, 'thumbnailUri', e.target.value);
                }}
              />
            </div>
          )}
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text>File url </Text>
            <Input
              value={asset.fileUri}
              onChange={(e) => {
                onAssetListChange(index, 'fileUri', e.target.value);
              }}
            />
          </div>
          <div>
            <InputI18nStringBlock
              containerStyle={{}}
              fieldName={'Title'}
              stringifiedJson={
                asset.title_i18n
                  ? asset.title_i18n
                  : '{"en":"","ar":"","tr":""}'
              }
              onChange={(e) => {
                onAssetListChange(index, 'title_i18n', e);
              }}
            />
          </div>
          <div>
            <InputI18nStringBlock
              containerStyle={{}}
              fieldName={'Sub-title'}
              stringifiedJson={
                asset.subTitle_i18n
                  ? asset.subTitle_i18n
                  : '{"en":"","ar":"","tr":""}'
              }
              onChange={(e) => {
                onAssetListChange(index, 'subTitle_i18n', e);
              }}
            />
          </div>
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text>Position </Text>
            <Input
              min={0}
              value={asset.position}
              type={'number'}
              onChange={(e) => {
                onAssetListChange(index, 'position', parseInt(e.target.value));
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
