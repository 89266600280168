import {IIAccountsRepository} from './IIAccountsRepository';
import {
  adminGetUser,
  getUserByCognitoId,
  listGroupsForUser,
} from '../../graphql/queries';
import {AdminListUser, AdminListUserGroups} from '../../graphql/API';
import {
  addUserToGroup,
  removeUserFromGroup,
  sendMessage,
} from '../../graphql/mutations';
import {listCognitoUsers} from './AccountsQueries';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class AccountsRepository implements IIAccountsRepository {
  async getListCognitoUsers(
    nextToken: string | undefined,
    scannedCount: number,
  ): Promise<AdminListUser> {
    try {
      const res: GraphQLResult<any> = await client.graphql({
        query: listCognitoUsers,
        variables: {nextToken, scannedCount},
      });
      console.log('getListCognitoUsers from repository:', res);

      return res?.data?.listCognitoUsers;
    } catch (error) {
      console.log('update asset Error: ', error);
      throw new Error(error);
    }
  }
  async addUserToRole(userName: string, groupName: string): Promise<void> {
    try {
      await client.graphql({
        query: addUserToGroup,
        variables: {userName, groupName},
      });
    } catch (error) {
      console.log('addUserToRole Error: ', error);
      throw new Error(error);
    }
  }

  async removeUserFromGroup(
    userName: string,
    groupName: string,
  ): Promise<void> {
    try {
      await client.graphql({
        query: removeUserFromGroup,
        variables: {userName, groupName},
      });
    } catch (error) {
      console.log('removeUserFromGroup Error: ', error);
      throw new Error(error);
    }
  }

  async listEmailGroupList(userName: string): Promise<AdminListUserGroups> {
    try {
      const res: GraphQLResult<any> = await client.graphql({
        query: listGroupsForUser,
        variables: {userName},
      });
      console.log('listEmailGroupList from repository:', res);

      return res?.data.listGroupsForUser;
    } catch (error) {
      console.log('listEmailGroupList Error: ', error);
      throw new Error(error);
    }
  }
  async getUserByCongintoId(congintoUserId: string): Promise<any> {
    try {
      const res: GraphQLResult<any> = await client.graphql({
        query: getUserByCognitoId,
        variables: {id: congintoUserId},
      });
      console.log('getUserByCongintoId from repository:', res);

      return res?.data?.getUserByCognitoId;
    } catch (error) {
      console.log('getUserByCongintoId Error: ', error);
      throw new Error(error);
    }
  }

  async checkEmail(userName: string): Promise<any> {
    try {
      const res: GraphQLResult<any> = await client.graphql({
        query: adminGetUser,
        variables: {userName},
      });
      console.log('checkEmail from repository:', res);

      return res?.data.adminGetUser;
    } catch (error) {
      console.log('checkEmail Error: ', error);
      throw new Error(error);
    }
  }

  async sendNotification(
    title: string,
    body: string,
    targetArn: string,
    conversationId: string,
  ): Promise<void> {
    try {
      await client.graphql({
        query: sendMessage,
        variables: {conversationId, title, body, targetArn},
      });
      console.log('sendNotification from repository:');
    } catch (error) {
      console.log('sendNotification Error: ', error);
      throw new Error(error);
    }
  }
}
