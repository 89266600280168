export const subscribeToNewUCs = /* GraphQL */ `
  subscription SubscribeToNewUCs {
    subscribeToNewUCs {
      createdAt
      updatedAt
      id
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
        userconversations {
          createdAt
          updatedAt
          id
          conversation {
            id
            name
            userconversations {
              id
              conversation {
                id
              }
              user {
                id
              }
            }
            studentconversations {
              id
              student {
                id
                name
              }
            }
          }
        }
        studentconversations {
          id
          student {
            id
            name
          }
        }
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
      }
    }
  }
`;

export const subscribeToNewMessage = /* GraphQL */ `
  subscription SubscribeToNewMessage($conversationId: ID!) {
    subscribeToNewMessage(conversationId: $conversationId) {
      content
      createdAt
      updatedAt
      id
      isSent
      senderType
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
      conversationId
    }
  }
`;

export const subscribeDeleteUCs = /* GraphQL */ `
  subscription SubscribeDeleteUCs($id: ID!) {
    subscribeDeleteUCs(id: $id) {
      createdAt
      updatedAt
      id
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
    }
  }
`;

export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: MessageInsert!) {
    createMessage(input: $input) {
      content
      createdAt
      updatedAt
      id
      isSent
      senderType
      student {
        id
        createdAt
        updatedAt
        schoolName
        name
        birthDate
        age
        targetAge
        gender
        notes
        facebookProfileUrl
        status
        assignedTo
        isSchoolVerfied
        placementStatus
        email
        userName
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
      conversationId
    }
  }
`;

export const deleteUserconversation = /* GraphQL */ `
  mutation DeleteUserconversation($id: ID!) {
    deleteUserconversation(id: $id) {
      createdAt
      updatedAt
      id
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation($input: ConversationInsert!) {
    createConversation(input: $input) {
      createdAt
      updatedAt
      id
      name
      isActive
      messages {
        content
        createdAt
        updatedAt
        id
        isSent
        senderType
        conversationId
      }
      group {
        id
        createdAt
        updatedAt
        startDate
        name
        division
        limit
        isGroupHasInstructor
        currentSessionNumber
        isActive
      }
      userconversations {
        createdAt
        updatedAt
        id
      }
      studentconversations {
        createdAt
        updatedAt
        id
      }
    }
  }
`;

export const createUserconversation = /* GraphQL */ `
  mutation CreateUserconversation($input: UserconversationInsert!) {
    createUserconversation(input: $input) {
      createdAt
      updatedAt
      id
      conversation {
        createdAt
        updatedAt
        id
        name
        isActive
      }
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
        firstName
        lastName
        deviceToken
        devicePlatform
        endPointArn
        email
        contactIDActiveCampaign
        partnerAppName
      }
    }
  }
`;
