import {Amplify} from 'aws-amplify';
import {getUrl} from 'aws-amplify/storage';
import awsconfig from '../amplifyconfiguration.json';
export const getFileUrlByKey = async (
  key: string,
  customBucketName?: string,
): Promise<any> => {
  if (customBucketName) {
    Amplify.configure({
      ...awsconfig,
      //@ts-ignore
      aws_user_files_s3_bucket: customBucketName,
    });
  }
  return await getUrl({
    path: key,
    options: {
      validateObjectExistence: true,
    },
  });
};
