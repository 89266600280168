import React, {useEffect, useState, useCallback} from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Spin,
} from 'antd';

import axios from 'axios';

import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

import {
  CampaignInsert,
  Segment,
  SegmentConnection,
  Topic,
  TopicConnection,
} from '../../graphql/API';
import {useHistory} from 'react-router-dom';
import {ChannelService} from './ChannelService';
import moment from 'moment-timezone';

const {RangePicker} = DatePicker;

const channelService = new ChannelService();

const CampaignFerquences = [
  'ONCE',
  'HOURLY',
  'DAILY',
  'WEEKLY',
  'MONTHLY',
  // 'EVENT',
  // 'IN_APP_EVENT',
];

export const ChannelsNotifications = () => {
  const history = useHistory();

  const [channels, setChannels] = useState<TopicConnection>();

  const [segments, setSegments] = useState<SegmentConnection>();
  const [selectedSegment, setSelectedSegment] = useState<Segment | undefined>();
  const [campaign, setCampaign] = useState<CampaignInsert>();

  const [title, setTitle] = useState<string>();
  const [body, setBody] = useState<string>();
  const [selectedChannel, setSelectedChannel] = useState<Topic | undefined>();
  const [isloading, setIsLoading] = useState<boolean>(true);

  const [isDirectChannel, setIsDirectChannel] = useState<boolean>(false);
  const [isCampaignComm, setIsCampaignComm] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    channelService
      .getTopicsList(channels?.nextToken, 100)
      .then((channls: TopicConnection) => {
        setChannels(channls);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsError(true);
        setIsLoading(false);

        console.log('Error ->', error);
      });

    channelService
      .getAllSegments(segments?.nextToken, 100)
      .then((sgems: SegmentConnection) => {
        setSegments(sgems);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsError(true);
        setIsLoading(false);

        console.log('Error ->', error);
      });
  }, []);

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Communication Channels</Title>
      <div>
        <Row style={{justifyContent: 'space-around'}}>
          <Col>
            <div style={{margin: 20}}>
              <Checkbox
                value={isDirectChannel}
                onChange={(e) => {
                  setIsDirectChannel(e.target.checked);
                }}>
                Direct Channel
              </Checkbox>
            </div>
          </Col>

          <Col>
            <div style={{margin: 20}}>
              <Checkbox
                value={isDirectChannel}
                onChange={(e) => {
                  setIsCampaignComm(e.target.checked);
                }}>
                Create Campaign
              </Checkbox>
            </div>
          </Col>
        </Row>
        <Row style={{display: 'flex', alignItems: 'center'}}>
          {isDirectChannel && (
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Select Channel
                </Text>
                <Select
                  value={selectedChannel?.id}
                  showSearch
                  style={{width: '100%'}}
                  placeholder="Select a Channel"
                  optionFilterProp="children"
                  onChange={(chnnelId) => {
                    let selectedChnnl = channels?.items?.find(
                      // @ts-ignore
                      (channl: Topic) => channl.id === chnnelId,
                    );
                    setSelectedChannel(selectedChnnl);
                  }}>
                  {channels?.items?.map((chnnl) => (
                    <Select.Option value={chnnl.id!}>
                      {chnnl.topicName}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Notfication Title
                </Text>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Input
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                    value={title}
                    placeholder="Notification Title"
                  />
                </div>
              </div>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Notfication Content
                </Text>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Input
                    onChange={(event) => {
                      setBody(event.target.value);
                    }}
                    value={body}
                    placeholder="Notification Content"
                  />
                </div>
              </div>
              <Row>
                <Col>
                  <div style={{margin: 20}}>
                    <Button
                      onClick={async () => {
                        if (title && body && selectedChannel?.topicArn) {
                          setIsLoading(true);
                          try {
                            await channelService.publishMessageInChannel(
                              title,
                              body,
                              selectedChannel.topicArn,
                            );
                            setTitle('');
                            setBody('');
                            setSelectedChannel(undefined);
                            alert('Notification published');
                          } catch (error) {
                            alert('Error happen');
                          }
                          setIsLoading(false);
                        }
                      }}
                      style={{
                        width: '100%',
                        height: 52,
                        borderRadius: 10,
                        backgroundColor: '#40B2F2',
                        marginBottom: '2.5%',
                      }}>
                      <Text
                        style={{
                          fontSize: 16,
                          color: 'white',
                          fontWeight: 'bold',
                        }}>
                        Send
                      </Text>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          )}

          {isCampaignComm && (
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Campaign Name
                </Text>

                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Input
                    maxLength={14}
                    onChange={(event) => {
                      setCampaign({
                        ...campaign,
                        timeZone: '',
                        campaignName: event.target.value,
                      });
                    }}
                    value={campaign?.campaignName}
                    placeholder="Campaign name"
                  />
                </div>
              </div>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Campaign Ferquency
                </Text>

                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Select
                    value={campaign?.frequency}
                    showSearch
                    style={{width: '100%'}}
                    placeholder="Select a frequency"
                    optionFilterProp="children"
                    onChange={(ferq) => {
                      setCampaign({
                        ...campaign,
                        frequency: ferq,
                      });
                    }}>
                    {CampaignFerquences?.map((fer) => (
                      <Select.Option value={fer!}>{fer}</Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Campaign Range in UTC Coordinated Universal Time
                </Text>
                <RangePicker
                  style={{marginInlineEnd: 30}}
                  showToday
                  value={[
                    campaign?.startDateTime
                      ? moment(campaign?.startDateTime).utc(false)
                      : null,
                    campaign?.endDateTime
                      ? moment(campaign?.endDateTime).utc(false)
                      : null,
                  ]}
                  // disabledDate={disabledDate}
                  // disabledTime={disabledRangeTime}
                  // separator={'TO'}
                  disabledDate={(current) => {
                    var a = moment(); //now
                    var b = moment(current, 'YYYY-MM-DD HH:mm:ss');

                    return b.isSame(a, 'date') ? false : a.isAfter(b);
                  }}
                  onChange={(e) => {
                    setCampaign({
                      ...campaign,
                      startDateTime:
                        e.length > 0 ? e[0].utc(false).toISOString() : null,
                      endDateTime:
                        e.length > 1 ? e[1].utc(false).toISOString() : null,
                    });
                  }}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment().add('20', 'm'),
                      moment().add(1, 'day'),
                    ],
                  }}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </div>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Select Segment
                </Text>

                <Select
                  value={selectedSegment?.segmantName}
                  showSearch
                  style={{width: '100%'}}
                  placeholder="Select a Segment"
                  optionFilterProp="children"
                  onChange={(segmId) => {
                    let selectedSegm = segments?.items?.find(
                      // @ts-ignore
                      (seg: Segment) => seg.id === segmId,
                    );
                    setSelectedSegment(selectedSegm);
                    console.log('selectedSegm', selectedSegm);
                    setCampaign({
                      ...campaign,
                      segmentId: selectedSegm.createdPinpointSegmentId,
                    });
                  }}>
                  {segments?.items?.map((segm) => (
                    <Select.Option value={segm.id!}>
                      {segm.segmantName}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Notfication Title
                </Text>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Input
                    onChange={(event) => {
                      setCampaign({
                        ...campaign,
                        campaignTitle: event.target.value,
                      });
                    }}
                    value={campaign?.campaignTitle}
                    placeholder="Notification Title"
                  />
                </div>
              </div>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Notfication Content
                </Text>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Input
                    onChange={(event) => {
                      setCampaign({
                        ...campaign,
                        campaignMessage: event.target.value,
                      });
                    }}
                    value={campaign?.campaignMessage}
                    placeholder="Notification Content"
                  />
                </div>
              </div>
              <Row>
                <Col>
                  <div style={{margin: 20}}>
                    <Button
                      onClick={async () => {
                        console.log('campaign.segmentId', campaign);
                        if (
                          campaign.campaignMessage &&
                          campaign.campaignTitle &&
                          campaign.segmentId &&
                          campaign.startDateTime &&
                          campaign.endDateTime &&
                          campaign.frequency &&
                          campaign.campaignName
                        ) {
                          setIsLoading(true);
                          try {
                            await channelService.createCampaign(campaign);
                            setCampaign(null);
                            setSelectedSegment(null);
                            alert('Campaign published');
                          } catch (error) {
                            alert('Error happen. while creating campaign ');
                          }
                          setIsLoading(false);
                        } else {
                          alert('Missing Data' + JSON.stringify(campaign));
                        }
                      }}
                      style={{
                        width: '100%',
                        height: 52,
                        borderRadius: 10,
                        backgroundColor: '#40B2F2',
                        marginBottom: '2.5%',
                      }}>
                      <Text
                        style={{
                          fontSize: 16,
                          color: 'white',
                          fontWeight: 'bold',
                        }}>
                        Send
                      </Text>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>

      {isloading && (
        <Spin
          size="large"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
    </div>
  );
};
