import {IPromoCodesService} from './IPromoCodeService';
import {PromoCodeRepository} from './PromoCodeRepository';
import {Promocode, PromocodeConnection} from '../../graphql/API';

export class PromoCodeService implements IPromoCodesService {
  promoCodeRepository: PromoCodeRepository;
  constructor() {
    this.promoCodeRepository = new PromoCodeRepository();
  }
  async createPromoCodes(
    numOfCodes: number,
    packageId: string,
    sessionPerWeek: number,
    schoolId: string | undefined,
    classId: string | undefined,
    levelId: string | undefined,
    curriculumId: string | undefined,
  ): Promise<any> {
    return await this.promoCodeRepository.createPromoCodes(
      numOfCodes,
      packageId,
      sessionPerWeek,
      schoolId,
      classId,
      levelId,
      curriculumId,
    );
  }

  async getPromoCodes(
    nextToken: string,
    searchValue?: string,
  ): Promise<PromocodeConnection> {
    return await this.promoCodeRepository.getPromoCodes(nextToken, searchValue);
  }
}
