export const createInstructor = /* GraphQL */ `
  mutation CreateInstructor($input: InstructorInsert!) {
    createInstructor(input: $input) {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        cognitoUserId
        country
        state
        whatsappNumber
        timeZone
      }
      name
      gender
      program {
        id
        createdAt
        updatedAt
        name
        name_i18n
        flag
        supportAi
        isActive
      }
      promo {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      rating
      sessionsCount
      speakingLanguages
    }
  }
`;

export const createAvailability = /* GraphQL */ `
  mutation CreateAvailability($input: AvailabilityInsert!) {
    createAvailability(input: $input) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const updateInstructor = /* GraphQL */ `
  mutation UpdateInstructor(
    $id: ID!
    $updatedAt: String!
    $input: InstructorUpdate!
  ) {
    updateInstructor(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const deleteAvailability = /* GraphQL */ `
  mutation DeleteAvailability($id: ID!) {
    deleteAvailability(id: $id) {
      id
    }
  }
`;

export const updateInstructorUser = /* GraphQL */ `
  mutation UpdateUser($id: ID!, $updatedAt: String!, $input: UserUpdate!) {
    updateUser(id: $id, updatedAt: $updatedAt, input: $input) {
      id
      createdAt
      updatedAt
      cognitoUserId
    }
  }
`;
