import {IPlansService} from './IPlansService';
import {PlansRepository} from './PlansRepository';
import {Plan, PlanInsert} from '../../graphql/API';

export class PlansService implements IPlansService {
  plansRepository: PlansRepository;
  constructor() {
    this.plansRepository = new PlansRepository();
  }

  async createPlan(plan: PlanInsert): Promise<Plan> {
    let createdPlan = await this.plansRepository.createPlan(plan);
    return createdPlan;
  }

  async getPlanById(planId: string): Promise<Plan> {
    let plan = await this.plansRepository.getPlanById(planId);
    return plan;
  }

  async updatePlan(
    planId: string,
    updatedAt: string,
    plan: string,
  ): Promise<any> {
    return await this.plansRepository.updatePlan(planId, updatedAt, plan);
  }
}
