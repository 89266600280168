import {useEffect, useState} from 'react';
import Title from 'antd/es/typography/Title';

import {
  Button,
  Checkbox,
  Col,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from 'antd';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {AssetType, Package, DivisionType, Program} from '../../graphql/API';
import {PackagesService} from './PackagesService';
import {
  EditAssetsBlock,
  InputI18nStringBlock,
  InputI18nStringObjectBlock,
  Loading,
} from '../../components';
import {ProgramsService} from '../Program';
import {cloneDeep} from 'lodash';

const packagesService = new PackagesService();
const programService = new ProgramsService();
export const PackageEdit = () => {
  const history = useHistory();

  const location: {
    state: any;
    hash: any;
    search: any;
    pathname: any;
  } = useLocation();

  const {Title, Text} = Typography;

  const [isError, setIsError] = useState<boolean>(false);

  let params: {packageId: string} = useParams();
  const [programPackage, setProgramPackage] = useState<Package>();
  const [programs, setPrograms] = useState<Array<Program> | []>();
  const [packagesList, setPackagesList] = useState<Array<Package> | []>();

  useEffect(() => {
    packagesService
      .getPackages()
      .then((e) => {
        setPackagesList(e);
      })
      .catch((err) => {
        //console.log('Packages fetching error ->', err);
      });
    programService
      .getPrograms()
      .then((e) => {
        //console.log('Programss->', e);
        setPrograms(e);
      })
      .catch((err) => {
        //console.log('Programs fetchign error ->', err);
      });
    /************ NEW */
    if (location.state?.package) {
      setProgramPackage(location.state.package);
    } else {
      packagesService
        .getPackageById(params.packageId)
        .then((pkg: Package) => {
          //console.log('Packageee,', pkg);
          setProgramPackage(pkg);
        })
        .catch((e) => {
          setIsError(true);
        });
    }
  }, []);

  const onChange = (
    prop: any,
    value: any,
    index?: number,
    isDelete?: boolean,
  ) => {
    if (prop === 'benefits') {
      if (programPackage?.benefits) {
        let benefitsObj;
        if (isDelete) {
          //console.log('in dete ->', value);
          benefitsObj = value;
        } else {
          benefitsObj = JSON.parse(programPackage?.benefits);
          //@ts-ignore
          benefitsObj[index] = value;
        }
        setProgramPackage({
          ...programPackage,
          [prop]: JSON.stringify(benefitsObj),
        });
      }
    } else if (prop.includes('.')) {
      let propList = prop.split('.');
      let p0 = propList[0];
      let p1 = propList[1];
      // @ts-ignore
      setProgramPackage({
        ...programPackage,
        // @ts-ignore
        [p0]: {...programPackage[p0], [p1]: value},
      });
    } else {
      // @ts-ignore
      setProgramPackage({...programPackage, [prop]: value});
    }
  };

  const onAddBenefits = () => {
    if (programPackage?.benefits) {
      let tempBen = JSON.parse(programPackage?.benefits);
      //console.log('tempBen on add ->', tempBen);
      tempBen.push('');
      onChange('benefits', '', tempBen.length !== 0 ? tempBen.length - 1 : 0);
    }
  };

  const onBenefitDelete = (index: number) => {
    if (programPackage?.benefits) {
      let tempBen = JSON.parse(programPackage?.benefits);
      //console.log('tempBen->', tempBen);
      delete tempBen[index];
      tempBen = tempBen?.filter((t: any) => t);
      //console.log('afet filter ->', tempBen);
      onChange('benefits', tempBen, 1, true);
    }
  };

  const onChangeNumberOfSessionsPerWeek = (value) => {
    //console.log('changed', value);

    let sessionsPerWeek: number[] = [1];
    for (let index = 2; index <= value; index++) {
      sessionsPerWeek.push(index);
    }
    //console.log('SASD', sessionsPerWeek);
    onChange('sessionPerWeek', sessionsPerWeek);
  };

  function toggleCheck(GroupDivision: DivisionType) {
    //console.log(`checked = ${GroupDivision}`);
    onChange('typePackage', GroupDivision);
  }

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Edit package </Title>
      {programPackage ? (
        <>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <InputI18nStringBlock
                  fieldName={'Name'}
                  stringifiedJson={programPackage?.name_i18n}
                  onChange={(e) => {
                    setProgramPackage({...programPackage, name_i18n: e});
                  }}
                />
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Program
                </Text>
                <Select
                  value={programPackage?.program.id}
                  showSearch
                  style={{width: '100%'}}
                  placeholder="Select a asset type"
                  optionFilterProp="children"
                  onChange={(e) => {
                    //console.log('proge->', e);
                    let selectedProgram = programs.find(
                      (prog: Program) => prog.id === e,
                    );
                    // @ts-ignore
                    onChange('program', selectedProgram);
                  }}>
                  {programs?.map((program) => {
                    return (
                      <Select.Option value={program.id}>
                        {JSON.parse(program.name_i18n)['en']}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Enrollment/exercise package (Only if this live package
                  contains exercise also)
                </Text>
                <Select
                  value={programPackage?.enrollmentPackage?.id}
                  showSearch
                  style={{width: '100%'}}
                  placeholder="Select a enrollment package"
                  optionFilterProp="children"
                  onChange={(e) => {
                    let selectedEnrollmentPackage = packagesList.find(
                      (pkg: Package) => pkg.id === e,
                    );
                    // @ts-ignore
                    onChange('enrollmentPackage', selectedEnrollmentPackage);
                  }}>
                  {packagesList
                    //@ts-ignore
                    ?.filter((p) => p.onlyAI)
                    .map((pkg) => {
                      return (
                        <Select.Option key={pkg.id} value={pkg.id}>
                          {JSON.parse(pkg.name_i18n)['en']}
                        </Select.Option>
                      );
                    })}
                </Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <InputI18nStringObjectBlock
                  fieldName={'Details'}
                  stringifiedJson={programPackage?.details_i18n}
                  onChange={(e) => {
                    setProgramPackage({...programPackage, details_i18n: e});
                  }}
                />
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <InputI18nStringObjectBlock
                  fieldName={'Benefits'}
                  stringifiedJson={programPackage?.benefits_i18n}
                  onChange={(e) => {
                    setProgramPackage({...programPackage, benefits_i18n: e});
                  }}
                />
              </div>
            </Col>
          </Row>

          {/************************************************************************************************************************/}
          <Row>
            <Col span={6}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Package Duration, day/month*
                </Text>
                <Input
                  placeholder="Only AI Package Duration in days"
                  value={programPackage?.packageDuration ?? ''}
                  onChange={(event) =>
                    onChange('packageDuration', parseInt(event.target.value))
                  }
                />
              </div>
            </Col>
            <Col span={6}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Discount Description
                </Text>
                <Input
                  placeholder="Enter discount percentage"
                  value={programPackage?.discountDescription ?? ''}
                  onChange={(event) =>
                    onChange('discountDescription', event.target.value)
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <div>
              <Text>Type of Package</Text>
              <div
                style={{
                  margin: 20,
                }}>
                <Checkbox
                  checked={
                    programPackage.typePackage === DivisionType.ONE_TO_ONE
                  }
                  onChange={() => toggleCheck(DivisionType.ONE_TO_ONE)}>
                  1to1
                </Checkbox>
                <Checkbox
                  checked={programPackage.typePackage === DivisionType.GROUP}
                  onChange={() => toggleCheck(DivisionType.GROUP)}>
                  Group
                </Checkbox>
                <Checkbox
                  checked={
                    programPackage.typePackage === DivisionType.VIDEO_SESSION
                  }
                  onChange={() => toggleCheck(DivisionType.VIDEO_SESSION)}>
                  Video Session
                </Checkbox>
              </div>
            </div>
          </Row>

          <Row>
            <Col span={7}>
              <div style={{margin: 20}}>
                <Checkbox
                  checked={programPackage?.onlyAI}
                  onChange={(event) =>
                    onChange('onlyAI', event.target.checked)
                  }>
                  Only ai
                </Checkbox>
              </div>
              <div style={{margin: 20}}>
                <Checkbox
                  checked={programPackage?.isNative}
                  onChange={(event) =>
                    onChange('isNative', event.target.checked)
                  }>
                  Is native ?
                </Checkbox>
              </div>
            </Col>
            <Col span={7}>
              <div style={{margin: 20}}>
                <Checkbox
                  checked={programPackage?.isFree}
                  onChange={(event) =>
                    onChange('isFree', event.target.checked)
                  }>
                  Free package
                </Checkbox>
              </div>
            </Col>
            <Col>
              <div style={{margin: 20}}>
                <Checkbox
                  checked={programPackage?.isActive}
                  onChange={(event) =>
                    onChange('isActive', event.target.checked)
                  }>
                  Active
                </Checkbox>
              </div>
            </Col>
            <Col span={6}>
              <div style={{margin: 20}}>
                <Checkbox
                  checked={programPackage?.isRecommended}
                  onChange={(event) =>
                    onChange('isRecommended', event.target.checked)
                  }>
                  is recommended
                </Checkbox>
              </div>
            </Col>
            <Col span={8}>
              <div style={{margin: 20}}>
                <Text>Allowed Max Number of Sessions Per Week</Text>
                <InputNumber
                  size="large"
                  min={1}
                  max={4}
                  defaultValue={1}
                  value={
                    programPackage?.sessionPerWeek
                      ? programPackage?.sessionPerWeek[
                          programPackage?.sessionPerWeek?.length - 1
                        ]
                      : undefined
                  }
                  onChange={onChangeNumberOfSessionsPerWeek}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <InputI18nStringBlock
                  fieldName={'Description'}
                  stringifiedJson={programPackage?.description_i18n}
                  onChange={(e) => {
                    onChange('description_i18n', e);
                  }}
                />
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Number of sessions*
                </Text>

                <InputNumber
                  size="large"
                  min={0}
                  max={100}
                  defaultValue={0}
                  value={programPackage.numberOfSessions ?? 0}
                  onChange={(value) => {
                    onChange('numberOfSessions', value);
                    //console.log('SADSAD', value);
                  }}
                />
              </div>
            </Col>
          </Row>
          {/************************************************************************************************************************/}
          <Row>
            <Col span={12}>
              <EditAssetsBlock
                assets={programPackage.activities}
                fieldNameSingular={'Activity'}
                fieldName={'Activities'}
                onChange={(e) => {
                  onChange('activities', e);
                }}
                defaultAssetType={AssetType.VIDEO}
              />
            </Col>

            <Col span={12}>
              <EditAssetsBlock
                fieldNameSingular={'Record'}
                fieldName={'Records'}
                assets={programPackage.records}
                onChange={(e) => {
                  onChange('records', e);
                }}
                defaultAssetType={AssetType.VIDEO}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Col span={12}>
                  <div style={{margin: 20}}>
                    <Text style={{display: 'block'}} strong={true}>
                      Promo type
                    </Text>
                    <Select
                      value={programPackage?.promoAsset?.type!}
                      showSearch
                      style={{width: '100%'}}
                      placeholder="Select a asset type"
                      optionFilterProp="children"
                      onChange={(e) =>
                        // @ts-ignore
                        onChange('promoAsset.type', e)
                      }>
                      <Select.Option value={AssetType.VIDEO}>
                        {AssetType.VIDEO}
                      </Select.Option>
                      <Select.Option value={AssetType.PHOTO}>
                        {AssetType.PHOTO}
                      </Select.Option>
                      <Select.Option value={AssetType.AUDIO}>
                        {AssetType.AUDIO}
                      </Select.Option>
                    </Select>
                    <Text style={{display: 'block'}} strong={true}>
                      Promo file
                    </Text>
                    <Input
                      value={programPackage?.promoAsset?.fileUri ?? ''}
                      onChange={(event) =>
                        onChange('promoAsset.fileUri', event.target.value)
                      }
                    />
                    <Text style={{display: 'block'}} strong={true}>
                      Promo thumbnailUri
                    </Text>
                    <Input
                      value={programPackage?.promoAsset?.thumbnailUri ?? ''}
                      onChange={(event) =>
                        onChange('promoAsset.thumbnailUri', event.target.value)
                      }
                    />
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Loading />
      )}
      <Button
        onClick={async () => {
          //console.log('onEdit programPackage->', programPackage);
          const temp = cloneDeep(programPackage);
          await packagesService
            .updatePackage(temp)
            .then((resp: any) => {
              //console.log('tmpe after!!->', resp);
              alert('Package edited');
              history.go(0);
            })
            .catch((err) => {
              alert('Package failed');
              //console.log('upadate errpr PACKAGE EDIT->', err);
            });
        }}>
        Edit
      </Button>

      {isError && <Title>Package fetching error ⚠️</Title>}
    </div>
  );
};
