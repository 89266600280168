import {generateClient, GraphQLResult} from 'aws-amplify/api';
import {ILevelsRepository} from './ILevelsRepository';
import {levels} from './LevelsQueries';
const client = generateClient();
export class LevelsRepository implements ILevelsRepository {
  async getLevels(): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: levels,
        variables: {},
      });
      //console.log('Levels->', response);

      return response.data?.levels.items;
    } catch (error) {
      //console.log('getLevels repository Error:', error);
      throw new Error(error);
    }
  }
}
