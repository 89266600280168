import {useEffect, useState} from 'react';
import {Button, Col, Input, Row, Typography} from 'antd';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {Curriculum} from '../../graphql/API';
import {CurriculumsService} from './CurriculumsService';
import {cloneDeep} from 'lodash';

const curriculumsService = new CurriculumsService();

export const CurriculumEdit = () => {
  const history = useHistory();
  const location: {
    state: any;
    hash: any;
    search: any;
    pathname: any;
  } = useLocation();

  const {Title, Text} = Typography;
  const [isError, setIsError] = useState<boolean>(false);

  let params: {curriculumId: string} = useParams();
  const [curriculum, setCurriculum] = useState<Curriculum>();

  useEffect(() => {}, []);

  const onChange = (prop: any, value: any) => {
    //@ts-ignore
    setCurriculum({...curriculum, [prop]: value});
  };
  //console.log('membership', curriculum);
  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Edit Curriculum </Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Curriculum Name
              </Text>
              <Input
                disabled
                onChange={(event) => onChange('name', event.target.value)}
                value={curriculum?.name}
              />
            </div>
          </Col>
        </Row>
      </>
      <Button
        onClick={async () => {
          //console.log('saved membership->', membership);
          // let tempMembership = cloneDeep(membership);
          // await membershipsService
          //   .updateMembership(
          //     membership.id,
          //     membership.updatedAt,
          //     tempMembership,
          //   )
          //   .then((e: any) => {
          //     alert('Membership edited');
          //     history.go(0);
          //   })
          //   .catch((err) => {
          //console.log('upadate errpr ->', err);
          //   });
        }}>
        Edit
      </Button>
    </div>
  );
};
