import {useEffect, useRef, useState} from 'react';
import Title from 'antd/es/typography/Title';
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import {useHistory} from 'react-router-dom';
import {
  Level,
  Package,
  Status,
  Student,
  StudentsConnection,
  Test,
} from '../../graphql/API';
import {StudentsService} from '../Students';
import {PackagesService} from '../Package';
import {LevelsService} from '../Level';
import {TestsService} from '../Test';
import {EnrollmentsService} from './EnrollmentsService';
import {SmileOutlined} from '@ant-design/icons';

const studentsService = new StudentsService();
const packagesService = new PackagesService();
const levelService = new LevelsService();
const testsService = new TestsService();
const enrollmentsService = new EnrollmentsService();

export const EnrollmentCreate = () => {
  const history = useHistory();
  const {Title, Text} = Typography;
  const [students, setStudents] = useState<StudentsConnection>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const Timeout = useRef<any>();
  const [searchBy, setSearchBy] = useState<number>(0);

  const [packages, setPackages] = useState<Array<Package> | []>([]);
  const [levels, setLevels] = useState<Array<Level> | []>([]);
  const [tests, setTests] = useState<Array<Test> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [enrollment, setEnrollment] = useState<{
    studentId: string;
    packageId: string;
    levelId: string;
    testId: string;
    placementStatus: Status;
    paymentStatus: Status;
    startDate: Date;
    selfRating: number;
  }>({
    studentId: '',
    packageId: '',
    levelId: '',
    testId: '',
    placementStatus: Status.TODO,
    paymentStatus: Status.TODO,
    startDate: new Date(),
    selfRating: 0,
  });
  useEffect(() => {
    testsService
      .getTests()
      .then((tsts) => {
        setTests(tsts);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
    levelService
      .getLevels()
      .then((lvls) => {
        setLevels(lvls);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });

    packagesService
      .getPackages()
      .then((pkgs) => {
        console.log('pkgs', pkgs);
        const exercisesPackages = pkgs.filter((pack: Package) => pack.onlyAI);
        setPackages(exercisesPackages);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
    studentsService
      .getStudents(null)
      .then((stds) => {
        const currentItems = students?.items ?? [];
        setStudents({
          ...students,
          ...stds,
          items: [...currentItems, ...stds.items],
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);

  const onChange = (prop: any, value: any) => {
    let tempEnrollment = enrollment;
    // @ts-ignore
    tempEnrollment[prop] = value;
    setEnrollment(tempEnrollment);
  };

  const onScroll = (event) => {
    var target = event.target;
    if (
      !isLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      setIsLoading(true);
      studentsService
        .getStudents(students?.nextToken)
        .then((stds) => {
          const currentItems = students?.items ?? [];
          setStudents({
            ...students,
            ...stds,
            items: [...currentItems, ...stds.items],
          });
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log('Error ->', error);
        });
    }
  };

  const customizeRenderEmpty = () => (
    <div style={{textAlign: 'center'}}>
      {isLoading ? (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      ) : (
        <>
          <SmileOutlined style={{fontSize: 20}} />
          <p>No Data</p>
        </>
      )}
    </div>
  );

  const searchStudents = (nextToken: string, textSearch?: string) => {
    const searchType = searchBy === 1 ? 'ID' : 'NAME';

    studentsService
      .getStudents(nextToken, searchType, textSearch)
      .then((stds) => {
        setStudents({
          ...stds,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('Error ->', error);
      });
  };

  function toggleCheck(numb: number) {
    console.log(`checked = `);
    setSearchBy(numb);
  }

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Create enrollment </Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Student
              </Text>
              <Checkbox
                checked={searchBy === 0}
                onChange={() => toggleCheck(0)}>
                SearchBy Name
              </Checkbox>
              <Checkbox
                checked={searchBy === 1}
                onChange={() => toggleCheck(1)}>
                SearchBy ID
              </Checkbox>
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Select
                  labelInValue
                  filterOption={false}
                  notFoundContent={isLoading ? <Spin size="small" /> : null}
                  showSearch
                  onSearch={(e) => {
                    setIsLoading(true);
                    if (Timeout.current) {
                      clearTimeout(Timeout.current);
                    }
                    Timeout.current = setTimeout(() => {
                      searchStudents(null, e);
                    }, 1100);
                  }}
                  style={{width: '100%'}}
                  placeholder="Select a student"
                  optionFilterProp="children"
                  onPopupScroll={onScroll}
                  loading={isLoading}
                  onChange={(studentId: any) => {
                    searchStudents(null);
                    console.log('sessionDuration', studentId.value);
                    onChange('studentId', studentId.value);
                  }}>
                  {students?.items?.map((std) => (
                    <Select.Option value={std.id!}>{std.name}</Select.Option>
                  ))}
                </Select>
              </ConfigProvider>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Package
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                showSearch
                style={{width: '100%'}}
                placeholder="Select a package"
                optionFilterProp="children"
                onChange={(packageId) => onChange('packageId', packageId)}>
                {packages?.map((pkg) => (
                  <Select.Option value={pkg.id!}>
                    {JSON.parse(pkg.name_i18n)['en']}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Level
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                showSearch
                style={{width: '100%'}}
                placeholder="Select a level"
                optionFilterProp="children"
                onChange={(levelId) => onChange('levelId', levelId)}>
                {levels?.map((lvl) => (
                  <Select.Option value={lvl.id!}>{lvl.name}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Test
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                showSearch
                style={{width: '100%'}}
                placeholder="Select a test"
                optionFilterProp="children"
                onChange={(testId) => onChange('testId', testId)}>
                {tests?.map((tst) => {
                  if (tst.type === 'PLACEMENT_TEST') {
                    return (
                      <Select.Option value={tst.id!}>{tst.name}</Select.Option>
                    );
                  }
                })}
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          {/* <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Placement status
              </Text> */}
          {/* TODO: Add parent name after fixing user/parent bug */}
          {/* <Select
                showSearch
                style={{width: '100%'}}
                placeholder="Select a status"
                optionFilterProp="children"
                onChange={(placementStatus) =>
                  onChange('placementStatus', placementStatus)
                }>
                <Select.Option value={Status.TODO}>TODO</Select.Option>
                <Select.Option value={Status.DOING}>DOING</Select.Option>
                <Select.Option value={Status.DONE}>DONE</Select.Option>
              </Select>
            </div>
          </Col> */}

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Payment status
              </Text>
              {/* TODO: Add parent name after fixing user/parent bug */}
              <Select
                showSearch
                style={{width: '100%'}}
                placeholder="Select a status"
                optionFilterProp="children"
                onChange={(paymentStatus) =>
                  onChange('paymentStatus', paymentStatus)
                }>
                <Select.Option value={Status.TODO}>TODO</Select.Option>
                <Select.Option value={Status.DOING}>DOING</Select.Option>
                <Select.Option value={Status.DONE}>DONE</Select.Option>
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Start Date
              </Text>

              <Space direction="vertical">
                <DatePicker
                  onChange={(date, dateString) => {
                    onChange('startDate', dateString);
                  }}
                />
              </Space>
            </div>
          </Col>

          <Col span={12}></Col>
        </Row>
      </>
      <Button
        onClick={async () => {
          console.log('enr->', enrollment);
          if (
            enrollment.studentId &&
            enrollment.levelId &&
            enrollment.paymentStatus &&
            enrollment.startDate &&
            enrollment.packageId
          ) {
            const selectedStudent = await studentsService.getStudentById(
              enrollment.studentId,
            );

            const selectedRecentlyEnrollment =
              selectedStudent?.enrollments?.reduce(function (a, b) {
                return a.updatedAt > b.updatedAt ? a : b;
              });
            console.log(
              'selectedRecentlyEnrollment',
              selectedRecentlyEnrollment,
            );
            if (selectedRecentlyEnrollment?.package?.packageDuration >= 90) {
              alert(
                'Cannot Update Enrollment Student Has Already Enrollment this Year, Must be completed',
              );
            } else {
              enrollmentsService.createEnrollment(enrollment).then(() => {
                alert('Enrollment created');
                history.push('/enrollments');
              });
            }
          } else {
            alert('Data missing');
          }
        }}>
        Create
      </Button>
    </div>
  );
};
