import {useCallback, useEffect, useRef, useState} from 'react';
import Title from 'antd/es/typography/Title';
import moment from 'moment';
import {Button, Col, Input, Row, Select, Spin, Typography} from 'antd';
import {useHistory} from 'react-router-dom';

import {SchoolService} from './SchoolService';
import {AdminGetUserResponse, SchoolInsert, User} from '../../graphql/API';
import {AccountService} from '../Accounts/AccountService';
import axios from 'axios';
import CountriesWithCurrency from 'iso-country-currency';
import {SignUpOutput} from 'aws-amplify/auth';

const schoolsService = new SchoolService();
const accountService = new AccountService();

export const SchoolCreate = () => {
  const history = useHistory();
  const Timeout = useRef<any>();

  const [avaliableTimeZones, setAvaliableTimeZones] = useState<
    Array<string> | []
  >([]);

  const {Title, Text} = Typography;
  const [createSchool, setCreateSchool] = useState<SchoolInsert>();
  const [schoolEmail, setSchoolEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [schoolUser, setSchoolUser] = useState<User>();
  const [countries, setCountries] = useState<Array<any>>([]);

  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isEmailExist, setIsEmailExist] = useState<boolean>(true);

  const [userEmailInfo, setUserEmailInfo] =
    useState<AdminGetUserResponse>(null);
  const [isEmailChecked, setIsEmailChecked] = useState<boolean>(false);

  const getAvaiableTimezone = useCallback(async () => {
    // axios
    // .get('http://worldtimeapi.org/api/timezone/' + value)
    // .then((response) => {
    //   //console.log(response);
    // });

    axios
      .get('https://worldtimeapi.org/api/timezone/')
      .then((response: any) => {
        //console.log(response);
        setAvaliableTimeZones(response.data);
      });
  }, []);

  useEffect(() => {
    getAvaiableTimezone();

    setCountries(CountriesWithCurrency.getAllISOCodes());
  }, [getAvaiableTimezone]);

  const onChange = (prop: any, value: any) => {
    //console.log('createSchool', createSchool);
    //@ts-ignore
    setCreateSchool({...createSchool, [prop]: value});
  };

  const onCheckEmail = async () => {
    if (schoolEmail && !isLoading) {
      try {
        setIsLoading(true);
        const user: AdminGetUserResponse = await accountService.checkEmail(
          schoolEmail,
        );
        setUserEmailInfo(user);
        setIsEmailChecked(true);
        setIsEmailExist(true);
        setIsLoading(false);
        alert('User Exist ');
      } catch (error: any) {
        alert(error.errors[0].message + ', Create New User');
        setIsEmailChecked(true);
        setIsEmailExist(false);
        setIsLoading(false);
        setUserEmailInfo(null);
      }
    }
  };

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Create new School </Title>
      <>
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Email
              </Text>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <Input
                  style={{backgroundColor: isEmailChecked ? 'gray' : 'white'}}
                  onPressEnter={onCheckEmail}
                  onChange={(event) => {
                    if (isEmailChecked) {
                      setIsEmailChecked(false);
                    }
                    setSchoolEmail(event.target.value);
                  }}
                  value={schoolEmail}
                />

                {!isEmailChecked && (
                  <Button onClick={onCheckEmail}>Check Email</Button>
                )}
              </div>
            </div>
          </Col>
          {!isEmailExist && isEmailChecked && (
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Password
                </Text>
                <Input
                  type="password"
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                />
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={9}>
            <div style={{paddingInline: 20}}>
              <Input
                placeholder={'add school name english'}
                onChange={(e) => onChange('name', e.target.value)}
                value={createSchool?.name}
              />
            </div>
          </Col>
          <Col span={9}>
            <div style={{paddingInline: 20}}>
              <Input
                placeholder={'Add school name arabic'}
                onChange={(e) => onChange('nameArabic', e.target.value)}
                value={createSchool?.nameArabic}
              />
            </div>
          </Col>
          <Row>
            <Col span={9}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Phone Number
                </Text>
                <Input
                  type={'number'}
                  onChange={(event) =>
                    setSchoolUser({
                      ...schoolUser,
                      whatsappNumber: event.target.value,
                    })
                  }
                  value={schoolUser?.whatsappNumber}
                />
              </div>
            </Col>
            <Col span={9}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  TimeZone
                </Text>
                <Select
                  //@ts-ignore
                  value={schoolUser?.timeZone}
                  showSearch
                  style={{width: '100%'}}
                  placeholder="Select TimeZone"
                  optionFilterProp="children"
                  onChange={(value) => {
                    setSchoolUser({
                      ...schoolUser,
                      timeZone: value,
                    });
                  }}>
                  {avaliableTimeZones?.map((timeZone) => (
                    <Select.Option value={timeZone}>{timeZone}</Select.Option>
                  ))}
                </Select>
              </div>
            </Col>

            <Col span={9}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Country
                </Text>

                <Select
                  showSearch
                  style={{width: '100%'}}
                  placeholder="Select a country"
                  optionFilterProp="children"
                  value={schoolUser?.country}
                  onChange={(c) => {
                    setSchoolUser({
                      ...schoolUser,
                      country: c,
                    });
                  }}>
                  {countries?.map((country) => {
                    return (
                      <Select.Option value={country.iso}>
                        {country.countryName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
          </Row>
        </Row>
      </>
      <Button
        style={{margin: 30}}
        onClick={async () => {
          //console.log('INCREATEEET');
          if (
            createSchool.name &&
            createSchool.nameArabic &&
            schoolUser.country &&
            schoolUser.timeZone &&
            schoolUser.whatsappNumber
          ) {
            //console.log('SCHOOLUSER', schoolUser);
            try {
              setIsLoading(true);
              let schoolUserResp: User | null = null;
              if (!isEmailExist) {
                const conginitoUser: SignUpOutput =
                  await schoolsService.signUpSchool(schoolEmail, password);
                const cognitoUserId: string = conginitoUser.userId;
                //console.log('cognitoUserId', cognitoUserId);
                schoolUserResp = await schoolsService.createUser(
                  cognitoUserId,
                  schoolUser.country,
                  schoolUser.whatsappNumber,
                  schoolUser.timeZone,
                );
                //console.log('schoolUser', schoolUser);
              } else {
                schoolUserResp = await accountService.getUserByCongintoId(
                  userEmailInfo.Username,
                );
              }
              createSchool.userId = schoolUserResp.id;
              createSchool.isActive = true;
              await schoolsService.createSchool(createSchool);
              alert('School Created');
              history.goBack();

              setIsLoading(false);
            } catch (e) {
              setIsLoading(false);
              //console.log('upadate errpr ->', e);
              alert('School failed' + e);
            }
          }
        }}>
        Create
      </Button>

      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
    </div>
  );
};
