import {IInstructorsService} from './IInstructorsService';
import {InstructorsRepository} from './InstructorsRepository';
import {
  AvailabilityInsert,
  Instructor,
  InstructorInsert,
  InstructorUpdate,
  InstructorsConnection,
  Session,
  SessionsConnection,
  Status,
  UserUpdate,
} from '../../graphql/API';
import moment from 'moment-timezone';

export class InstructorService implements IInstructorsService {
  InstructorRepository: InstructorsRepository;
  constructor() {
    this.InstructorRepository = new InstructorsRepository();
  }
  async deleteAvailability(id: string): Promise<any> {
    return await this.InstructorRepository.deleteAvailability(id);
  }
  async createAvailability(availability: AvailabilityInsert): Promise<any> {
    return await this.InstructorRepository.createAvailability(availability);
  }
  async createUser(
    cognitoUserId: string,
    country: string,
    whatsappNumber: string,
    timestamp: string,
  ): Promise<any> {
    let user = await this.InstructorRepository.createUser(
      cognitoUserId,
      country,
      whatsappNumber,
      timestamp,
    );
    return user;
  }

  async signUpInstructor(email: string, password: string): Promise<any> {
    return await this.InstructorRepository.signUpInstructor(email, password);
  }

  async getInstructors(
    isActiveInstructors: boolean | null,
    nextToken: string | undefined,
  ): Promise<InstructorsConnection> {
    let instructors = await this.InstructorRepository.getInstructors(
      isActiveInstructors,
      nextToken,
    );
    return instructors;
  }

  async getInstructorById(instructorId: string): Promise<Instructor> {
    let instructor = await this.InstructorRepository.getInstructorById(
      instructorId,
    );
    return instructor;
  }

  async createInstructor(instructor: InstructorInsert): Promise<Instructor> {
    return await this.InstructorRepository.createInstructor(instructor);
  }

  async updateInstructor(
    instructorId: string,
    updatedAt: string,
    instructor: InstructorUpdate,
  ): Promise<any> {
    return await this.InstructorRepository.updateInstructor(
      instructorId,
      updatedAt,
      instructor,
    );
  }

  async updateInstructorUser(
    userId: string,
    updatedAt: string,
    user: UserUpdate,
  ): Promise<any> {
    return await this.InstructorRepository.updateInstructorUser(
      userId,
      updatedAt,
      user,
    );
  }
  async getSessionsByInstructorIdFromTo(
    instructorId: string,
    periodRange: number,
  ): Promise<any> {
    const startOfMonth = moment()
      .subtract(periodRange, 'M')
      .startOf('month')
      .format('YYYY-MM-DD');

    const endOfMonth = moment()
      .subtract(periodRange, 'M')
      .endOf('month')
      .format('YYYY-MM-DD');

    let start_time = '00:00:00';
    let end_time = '23:59:59';

    let startDateTime = moment(startOfMonth + ' ' + start_time)
      .utc(true)
      .set('D', 1)
      .toISOString();

    let endDateTime = moment(endOfMonth + ' ' + end_time)
      .utc(true)
      .toISOString();

    const status: Status = Status.DONE;
    let sessionsConnection: SessionsConnection | undefined = undefined;
    let totalSessions: Session[] = [];
    do {
      sessionsConnection =
        await this.InstructorRepository.getSessionsByInstructorIdFromTo(
          instructorId,
          startDateTime,
          endDateTime,
          status,
          sessionsConnection?.nextToken,
        );
      //console.log('sessionsConnection', sessionsConnection);
      sessionsConnection?.items?.forEach((session: Session) => {
        totalSessions.push(session);
      });
    } while (sessionsConnection?.nextToken);

    return totalSessions;
  }
}
