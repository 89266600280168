import React, {useEffect, useState} from 'react';
import {getFileUrlByKey} from '../../utils';
import {Player} from 'video-react';

type VideoIntroCardProps = {
  thumbnailUri: string | undefined;
  fileUri: string | undefined;
  style?: any;
};

export default function VideoIntroCard({
  thumbnailUri,
  style,
  fileUri,
}: VideoIntroCardProps) {
  const [img, setImg] = useState<string | undefined>(thumbnailUri);
  const [fileVideoUrl, setFileVideoUrl] = useState<string | undefined>(fileUri);

  const fetchVideoUrl = async () => {
    if (thumbnailUri) {
      const pathContent = thumbnailUri.split('/');
      const bucketName = pathContent[3];
      let uri = thumbnailUri;
      //console.log('bucketName', bucketName);
      //console.log('proPicPath', pathContent);

      try {
        const pathVideo = thumbnailUri?.split('.pikado.io/');
        //console.log('thumbnailUri00', pathVideo);

        const videoProfileUrl = await getFileUrlByKey(pathVideo[1], bucketName);
        uri = videoProfileUrl.url.toJSON();
        setImg(uri);
        // //console.log('uri33', uri);
      } catch (error) {
        console.error('ERRRR:->', error);
      }

      if (fileUri) {
        const pathVideo = fileUri?.split('.pikado.io/');
        let uri = fileUri;
        const videoProfileUrl = await getFileUrlByKey(pathVideo[1], bucketName);

        uri = videoProfileUrl.url.toJSON();

        setFileVideoUrl(uri);
      }
    }
  };

  useEffect(() => {
    fetchVideoUrl();
  }, []);

  return (
    <>
      <Player playsInline poster={img} src={fileVideoUrl} />
    </>
  );
}
