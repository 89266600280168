import {IUserRepository} from './IUserRepository';
import {updateUser} from './UserMutation';
import {UserUpdate} from '../../graphql/API';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
export class UserRepository implements IUserRepository {
  async updateUser(
    userId: string,
    updatedAt: string,
    user: UserUpdate,
  ): Promise<any> {
    try {
      const response: GraphQLResult<any> = await client.graphql({
        query: updateUser,
        variables: {
          id: userId,
          updatedAt: updatedAt,
          input: user,
        },
      });

      return response;
    } catch (error) {
      console.log('update user Error: ', error);
      throw new Error(error);
    }
  }
}
