import {useEffect, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import {Button, Spin, Typography, Collapse} from 'antd';

import {Plan, Question, Test} from '../../graphql/API';
import {PlansService} from './PlansService';
import Title from 'antd/es/typography/Title';

const plansService = new PlansService();

export const PlanDetails = () => {
  // 4fe2f51f-302e-40d1-ad9a-5d28d8e3fd69
  const [plan, setPlan] = useState<Plan | undefined>(undefined);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let params: {planId: string} = useParams();

  const {Panel} = Collapse;
  const {Title, Text} = Typography;

  useEffect(() => {
    plansService
      .getPlanById(params.planId)
      .then((resp) => {
        setIsLoading(false);
        setPlan(resp);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
        console.log('getPlanById Error ->', error);
      });
  }, []);
  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Plan </Title>
      <Button>
        <Link
          // @ts-ignore
          to={{
            pathname: '/plans/' + plan?.id + '/edit',
            state: {plan: plan},
          }}>
          Edit
        </Link>
      </Button>
      {!isError && !isLoading && (
        <>
          <Collapse defaultActiveKey={0}>
            {plan &&
                  Array.from({ length: 365 }, (_, i) => i + 1).map((i) => (
                    <Panel header={`d${i}`} key={i}>
                      {/* @ts-ignore */}
                      {plan[`d${i}`]?.map((test: Test) => {
                        return (
                          <Collapse defaultActiveKey={['1']}>
                            <Panel
                              key={test?.id!}
                              header={'Test: ' + test?.name}>
                              <Text style={{display: 'block'}}>
                                <Text style={{fontWeight: 'bold'}}>
                                  Description:
                                </Text>
                                {test?.description}
                              </Text>
                              <Text style={{display: 'block'}}>
                                <Text style={{fontWeight: 'bold'}}>
                                  Summary:
                                </Text>{' '}
                                {test?.summary}
                              </Text>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{display: 'block'}}
                                href={test?.asset?.thumbnailUri!}>
                                Thumbnail url
                              </a>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{display: 'block'}}
                                href={test?.asset?.fileUri!}>
                                Content url
                              </a>
                              <Collapse defaultActiveKey={['1']}>
                                {test?.questions?.map(
                                  (question: Question | null) => {
                                    return (
                                      <Panel
                                        key={question?.id!}
                                        header={'question: ' + question?.name}>
                                        <Text style={{display: 'block'}}>
                                          <Text style={{fontWeight: 'bold'}}>
                                            Text:{' '}
                                          </Text>
                                          {question?.text}
                                        </Text>
                                        <Text style={{display: 'block'}}>
                                          <Text style={{fontWeight: 'bold'}}>
                                            Summary:{' '}
                                          </Text>
                                          {question?.summary}
                                        </Text>
                                        <Text style={{display: 'block'}}>
                                          <Text style={{fontWeight: 'bold'}}>
                                            Description:{' '}
                                          </Text>
                                          {question?.description}
                                        </Text>
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{display: 'block'}}
                                          href={question?.asset?.thumbnailUri!}>
                                          Thumbnail url
                                        </a>
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{display: 'block'}}
                                          href={question?.asset?.fileUri!}>
                                          Content url
                                        </a>
                                        {question?.correctAsset?.fileUri && (
                                          <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{display: 'block'}}
                                            href={
                                              question?.correctAsset?.fileUri
                                            }>
                                            Correct answer
                                          </a>
                                        )}
                                      </Panel>
                                    );
                                  },
                                )}
                              </Collapse>
                            </Panel>
                          </Collapse>
                        );
                      })}
                    </Panel>
                    ))
              }
          </Collapse>
        </>
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Plan fetching error ⚠️</Title>}
    </div>
  );
};
