export const programs = /* GraphQL */ `
  query Programs($filter: ProgramsFilter, $limit: Int, $nextToken: String) {
    programs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        courses {
          id
          name
        }
        packages {
          id
          name_i18n
        }
        name
        name_i18n
        flag
        supportAi
        courses {
          id
          name
        }
        isActive
        instructors {
          id
          name
          user {
            id
            timeZone
          }
          availabilities {
            id
            createdAt
            updatedAt
            startTime
            endTime
            day
            slots {
              id
              name
              startTime
              endTime
              status
              limit
              current
              day
            }
          }
        }
      }
      nextToken
      scannedCount
    }
  }
`;

export const program = /* GraphQL */ `
  query Program($id: String!) {
    program(id: $id) {
      id
      createdAt
      updatedAt
      name
      name_i18n
      packages {
        id
        createdAt
        updatedAt
        number
        onlyAI
        name
        name_i18n
        sessionDuration
        description
        benefits
        benefits_i18n
        details
        details_i18n
        isFree
        isActive
      }
      test {
        id
        createdAt
        updatedAt
        type
        name
        name_i18n
        description
        description_i18n
        summary
        summary_i18n
      }
      courses {
        id
        createdAt
        updatedAt
        name
      }
      activities {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      records {
        id
        createdAt
        updatedAt
        type
        thumbnailUri
        fileUri
        title_i18n
        subTitle_i18n
        position
      }
      flag
      supportAi
      isActive
      instructors {
        id
        name
        user {
          id
          timeZone
        }
        availabilities {
          id
          createdAt
          updatedAt
          startTime
          endTime
          day
          slots {
            id
            name
            startTime
            endTime
            status
            limit
            current
            day
          }
        }
      }
    }
  }
`;
