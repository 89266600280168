import {IAssetsRepository} from './IAssetsRepository';
import {createAsset, updateAsset} from './AssetsMutations';
import {Asset, AssetInsert} from '../../graphql/API';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();

export class AssetsRepository implements IAssetsRepository {
  async createAsset(asset: AssetInsert): Promise<any> {
    try {
      const createAssetResponse: GraphQLResult<any> = await client.graphql({
        query: createAsset,
        variables: {input: asset},
      });
      console.log('createAssetResponse from repository:', createAssetResponse);

      return createAssetResponse.data?.createAsset.id;
    } catch (error) {
      console.log('Create asset Error: ', error);
      throw new Error(error);
    }
  }

  async updateAsset(
    assetId: string,
    updatedAt: string,
    asset: any,
  ): Promise<any> {
    try {
      const updateAssetResponse: GraphQLResult<any> = await client.graphql({
        query: updateAsset,
        variables: {id: assetId, updatedAt: updatedAt, input: asset},
      });
      console.log('updateAssetResponse from repository:', updateAssetResponse);

      return updateAssetResponse;
    } catch (error) {
      console.log('update asset Error: ', error);
      throw new Error(error);
    }
  }
}
