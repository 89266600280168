import {Button, Image, Input, Popconfirm, Spin} from 'antd';
import {createRef, memo, useCallback, useEffect, useRef, useState} from 'react';
import {
  Conversation,
  Gender,
  Message,
  SenderType,
  Student,
  User,
  Userconversation,
} from '../graphql/API';
import {useDispatch, useSelector} from 'react-redux';
import {
  createUserMessage,
  fetchMessagesBygroupConversationId,
} from '../redux/actions/user';

import {
  CloseOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import ProfileStudent from '../assets/icons/child.png';

import UserProfile from '../assets/icons/pikdoIcon.png';
import {useHistory} from 'react-router-dom';
import moment from 'moment-timezone';

type ConversationProps = {
  conversation: Conversation;
  index: number;
  onClose: (convIndex: number) => void;
};

export const GroupConversation = ({
  conversation,
  index,
  onClose,
}: ConversationProps) => {
  const dispatch = useDispatch();
  //@ts-ignore
  const textInput = useRef<Input>();
  const groupConversations: Array<Conversation> = useSelector(
    (state: any) => state.user.groupConversations,
  );
  const [inputComment, setInputComment] = useState<string>('');

  const [conversationIndex, setConversationIndex] = useState<number>(
    groupConversations.findIndex(
      (conver: Conversation) => conver.id === conversation.id,
    ),
  );

  const history = useHistory();

  const [currentConversation, setCurrentConversation] =
    useState<Conversation>(conversation);

  const isLoading: boolean = useSelector((state: any) => state.user.isLoading);
  const messagesEndRef = createRef<any>();

  // useEffect(() => {
  //   if (currentConversation?.id) {
  //     const nextToken = null;
  //     dispatch(
  //       fetchMessagesBygroupConversationId(currentConversation?.id, nextToken),
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const scrollToBottom = useCallback(() => {
    // messagesEndRef.current.scrollIntoView({behavior: 'smooth'});
  }, [messagesEndRef]);

  useEffect(() => {
    scrollToBottom();
    if (groupConversations) {
      const newConversationIndex = groupConversations.findIndex(
        (convers: Conversation) => convers.id === conversation?.id,
      );
      setConversationIndex(newConversationIndex);
      setCurrentConversation(groupConversations[newConversationIndex]);
    }
  }, [scrollToBottom, conversation, groupConversations]);

  const handleSentMessage = async (content: string) => {
    if (content !== '') {
      console.log(
        'currentConversation?.studentconversations',
        currentConversation?.studentconversations,
      );
      setInputComment('');
      dispatch(
        createUserMessage(
          content,
          currentConversation?.id,
          currentConversation?.studentconversations[0]?.student?.user
            .endPointArn,
        ),
      );
    }
  };

  const onScroll = useCallback(
    (event) => {
      var target = event.target;

      if (
        Math.abs(target.scrollTop) + target.offsetHeight ===
        target.scrollHeight
      ) {
        //@ts-ignore
        const isNextTokenNull = groupConversations[conversationIndex]?.nextToken
          ? false
          : true;

        if (!isNextTokenNull) {
          dispatch(fetchMessagesBygroupConversationId(currentConversation?.id));
        }
      }
    },
    [conversationIndex, currentConversation?.id, dispatch, groupConversations],
  );

  return (
    <div
      style={{
        display: 'flex',
        width: '20.5rem',
        height: '25.5rem',
        marginInlineStart: 15,
        background: '#ffffff',
        boxShadow: '0px 0px 10px 0px #00000026',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        paddingInline: 10,
        paddingBottom: 8,
        paddingTop: 8,
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: '#fff',
        overflow: 'hidden',
      }}
      key={conversation?.id}>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          padding: 5,
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <span
          onClick={() => {
            history.replace(`/group/` + conversation.group?.id + `/edit`);
          }}
          className="nav-text"
          style={{
            display: 'flex',
            textAlign: 'left',
            fontWeight: 'bold',
            fontSize: 11,
            marginInlineEnd: 3,
            flexDirection: 'column',
          }}>
          Conversation ID:
          <a>{currentConversation?.id}</a>
        </span>

        <CloseOutlined
          onClick={() => {
            onClose(index);
          }}
        />
      </div>
      {currentConversation ? (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}>
              <span className="nav-text" style={{fontWeight: 'bold'}}>
                {currentConversation?.name}
              </span>
            </div>
            <div
              style={{
                backgroundColor: '#E6E7E8',
                height: 1,
                width: '100%',
                marginTop: 6,
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
              flex: 1,
              paddingBottom: 15,
              width: '100%',
              alignItems: 'flex-start',
              overflowY: 'scroll',
              height: '100%',
            }}
            onScroll={onScroll}>
            {isLoading && (
              <Spin
                size="large"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            )}
            {groupConversations[conversationIndex]?.messages?.map(
              (messg: Message) => (
                <MessageLabel
                  createdAt={messg?.createdAt ?? ''}
                  sender={messg.student ?? messg.user}
                  content={messg?.content ?? ''}
                  senderType={messg?.senderType ?? SenderType.STUDENT}
                  studentName={
                    messg?.senderType === SenderType.STUDENT
                      ? messg?.student?.name
                      : messg?.user?.admin?.firstName
                  }
                  studentGender={messg.student?.gender ?? Gender.NONE}
                />
              ),
            )}
            <div ref={messagesEndRef} />
          </div>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <Input
              ref={textInput}
              placeholder={'Add a new Message'}
              onChange={(e) => setInputComment(e.target.value)}
              value={inputComment}
            />
            <Button onClick={() => handleSentMessage(inputComment)}>
              <Text>Send</Text>
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};

type MessageLabelProps = {
  content: string;
  senderType: SenderType;
  studentName: string | null;
  studentGender: Gender;
  sender: Student | User;
  createdAt: string;
};

const MessageLabel = memo(
  ({
    studentName,
    content,
    senderType,
    studentGender,
    sender,
    createdAt,
  }: MessageLabelProps) => {
    const isSenderStudent: boolean =
      senderType === SenderType.STUDENT ? true : false;
    const history = useHistory();

    const showStudentDetails = () => {
      senderType === SenderType.STUDENT &&
        history.replace(`/students/` + sender.id + `/edit`);
    };

    return (
      <div style={styles.mainContainer}>
        <div
          style={{
            display: 'flex',
            justifyContent: isSenderStudent ? 'flex-start' : 'flex-end',
            alignItems: 'center',
          }}>
          {!isSenderStudent ? (
            <Image
              preview={false}
              src={UserProfile}
              alt=""
              style={styles.profileImg}
            />
          ) : (
            <>
              {studentGender === Gender.MALE ? (
                <Image
                  src={ProfileStudent}
                  alt=""
                  preview={false}
                  style={styles.profileImg}
                  onClick={showStudentDetails}
                />
              ) : (
                <a>
                  <img
                    src={ProfileStudent}
                    alt=""
                    preview={false}
                    style={styles.profileImg}
                    onClick={showStudentDetails}
                  />
                </a>
              )}
            </>
          )}
          <Text style={styles.studentNameTxt}>{studentName}</Text>
        </div>
        <div
          style={
            (styles.containerContent,
            {
              display: 'flex',
              justifyContent: isSenderStudent ? 'flex-start' : 'flex-end',
            })
          }>
          <Text style={styles.messageTxt}>{content}</Text>
        </div>
        <Text
          style={{
            display: 'flex',
            justifyContent: isSenderStudent ? 'flex-start' : 'flex-end',
            fontSize: 12,
            fontFamily: 'monospace',
          }}>
          {moment
            .tz(createdAt, 'YYYY-MM-DD HH:mm', 'UTC')
            .clone()
            .tz('Africa/Cairo')
            .format('YYYY-MM-DD hh:mm A')}
        </Text>
      </div>
    );
  },
);

const styles = {
  mainContainer: {
    alignSelf: 'center',
    marginBottom: 28,
    width: '100%',
  },

  studentNameTxt: {
    marginStart: 10,
    marginInlineEnd: 10,
    color: '#40B2F2',
    fontFamily: 'bold',
    fontSize: 14,
  },
  messageTxt: {
    fontFamily: 'bold',
  },
  containerContent: {
    marginTop: 10,
  },
  profileImg: {
    width: 19,
    height: 19,
    resizeMode: 'contain',
    marginInlineEnd: 10,
  },
};
